import clsx from "clsx";
import React, { useState, MouseEvent, useEffect } from "react";
import styled from "styled-components";

import { theme, spacing } from "../theme";

export type TOption = {
  label: string;
  value: string;
  selected?: boolean;
};

interface Props {
  options: TOption[];
  name: string;
  placeholder?: string;
  preLabel?: string;
  onSelect: (value: TOption) => void;
}
export const Dropdown: React.FC<Props> = ({
  options,
  name,
  placeholder,
  preLabel,
  onSelect,
}) => {
  const defaultValue = placeholder ? placeholder : "—";
  const [expanded, setExpanded] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<TOption>({
    label: defaultValue,
    value: "",
  });

  const onOpenHandler = (evt: MouseEvent) => {
    evt.preventDefault();
    setExpanded(true);
  };

  const onCloseHandler = () => {
    setExpanded(false);
  };

  const onSelectHandler = (value: string | number) => {
    options.map((option) => delete option.selected);

    const current = options.filter((option) => option.value === value)[0];
    current.selected = true;
    setCurrentValue(current);
    onSelect(current);
    setExpanded(false);
  };

  useEffect(() => {
    options.forEach((option) => {
      if (option.selected) {
        setCurrentValue(option);
      }
    });
  }, [options]);

  return (
    <>
      <DropdownStyled aria-haspopup="listbox" aria-expanded={expanded}>
        <SelectedStyled
          id={`${name}-selector`}
          className={clsx(expanded && "expanded")}
          onClick={onOpenHandler}
        >
          <span>{preLabel}</span>
          {currentValue && currentValue.label}
          <IconStyled
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M23.9999 7.11139L12.1234 19.1447L9.9841 17.0333L21.8606 5.00001L23.9999 7.11139Z" />
            <path d="M2.10583 5.11707L14.1697 16.9626L12.0638 19.1073L-3.30736e-05 7.26174L2.10583 5.11707Z" />
          </IconStyled>
        </SelectedStyled>
        <ListStyled
          className={clsx(expanded && "expanded")}
          aria-labelledby={`${name}-selector`}
          role="menu"
          tabIndex={-1}
          aria-activedescendant={`${name}-${currentValue.value}`}
        >
          {options.map((option: TOption, index: number) => (
            <ListItemStyled
              id={`${name}-${option.value}`}
              className={clsx(option.selected && "selected")}
              onClick={() => onSelectHandler(option.value)}
              role="option"
              aria-selected={option.selected}
              tabIndex={0}
              key={index}
            >
              {option.label}
            </ListItemStyled>
          ))}
        </ListStyled>
      </DropdownStyled>
      <MaskStyled
        className={clsx(expanded && "expanded")}
        onClick={onCloseHandler}
      />
    </>
  );
};

const MaskStyled = styled.div`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.expanded {
    display: block;
  }
`;

const DropdownStyled = styled.div`
  position: relative;
  width: 100%;
  border-radius: 83px;
  background: ${theme.colors.accent.secondary};
  color: ${theme.colors.base.secondary};
  font-size: ${theme.font.size.md};
  font-weight: ${theme.font.weight.black};
  z-index: 1;
`;

const SelectedStyled = styled.button`
  position: relative;
  width: 100%;
  padding: ${spacing(0.7)} ${spacing(3)} ${spacing(0.7)} ${spacing(1.5)};
  border: 0;
  background-color: transparent;
  overflow-x: hidden;
  color: ${theme.colors.base.secondary};
  font-size: ${theme.font.size.md};
  font-weight: ${theme.font.weight.black};
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background ${theme.transition.fast};
  z-index: 3;
  border-radius: 83px;

  &.expanded {
    background-color: ${theme.colors.base.dark};
    color: ${theme.colors.base.light};
  }
`;

const IconStyled = styled.svg`
  position: absolute;
  top: 50%;
  right: ${theme.spacing[1]};
  transform: translateY(-50%);
`;

const ListStyled = styled.ul`
  display: none;
  position: absolute;
  top: 40px;
  left: -0;
  right: -0;
  height: 0;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${theme.colors.base.secondary};
  background-color: ${theme.colors.accent.secondary};
  border-radius: 15px;
  border: solid 1px ${theme.colors.base.dark};

  &.expanded {
    display: block;
    height: auto;
  }
`;

const ListItemStyled = styled.li`
  padding: ${spacing(0.7)} ${spacing(1.6)};
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${theme.colors.accent.third};
  }

  &.selected {
    color: ${theme.colors.base.secondary};
  }
`;
