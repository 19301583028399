import { TCategoriesTree, TCategory } from "../../types/Category";
import { stringify } from "querystring";
import { useQuery } from "react-query";
import axios from "redaxios";
import config from "../../config";

type Params = Record<string, string | number | boolean | null | undefined>;

const apiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/categories`;

export const useCategories = (locale?: string) => {
  const token = localStorage.getItem("token");
  const params: Params = {};

  if (locale) {
    params.locale = locale;
  }

  return useQuery<TCategory[]>(["useCategories"], () =>
    axios
      .get(`${apiUrl}?${stringify(params)}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data.payload)
  );
};

/**
 * Get categories tree
 * @returns A payload containing a list of all categories
 */
export const useCategoriesTree = () => {
  return useQuery<TCategoriesTree>(["useCategoriesTree"], () =>
    axios
      .get(`${apiUrl}/tree`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data.payload)
  );
};
