import React from "react";

const VegetablesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="69"
      height="70"
      viewBox="0 0 69 70"
      fill="none"
    >
      <path
        d="M60.2184 54.0625C65.3423 54.0625 67.5815 50.6519 67.4301 45.3287C67.1751 36.4064 59.3683 30.985 50.438 30.985C41.5077 30.985 33.6983 36.4064 33.446 45.3287C33.2919 50.6492 35.4966 54.0625 40.6179 54.0625H60.2184Z"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.813 54.0625C54.8634 59.1386 60.2688 62.7458 56.6855 66.5681C55.4743 67.8591 53.8513 67.875 50.353 67.875H50.5151C47.0221 67.875 45.3991 67.8591 44.1879 66.5681C40.6046 62.7458 46.0127 59.1386 44.0604 54.0625"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.8755 41.3125C40.8755 41.3125 41.8928 39.0866 44.063 38.125"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.563 12.625V3.0625C35.563 2.47547 35.0875 2 34.5005 2H28.6753C27.913 2 27.3977 2.78094 27.6978 3.48219L30.2505 9.4375C30.2505 9.4375 21.7505 7.3125 12.188 11.5625C12.188 11.5625 19.1553 11.5625 24.4174 14.7155C25.3524 15.2759 25.6631 16.4872 25.1771 17.462L21.7744 24.2673C21.19 25.4361 22.5341 26.6234 23.6231 25.8983L33.3291 19.4277C34.6944 18.5166 36.4661 18.4741 37.8739 19.3187L43.731 22.833C44.5756 23.3403 45.5983 22.5222 45.2849 21.5872L43.2635 15.5203C43.1121 15.0661 43.2847 14.5667 43.6831 14.3011L46.196 12.625L51.5085 9.4375C51.5085 9.4375 45.1335 7.3125 40.8835 8.375"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.2914 27.5C58.6059 20.9842 53.2563 15.6239 46.4297 12.4816"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.063 18.0119C4.79564 22.9685 1.56299 29.6782 1.56299 37.0625C1.56299 52.32 15.3596 64.6875 32.3755 64.6875C33.8178 64.6875 35.2363 64.5999 36.6255 64.4272"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VegetablesIcon;
