import React from "react";
import nl2br from "react-nl2br";
import styled from "styled-components";
import { Heading } from "../../../components/Heading";
import { Progress } from "../../../components/Progress";
import { theme } from "../../../components/theme";

interface Props {
  current: number;
  title?: string;
}

export const SignUpProgress: React.FC<Props> = ({ current, title }) => {
  return (
    <Container>
      {title && (
        <HeadingStyled element="h1" size="xl" center>
          {nl2br(title)}
        </HeadingStyled>
      )}
      <Progress
        center
        current={current}
        steps={[
          { index: 1 },
          { index: 2 },
          { index: 3 },
          { index: 4 },
          { index: 5 },
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeadingStyled = styled(Heading)`
  max-width: ${theme.breakpoints.sm};
  margin-bottom: ${theme.spacing[1]};
  font-size: ${theme.font.size.xl};
  line-height: ${theme.font.size.xxl};

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-bottom: ${theme.spacing[3]};
    font-size: ${theme.font.size.xxxl};
    line-height: ${theme.font.size.bigxl};
  }
`;
