import React from "react";
import styled, { css } from "styled-components";

import { theme } from "../theme";

type HeadingTags = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div" | "span";
type TextSizes = "xxs" | "xs" | "sm" | "md" | "mdx" | "lg" | "xl";

interface Props {
  size?: TextSizes;
  center?: boolean;
  element?: HeadingTags;
  children?: React.ReactNode;
  className?: string;
}

const Heading: React.FC<Props> = ({
  size = "md",
  center = false,
  element: Element = "h2",
  children,
  className,
}) => {
  return <Element className={className}>{children}</Element>;
};

export const HeadingStyled = styled(Heading)`
  margin-bottom: ${theme.spacing[1]};
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.black};
  letter-spacing: 1px;
  color: ${theme.colors.base.secondary};

  ${(props) =>
    props.size === "xs" &&
    css`
      margin-bottom: ${theme.spacing[0.5]};
      font-size: ${theme.font.size.sm};

      @media (min-width: ${theme.breakpoints.sm}) {
        font-size: ${theme.font.size.md};
      }
    `}

  ${(props) =>
    props.size === "sm" &&
    css`
      font-size: ${theme.font.size.mdx};
    `}

  ${(props) =>
    props.size === "mdx" &&
    css`
      font-size: ${theme.font.size.mdx};

      @media (min-width: ${theme.breakpoints.sm}) {
        font-size: ${theme.font.size.lg};
        line-height: ${theme.font.size.xl};
      }
    `}


  ${(props) =>
    props.size === "lg" &&
    css`
      font-size: ${theme.font.size.lg};

      @media (min-width: ${theme.breakpoints.sm}) {
        font-size: ${theme.font.size.xxl};
      }
    `}

  ${(props) =>
    props.size === "xl" &&
    css`
      font-size: ${theme.font.size.xxl};

      @media (min-width: ${theme.breakpoints.sm}) {
        font-size: ${theme.font.size.xxxl};
        line-height: ${theme.font.size.bigxl};
      }
    `}

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}
`;

export const HeadingMemoized = React.memo(HeadingStyled);
