import React from "react";

const ShippingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79"
      height="68"
      viewBox="0 0 79 68"
      fill="none"
    >
      <path
        d="M31.6075 47.422L29.8323 44.3491"
        stroke="#10594D"
        strokeWidth="3.26472"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.8942 39.2583L21.3237 29.6131L26.382 28.5786L29.8059 24.716L34.8641 23.6815L38.288 19.8189L43.3463 18.7844L46.7702 14.9219L51.8284 13.8873L55.2523 10.0248L76.473 46.7794L42.5464 66.3677L34.5234 52.4743"
        stroke="#10594D"
        strokeWidth="3.26472"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.8254 16.8215L17.8611 3.70137C13.3415 0.20608 9.13209 1.74662 7.40995 3.87277C5.68781 5.99891 5.35318 10.5348 9.87073 14.0301L23.4458 24.5303"
        stroke="#10594D"
        strokeWidth="3.26472"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.449 10.8634C18.6465 8.02309 19.2811 4.79918 17.861 3.70142"
        stroke="#10594D"
        strokeWidth="3.26472"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.1968 16.8562C26.3943 14.0159 27.0289 10.792 25.6088 9.69421"
        stroke="#10594D"
        strokeWidth="3.26472"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5271 39.2603H41.7037"
        stroke="#10594D"
        strokeWidth="3.26472"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.1742 47.4221H15.5859"
        stroke="#10594D"
        strokeWidth="3.26472"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.7477 57.2162H5.79175"
        stroke="#10594D"
        strokeWidth="3.26472"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.6009 9.87781L41.3835 3.32593L33.2217 8.223"
        stroke="#10594D"
        strokeWidth="3.26472"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShippingIcon;
