import React from "react";
import styled from "styled-components";

import { Info } from "./Info";
import { Legal } from "./Legal";
import { Navigation } from "./Navigation";
import { Newsletter } from "./Newsletter";
import { Section } from "../../components/Section";
import { theme } from "../theme";

interface Props {
  className?: string;
}

export const Footer: React.FC<Props> = () => {
  return (
    <SectionStyled backgroundColor={theme.colors.base.third}>
      <FooterStyled data-footer>
        <Info />

        <NavigationSection>
          <Navigation />
        </NavigationSection>

        <NewsletterSection>
          <Newsletter />
        </NewsletterSection>
      </FooterStyled>

      <LegalSection>
        <Legal />
      </LegalSection>
    </SectionStyled>
  );
};

export const FooterMemoized = React.memo(Footer);

const SectionStyled = styled(Section)`
  padding-top: ${theme.spacing[3]};
  padding-bottom: ${theme.spacing[2]};
`;

const FooterStyled = styled.footer`
  display: grid;
  grid-template-areas:
    "newsletter newsletter"
    "info navigation"
    "legal legal";
  grid-gap: ${theme.spacing[2]};

  @media (min-width: ${theme.breakpoints.sm}) {
    grid-gap: ${theme.spacing[1]};
    grid-template-areas:
      "info navigation"
      "newsletter newsletter"
      "legal legal legal";
  }

  @media (min-width: ${theme.breakpoints.md}) {
    grid-template-areas:
      "info navigation newsletter"
      "legal legal legal";
  }
`;

const NavigationSection = styled.div`
  grid-area: navigation;
  flex: 1;
  padding-top: 0;
  text-align: right;

  @media (min-width: ${theme.breakpoints.sm}) {
    min-width: 240px;
    padding-top: ${theme.spacing[3]};
    text-align: left;
  }
`;

const NewsletterSection = styled.div`
  grid-area: newsletter;
  max-width: 480px;

`;

const LegalSection = styled.div`
  display: flex;
  grid-area: legal;
  flex-direction: column;
  padding-top: ${theme.spacing[2]};
  border-top: 1px solid ${theme.colors.base.dark};

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;
