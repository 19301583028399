import React, { useState } from "react";
import GooglePlacesAutocomplete, {
  getLatLng,
} from "react-google-places-autocomplete";

import { useTranslation } from "react-i18next";
import { TGeolocation } from "../../../../types/Geolocation";

interface Props {
  defaultValue?: string;
  setValue: (value: any) => void;
  setLatLng: (value: TGeolocation) => void;
  locale?: string;
  region?: string;
}

export const AddressAutocomplete: React.FC<Props> = ({
  defaultValue,
  setValue,
  setLatLng,
  locale,
  region,
}) => {
  const { t } = useTranslation();
  const [text, setText] = useState<string>();
  const onSelect = (place: any) => {
    new google.maps.Geocoder().geocode(
      { placeId: place.value.place_id },
      (response, status) => {
        if (status === "OK" && response) {
          getLatLng(response[0]).then((latLng) => {
            setText(place.label);
            setLatLng(latLng);
            setValue(response[0]?.address_components);
          });
        }
      }
    );
  };

  return (
    <>
      <GooglePlacesAutocomplete
        apiOptions={{
          language: locale?.slice(0, 2),
          region: region,
        }}
        debounce={500}
        selectProps={{
          onChange: onSelect,
          value: text,
          placeholder: `${t("AddressAutocomplete.placeholder")}`,
        }}
      />
    </>
  );
};
