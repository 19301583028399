import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AlternateLayout } from "../../components/AlternateLayout";
import { CollectPoints } from "../../components/CollectPoints";
import { Hero } from "../../components/Hero";
import { OurStory } from "../../components/OurStory";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { Testimonials } from "../../components/Testimonials";
import { theme } from "../../components/theme";

export const About: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page centerX centerY backgroundColor={theme.colors.accent.primary}>
      {/* <FlashBanner times={10} type="warning">
        {t("Announcement")}
      </FlashBanner> */}
      <SectionStyled size="md">
        <Hero
          image="/common/images/hero-about-us"
          imageAlt={t("About.Hero.imageAlt")}
          title={t("About.Hero.heading")}
          subtitle={t("Home.Hero.content")}
        />
      </SectionStyled>
      <AlternateLayout />

      <OurStory />
      <CollectPoints />
    </Page>
  );
};

const SectionStyled = styled(Section)`
  padding-bottom: 0;
`;
