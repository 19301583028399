import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import styled from "styled-components";
import { spacing, theme } from "../../components/theme";
import { useAppState } from "../../contexts";
import { TInitialUser } from "../../types/User";
import { AlertBanner } from "../AlertBanner";
import { Form } from "../Form";
import { FormCheckbox } from "../FormCheckbox";
import { FormControl } from "../FormControl";
import { FormInput } from "../FormInput";
import { FormInputControl } from "../FormInputControl";
import { FormLabel } from "../FormLabel";
import { FormRadio } from "../FormRadio";
import { FormSection } from "../FormSection";
import { FormSelect } from "../FormSelect";
import { Loading } from "../Loading";
import { useLocation } from "react-router-dom";

interface Props {
  inputs: Partial<TInitialUser>;
  newsletter: string;
  isSignup?: boolean;
  showPassword: boolean;
  isLoading: boolean;
  setValue: boolean;
  disableEmailAndZip: boolean;
  onInputChange: (key: string) => (value: string) => void;
  onNewsletterChange: (checked: boolean) => void;
  setIsValidPhone: (isValid: boolean) => void;
  onSubmit: () => void;
}

export const UserInfoForm: React.FC<Props> = ({
  inputs,
  newsletter,
  showPassword,
  isSignup = false,
  isLoading,
  setValue,
  disableEmailAndZip,
  onInputChange,
  onNewsletterChange,
  setIsValidPhone,
  onSubmit,
  children,
}) => {
  const { t } = useTranslation();
  const methods = useForm();
  const { errors } = methods;
  const { errors: appErrors } = useAppState();
  const { search } = useLocation();

  let params = new URLSearchParams(search);
  let corpo = params.get("corpo");

  const onPhoneChange = (value: string) => {
    setIsValidPhone(value.length >= 11);
    onInputChange("phone")(value);
  };

  return (
    <>
      <Form useForm={methods} onSubmit={onSubmit}>
        <FormSectionStyled>
          {appErrors && (
            <FormControl>
              {appErrors.map((error: any, i: number) => (
                <AlertBanner type="error" key={i}>
                  {error.code} : {error.message}
                </AlertBanner>
              ))}
            </FormControl>
          )}

          <FormControl columns={2}>
            <FormInputControl
              errors={
                errors.firstName && [
                  t(`Signup.step2.firstName.errors.${errors.firstName.type}`),
                ]
              }
            >
              <FormLabel target="firstName">
                {t("Signup.step2.firstName.label")}
              </FormLabel>
              <FormInput
                id="firstName"
                type="text"
                name="firstName"
                placeholder={t("Signup.step2.firstName.placeholder")}
                error={errors.firstName}
                defaultValue={inputs.first_name}
                value={setValue ? inputs.first_name : undefined}
                onChange={onInputChange("first_name")}
                register={methods.register}
                validation={{
                  required: true,
                }}
              />
            </FormInputControl>

            <FormInputControl
              errors={
                errors.lastName && [
                  t(`Signup.step2.lastName.errors.${errors.lastName.type}`),
                ]
              }
            >
              <FormLabel target="lastName">
                {t("Signup.step2.lastName.label")}
              </FormLabel>
              <FormInput
                id="lastName"
                type="text"
                name="lastName"
                placeholder={t("Signup.step2.lastName.placeholder")}
                error={errors.lastName}
                defaultValue={inputs.last_name}
                value={setValue ? inputs.last_name : undefined}
                onChange={onInputChange("last_name")}
                register={methods.register}
                validation={{
                  required: true,
                }}
              />
            </FormInputControl>
          </FormControl>

          <FormControl widthRatio={4}>
            <FormInputControl
              errors={
                errors.email && [
                  t(`Signup.step2.email.errors.${errors.email.type}`),
                ]
              }
            >
              <FormLabel target="email">
                {t("Signup.step2.email.label")}
              </FormLabel>
              <FormInput
                id="email"
                type="email"
                name="email"
                placeholder={t("Signup.step2.email.placeholder")}
                error={errors.email}
                defaultValue={inputs.email}
                value={setValue ? inputs.email : undefined}
                onChange={onInputChange("email")}
                disabled={disableEmailAndZip}
                register={methods.register}
                validation={{
                  required: true,
                }}
              />
            </FormInputControl>
            {corpo && (
              <FormInputControl
                errors={
                  errors.email && [
                    t(`Signup.step2.company.errors.${errors.email.type}`),
                  ]
                }
              >
                <FormLabel target="email">
                  {t("Signup.step2.company.label")}
                </FormLabel>
                <FormInput
                  id="company_name"
                  type="text"
                  name="company_name"
                  placeholder={t("Signup.step2.company.placeholder")}
                  error={errors.company_name}
                  defaultValue={inputs.company_name}
                  value={setValue ? inputs.company_name : undefined}
                  onChange={onInputChange("company_name")}
                  register={methods.register}
                  validation={{
                    required: true,
                  }}
                />
              </FormInputControl>
            )}
            {/* <FormInputControl
              errors={
                errors.email && [
                  t(`Signup.step2.email.errors.${errors.email.type}`),
                ]
              }
            >
              <FormLabel target="email">
                {t("Signup.step2.email.label")}
              </FormLabel>
              <FormInput
                id="email"
                type="email"
                name="email"
                placeholder={t("Signup.step2.emailconfirmation.placeholder")}
                error={errors.confirmation_email}
                defaultValue={inputs.confirmation_email}
                value={setValue ? inputs.confirmation_email : undefined}
                onChange={onInputChange("confirmation_email")}
                disabled={disableEmailAndZip}
                register={methods.register}
                validation={{
                  required: true,
                }}
              />
            </FormInputControl> */}
          </FormControl>
          {showPassword && (
            <>
              <FormControl columns={1}>
                <FormInputControl
                  errors={
                    errors.password && [
                      t(`Signup.step2.password.errors.${errors.password.type}`),
                    ]
                  }
                >
                  <FormLabel target="password">
                    {t("Signup.step2.password.label")}
                  </FormLabel>
                  <FormInput
                    id="password"
                    type="password"
                    name="password"
                    placeholder={t("Signup.step2.password.placeholder")}
                    error={errors.password}
                    defaultValue={inputs.password}
                    onChange={onInputChange("password")}
                    register={methods.register}
                    validation={{
                      required: true,
                    }}
                  />
                </FormInputControl>

                {/* <FormInputControl
                  errors={
                    errors.passwordConfirmation && [
                      t(
                        `Signup.step2.passwordConfirmation.errors.${errors.passwordConfirmation.type}`
                      ),
                    ]
                  }
                >
                  <FormLabel target="passwordConfirmation">
                    {t("Signup.step2.passwordConfirmation.label")}
                  </FormLabel>
                  <FormInput
                    id="passwordConfirmation"
                    type="password"
                    name="passwordConfirmation"
                    placeholder={t(
                      "Signup.step2.passwordConfirmation.placeholder"
                    )}
                    error={errors.passwordConfirmation}
                    defaultValue={inputs.password_confirmation}
                    onChange={onInputChange("password_confirmation")}
                    register={methods.register}
                    validation={{
                      required: true,
                    }}
                  />
                </FormInputControl> */}
              </FormControl>
              <SeparatorStyled />
            </>
          )}
          <FormControl columns={2}>
            {/* <FormControl widthRatio={2}>
              <FormInputControl
                widthRatio={2}
                errors={
                  errors.zip_code && [
                    t(`Signup.step2.zipCode.errors.${errors.zip_code.type}`),
                  ]
                }
              >
                <FormLabel target="zip_code">
                  {t("Signup.step2.zipCode.label")}
                </FormLabel>
                <FormInput
                  id="zip_code"
                  type="text"
                  name="zip_code"
                  placeholder={t("Signup.step2.zipCode.placeholder")}
                  error={errors.zip_code}
                  defaultValue={inputs.zip_code}
                  value={setValue ? inputs.zip_code : undefined}
                  onChange={onInputChange("zip_code")}
                  disabled={disableEmailAndZip}
                  register={methods.register}
                  validation={{
                    validate: {
                      checkPostal: (value: string) => {
                        const regex = new RegExp(
                          /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i
                        );
                        if (regex.test(value)) return true;
                        else return false;
                      },
                    },
                  }}
                />
              </FormInputControl>
            </FormControl> */}
            <FormInputControl
              errors={
                errors.phone && [
                  t(`Signup.step2.phone.errors.${errors.phone.type}`),
                ]
              }
            >
              <FormLabel target="phone">
                {t("Signup.step2.phone.label")}
              </FormLabel>
              <PhoneInput
                preferredCountries={["ca"]}
                onlyCountries={["ca", "us", "fr"]}
                country={"ca"}
                inputProps={{
                  name: "phone",
                  required: true,
                }}
                isValid={(value) => {
                  if (
                    (value.startsWith("1") || value.startsWith("33")) &&
                    value.length >= 11
                  )
                    return true;
                  return false;
                }}
                value={inputs.phone}
                onChange={onPhoneChange}
              />
            </FormInputControl>

            <FormInputControl
              errors={
                errors.referral && [
                  t(`Signup.step2.referral.errors.${errors.referral.type}`),
                ]
              }
            >
              <FormLabel target="referral">
                {t("Signup.step2.referral.label")}
              </FormLabel>
              <FormSelect
                id="referral"
                name="referral"
                options={[
                  {
                    label: t("Signup.step2.referral.options.opt2"),
                    value: "facebook",
                  },
                  {
                    label: t("Signup.step2.referral.options.opt1"),
                    value: "friend",
                  },
                  {
                    label: t("Signup.step2.referral.options.opt3"),
                    value: "google",
                  },
                  {
                    label: t("Signup.step2.referral.options.opt4"),
                    value: "papers",
                  },
                  {
                    label: t("Signup.step2.referral.options.opt5"),
                    value: "Instagram",
                  },
                  {
                    label: t("Signup.step2.referral.options.opt6"),
                    value: "trucks",
                  },
                  {
                    label: t("Signup.step2.referral.options.opt7"),
                    value: "pickup-point",
                  },
                  {
                    label: t("Signup.step2.referral.options.opt8"),
                    value: "tv",
                  },
                  {
                    label: t("Signup.step2.referral.options.opt9"),
                    value: "tiktok",
                  },

                  {
                    label: t("Signup.step2.referral.options.opt10"),
                    value: "radio",
                  },
                ]}
                placeholder={t("Signup.step2.referral.options.placeholder")}
                error={errors.referral}
                defaultValue={"DEFAULT"}
                onChange={onInputChange("referral")}
                register={methods.register}
                validation={{
                  validate: {
                    checkReferral: (value: string) => {
                      if (value === "DEFAULT") return false;
                      else return true;
                    },
                  },
                }}
              />
            </FormInputControl>
          </FormControl>

          <SeparatorStyled />
          {isSignup && (
            <FormControl>
              <FormControl columns={1}>
                <FormInputControl
                  errors={
                    errors.newsletter && [
                      t(
                        `Signup.step2.newsletter.errors.${errors.newsletter.type}`
                      ),
                    ]
                  }
                >
                  <FormCheckbox
                    id="newsletter"
                    name="newsletter"
                    label={t("Signup.step2.newsletter")}
                    isChecked={newsletter === "add"}
                    onChange={onNewsletterChange}
                  />
                </FormInputControl>
              </FormControl>
            </FormControl>
          )}
          {isLoading && <Loading />}
        </FormSectionStyled>
        <BottomSectionStyled>{children}</BottomSectionStyled>
      </Form>
    </>
  );
};

const FormSectionStyled = styled(FormSection)`
  position: relative;
  padding: ${theme.spacing[1.5]} ${theme.spacing[1.5]};

  .react-tel-input {
    input {
      width: 100%;
      height: auto;
      padding: ${spacing(0.8)} ${theme.spacing[0.5]} ${spacing(0.8)} 48px;
      border: 0;
      box-shadow: inset 0 0 0 2px ${theme.colors.base.light};
      border-radius: 3px;
      background-color: ${theme.colors.base.light};
      font-size: ${theme.font.size.md};
    }
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[3]} ${theme.spacing[6]} ${theme.spacing[3]}
      ${theme.spacing[6]};
  }
`;

const SeparatorStyled = styled.hr`
  height: 2px;
  margin-top: ${theme.spacing[2]};
  margin-bottom: ${theme.spacing[1]};
  border: 0;
  background-color: ${theme.colors.accent.third};
`;

const BottomSectionStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;
