import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { Heading } from "../../components/Heading";
import { theme } from "../../components/theme";
import { useAppState } from "../../contexts";
import { formatNumber } from "../../utils/i18n";

interface Props {
  lookup_key: string;
  isActive: boolean;
  onSelect: (lookup_key: string) => void;
}
export const Card: React.FC<Props> = ({
  isActive = true,
  lookup_key,
  onSelect,
}) => {
  const { locale } = useAppState();
  const { t } = useTranslation();
  const name =
    lookup_key === "yearly-prime"
      ? `${t("Prime.yearly")}`
      : `${t("Prime.monthly")}`;

  const price = lookup_key === "yearly-prime" ? 9900 : 900;
  const freq =
    lookup_key === "yearly-prime"
      ? `${t("Prime.freq.yearly")}`
      : `${t("Prime.freq.monthly")}`;

  return (
    <CardStyled onClick={() => onSelect(lookup_key)} isActive={isActive}>
      <Heading element="h1">{name}</Heading>
      <RowWrapper>
        <RedHeading>
          {`${formatNumber(locale, price / 100)}$`} + taxes / {freq}
        </RedHeading>
      </RowWrapper>
    </CardStyled>
  );
};

const RedHeading = styled(Heading)`
  color: ${theme.colors.base.primary};
`;
const CardStyled = styled.div<Pick<Props, "isActive">>`
  padding: ${theme.spacing[2]};
  background: ${theme.colors.base.background};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  ${(props) =>
    props.isActive &&
    css`
      border: 2px solid ${theme.colors.base.secondary};
      box-sizing: border-box;
      /* box-shadow: 0px 4px 26px rgba(238, 83, 64, 0.7); */
      border-radius: 8px;
    `}
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
