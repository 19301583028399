import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { icons } from "../../assets/icons";
import { AlternateSection } from "../../components/AlternateSection";
import { Button } from "../../components/Button";
import { FlashBannerReactivate } from "../../components/FlashBannerReactivate";
import { Heading } from "../../components/Heading";
import { Label } from "../../components/Label";
import { Page } from "../../components/Page";
import { Snackbar } from "../../components/Snackbar";
import { theme } from "../../components/theme";
import { UserInfoForm } from "../../components/UserInfoForm";
import { WithAuth } from "../../components/WithAuth";
import { useAppState } from "../../contexts";
import { useSubscription } from "../../hooks/Subscriptions";
import { useSignupNewsletter, useUpdateUser, useUser } from "../../hooks/Users";
import { TInitialUser } from "../../types/User";
import { Billing } from "./Billing";
import { Password } from "./Password";

interface LocationState {
  prevPath?: string;
}

export const UserInfo: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: locationState } = useLocation();
  const { locale } = useAppState();
  const { data: subscription } = useSubscription();
  const { data: user } = useUser();
  const { mutate, isLoading, error } = useMutation(useUpdateUser);
  const { mutate: mutateNewsletter } = useMutation(useSignupNewsletter);

  const [newsletter, setNewletter] = useState("add");
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [inputs, setInputs] = useState<Partial<TInitialUser>>({
    first_name: "",
    last_name: "",
    email: "",
    zip_code: "",
    phone: "",
    locale,
    referral: "friend",
  });

  useEffect(() => {
    // No email means the user inputs has not been set yet
    if (!inputs.email) {
      resetInputFromUser();
    }
  }, [user]);

  const resetInputFromUser = () => {
    if (user) {
      setInputs({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        zip_code: user.zip_code,
        phone: user.phone,
        locale: user.locale,
        referral: user.referral,
      });
      setIsValidPhone(!!user.phone && user.phone.length >= 11);
    }
  };

  const handleBack = () => {
    if (locationState && (locationState as LocationState).prevPath) {
      history.goBack();
    } else {
      history.push("/account");
    }
  };

  const onInputChange = (key: string) => (value: string) => {
    const inputCopy = { ...inputs };
    if (key === "phone") {
      // @ts-ignore
      inputCopy[key] = value.toLowerCase();
    } else if (key === "zip_code") {
      // @ts-ignore
      inputCopy[key] = value.toUpperCase();
    } else {
      // @ts-ignore
      inputCopy[key] = value;
    }

    setInputs(inputCopy);
  };

  const onNewsletterChange = (isChecked: boolean) =>
    setNewletter(isChecked ? "add" : "");

  const onSubmitHandler = () => {
    if (inputs && inputs?.phone) mutate(inputs);
    if (newsletter === "add" && inputs.email) {
      mutateNewsletter({ email: inputs.email });
    }
  };

  return (
    <WithAuth>
      <Page centerY centerX backgroundColor={theme.colors.base.background}>
        {subscription?.status === "cancel" && (
          <FlashBannerReactivate times={10} type="normal">
            {t("Subscription.cancelled")}
          </FlashBannerReactivate>
        )}
        <AlternateSection>
          <BackWrapper>
            <BackButton onClick={handleBack}>
              <icons.arrowTop />
              <BackLabel size="mdx" weight="bold">
                {t("Common.action.back")}
              </BackLabel>
            </BackButton>
            <RouteLabel size="mdx" weight="bold">
              {t("Navigation.personal-infos")}
            </RouteLabel>
          </BackWrapper>
          <InfoWrapper>
            <BillingWrapper>
              <HeadingStyled element="p" size="sm">
                {t("Account.billing.heading")}
              </HeadingStyled>
              <Billing couponName="" onChangingCard={() => {}} />
            </BillingWrapper>
            <>
              <HeadingStyled element="p" size="sm">
                {t("Account.user.info")}
              </HeadingStyled>
              <UserInfoForm
                inputs={inputs}
                newsletter={newsletter}
                showPassword={false}
                isLoading={isLoading}
                setValue={true}
                disableEmailAndZip={true}
                onInputChange={onInputChange}
                onNewsletterChange={onNewsletterChange}
                setIsValidPhone={setIsValidPhone}
                onSubmit={onSubmitHandler}
              >
                <ActionsWrapper>
                  <StyleButton primary onClick={resetInputFromUser}>
                    {t("Common.action.cancel")}
                  </StyleButton>
                  <StyleButton secondary disabled={!isValidPhone} type="submit">
                    {t("Common.action.save")}
                  </StyleButton>
                </ActionsWrapper>
              </UserInfoForm>
            </>
            {/* <PasswordWrapper>
              <HeadingStyled element="p" size="sm">
                {t("Info.password.heading")}
              </HeadingStyled>
              <Password />
            </PasswordWrapper> */}
          </InfoWrapper>
          {error && <Snackbar type="error" error={error} />}
        </AlternateSection>
      </Page>
    </WithAuth>
  );
};

const BackWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    margin-right: ${theme.spacing[1]};
    fill: ${theme.colors.base.primary};
    transform: rotate(-90deg);
  }

  &:hover {
    svg {
      fill: ${theme.colors.base.dark};
    }

    div {
      color: ${theme.colors.base.dark};
    }
  }
`;

const BackLabel = styled(Label)`
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.primary};
`;

const RouteLabel = styled(Label)`
  margin-left: ${theme.spacing[1]};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.mdx};
  line-height: ${theme.font.size.lg};
  font-weight: ${theme.font.weight.bold};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[2]};
`;

const BillingWrapper = styled.div`
  margin-bottom: ${theme.spacing[2]};
`;

const PasswordWrapper = styled.div`
  margin-top: ${theme.spacing[2]};
`;

const HeadingStyled = styled(Heading)`
  line-height: ${theme.font.size.lg};
  text-align: center;
  letter-spacing: normal;

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.xl};
    line-height: ${theme.font.size.xxl};
    text-align: left;
    letter-spacing: 1px;
  }
`;

const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${theme.spacing[1.5]};

  > button {
    margin-left: ${theme.spacing[1]};
    margin-right: ${theme.spacing[1]};

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const StyleButton = styled(Button)`
  width: 200px;
`;
