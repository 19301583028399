import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { icons } from "../../assets/icons";
import { useAppState } from "../../contexts";
import { TPlan, TPlanQuantity } from "../../types/Plan";
import { Button } from "../Button";
import { theme } from "../theme";
import { PlanContent } from "./PlanContent";
import { QuantityPopup } from "./QuantityPopup";
import { useLocation } from "react-router-dom";

interface Props {
  plan: TPlan;
  isActive: boolean;
  quantity: TPlanQuantity;
  index?: number;
  className?: string;
  onSelect: (plan: TPlan, quantity: TPlanQuantity, active: boolean) => void;
  onDelete: (plan: TPlan) => void;
}

export const Plan: React.FC<Props> = ({
  plan,
  isActive = false,
  quantity,
  index,
  className,
  onSelect,
  onDelete,
  children,
}) => {
  const { t } = useTranslation();
  const [quantityOpen, setQuantityOpen] = useState<boolean>();
  const { locale } = useAppState();
  const [lang] = locale.split("-");
  const path = useLocation();

  const toggleQuantityPopup = () => {
    setQuantityOpen(!quantityOpen);
  };

  const handleSelect = () => {
    if (path.pathname === "/sign-up/step-1") {
      onSelect(plan, 2, true);
      return;
    }
    setQuantityOpen(true);
  };

  const handleQuantitySelected = (persons: TPlanQuantity) => {
    toggleQuantityPopup();
    onSelect(plan, persons, true);
  };

  const handlePlanDeleted = () => onDelete(plan);
  return (
    <ArticleStyled isActive={isActive}>
      {isActive && (
        <HeaderStyle>
          <TagStyled>{t("Signup.step1.bundles.selection")}</TagStyled>
          <IconButton onClick={handlePlanDeleted}>
            <icons.trash />
          </IconButton>
        </HeaderStyle>
      )}
      {plan?.copy[lang]?.most_popular && (
        <HeaderStyle>
          <MostPopular>{t("Signup.step1.most-popular")}</MostPopular>
          {isActive && (
            <IconButton onClick={handlePlanDeleted}>
              <icons.trash />
            </IconButton>
          )}
        </HeaderStyle>
      )}
      <ContentStyled>
        <PlanContent plan={plan}>{children}</PlanContent>
        {isActive ? (
          <Button third onClick={handleSelect}>
            {t("Signup.step1.bundles.quantity", { count: quantity })}
          </Button>
        ) : (
          <InactiveButton third onClick={handleSelect}>
            {t("Signup.step1.select")}
          </InactiveButton>
        )}

        {quantityOpen && plan && (
          <QuantityPopup
            plan={plan}
            quantity={quantity}
            onValidate={handleQuantitySelected}
            onClose={toggleQuantityPopup}
          />
        )}
      </ContentStyled>
    </ArticleStyled>
  );
};

const ArticleStyled = styled.article<Pick<Props, "isActive">>`
  position: relative;

  height: 100%;
  width: 350px;

  margin-bottom: ${theme.spacing[1]};
  padding: ${theme.spacing[1.5]} ${theme.spacing[1]} ${theme.spacing[1]};
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  box-sizing: border-box;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-bottom: ${theme.spacing[0.5]};
  }

  ${(props) =>
    props.isActive &&
    css`
      border: 2px solid ${theme.colors.base.primary};
      box-sizing: border-box;
      /* box-shadow: 0px 4px 26px rgba(238, 83, 64, 0.7); */
      border-radius: 8px;
    `}
`;

const HeaderStyle = styled.div`
  position: absolute;
  top: 0;
  left: ${theme.spacing[1]};
  right: ${theme.spacing[1]};
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 25;
`;

const TagStyled = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.normal};
  font-size: ${theme.font.size.xs};
  line-height: ${theme.font.size.xs};
  text-transform: uppercase;
  color: ${theme.colors.base.white};
  background: ${theme.colors.base.primary};
  border-radius: 30px;
`;

const MostPopular = styled(TagStyled)`
  background: ${theme.colors.base.secondary};
`;

const IconButton = styled.div`
  width: 32px;
  height: 32px;
  background: ${theme.colors.base.primary};
  border: 2px solid ${theme.colors.base.primary};
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color ${theme.transition.fast};

  svg {
    width: 100%;
    height: 100%;
    fill: ${theme.colors.base.white};
    transition: fill ${theme.transition.fast};
  }

  &:hover {
    background-color: ${theme.colors.accent.primary};

    svg {
      fill: ${theme.colors.base.dark};
    }
  }
`;

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding-top: 0;
  border-bottom-left-radius: ${theme.border.radius.lg};
  border-bottom-right-radius: ${theme.border.radius.lg};
`;

const InactiveButton = styled(Button)`
  background: ${theme.colors.base.background};
  border: 1px solid ${theme.colors.base.primary};
`;
