import clsx from "clsx";
import React from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

import { theme } from "../theme";
import { useUser } from "../../hooks/Users";

interface Props {
  className?: string;
}

const Avatar: React.FC<Props> = ({ className }) => {
  const { data: user } = useUser();

  if (!user) return null;

  return user.first_name && user.last_name ? (
    <RouterLink className={clsx(className)} to="/account">
      {firstUpperLetter(user.first_name)}
      {firstUpperLetter(user.last_name)}
    </RouterLink>
  ) : null;

  function firstUpperLetter(string: string) {
    return string.toUpperCase().charAt(0);
  }
};

export const AvatarStyled = styled(Avatar)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing[0.5]};
  border-radius: ${theme.border.radius.round};
  background-color: ${theme.colors.base.dark};
  color: ${theme.colors.base.light};
  font-size: ${theme.font.size.sm};
  transition: background ${theme.transition.fast};

  &:hover {
    background-color: ${theme.colors.accent.primary};
  }
`;

export const AvatarMemoized = React.memo(AvatarStyled);
