import React from 'react';
import { Label } from '../Label';
import { useAppState } from '../../contexts';
import styled from 'styled-components';
import { theme } from '../theme';
import { icons } from '../../assets/icons';
import { TPlanQuantity } from '../../types/Plan';

interface Props {
  name: string;
  tag: string;
  quantity: TPlanQuantity;
}

export const SummaryPlanCell: React.FC<Props> = ({ name, tag, quantity }) => {
  const { locale } = useAppState();

  return (
    <>
      <RowWrapper>
        <ColumnWrapper>
          <PlanNameLabel size='lg' weight='black'>
            {name}
          </PlanNameLabel>
          <PlanTagLabel>{tag}</PlanTagLabel>
        </ColumnWrapper>
        <PlanQuantityContainer>
          <icons.person fill={theme.colors.base.secondary} />
          <PlanQuantityLabel>{quantity}</PlanQuantityLabel>
        </PlanQuantityContainer>
      </RowWrapper>
    </>
  );
};

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: ${theme.spacing[0.25]};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanQuantityContainer = styled(RowWrapper)`
  flex: unset;
  margin: 0;
  margin-left: ${theme.spacing[1]};
  padding: ${theme.spacing[1]};
  background: ${theme.colors.base.third};
  border: 1px solid ${theme.colors.base.third};
  border-radius: 3px;
`;

const PlanNameLabel = styled(Label)`
  line-height: ${theme.font.size.xl};
  font-weight: ${theme.font.weight.bold};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.secondary};
`;

const PlanTagLabel = styled(Label)`
  line-height: ${theme.font.size.mdx};
  font-weight: ${theme.font.weight.bold};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.primary};
`;

const PlanQuantityLabel = styled(PlanTagLabel)`
  margin-left: ${theme.spacing[0.5]};
  color: ${theme.colors.base.secondary};
`;
