// @ts-nocheck
import clsx from "clsx";
import React from "react";
import styled, { css } from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { Loading } from "../Loading";
import { theme, spacing } from "../theme";

type ButtonSizes = "sm" | "md" | "lg";

interface Props {
  type?: "submit";
  secondary?: boolean;
  thirdReverse?: boolean;
  third?: boolean;
  info?: boolean;
  center?: boolean;
  noBorder?: boolean;
  size?: ButtonSizes;
  to?: string;
  href?: string;
  disabled?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
  className?: string;
}

const Button: React.FC<Props> = ({
  children,
  type,
  isLoading = false,
  secondary,
  thirdReverse,
  third,
  noBorder,
  center,
  size = "md",
  info,
  to,
  href,
  disabled = false,
  onClick,
  className,
}) => {
  if (to) {
    return (
      <RouterLink className={clsx(className)} to={to}>
        {children}
      </RouterLink>
    );
  }

  if (href) {
    return (
      <a className={clsx(className)} href={href}>
        {children}
      </a>
    );
  }

  if (isLoading) {
    return (
      <FormWrapper>
        <Loading />
      </FormWrapper>
    );
  }
  return (
    <button
      type={type && type}
      className={clsx(className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

const FormWrapper = styled.div`
  position: relative;
  padding: ${theme.spacing[4]};
  border-radius: 4px;
  background-color: ${theme.colors.accent.primary};
`;

export const ButtonStyled = styled(Button)`
  display: inline-block;
  padding: ${spacing(0.7)} ${spacing(1.2)};
  border: 0;
  border-radius: ${theme.border.radius.sm};
  /* border: 2px solid ${theme.colors.base.dark}; */
  background-color: ${theme.colors.base.primary};
  color: ${theme.colors.base.white};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.md};
  font-weight: ${theme.font.weight.black};
  letter-spacing: 1px;
  cursor: pointer;
  transition: background ${theme.transition.fast};

  &:hover {
    background-color: ${theme.colors.accent.primary};
    color: ${theme.colors.base.dark};
  }

  ${(props) =>
    props.secondary &&
    css`
      background-color: ${theme.colors.base.secondary};
      color: ${theme.colors.base.white};

      &:hover {
        background-color: ${theme.colors.accent.secondary};
        color: ${theme.colors.base.secondary};
      }
    `}

  ${(props) =>
    props.third &&
    css`
      background-color: ${theme.colors.base.third};
      color: ${theme.colors.base.secondary};

      &:hover {
        background-color: ${theme.colors.accent.primary};
        color: ${theme.colors.base.secondary};
      }
    `}

    ${(props) =>
    props.thirdReverse &&
    css`
      background-color: transparent;
      color: ${theme.colors.base.primary};
      border: 1px solid ${theme.colors.base.primary};

      &:hover {
        background-color: ${theme.colors.base.primary};
        color: ${theme.colors.base.white};
      }
    `}

    ${(props) =>
      props.thirdReverseTextGreen &&
      css`
        background-color: ${theme.colors.base.background};
        color: ${theme.colors.base.secondary};
        border: 1px solid ${theme.colors.base.primary};
  
        &:hover {
          background-color: ${theme.colors.base.secondary};
          color: ${theme.colors.base.white};
        }
      `}

   ${(props) =>
    props.info &&
    css`
      background-color: ${theme.colors.status.info};
      color: ${theme.colors.base.dark};
      align-self: center;
      &:hover {
        background-color: ${theme.colors.accent.third};
        color: ${theme.colors.base.dark};
      }
    `}

    ${(props) =>
    props.center &&
    css`
      align-self: center;
    `}


  ${(props) =>
    props.size === "sm" &&
    css`
      padding: ${spacing(0.6)} ${spacing(1)};
      font-size: ${theme.font.size.sm};
    `}

  ${(props) =>
    props.size === "lg" &&
    css`
      padding: ${spacing(0.8)} ${spacing(1.8)};
      font-size: ${theme.font.size.lg};
    `}

  ${(props) =>
    props.noBorder &&
    css`
      border: none;
    `}

  &[disabled] {
    border-color: ${theme.colors.neutral.primary};
    background-color: ${theme.colors.neutral.primary};
    color: ${theme.colors.neutral.secondary};
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ButtonMemoized = React.memo(ButtonStyled);
