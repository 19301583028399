import React, { ReactElement, FC } from "react";
import styled from "styled-components";
import { theme } from "../theme";

interface IconWithTooltipsProps {
  tooltipText: string;
  icon: ReactElement; // Accepts a React element
}

const DynamicIconWrapper = styled.div`
  position: relative; // This is important for absolute positioning of children
  display: inline-block; // Use inline-block for proper alignment
  vertical-align: middle; // Aligns the icon properly if it's inline with text or other elements
  cursor: pointer;
`;

const TooltipText = styled.div`
  display: none;
  position: absolute;
  left: 50%;
  bottom: calc(100% + 5px); // Adjust this value to move the tooltip up or down
  transform: translateX(-50%);
  padding: ${theme.spacing[1]};
  background-color: ${theme.colors.accent.primary};
  color: ${theme.colors.base.secondary};
  border-radius: 8px;
  z-index: 1000; // Increase z-index to ensure it's on top
  white-space: nowrap; // Prevents the text from wrapping
  text-align: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); // Optional: adds a shadow for better visibility
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  &:hover + ${TooltipText} {
    display: block; // Shows the tooltip on hover
  }
`;

export const IconWithTooltips: FC<IconWithTooltipsProps> = ({
  tooltipText,
  icon,
}) => (
  <DynamicIconWrapper>
    <Icon>{icon}</Icon>
    <TooltipText className="tooltip-text">{tooltipText}</TooltipText>
  </DynamicIconWrapper>
);
