import clsx from 'clsx';
import React from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';
import { icons } from '../../assets/icons';
import { Text } from '../Text';

import { alpha, theme } from '../theme';

interface Props {
  id?: string;
  name: string;
  label: string;
  isChecked?: boolean;
  defaultValue?: string | number;
  onChange: (isChecked: boolean) => void;
  className?: string;
}

export const FormCheckbox: React.FC<Props> = ({ id, name, label, isChecked = false, onChange, className }) => {
  const uniqId = uuid();

  const onValueChange = () => onChange(!isChecked);

  return (
    <FormCheckboxStyled>
      <input type="checkbox" defaultChecked={isChecked} onClick={onValueChange}/>
      <IconWrapper isChecked={isChecked}>
       <icons.checkboxUnchecked className='unchecked'/>
       <icons.checkboxChecked className='checked'/>
     </IconWrapper>
        <TextStyled>{label}</TextStyled>
    </FormCheckboxStyled>
  );
};

const FormCheckboxStyled = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    display: none;
  }
`;

const IconWrapper = styled.div<Pick<Props, 'isChecked'>>`
  position: relative;
  width: 18px;
  height: 18px;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity ease-in 0.3s;
  }

  ${props => props.isChecked && css`
    .checked {
      opacity: 1;
    }

    .unchecked {
      opacity: 0;
    }
  `}

  ${props => !props.isChecked && css`
    .unchecked {
      opacity: 1;
    }

    .checked {
      opacity: 0;
    }
  `}
`;

const TextStyled = styled(Text)`
  margin-left: ${theme.spacing[1]};
  line-height: ${theme.font.size.lg};
`;
