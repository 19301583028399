import { TFaqTopics, TTopicEnum } from "../../types/Faq";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "../../components/Button";
import { HelpList } from "../../components/HelpList";
import { Hero } from "../../components/Hero";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { theme } from "../../components/theme";
import { useAppState } from "../../contexts";
import { useTopics } from "../../hooks/Faq";

export const Help: React.FC = () => {
  const { t } = useTranslation();
  const { data: topics } = useTopics();
  const { locale } = useAppState();
  const [lang] = locale.split("-");
  const [topic, setTopic] = useState<TTopicEnum>(TTopicEnum.GENERAL);

  return (
    <Page centerX centerY backgroundColor={theme.colors.base.background}>
      <Section>
        <Hero
          image="/common/images/hero-2"
          imageAlt={t("Help.Hero.imageAlt")}
          title={t("Help.Hero.heading")}
        />
      </Section>

      <SectionStyled>
        <WrapperButtons>
          {topics &&
            topics.map((topicFound: TFaqTopics, index) => (
              <div
                key={index}
                onClick={() => {
                  setTopic(topicFound.topic);
                }}
              >
                <Button secondary size="lg">
                  {topicFound?.topic_copies[lang]?.text}
                </Button>
              </div>
            ))}
        </WrapperButtons>
      </SectionStyled>

      <HelpList topic={topic} />
    </Page>
  );
};

const WrapperButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${theme.spacing[0.5]};
`;

const SectionStyled = styled(Section)`
  padding-bottom: 0;
  z-index: 1;
`;
