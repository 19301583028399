import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import styled from "styled-components";
import { useUpdateFrequency } from "../../hooks/NextDeliveries";
import { TFrequenceEnum } from "../../types/PickupPoint";
import { TSubscriptionComplete } from "../../types/Subscription";
import { FormTagRadio } from "../FormTagRadio";
import { SelectOption } from "../FormTagRadio/FormTagRadio";
import { Label } from "../Label";
import { Text } from "../Text";
import { theme } from "../theme";
import { AddressForm } from "./AddressForm";
import { MapSection } from "./MapSection";
import { Page } from "../Page";

interface Props {
  isSignup: boolean;
  subscription?: TSubscriptionComplete;
  onDone: () => void;
}

const DeliveryModes = {
  COLLECT: "PUP",
  HOUSE: "LAD",
};

export const PickUpPoints: React.FC<Props> = ({ isSignup, subscription, onDone, }) => {
  const { t } = useTranslation();
  const { mutate: mutateFrequency } = useMutation(useUpdateFrequency);
  const [deliveryMode, setDeliveryMode] = useState<string>();

  const frequency = TFrequenceEnum.WEEKLY

  return (
    <Page centerX centerY backgroundColor={theme.colors.base.background}>
      <SectionStyled>
        <MapSection
          isSignup={false}
          showLocations={true}
          frequency={frequency}
          showForm={true}
          viewOnly={true}
          onDone={() => {
          }}
        />
      </SectionStyled>
    </Page>
  );
};

const SectionStyled = styled.div`
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;

    > div {
        margin-top: 2px;
    }
`;

const RadioSectionStyled = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing[1.5]} ${theme.spacing[1]};
    background-color: ${theme.colors.accent.secondary};

    @media (min-width: ${theme.breakpoints.sm}) {
        flex-direction: row;
        align-items: center;
        padding: ${theme.spacing[1.5]} ${theme.spacing[2]};
    }
`;

const LabelStyled = styled(Label)`
    width: 170px;
    margin-right: ${theme.spacing[2]};
    font-family: ${theme.font.family.secondary};
    font-weight: ${theme.font.weight.bold};
    line-height: ${theme.font.size.lg};
    color: ${theme.colors.base.secondary};
`;
const SpanStyled = styled(Text)`
    background-color: ${theme.colors.accent.secondary};
    padding: ${theme.spacing[1.5]} ${theme.spacing[1.5]};
    padding-top: 0;
    font-family: ${theme.font.family.secondary};
`;
