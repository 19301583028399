import React, { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { icons } from "../../assets/icons";
import useDebounce from "../../hooks/useDebounce";
import { spacing, theme } from "../theme";
import { useSearchBox, UseSearchBoxProps } from "react-instantsearch-hooks-web";
import { useTranslation } from "react-i18next";

interface Props {
  showFull: boolean;
  onClick: () => void;
  onSendRequest: (done: boolean) => void;
}

export const SearchAlgolia: React.FC<Props> = ({
  onClick,
  showFull,
  onSendRequest,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);
  const { query: searchQuery, refine } = useSearchBox();
  const [query, setQuery] = useState(searchQuery);
  const request = useDebounce(query, 250);
  const location = useLocation();

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const searchValue = searchParams.get("search");
  //   setQuery(searchValue || "");
  // }, [location]);

  useEffect(() => {
    console.log("Request", request);

    refine(String(request));

    if (request !== "") {
      const params = new URLSearchParams();
      params.set("search", String(request));
      history.replace({ pathname: "/products", search: params.toString() });
    } else {
      history.replace({ pathname: "/products" });
    }
    onSendRequest(true);
  }, [request]);

  const onClickWrapper = () => {
    inputRef.current?.focus();
    onClick();
  };

  const onSearchChange = (event: React.FormEvent<HTMLInputElement>) => {
    setQuery(event.currentTarget.value);
  };

  const onKeyPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      inputRef?.current?.blur();
    }
  };

  return (
    <SearchWrapper noValidate action="" role="search" onClick={onClickWrapper}>
      <SearchInput
        ref={inputRef}
        showFull={showFull}
        value={query}
        onChange={onSearchChange}
        onKeyDown={onKeyPressed}
        placeholder={t("Catalog.search")}
      />
      <icons.search />
    </SearchWrapper>
  );
};

const SearchWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing(0.75)} ${spacing(1.25)};
  background: ${theme.colors.accent.secondary};
  border: 1px solid ${theme.colors.base.white};
  box-sizing: border-box;
  border-radius: 83px;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;

    path {
      fill: ${theme.colors.base.secondary};
    }
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    padding: 14px;
  }
`;

const SearchInput = styled.input<{ showFull: boolean }>`
  flex: 1;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.secondary};
  transition: max-width ${theme.transition.fast};

  @media (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
    line-height: ${theme.font.size.mdx};
  }
`;
