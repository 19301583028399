import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { icons } from "../../assets/icons";
import { Button } from "../../components/Button";
import { theme } from "../../components/theme";
import { useAppState } from "../../contexts";
import { useSavings } from "../../hooks/Orders";
import { useSubscription } from "../../hooks/Subscriptions";
import { formatNumber } from "../../utils/i18n";
import { Text } from "../Text";

export const SavingDone: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const path = useLocation();

  const { data: elementSaved } = useSavings();
  const { data: subscription } = useSubscription();
  return (
    <ContainerStyled>
      <NumberItemStyled>
        <IconStyled>
          <icons.money />
        </IconStyled>
        <TextSavedStyled size="lg">
          <strong> {t("Prime.saving.done")} </strong>{" "}
          {elementSaved && subscription?.is_prime && (
            <SpanStyled>
              {" "}
              {formatNumber(locale, elementSaved?.done_savings / 100)} $
            </SpanStyled>
          )}
          {elementSaved && !subscription?.is_prime && (
            <SpanStyled>
              {formatNumber(
                locale,
                elementSaved?.potential_savings / 100 || 130
              )}
              $
            </SpanStyled>
          )}
        </TextSavedStyled>
      </NumberItemStyled>
      {path.pathname !== "/adhesions/signup/yearly-prime" && (
        <NumberItemStyled>
          <Button to="/adhesions/signup/yearly-prime">
            {t("Prime.button.showmore")}
          </Button>
        </NumberItemStyled>
      )}
    </ContainerStyled>
  );
};

const TextSavedStyled = styled(Text)`
  color: ${theme.colors.base.secondary};
`;
const SpanStyled = styled.span`
  color: ${theme.colors.base.primary};
`;
const NumberItemStyled = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing[1]};
  border-radius: ${theme.border.radius.md};
  background-color: ${theme.colors.base.white};

  & > :first-child {
    margin-right: ${theme.spacing[0.5]};
  }
`;

const IconStyled = styled.span`
  padding: ${theme.spacing[1]};
  border-radius: 50%;
  background-color: ${theme.colors.accent.secondary};

  svg {
    max-width: ${theme.spacing[3]};
    max-height: ${theme.spacing[3]};
  }
`;

const ContainerStyled = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  justify-content: space-between;
  gap: ${theme.spacing[0.5]};
  background-color: ${theme.colors.base.white};
`;
