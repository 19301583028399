import React from "react";
import styled from "styled-components";

import { Heading } from "../Heading";
import { Text } from "../Text";
import { TPlan } from "../../types/Plan";
import { theme } from "../theme";
import { useAppState } from "../../contexts";
import { formatCurrency } from "../../utils/i18n";
import { useTranslation } from "react-i18next";

interface Props {
  plan: TPlan;
}

export const PlanContent: React.FC<Props> = ({ plan, children }) => {
  const { locale } = useAppState();
  const [lang] = locale.split("-");
  const { t } = useTranslation();
  return (
    <div>
      <ContentHeaderStyled>
        <HeadingStyled size="md" weight="bold">
          {plan?.copy[lang].name}
        </HeadingStyled>
        <ULStyled>
          {plan?.copy[lang]?.included?.map((text, index) => (
            <Tag key={`tag-${text}-${index}`} size="md">
              - {text}
            </Tag>
          ))}
        </ULStyled>
      </ContentHeaderStyled>
      <ContentCenterStyled>
        <PricingContent>
          {t("Signup.step1.plan.pricing", {
            min_price: formatCurrency(locale, plan.min_price / 100),
            max_price: formatCurrency(locale, plan.max_price / 100),
          })}
        </PricingContent>
        {children}
      </ContentCenterStyled>
    </div>
  );
};

const PricingContent = styled.span`
  padding-top: ${theme.spacing[1]};
`;
const ULStyled = styled.ul`
  padding-top: ${theme.spacing[1]};
`;

const ContentHeaderStyled = styled.div`
  /* & > * {
    margin-bottom: 0;
  } */
`;

const ContentCenterStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  flex: 1;
  padding-bottom: ${theme.spacing[0.5]};

  & * + * {
    margin-left: ${theme.spacing[0.5]};
  }
`;

const HeadingStyled = styled(Heading)`
  @media (min-width: ${theme.breakpoints.sm}) {
    /* height: 64px; */
  }
`;

const Tag = styled(Text)`
  color: ${theme.colors.base.primary};
  line-height: ${theme.font.size.lg};
  font-family: ${theme.font.family.secondary};

  @media (min-width: ${theme.breakpoints.sm}) {
    /* height: 48px; */
  }
`;
