import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { icons } from '../../assets/icons';
import { spacing, theme } from '../theme';

type SizeType = 'sm' | 'md' | 'lg';

interface Props {
  visible: boolean;
  onClose: () => void;
}

export const Popup: React.FC<Props> = ({ children, visible = false, onClose }) => {
  let scroll = 0;
  const body = document.body;

  useEffect(() => {
    toggleScroll(visible);
  }, [visible]);

  useEffect(() => {
    return () => {
      toggleScroll(false);
    };
  }, []);

  const toggleScroll = (deactivateScroll: boolean) => {
    if (deactivateScroll) {
      scroll = window.scrollY;
      body.style.position = 'fixed';
    } else {
      body.style.position = 'static';
      window.scroll(0, scroll);
    }
  };

  const handleClose = () => onClose();

  return (
    <PopupWrapper>
      <Container>
        <>
          <ButtonStyled onClick={handleClose}>
            <icons.close fill={theme.colors.base.dark} />
          </ButtonStyled>
          {children}
        </>
      </Container>
    </PopupWrapper>
  );
};

const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing[1]};
  backdrop-filter: blur(24px);
  z-index: 50;

  @media (min-width: ${theme.breakpoints.sm}) {
    background: rgba(209, 231, 215, 0.9);
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing[1.5]};
  background: ${theme.colors.base.white};

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 520px;
    height: auto;
    display: flex;
  }
`;

const ButtonStyled = styled.button`
  align-self: flex-end;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;
