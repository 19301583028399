import React from "react";

const QualityVegiesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="69"
      height="68"
      viewBox="0 0 69 68"
      fill="none"
    >
      <path
        d="M56.1139 26.5917C55.3595 25.4628 55.0594 23.8744 54.9611 22.4108C54.7114 18.6496 51.5797 15.6135 47.8131 15.7303C44.7106 15.826 41.6692 17.3746 39.8178 20.2539L14.8305 59.1069C13.5449 61.1044 13.7095 63.7766 15.375 65.4686C15.4016 65.4952 15.4281 65.5218 15.4547 65.5483C17.1441 67.2032 19.7977 67.3732 21.7872 66.0955C28.0745 62.0553 48.2036 49.1274 60.6774 41.1161C65.9713 37.7161 66.7681 30.2972 62.3189 25.848L61.0625 24.4402L67.4375 20.1902C62.125 18.0652 61.0625 13.8152 61.0625 13.8152L58.9375 17.0027"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8405 54.384L26.5312 57.0722"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.7031 40.9355L38.1842 45.4193"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.668 26.5918L49.8398 33.7637"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.149 42.7285L51.3884 47.0821"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5983 52.5911L37.6875 55.8822"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.6644 9.55984C45.8555 4.58734 41.3213 1.0625 36.0114 1.0625C31.2727 1.0625 27.3042 3.47969 25.0331 7.51188C22.762 3.48234 18.7936 1.0625 14.0548 1.0625C7.15656 1.0625 1.5625 7.01781 1.5625 14.3623C1.5625 24.1187 11.6111 34.3931 18.5572 40.3298"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default QualityVegiesIcon;
