import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AlertBanner } from "../../components/AlertBanner";
import { Button } from "../../components/Button";
import { Form } from "../../components/Form";
import { FormControl } from "../../components/FormControl";
import { FormInput } from "../../components/FormInput";
import { FormInputControl } from "../../components/FormInputControl";
import { FormLabel } from "../../components/FormLabel";
import { FormSection } from "../../components/FormSection";
import { Heading } from "../../components/Heading";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { theme } from "../../components/theme";
import { useAppState, useAppDispatch } from "../../contexts";
import { useMutation } from "react-query";
import { useResetPassword } from "../../hooks/Users";
import { Snackbar } from "../../components/Snackbar";
import { Loading } from "../../components/Loading";

export const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const methods = useForm();
  const appDispatch = useAppDispatch();
  const { errors } = methods;
  const { errors: appErrors } = useAppState();
  const { mutate, isLoading, isSuccess, error } = useMutation(useResetPassword);
  const [inputs, setInputs] = useState({
    newPassword: "",
    newPasswordConfirmation: "",
  });

  useEffect(() => {
    isSuccess && history.push("/login");
  }, [isSuccess, history]);

  const onSubmitHandler = useCallback(() => {
    mutate({
      token: new URLSearchParams(location.search).get("token") as string,
      newPassword: inputs.newPassword,
      newPasswordConfirmation: inputs.newPasswordConfirmation,
    });
  }, [location, inputs]);

  return (
    <Page centerY centerX>
      <Section centerX>
        <FormSectionStyled
          title={
            <Heading element="h1" size="md">
              {t("ResetPassword.heading")}
            </Heading>
          }
        >
          <Form useForm={methods} onSubmit={onSubmitHandler}>
            <FormControl>
              {appErrors.map((error: any, i: number) => (
                <AlertBanner type="error" key={i}>
                  {error.code} : {error.message}
                </AlertBanner>
              ))}

              <FormInputControl
                errors={
                  errors.newPassword && [
                    t(
                      `ResetPassword.newPassword.errors.${errors.newPassword.type}`
                    ),
                  ]
                }
              >
                <FormLabel target="newPassword">
                  {t("ResetPassword.newPassword.label")}
                </FormLabel>
                <FormInput
                  id="newPassword"
                  type="password"
                  name="newPassword"
                  placeholder={t("ResetPassword.newPassword.placeholder")}
                  error={errors.password}
                  onChange={(value) =>
                    setInputs({ ...inputs, newPassword: value })
                  }
                  register={methods.register}
                  validation={{
                    required: true,
                    minLength: 2,
                  }}
                />
              </FormInputControl>

              <FormInputControl
                errors={
                  errors.newPasswordConfirmation && [
                    t(
                      `ResetPassword.newPasswordConfirmation.errors.${errors.newPasswordConfirmation.type}`
                    ),
                  ]
                }
              >
                <FormLabel target="newPasswordConfirmation">
                  {t("ResetPassword.newPasswordConfirmation.label")}
                </FormLabel>
                <FormInput
                  id="newPasswordConfirmation"
                  type="password"
                  name="newPasswordConfirmation"
                  placeholder={t(
                    "ResetPassword.newPasswordConfirmation.placeholder"
                  )}
                  error={errors.newPasswordConfirmation}
                  onChange={(value) =>
                    setInputs({ ...inputs, newPasswordConfirmation: value })
                  }
                  register={methods.register}
                  validation={{
                    required: true,
                    minLength: 2,
                  }}
                />
              </FormInputControl>

              <ButtonStyled type="submit">
                {t("ResetPassword.action")}
              </ButtonStyled>

              {isLoading && <Loading />}
            </FormControl>
          </Form>
        </FormSectionStyled>
        {error && <Snackbar type="error" error={error} />}
      </Section>
    </Page>
  );
};

const FormSectionStyled = styled(FormSection)`
  @media (min-width: ${theme.breakpoints.xs}) {
    width: 480px;
  }
`;

const ButtonStyled = styled(Button)`
  margin-top: ${theme.spacing[2]};
`;
