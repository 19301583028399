import React from "react";
import styled from "styled-components";
import { icons } from "../../../assets/icons";
import { ProductQuantitySelector } from "../../../components/ProductQuantitySelector";
import { Text } from "../../../components/Text";
import { theme } from "../../../components/theme";
import { useAppState } from "../../../contexts";
import { origins } from "../../../data/origins";
import { TOrder } from "../../../types/Order";
import { TProduct } from "../../../types/Product";
import { formatCurrency, formatNumber } from "../../../utils/i18n";
import { useTranslation } from "react-i18next";
import { Label } from "../../../components/Label";
import { IconWithTooltips } from "../../../components/Tooltips/IconWithTooltips";

interface Props {
  isPreOrder: boolean; // means there is no order but user can pre-order products
  product: TProduct;
  quantity: number;
  order?: TOrder;
  onSelectProduct: (product: TProduct) => void;
  onRemoveProduct: (product: TProduct) => void;
  onKeepProduct: (product: TProduct) => void;
}

export const Product: React.FC<Props> = ({
  isPreOrder,
  product,
  quantity,
  order,
  onSelectProduct,
  onRemoveProduct,
  onKeepProduct,
}) => {
  const { locale } = useAppState();
  const [lang] = locale.split("-");
  const { t } = useTranslation();
  const handleCardClicked = () => onSelectProduct(product);

  const handleRemoveProduct = () => onRemoveProduct(product);
  const handleKeepProduct = () => onKeepProduct(product);

  // @ts-ignore
  const origin = origins[lang][product?.origin];
  const brand = product.brand?.copy[`${lang}`]?.name;
  return (
    <ProductCard>
      {product.is_promo && (
        <PromoLabel size="md">{product.promotion}%</PromoLabel>
      )}
      <PresentationWrapper onClick={handleCardClicked}>
        {product.images && product.images.length > 0 && (
          <img src={product.images[0]} loading={"lazy"} />
        )}
        <InfoWrapper>
          <LocationText size="sm">{`${brand ? brand : ""}${
            brand && origin ? " | " : ""
          }${origin ? origin : ""}`}</LocationText>
          <ProductLabel size="mdx" weight="black">
            {product?.copy[lang]?.name} ({product?.volume / 100} {product.unit})
          </ProductLabel>

          {/* Short description, 2 lines max */}
          <div>
            <ProductDescription>
              {product.copy[lang].description}
            </ProductDescription>
          </div>

          <TagsWrapper>
            {product.is_saved && <SavedTag key="saved">{t("Saved")}</SavedTag>}
            {!product.is_saved && (
              <NotSavedTag key="notSaved">{t("Regular")}</NotSavedTag>
            )}
            {product.is_featured && <NewTag key="new">{t("New")}</NewTag>}
            {product.is_local && <LocalTag key="local">{t("Quebec")}</LocalTag>}
            {product.tags?.map((tag) => (
              <Tag key={tag.tid}>{tag.copy[lang]?.name}</Tag>
            ))}
          </TagsWrapper>
        </InfoWrapper>
      </PresentationWrapper>
      <Separator />
      <QuantityWrapper>
        <ValuesWrapper className={"values-wrapper"}>
          <PriceWrapper className={"price-wrapper"}>
            <icons.trash
              fill={theme.colors.base.secondary}
              onClick={handleRemoveProduct}
            />

            <PriceText size="lg">
              <span className={"old-price"}>
                {product.is_promo && (
                  <s>{`${formatNumber(
                    locale,
                    (product.regular_price * (quantity || 1)) / 100
                  )}$`}</s>
                )}
                &nbsp;&nbsp;
              </span>
              {formatCurrency(locale, (product.display_price * quantity) / 100)}
            </PriceText>
          </PriceWrapper>

          <UnitPriceText size="sm">{`${formatCurrency(
            locale,
            product.display_price / 100
          )} / ${product.volume / 100} ${product.unit}`}</UnitPriceText>
        </ValuesWrapper>
        {
          <PriceWrapper>
            <IconWrapper>
              <IconWithTooltips
                icon={
                  <icons.heart
                    onClick={() => handleKeepProduct()}
                    fill={product.keep ? theme.colors.base.primary : "#c2bcbc"}
                  />
                }
                tooltipText={
                  product.keep
                    ? t("ProductCard.Explaining.SavedForNextCart")
                    : t("ProductCard.Explaining.ClickToSave")
                }
              />
            </IconWrapper>
          </PriceWrapper>
        }
        <ActionWrapper>
          <>
            {order?.status === "paid" || order?.status === "cancel" ? (
              <QuantityText size="lg">{quantity}</QuantityText>
            ) : (
              <ProductQuantitySelector
                isPreOrder={isPreOrder}
                productId={product.pid}
                quantity={quantity}
                order={order}
                withBorder={true}
              />
            )}
          </>
        </ActionWrapper>
      </QuantityWrapper>
    </ProductCard>
  );
};

const ProductDescription = styled(Text)`
  text-align: left;
  font-family: ${theme.font.family.secondary};
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const TagsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  gap: 5px;

  span {
    align-self: flex-start;
    padding: ${theme.spacing[0.25]} ${theme.spacing[0.5]};
    white-space: nowrap;
    border-radius: 64px;
    border: 0;
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.font.size.mdx};
  }
`;

const Tag = styled.span`
  background: ${theme.colors.base.background};
  color: ${theme.colors.base.secondary};
`;

const SavedTag = styled.span`
  background: ${theme.colors.base.secondary};
  color: ${theme.colors.base.white};
`;

const NotSavedTag = styled.span`
  background: ${theme.colors.status.info};
  color: ${theme.colors.base.white};
`;

const LocalTag = styled.span`
  background: #003da5;
  color: ${theme.colors.base.white};
`;

const NewTag = styled.span`
  background: ${theme.colors.base.third};
  color: ${theme.colors.base.white};
`;

const PromoLabel = styled(Label)`
  position: absolute;
  top: -5px;
  left: -5px;
  padding: 2px ${theme.spacing[0.5]};
  font-family: ${theme.font.family.primary};
  background: ${theme.colors.base.primary};
  border-radius: ${theme.border.radius.xl};
  color: ${theme.colors.base.white};
  text-align: center;

  @media (min-width: ${theme.breakpoints.sm}) {
    max-width: none;
    padding: ${theme.spacing[0.25]} ${theme.spacing[0.5]};
  }
`;

const IconWrapper = styled.div`
  padding: ${theme.spacing[0.25]};
`;
const ProductCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: ${theme.spacing[0.25]} 0;
  background: ${theme.colors.base.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
    margin: ${theme.spacing[0.5]} 0;
  }
`;

const PresentationWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: ${theme.spacing[0.5]};
  cursor: pointer;

  > img {
    width: 98px;
    height: 98px;
    margin-right: ${theme.spacing[0.5]};
    object-fit: contain;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[1]};

    > img {
      margin-right: ${theme.spacing[1]};
    }
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LocationText = styled(Text)`
  display: flex;
  flex-direction: column;
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.primary};
`;

const ProductLabel = styled(Text)`
  display: flex;
  flex-direction: column;
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.black};
  margin: ${theme.spacing[0.25]} 0 ${theme.spacing[0.5]} 0;
`;

const Separator = styled.div`
  width: 1px;
  height: 100%;
  background: ${theme.colors.accent.secondary};
  display: none;

  @media (min-width: ${theme.breakpoints.sm}) {
    display: block;
  }
`;

const QuantityWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 185px;
    flex-direction: column;
    border-left: 1px solid ${theme.colors.base.grey};
  }
`;

const PriceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
    margin-right: ${theme.spacing[1]};
  }
`;

const ValuesWrapper = styled.div`
  flex: 1;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 ${theme.spacing[1.5]};

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[1]} ${theme.spacing[1]} ${theme.spacing[0.25]}
      ${theme.spacing[1]};
  }
`;

const UnitPriceText = styled(Text)`
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.grey};
`;

const PriceText = styled(Text)`
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.colors.base.primary};
  text-align: right;

  span {
    color: ${theme.colors.base.secondary};
  }
`;

const QuantityText = styled(Text)`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: ${theme.font.size.lg};
  line-height: ${theme.font.size.xxl};
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.black};
  text-align: center;
  color: ${theme.colors.base.primary};
  border: 1px solid ${theme.colors.base.grey};
  border-radius: 3px;

  @media (min-width: ${theme.breakpoints.sm}) {
    height: 55px;
    border: none;
  }
`;

const ActionWrapper = styled.div`
  width: 200px;
  margin: 0 ${theme.spacing[0.5]};

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 100%;
    margin: 0;
    border-top: 1px solid ${theme.colors.base.grey};

    > div {
      border: none;

      > .separator {
        height: unset;
        margin-top: ${theme.spacing[0.5]};
        margin-bottom: ${theme.spacing[0.5]};
      }
    }
  }
`;
