import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { icons } from "../../assets/icons";
import { FormControl } from "../FormControl";
import { inputStyles } from "../FormInput/FormInput";
import { FormInputControl } from "../FormInputControl";
import { FormLabel } from "../FormLabel";
import { Label } from "../Label";
import { Snackbar } from "../Snackbar";
import { theme } from "../theme";

interface Props {
  error?: any;
}

export const CardForm: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormControl>
        <FormInputControl>
          <FormLabel>{t("CreditCard.number")}</FormLabel>
          <CardNumberStyled />
        </FormInputControl>
      </FormControl>

      <FormControl columns={2}>
        <FormInputControl>
          <FormLabel>{t("CreditCard.expiration")}</FormLabel>
          <CardExpiryStyled />
        </FormInputControl>

        <FormInputControl>
          <FormLabel>{t("CreditCard.cvc")}</FormLabel>
          <CardCVCStyled />
        </FormInputControl>
      </FormControl>
      <StripeRowWrapper>
        <icons.lock />
        <StripeLabel size="md">
          {t("Signup.confirm.payment.stripe")}
        </StripeLabel>
      </StripeRowWrapper>

      {error && (
        <Snackbar type="error" text={t("GenericError.card")} error={error} />
      )}
    </>
  );
};

const CardNumberStyled = styled(CardNumberElement)`
  ${inputStyles}
`;
const CardExpiryStyled = styled(CardExpiryElement)`
  ${inputStyles}
`;
const CardCVCStyled = styled(CardCvcElement)`
  ${inputStyles}
`;

const StripeRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  justify-content: flex-start;
  margin-top: ${theme.spacing[1]};
  margin-bottom: ${theme.spacing[1]};

  svg {
    fill: ${theme.colors.base.secondary};
  }
`;

const StripeLabel = styled(Label)`
  margin-left: ${theme.spacing[1]};
  line-height: ${theme.font.size.mdx};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.secondary};
`;
