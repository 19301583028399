import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { spacing, theme } from "../../components/theme";
import { Image } from "../../components/Image";
import { icons } from "../../assets/icons";
import { CarouselProducts } from "../../components/CarousellProducts";
import { useHighlightedProducts } from "../../hooks/Products";
import { PrimePitch } from "../Prime/PrimePitch";
import { JoinUsMsl } from "./JoinUsMsl";
import { Press } from "../../components/Press";
import { HeroMSL } from "../../components/HeroMSL";
import { Stack } from "../../components/Stack";
import { Button } from "../../components/Button";
import { useUser } from "../../hooks/Users";

export const Msl: React.FC = () => {
  const { t } = useTranslation();
  const { data: products = [] } = useHighlightedProducts("homepage");
  const { data: user } = useUser();

  return (
    <Page centerX centerY backgroundColor={theme.colors.base.background}>
      <HeroMSL
        title={t("Msl.explanation.hero")}
        image={"/common/images/hero-msl"}
        imageAlt={t("EmptyState.Image.alt")}
        wide={true}
        centerWhenSmall={true}
        imageOnTopWhenSmall={true}
        subtitle={t("Msl.explanation.hero.subtitle")}
      >
        <Stack>
          {user ? (
            <Button to="/dashboard">{t("Msl.explanation.button")}</Button>
          ) : (
            <Button to="/login">{t("Msl.explanation.button")}</Button>
          )}
        </Stack>
      </HeroMSL>
      <SectionAlignedStyled>
        <CarouselProducts
          products={products}
          withBannerProduct={false}
          isPreOrder={false}
        />
      </SectionAlignedStyled>

      <BackgroundSecondary>
        <Section>
          <WrapperColumn>
            <PrimePitch
              withTitle
              title={"Msl.explanation.header"}
              backgroundColor={theme.colors.base.white}
              datas={[
                { text: "Msl.explanation.text1" },
                { text: "Msl.explanation.text2" },
                { text: "Msl.explanation.text3" },
                { text: "Msl.explanation.text4" },
              ]}
              selectedPrime={"yearly-prime"}
            />
            <ImageStyledHuman>
              <Image
                path="/common/images/hero-1"
                alt={t("EmptyState.Image.alt")}
              />
            </ImageStyledHuman>
          </WrapperColumn>
        </Section>
      </BackgroundSecondary>

      <JoinUsMsl
        title={"Msl.icon.header"}
        datas={[
          { icon: <icons.food />, text: "Msl.icon.text1" },
          { icon: <icons.mls />, text: "Msl.icon.text2" },
          { icon: <icons.saved />, text: "Msl.icon.text3" },
          { icon: <icons.worldFruit />, text: "Msl.icon.text4" },
        ]}
        backgroundColor={"theme.colors.base.secondary"}
      />

      <WrapperBorder>
        <Press />
      </WrapperBorder>
    </Page>
  );
};

const BackgroundSecondary = styled.div`
  width: 100%;
  background-color: ${theme.colors.accent.primary};
`;
const WrapperBorder = styled.div`
  padding-top: ${spacing(2)};
  padding-bottom: ${spacing(2)};
`;

const WrapperColumn = styled.div`
  display: flex;
  padding-bottom: ${theme.spacing[2]};
  align-items: center;
  @media (max-width: ${theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[2]};
  }
  svg {
    width: 24px;
    height: 24px;
    fill: ${theme.colors.base.secondary};
  }
`;

const ImageStyledHuman = styled.div`
  img {
    max-width: 550px;
    min-width: auto;
  }
`;

const SectionAlignedStyled = styled(Section)`
  & {
    padding: ${theme.spacing[3]} ${theme.spacing[2]};
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    & {
      padding-top: 0px;
    }
  }
`;
