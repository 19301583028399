import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Heading } from "../../components/Heading";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { terms } from "../../data/terms";
import { useAppState } from "../../contexts";
import { Text } from "../../components/Text";
import { theme } from "../../components/theme";
import { Divider } from "../../components/Divider";
export const Terms: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const [lang] = locale.split("-");

  return (
    <Page centerX centerY backgroundColor={theme.colors.base.background}>
      <SectionStyled centerX>
        <Heading size="xl">{t(`Terms.heading`)}</Heading>
        <Text size="mdx">{t(`Terms.update`)}</Text>
        <Text size="mdx">{t(`Terms.gender`)}</Text>
        <Text size="mdx">{t(`Terms.acceptance`)}</Text>
      </SectionStyled>{" "}
      <Section>
        {terms[lang as "fr"].map((term: any) => (
          <>
            {term?.big_title && (
              <>
                <Divider></Divider>
                <BigTitle center size="xl">
                  {term.big_title}
                </BigTitle>
                <Divider></Divider>
              </>
            )}
            {term?.title && <Heading size="lg">{term.title}</Heading>}
            <TextStyled>
              {term.contents.map((content: string) => (
                <Text size="lg">{content}</Text>
              ))}
            </TextStyled>
          </>
        ))}
      </Section>
    </Page>
  );
};

const BigTitle = styled(Heading)`
  padding-bottom: ${theme.spacing[1]};
  color: ${theme.colors.base.primary};
`;

const TextStyled = styled.div`
  margin-bottom: ${theme.spacing[1]};
`;

const SectionStyled = styled(Section)`
  min-height: 20vh;
`;
