import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Button } from "../../Button";
import { Heading } from "../../Heading";
import { Label } from "../../Label";
import { Stack } from "../../Stack";
import { TPickupLocation } from "../../../types/PickupPoint";
import { TTimeSlot } from "../../../types/TimeSlot";
import { formatTimeslot } from "../../../utils";
import { alpha, theme } from "../../theme";
import { useAppState } from "../../../contexts";
import { useLocation } from "react-router-dom";

export interface Props {
  visible: boolean;
  content: TPickupLocation | null | undefined;
  onClose?: () => void;
}

export const Modal: React.FC<Props> = ({ visible, content, onClose }) => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const path = useLocation();

  if (!visible || !content) return null;

  return (
    <ModalStyled tabIndex={visible ? 0 : -1}>
      <Heading size="lg">{content.name}</Heading>
      <AddressStyled>
        <Label size="lg">
          {content.address}
          {content.address2 && `, ${content.address2}`}
          <br />
          {content.city}, {content.province}, {content.zip_code}
        </Label>
      </AddressStyled>
      {content.timeslots ? (
        <ListStyled>
          <Heading size="sm" element="h6">
            {t("Home.map.Modal.schedule")}
          </Heading>
          {content.timeslots.map((t: TTimeSlot) => {
            const dateTime = formatTimeslot(t.day, t.start_time, t.end_time, locale, "long");
            return <li key={t.id}>{dateTime}</li>;
          })}
        </ListStyled>
      ) : null}
      <Stack>
        {path.pathname === "/" ? (
          <ButtonStyled size="sm" to="/sign-up/step-1">
            {t("Home.map.Modal.getStarted")}
          </ButtonStyled>
        ) : null}
        <ButtonStyled secondary size="sm" onClick={onClose}>
          {t("Home.map.Modal.close")}
        </ButtonStyled>
      </Stack>
    </ModalStyled>
  );
};

const ModalStyled = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${theme.spacing[1.5]};
  background-color: ${alpha(theme.colors.base.light, theme.transparency.low)};
  z-index: 2;
`;

const AddressStyled = styled.address`
  margin-top: ${theme.spacing[1]};
  margin-bottom: ${theme.spacing[2]};
`;

const ListStyled = styled.ul`
  & > li + li {
    margin-top: ${theme.spacing[0.5]};
  }
`;

const ButtonStyled = styled(Button)`
  margin-top: ${theme.spacing[2]};
`;
