import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { icons } from "../../assets/icons";
import { Delivery as DeliveryContainer } from "../../components/Delivery";
import { AlternateSection } from "../../components/AlternateSection";
import { FlashBannerReactivate } from "../../components/FlashBannerReactivate";
import { Heading } from "../../components/Heading";
import { Label } from "../../components/Label";
import { Page } from "../../components/Page";
import { theme } from "../../components/theme";
import { WithAuth } from "../../components/WithAuth";
import { useSubscription } from "../../hooks/Subscriptions";

interface LocationState {
  prevPath?: string;
}

export const Delivery: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: locationState } = useLocation();
  const { data: subscription, refetch } = useSubscription();
  const queryClient = useQueryClient();

  const handleBack = () => {
    if (locationState && (locationState as LocationState).prevPath) {
      history.goBack();
    } else {
      history.push("/account");
    }
  };

  const onDone = () => {
    refetch().then(() => {
      queryClient.refetchQueries(["useDelivery"], { active: true });
      queryClient.refetchQueries(["useProcessingOrders"], { active: true });
    });
  };

  return (
    <WithAuth>
      <Page centerY centerX backgroundColor={theme.colors.base.background}>
        {subscription?.status === "cancel" && (
          <FlashBannerReactivate times={10} type="normal">
            {t("Subscription.cancelled")}
          </FlashBannerReactivate>
        )}
        <AlternateSection>
          <BackWrapper>
            <BackButton onClick={handleBack}>
              <icons.arrowTop />
              <BackLabel size="mdx" weight="bold">
                {t("Common.action.back")}
              </BackLabel>
            </BackButton>
            <RouteLabel size="mdx" weight="bold">
              {t("Navigation.personal-infos")}
            </RouteLabel>
          </BackWrapper>
          <DeliveryWrapper>
            <HeadingStyled element="p" size="sm">
              {t("Signup.step3.heading")}
            </HeadingStyled>
            <DeliveryContainer
              isSignup={false}
              subscription={subscription}
              onDone={onDone}
            />
          </DeliveryWrapper>
          <ButtonWrapper></ButtonWrapper>
        </AlternateSection>
      </Page>
    </WithAuth>
  );
};

const DeliveryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[1.5]};
  margin-bottom: ${theme.spacing[1]};

  @media (max-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[1.5]} 0;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-top: ${theme.spacing[2]};

    > div {
      margin-top: ${theme.spacing[2]};

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
`;

const HeadingStyled = styled(Heading)`
  line-height: ${theme.font.size.lg};
  text-align: center;
  letter-spacing: normal;

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.xl};
    line-height: ${theme.font.size.xxl};
    text-align: left;
    letter-spacing: 1px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${theme.spacing[1]};

  > button {
    width: 100%;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    display: none;
  }
`;

const BackWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    margin-right: ${theme.spacing[1]};
    fill: ${theme.colors.base.primary};
    transform: rotate(-90deg);
  }

  &:hover {
    svg {
      fill: ${theme.colors.base.dark};
    }

    div {
      color: ${theme.colors.base.dark};
    }
  }
`;

const BackLabel = styled(Label)`
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.primary};
`;

const RouteLabel = styled(Label)`
  margin-left: ${theme.spacing[1]};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.mdx};
  line-height: ${theme.font.size.lg};
  font-weight: ${theme.font.weight.bold};
`;
