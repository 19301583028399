import React from "react";
import styled from "styled-components";

import { alpha, theme } from "../theme";

interface Props {
  id: string;
  current?: number;
}

export const Options: React.FC<Props> = ({ id, current, children }) => {
  return (
    <OptionsStyled
      id={`${id}-list`}
      role="listbox"
      aria-activedescendant={current ? `${id}-options-${current}` : ""}
      tabIndex={0}
    >
      {children}
    </OptionsStyled>
  );
};

const OptionsStyled = styled.ul`
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: ${theme.colors.base.white};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #C4C4C4;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    max-height: 500px;

    &::-webkit-scrollbar {
      width: ${theme.spacing[1]};
    }
  }
`;
