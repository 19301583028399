import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components/Button';
import { Label } from '../../components/Label';
import { TSubscription } from '../../types/Subscription';
import { theme } from '../theme';

interface Props {
  subscription: TSubscription;
}

export const CreditCard: React.FC<Props> = ({ subscription }) => {
  const { t } = useTranslation();

  return (
    <CreditCardWrapper>
      <LineItemStyled>
        <Label weight='bold'>{t('CreditCard.brand')}</Label>
        <Label weight='bold'>{t('CreditCard.lastDigit')}</Label>
        <Label weight='bold'>{t('CreditCard.expiration')}</Label>
      </LineItemStyled>
      <LineItemStyled>
        <Label>{subscription.brand.toUpperCase()}</Label>
        <Label>{subscription.lastDigits}</Label>
        <Label>
          {subscription.exp_month}&nbsp;/&nbsp;{subscription.exp_year}
        </Label>
      </LineItemStyled>
    </CreditCardWrapper>
  );
};

const CreditCardWrapper = styled.div``;

const LineItemStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  &:first-child {
    margin-bottom: ${theme.spacing[1]};
    padding-bottom: ${theme.spacing[1]};
    border-bottom: 2px solid ${theme.colors.base.dark};
  }
`;
