import React from "react";
import styled from "styled-components";
import config from "../../config";
import { Heading } from "../Heading";
import { Section } from "../Section";
import { theme } from "../theme";
import { Text } from "../Text";
import { useTranslation } from "react-i18next";

export const MissionAlternative: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SectionStyled size="lg" backgroundColor={theme.colors.base.third}>
      <Wrapper>
        <ItemPictureStyled1>
          <source
            srcSet={`${config.CDN_URL}/common/images/mission-section2.webp`}
            type="image/webp"
          />
          <source
            srcSet={`${config.CDN_URL}/common/images/mission-section2.png`}
            type="image/png"
          />
          <img
            src={`${config.CDN_URL}/common/images/mission-section2.png`}
            alt="Placeholder"
            loading="lazy"
          />
        </ItemPictureStyled1>

        <WrapperText>
          <TextStyled size="md">{t("Mission.section2.text1")}</TextStyled>
          <br />
          <TextStyled>{t("Mission.section2.text2")}</TextStyled>
        </WrapperText>
      </Wrapper>
      <Wrapper>
        <WrapperText>
          <Heading element="h1" size="xl">
            {t("Mission.section3.title")}
          </Heading>
          <TextStyled size="md">{t("Mission.section3.text1")}</TextStyled>
          <br />
          <TextStyled>{t("Mission.section3.text2")}</TextStyled>
        </WrapperText>
        <GifStyled>
          <img src={`${config.CDN_URL}/common/images/mission-section3.gif`} />
        </GifStyled>
      </Wrapper>
    </SectionStyled>
  );
};

const SectionStyled = styled(Section)`
  padding-top: 0;
  padding-bottom: 0;
`;

const ItemPictureStyled1 = styled.picture`
  img,
  source {
    max-width: 120%;
  }
  transform: translateX(-20%);

  flex: 1;
  @media (max-width: ${theme.breakpoints.sm}) {
    display: flex;
    transform: translateX(0%);
    flex-direction: column;
  }
`;

const GifStyled = styled.picture`
  img,
  source {
    max-width: 110%;
  }
  transform: translateX(+15%);
  transform: translateY(-10%);
  @media (max-width: ${theme.breakpoints.sm}) {
    display: flex;
    transform: translateX(0%);
    flex-direction: column;
  }

  flex: 1;
`;

const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Wrapper = styled.div`
  display: flex;
  padding-bottom: ${theme.spacing[2]};
  @media (max-width: ${theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[2]};
  }
`;

const TextStyled = styled(Text)`
  color: ${theme.colors.base.secondary};
`;
