export type Locale = string;

export type TFaqCopies = Record<
  Locale,
  {
    text: string;
  }
>;

export type TFaq = {
  id: number;
  fid: string;
  question: TFaqCopies;
  anwser: TFaqCopies;
} & TFaqTopics;

export enum TTopicEnum {
  GENERAL = 'GENERAL',
  DELIVERY = 'DELIVERY',
  BASKET = 'BASKET',
  BILLING = 'BILLING',
}

export type TFaqTopics = {
  topic_copies: TFaqCopies;
  subtopic_copies: TFaqCopies;
  topic: TTopicEnum;
};
