import React from "react";
import { useTranslation } from "react-i18next";
import nl2br from "react-nl2br";
import styled from "styled-components";
import { Heading } from "../../components/Heading";
import { Image } from "../../components/Image";
import { Section } from "../../components/Section";
import { Text } from "../../components/Text";
import { theme } from "../theme";

export const AlternateLaoutHomePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CommitmentStyled>
      <SectionStyled size="lg">
        <GridSection>
          <ItemPictureStyled1>
            <Image
              path="/common/images/argument-1"
              alt={t("EmptyState.Image.alt")}
            />
          </ItemPictureStyled1>
          <ItemContentStyled1>
            <HeadingStyled element="h1" size="xl">
              {nl2br(t("Home.pitch.slide1.heading"))}
            </HeadingStyled>
            <Text>
              <span>{t("Home.pitch.slide1.text")}</span>
              <br></br>
              <br></br>
            </Text>
          </ItemContentStyled1>
          <ItemContentStyled1>
            <WrapperItems>
              <ImageStyled>
                <Image
                  path="/common/images/carrot-logo"
                  alt={t("EmptyState.Image.alt")}
                />
              </ImageStyled>
              <HeadingStyled element="h1" size="xl">
                {nl2br(t("Home.pitch.slide2.heading"))}
              </HeadingStyled>
              <Text weight="bold">
                <span>{t("Home.pitch.slide2.text")}</span>
                <br></br>
                <br></br>
              </Text>
            </WrapperItems>
          </ItemContentStyled1>
          <ItemPictureStyled2>
            <Image
              path="/common/images/argument-4"
              alt={t("EmptyState.Image.alt")}
            />
          </ItemPictureStyled2>
          <ItemPictureStyled>
            <Image
              path="/common/images/argument-2"
              alt={t("EmptyState.Image.alt")}
            />
          </ItemPictureStyled>
          <ItemContentStyled1>
            <HeadingStyled element="h1" size="xl">
              {nl2br(t("Home.pitch.slide3.heading"))}
            </HeadingStyled>
            <Text>
              <span>{t("Home.pitch.slide3.text")}</span>
              <br></br>
              <br></br>
            </Text>
          </ItemContentStyled1>
        </GridSection>
      </SectionStyled>
    </CommitmentStyled>
  );
};

const GridSection = styled.div`
  display: grid;
  grid-auto-flow: dense;
  @media (min-width: ${theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
`;

const ItemPictureStyled = styled.picture`
  padding-top: ${theme.spacing[2]};
  img,
  source {
    max-width: 100%;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    position: flex;

    max-width: 100%;

    img,
    source {
      max-width: 100%;
    }
  }
`;

const WrapperItems = styled.div`
  display: flex;
  flex-direction: column;
`;
const ItemPictureStyled1 = styled.picture`
  img,
  source {
    max-width: 80%;
  }
  @media (min-width: ${theme.breakpoints.sm}) {
    max-width: 60%;
    margin-left: ${theme.spacing[4]};

    img,
    source {
      max-width: 100%;
    }
  }
`;
const ItemPictureStyled2 = styled.picture`
  img,
  source {
    max-width: 100%;
  }
  @media (min-width: ${theme.breakpoints.sm}) {
    max-width: 100%;

    img,
    source {
      max-width: 100%;
    }
  }
`;
const SectionStyled = styled(Section)`
  position: relative;
  width: 100%;
  padding: 0;
  background-color: ${theme.colors.base.white};
`;

const CommitmentStyled = styled.ul`
  position: relative;
  width: 100%;
  margin-top: ${theme.spacing[2]};
  padding: ${theme.spacing[2]};
`;

const HeadingStyled = styled(Heading)`
  color: ${theme.colors.base.secondary};
`;

const ItemContentStyled1 = styled.div`
  padding: ${theme.spacing[4]};
`;

const ImageStyled = styled.div`
  position: absolute;
  max-width: 40%;
  transform: translateY(-20%) translateX(+110%);

  @media (min-width: ${theme.breakpoints.xs}) {
    transform: translateY(-20%) translateX(+130%);
    max-width: 20%;
  }
`;
