import { TPayload } from "../types/Payload";

/**
 * Parse and format response from the API
 * @param response - The response object fron the API
 * @returns Formated payload
 */
export const handleResponse = (response: Response): Promise<TPayload> => {
  return response.json().then((payload) => {
    if (payload.code > 400) {
      return {
        code: payload.code,
        message: payload.message,
      };
    }

    return {
      code: payload.code,
      payload: payload.payload,
    };
  });
};

export const basicHeader = (): Headers => {
  return new Headers({
    "Content-Type": "application/json",
  });
};

export const authorizationHeader = (): Headers => {
  const token = localStorage.getItem("token");

  if (!token) new Headers({});

  return new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  });
};
