import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { theme } from "../../../../components/theme";

export const Social: React.FC = () => {
  const { t } = useTranslation();

  const onTopClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <SocialStyled>
      <SocialNavigationStyled>
        <li>
          <SocialItemStyled>
            <a
              href="https://www.facebook.com/Marche.SecondLife"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                role="img"
                aria-labelledby="title-fb"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title id="title-fb">Facebook logo</title>
                <path d="M18.2267 0.00499376L15.1804 0C11.7576 0 9.54503 2.3171 9.54503 5.90762V8.63171H6.47919C6.21515 8.62921 6 8.84894 6 9.1186V13.0637C6 13.3333 6.21515 13.5531 6.47919 13.5531H9.54259V23.5106C9.54259 23.7803 9.75773 24 10.0218 24H14.0191C14.2831 24 14.4983 23.7803 14.4983 23.5106V13.5556H18.08C18.344 13.5556 18.5592 13.3358 18.5592 13.0662L18.5616 9.1211C18.5616 8.99126 18.5103 8.86642 18.4223 8.77403C18.3343 8.68165 18.2096 8.62921 18.0824 8.62921H14.4983V6.3196C14.4983 5.21099 14.7575 4.64669 16.173 4.64669H18.2267C18.4907 4.64669 18.7059 4.42697 18.7059 4.1573V0.494382C18.7059 0.224719 18.4907 0.00499376 18.2267 0.00499376Z" />
              </svg>
            </a>
          </SocialItemStyled>
        </li>
        <li>
          <SocialItemStyled>
            <a
              href="https://www.instagram.com/marche.secondlife"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                role="img"
                aria-labelledby="title-insta"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title id="title-insta">Instragram logo</title>
                <path d="M18.7741 24H5.22591C2.34513 24 0 21.6549 0 18.7741V5.22591C0 2.34513 2.34513 0 5.22591 0H18.7741C21.6549 0 24 2.34513 24 5.22591V18.7741C24 21.6581 21.6581 24 18.7741 24ZM5.22591 2.61296C3.78552 2.61296 2.61296 3.78552 2.61296 5.22591V18.7741C2.61296 20.2145 3.78552 21.387 5.22591 21.387H18.7741C20.2145 21.387 21.387 20.2145 21.387 18.7741V5.22591C21.387 3.78552 20.2145 2.61296 18.7741 2.61296H5.22591ZM12 17.8628C8.76647 17.8628 6.13718 15.2335 6.13718 12C6.13718 8.76647 8.76647 6.13718 12 6.13718C15.2335 6.13718 17.8628 8.76647 17.8628 12C17.8628 15.2335 15.2335 17.8628 12 17.8628ZM12 8.75013C10.2069 8.75013 8.75013 10.2069 8.75013 12C8.75013 13.7931 10.2069 15.2499 12 15.2499C13.7931 15.2499 15.2499 13.7931 15.2499 12C15.2499 10.2069 13.7931 8.75013 12 8.75013ZM19.3489 6.0293C19.3489 6.79053 18.7318 7.40763 17.9705 7.40763C17.2093 7.40763 16.5922 6.79053 16.5922 6.0293C16.5922 5.26807 17.2093 4.65097 17.9705 4.65097C18.7318 4.65097 19.3489 5.26807 19.3489 6.0293Z" />
              </svg>
            </a>
          </SocialItemStyled>
        </li>
        <li>
          <SocialItemStyled>
            <a
              href="https://www.pinterest.ca/secondlife_vege"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                role="img"
                aria-labelledby="title-pint"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title id="title-pint">Pinterest logo</title>
                <path d="M19.4172 2.45933C17.7653 0.87319 15.4793 0 12.9798 0C9.16187 0 6.81401 1.56524 5.51584 2.87812C3.91731 4.496 3 6.64414 3 8.77215C3 11.4436 4.11781 13.4942 5.98882 14.2574C6.11422 14.3085 6.24117 14.3349 6.36503 14.3349C6.75982 14.3349 7.07256 14.0763 7.18094 13.6622C7.24441 13.4245 7.39072 12.8377 7.4542 12.5831C7.59044 12.0807 7.48051 11.8384 7.18326 11.4885C6.64216 10.8483 6.3898 10.0904 6.3898 9.10501C6.3898 6.17735 8.56968 3.06623 12.6097 3.06623C15.8153 3.06623 17.8071 4.88847 17.8071 7.82077C17.8071 9.67166 17.4084 11.3855 16.6846 12.6473C16.1814 13.5236 15.2966 14.5686 13.9381 14.5686C13.3505 14.5686 12.8234 14.3271 12.4897 13.9068C12.1755 13.5089 12.0717 12.9957 12.1979 12.46C12.3411 11.8554 12.5354 11.2245 12.7243 10.6137C13.068 9.49981 13.3931 8.44702 13.3931 7.6079C13.3931 6.17193 12.5106 5.2074 11.1962 5.2074C9.52647 5.2074 8.21901 6.90346 8.21901 9.06786C8.21901 10.1292 8.50156 10.9234 8.62851 11.2284C8.41795 12.1194 7.16855 17.4173 6.9309 18.4167C6.79388 18.9996 5.96792 23.6063 7.33498 23.9732C8.87081 24.3858 10.2441 19.8999 10.3834 19.3929C10.4964 18.9803 10.8928 17.422 11.1358 16.4629C11.8774 17.1774 13.0711 17.6596 14.233 17.6596C16.423 17.6596 18.3923 16.6742 19.7787 14.8852C21.1233 13.1497 21.8634 10.7314 21.8634 8.07468C21.8641 5.99931 20.9724 3.95258 19.4172 2.45933Z" />
              </svg>
            </a>
          </SocialItemStyled>
        </li>
      </SocialNavigationStyled>
      <TopStyled>
        <SocialItemStyled onClick={onTopClick}>
          <svg
            role="img"
            aria-labelledby="title-top"
            width="24"
            height="24"
            viewBox="0 0 26 26"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title id="title-top">{t(`Footer.social.backTop`)}</title>
            <path d="M13.1111 0.80957L25.261 12.4735L23.1834 14.6376L13.1111 4.96822L3.03878 14.6376L0.961182 12.4735L13.1111 0.80957Z" />
            <path d="M11.6111 26L11.6111 2H14.6111L14.6111 26H11.6111Z" />
          </svg>
        </SocialItemStyled>
      </TopStyled>
    </SocialStyled>
  );
};

const SocialStyled = styled.div`
  display: flex;
`;

const SocialNavigationStyled = styled.ul`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: ${theme.spacing[2]};
  }
`;

const SocialItemStyled = styled.button`
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.accent.primary};
  }
`;

const TopStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: ${theme.spacing[0.5]};
`;
