import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme";

import { Link } from "../../Link";

export const Navigation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <NavigationStyled>
      <li>
        <Link to="/products" size="sm" weight="bold" className="nostate">
          {t(`Footer.navigation.products`)}
        </Link>
      </li>
      <li>
        <Link to="/about-us" size="sm" weight="bold" className="nostate">
          {t(`Footer.navigation.about`)}
        </Link>
      </li>
      <li>
        <Link to="/mission" size="sm" weight="bold" className="nostate">
          {t("Navigation.mission")}
        </Link>
      </li>
      <li>
        <Link to="/faq" size="sm" weight="bold" className="nostate">
          {t(`Footer.navigation.help`)}
        </Link>
      </li>
      <li>
        <Link to="/sign-up/step-1" size="sm" weight="bold" className="nostate">
          {t(`Footer.navigation.sign-up`)}
        </Link>
      </li>
      <li>
        <Link
          href="mailto:emplois@second-life.ca"
          size="sm"
          weight="bold"
          className="nostate"
        >
          {t(`Footer.navigation.careers`)}
        </Link>
      </li>
      <li>
        <Link
          href="mailto:info@second-life.ca"
          size="sm"
          weight="bold"
          className="nostate"
        >
          {t(`Footer.navigation.press`)}
        </Link>
      </li>
    </NavigationStyled>
  );
};

export const NavigationStyled = styled.ul`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  flex: 1;
  max-width: 320px;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;

  @media (min-width: ${theme.breakpoints.md}) {
    height: 110px;
  }

  li {
    margin-top: 2px;
    margin-bottom: 2px;
  }
`;
