import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { pageview } from "react-ga";
import { ThankYouState } from "../../components/ThankYouState";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { useSubscription } from "../../hooks/Subscriptions";
import { useHistory, useLocation } from "react-router-dom";

export const ThankYou: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: sub } = useSubscription();
  const path = useLocation();

  useEffect(() => {
    if (sub?.status === "active") {
      pageview(path.pathname);
    }

    if (sub && sub?.status !== "active") {
      history.push("/");
    }
  }, [sub]);
  return (
    <Page centerX centerY>
      <SectionStyled centerX>
        <ThankYouState
          title={{
            label: t("Thanks"),
            element: "h1",
          }}
          action={{
            label: t("Thanks.back"),
            to: "/dashboard",
          }}
        />
      </SectionStyled>
    </Page>
  );
};

const SectionStyled = styled(Section)`
  min-height: 60vh;
  padding-top: 0;
`;
