import React from 'react';
import styled, { css } from 'styled-components';

import { theme } from '../theme';

interface Props {
  direction?: 'row' | 'column';
  space?: 'around' | 'between';
  className?: string;
}

const Stack: React.FC<Props> = ({
  direction = 'row',
  space = 'flex-start',
  className,
  children
}) => {
  return <div className={className}>{children}</div>;
}

export const StackStyled = styled(Stack)`
  display: flex;
  width: 100%;
  align-items: center;

  & > * { margin-left: ${theme.spacing[1]}; }
  & > :first-child { margin-left: 0; }

  ${props => props.direction === 'column' && css`
    flex-direction: column;

    & > * { margin-top: ${theme.spacing[1]}; }
    & > :first-child { margin-top: 0; }
  `}

  ${props => props.space === 'around' && css`
    justify-content: space-around;

    & > * {
      margin-top: 0;
      margin-left: 0;
    }
  `}

  ${props => props.space === 'between' && css`
    justify-content: space-between;

    & > * {
      margin-top: 0;
      margin-left: 0;
    }
  `}

  @media (max-width: ${theme.breakpoints.sm}) {
    ${props => props.direction === 'row' ? css`flex-direction: row;` : css`
    flex-direction: column;
    & > * { margin-left: 0; width:100%; text-align:center; }
    & > * { margin-top: ${theme.spacing[1]}; }
    & > :first-child { margin-top: 0; }`

  }
`;

export const StackMemoized = React.memo(StackStyled);
