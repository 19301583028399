import { useQuery } from "react-query";
import config from "../../config";
import axios from "redaxios";

import { TSubscriptionComplete } from "../../types//Subscription";
import { TPlanQuantity } from "../../types//Plan";

const apiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/subscriptions`;
const apiUrlCashier = `${config.SERVICES.MARKETPLACE_API_URL}/orders`;

/**
 * Active prime subscription
 * @returns The Subscription object
 */

export const primeSubscription = (payload: { lookup_key: string }) => {
  const { lookup_key } = payload;
  const token = localStorage.getItem("token");
  return axios
    .post(
      `${apiUrl}/prime`,
      { lookup_key },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data.payload);
};

/**
 * Fetch a subscription
 * @param uid - The uid of the user
 * @returns The Subscription object
 */

export const useSubscription = () => {
  const token = localStorage.getItem("token");

  return useQuery<TSubscriptionComplete>(
    ["useSubscription", token],
    () =>
      axios
        .get(`${apiUrl}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data.payload),
    {
      // The query will not execute until the userId exists
      enabled: !!token,
    }
  );
};

/**
 * Change current card to a new card
 * @param uid - The id of the User
 * @param paymentMethodId - paymentMethodId from stripe
 * @returns The Subscription object
 */
export const useChangeCard = (payload: { paymentMethodId: string }) => {
  const token = localStorage.getItem("token");
  const { paymentMethodId } = payload;
  return axios
    .put(
      `${apiUrl}/card/change`,
      { paymentMethodId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data.payload);
};

/**
 * Add new card
 * @param uid - The id of the User
 * @param paymentMethodId - paymentMethodId from stripe
 * @returns The Subscription object
 */
export const useAddCard = async (payload: {
  paymentMethodId: string;
  code: string;
}) => {
  const token = localStorage.getItem("token");
  const { paymentMethodId, code } = payload;
  const subscription = await axios
    .post(
      `${apiUrl}/card`,
      { paymentMethodId, code },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data.payload);

  // create order
  await axios.post(`${apiUrlCashier}`, null, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return subscription;
};

/**
 * Put subscription on paused and remove from stripe
 * @param uid - The uid of the user
 * @returns The Subscription object
 */
export const useReactivateSubscription = () => {
  const token = localStorage.getItem("token");
  return axios
    .put(`${apiUrl}/card`, null, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data.payload);
};

/**
 * Add a new plan
 * @param uid - The id of the User
 * @param pid - The id of the Plan
 * @param quantity - The quantity for the Plan
 * @returns The Plan object
 */
export const useAddPlan = (payload: {
  pid: string;
  quantity: TPlanQuantity;
}) => {
  const { pid, quantity } = payload;
  const token = localStorage.getItem("token");
  return axios
    .post(
      `${apiUrl}/plan`,
      { pid, quantity },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data.payload);
};

/**
 * Remove an existing plan
 * @param uid - The id of the User
 * @param pid - The id of the Plan
 * @returns The Plan object
 */
export const useRemovePlan = (payload: { pid: string }) => {
  const { pid } = payload;
  const token = localStorage.getItem("token");
  return axios
    .delete(`${apiUrl}/plan/${pid}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data.payload);
};

/**
 * Set the user list of plans
 * @param plans - The list of the user plans
 * @returns The Plan object
 */
export const useSetPlans = (payload: {
  plans: { pid: string; persons: number }[];
}) => {
  const { plans } = payload;
  const token = localStorage.getItem("token");
  return axios
    .put(
      `${apiUrl}/plan`,
      { plans },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data.payload);
};
