// @flow
import * as React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { TProduct } from "../../types/Product";
import { Product } from "../Product";
import { theme } from "../theme";
import { TOrder } from "../../types/Order";

const settings = {
  dots: false,
  slidesToShow: 4,
  slidesToScroll: 2,
  infinite: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 870,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        autoplay: false,
      },
    },

    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        autoplay: false,
      },
    },
  ],
};

type Props = {
  products: TProduct[];
  withBannerProduct: boolean;
  isPreOrder: boolean;
  order?: TOrder;
};
export const CarouselProducts: React.FC<Props> = ({
  products,
  withBannerProduct = true,
  isPreOrder = false,
  order,
}) => {
  return (
    <Wrapper>
      <Slider {...settings}>
        {products.map((product) => (
          <Product
            order={order}
            isPreOrder={isPreOrder}
            withBanner={withBannerProduct}
            key={product.pid}
            product={product}
            onSelectProduct={() => {}}
          />
        ))}
      </Slider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .product-card {
    // box-shadow: none !important;
  }

  .slick-arrow::before {
    color: black;
    @media (max-width: ${theme.breakpoints.sm}) {
      display: none !important;
    }
  }

  .slick-track {
    display: flex;
    grid-gap: 10px;

    @media (min-width: ${theme.breakpoints.sm}) {
      min-height: 520px;
    }
  }

  .slick-dots {
    display: none !important;
    position: static;
    bottom: 0;
    margin-bottom: 1rem;
  }
`;
