import { TFaq, TFaqTopics, TTopicEnum } from "../../types/Faq";
import { useQuery } from "react-query";
import axios from "redaxios";
import config from "../../config";

const apiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/faqs`;

/**
 * Get all faq from specific topic
 * @returns A payload containing a list of all faq
 */
export const useFaqByTopic = (topic: TTopicEnum) => {
  return useQuery<TFaq[]>(
    ["useFaqByTopic", topic],
    () =>
      axios
        .get(`${apiUrl}/topics/${topic}`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => res.data.payload),
    {
      enabled: !!topic,
    },
  );
};

/**
 * Get all topics
 * @returns A payload containing a list of all topics
 */
export const useTopics = () => {
  return useQuery<TFaqTopics[]>(["useTopics"], () =>
    axios
      .get(`${apiUrl}/topics`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data.payload),
  );
};
