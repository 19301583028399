import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Link } from "../../Link";
import { theme } from "../../theme";
import { useProcessingOrders } from "../../../hooks/Orders";

export const Cart: React.FC = () => {
  const { t } = useTranslation();
  const { data: order } = useProcessingOrders();

  const currentOrder = order;
  const products = currentOrder?.products || [];

  return (
    <CartLinkStyled to="/cart">
      <span>{t("Navigation.cart")}</span>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19.3568 7.82171V5.13593C19.3568 3.40611 17.976 2 16.2462 2H7.77408C6.04426 2 4.63815 3.40611 4.63815 5.13593V7.82171H1.36565C0.612013 7.82171 0 8.43372 0 9.18736V12.1513C0 12.905 0.612013 13.517 1.36565 13.517H1.77028L3.26238 21.7058C3.37871 22.3532 3.94521 22.8236 4.60274 22.8236H19.4225C20.0801 22.8236 20.6466 22.3532 20.7629 21.7007L22.255 13.517H22.6344C23.388 13.517 24 12.905 24 12.1513V9.18736C24 8.43372 23.388 7.82171 22.6344 7.82171H19.3568ZM7.16712 5.13593C7.16712 4.80717 7.44531 4.52898 7.77408 4.52898H16.2512C16.58 4.52898 16.8329 4.80717 16.8329 5.13593V7.82171H7.16712V5.13593ZM9.05374 17.9528C9.05374 18.4788 8.63393 18.9037 8.1079 18.9037C7.58188 18.9037 7.16206 18.4788 7.16206 17.9528V12.7431C7.16206 12.2171 7.58188 11.7922 8.1079 11.7922C8.63393 11.7922 9.05374 12.2171 9.05374 12.7431V17.9528ZM12.9585 17.9528C12.9585 18.4788 12.5387 18.9037 12.0126 18.9037C11.4866 18.9037 11.0668 18.4788 11.0668 17.9528V12.7431C11.0668 12.2171 11.4866 11.7922 12.0126 11.7922C12.5387 11.7922 12.9585 12.2171 12.9585 12.7431V17.9528ZM16.8632 17.9528C16.8632 18.4788 16.4434 18.9037 15.9224 18.9037C15.3964 18.9037 14.9766 18.4788 14.9766 17.9528V12.7431C14.9766 12.2171 15.3964 11.7922 15.9224 11.7922C16.4485 11.7922 16.8632 12.2171 16.8632 12.7431V17.9528Z" />
      </svg>
      {products.length > 0 && order?.status === "open" && (
        <CartQuantityStyled>{products.length}</CartQuantityStyled>
      )}
    </CartLinkStyled>
  );
};

const CartLinkStyled = styled(Link)`
  position: relative;
  padding-left: ${theme.spacing[1]};
  border-left: 2px solid ${theme.colors.base.dark};
  font-weight: ${theme.font.weight.black};

  svg {
    width: 20px;
    height: 20px;
    margin-left: ${theme.spacing[0.5]};
    fill: currentColor;
  }
`;

const CartQuantityStyled = styled.span`
  position: absolute;
  top: -2px;
  right: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: ${theme.border.radius.round};
  background-color: ${theme.colors.base.primary};
  color: ${theme.colors.base.light};
  font-size: ${theme.font.size.xxs};
`;
