import config from "../../config";
import axios from "redaxios";
import { TFormWaitingList } from "../../pages/WaitingList/WaitingList";

const apiUrlPUP = `${config.SERVICES.MARKETPLACE_API_URL}/users/pickup-points`;
const apiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/users`;

/**
 * update frequency
 * @param frequency - frequency 7 / 14
 * @returns The Subscription object
 */
export const useAddWaitingList = (payload: { ppid: string }) => {
  const token = localStorage.getItem("token");
  const { ppid } = payload;
  return axios
    .post(
      `${apiUrlPUP}/${ppid}/waiting-list`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data.payload);
};

/**
 * add external user to waiting lists
 * @param inputs - paymentMethodId from stripe
 * @returns The Subscription object
 */
export const useAddPublicUserToWaitingList = (input: TFormWaitingList) => {
  return axios.post(`${apiUrl}/waiting-list`, input, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
