import config from "../../config";
import axios from "redaxios";
import { useQuery } from "react-query";
import { TFrequenceEnum } from "../../types/NextDeliveriesFrequencies";
import { TNextDeliveries } from "../../types/NextDeliveries";

const apiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/next-deliveries`;

/**
 * update frequency
 * @param frequency - frequency 7 / 14
 * @returns The Subscription object
 */
export const useUpdateFrequency = (payload: { frequency: TFrequenceEnum }) => {
  const token = localStorage.getItem("token");
  const { frequency } = payload;
  return axios
    .put(
      `${apiUrl}/frequence/${frequency}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data.payload);
};
/**
 * fetch next deliveries from user
 * @returns The TNextDeliveries object
 */
export const useNextDeliveries = () => {
  const token = localStorage.getItem("token");
  return useQuery<TNextDeliveries>(
    ["useNextDeliveries", token],
    () =>
      axios
        .get(`${apiUrl}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data.payload),
    {
      // The query will not execute until the userId exists
      enabled: !!token,
    }
  );
};
