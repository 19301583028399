import React from 'react';
import { SubmitHandler, UseFormMethods } from "react-hook-form";

interface Props {
  useForm: UseFormMethods<Record<string, any>>;
  onSubmit: SubmitHandler<Record<string, any>>;
  className?: string;
}

export const Form: React.FC<Props> = ({ useForm, onSubmit, className, children }) => {
  const { handleSubmit } = useForm;

  return (
    <form
      className={className}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      {children}
    </form>
  );
}
