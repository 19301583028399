import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../theme";

import { ChoiceListItem } from "./ChoiceListItem";
import { useLocation } from "react-router-dom";

export type TChoice = {
  day: string;
  time: string;
  value: string;
  text?: string;
  active?: boolean;
};

interface Props {
  shouldReset: boolean;
  selectedChoice?: TChoice;
  choices: TChoice[];
  onSelect: (choice: TChoice) => void;
}

export const ChoiceList: React.FC<Props> = ({
  shouldReset,
  selectedChoice,
  choices,
  onSelect = (choice: TChoice) => {},
}) => {
  const [localeChoices, setLocaleChoices] = useState<TChoice[]>(
    JSON.parse(JSON.stringify(choices))
  );
  const path = useLocation();

  const selectHandle = (index: number) => {
    setLocaleChoices(
      localeChoices.map((choice) => {
        choice.active = localeChoices[index].value === choice.value;
        return choice;
      })
    );

    onSelect(choices[index]);
  };
  useEffect(() => {
    if (selectedChoice) {
      const choicesCopy: TChoice[] = JSON.parse(JSON.stringify(choices));
      const index = choicesCopy.findIndex(
        (choice) => choice.value === selectedChoice.value
      );
      if (index >= 0) {
        choicesCopy[index].active = true;
        setLocaleChoices(choicesCopy);
      }
    }
  }, [selectedChoice, choices]);

  useEffect(() => {
    if (!localeChoices || localeChoices.length === 0 || choices) {
      setLocaleChoices(JSON.parse(JSON.stringify(choices)));
    }
  }, [choices]);

  useEffect(() => {
    if (shouldReset) {
      setLocaleChoices(JSON.parse(JSON.stringify(choices)));
    }
  }, [shouldReset]);

  return (
    <ChoiseListStyled role="radiogroup">
      {localeChoices.length > 0 &&
        localeChoices.map((choice, index) => (
          <ChoiceListItem
            choice={choice}
            index={index}
            onSelect={selectHandle}
            key={choice.value}
            isSignup={path.pathname === "/sign-up/step-1" ? true : false}
          />
        ))}
    </ChoiseListStyled>
  );
};

const ChoiseListStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${theme.spacing[0.25]};
`;
