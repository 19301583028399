import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AlertBanner } from "../AlertBanner";
import { Plan } from "../Plan";
import { theme } from "../theme";
import { useAppState } from "../../contexts";
import { TPlan, TPlanQuantity } from "../../types/Plan";
import { getDefaultQuantity } from "../../utils/Plans";

interface Props {
  userPlans: TPlan[];
  plans: TPlan[];
  onAddPlan: (plan: TPlan, persons: TPlanQuantity) => void;
  onDeletePlan: (plan: TPlan) => void;
}

export const Plans: React.FC<Props> = ({
  userPlans = [],
  plans = [],
  onAddPlan,
  onDeletePlan,
}) => {
  const { errors: appErrors } = useAppState();

  return (
    <div>
      <div>
        {appErrors &&
          appErrors.map((error: any, i: number) => (
            <AlertBanner type="error" key={i}>
              {error.code} : {error.message}
            </AlertBanner>
          ))}
      </div>
      <>
        {plans && plans.length > 0 && (
          <GridStyled>
            {plans.map((plan, index) => {
              const correspondingUserPlan = userPlans.find(
                (p) => p.pid === plan.pid
              );
              return (
                <Plan
                  plan={plan}
                  isActive={!!correspondingUserPlan}
                  quantity={
                    correspondingUserPlan
                      ? correspondingUserPlan.default_persons
                      : getDefaultQuantity(plan)
                  }
                  onSelect={onAddPlan}
                  onDelete={onDeletePlan}
                  index={index}
                  key={plan.pid}
                />
              );
            })}
          </GridStyled>
        )}
      </>
    </div>
  );
};

const WrapperFlex = styled.div``;

const GridStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing[1]};
  align-items: center; // Align items in the center horizontally in the flex direction

  @media (min-width: ${theme.breakpoints.md}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; // Center items horizontally in the row
    align-items: flex-start; // Align items at the start of the flex container vertically
  }
`;
