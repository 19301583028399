import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { heartBeat } from "../../animations";
import { theme } from "../../components/theme";

export const Navigation: React.FC = ({ children }) => {
  const { t } = useTranslation();

  const links = [
    {
      label: t(`Navigation.dashboard`),
      url: "/dashboard",
    },
    {
      label: t(`Navigation.personal-infos`),
      url: "/account",
    },
    {
      label: t(`Navigation.order-history`),
      url: "/order-history",
    }
  ];

  return (
    <NavigationWrapper>
      <NavigationStyled>
        {links.map((link, index) => (
          <li key={index}>
            <LinkStyled to={link.url} activeClassName="active" exact>
              {link.url === "/adhesions" ? (
                <Animated>{link.label}</Animated>
              ) : (
                link.label
              )}
            </LinkStyled>
          </li>
        ))}
      </NavigationStyled>
      <ButtonWrapper>{children}</ButtonWrapper>
    </NavigationWrapper>
  );
};
const Animated = styled.p`
    animation: ${heartBeat} 4s infinite;
    color: ${theme.colors.base.third};
`;
const NavigationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const NavigationStyled = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;

    li {
        margin-left: ${theme.spacing[1]};
        margin-right: ${theme.spacing[1]};

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    @media (min-width: ${theme.breakpoints.sm}) {
        justify-content: flex-start;
    }
`;

const LinkStyled = styled(RouterLink)`
    display: flex;
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.size.mdx};
    line-height: ${theme.font.size.lg};
    font-weight: ${theme.font.weight.bold};
    text-align: center;

    &.active {
        color: ${theme.colors.base.primary};
    }

    &:hover {
        color: ${theme.colors.base.primary};
    }
`;

const ButtonWrapper = styled.div`
    display: none;

    @media (min-width: ${theme.breakpoints.sm}) {
        display: flex;
        flex-direction: row;
    }
`;
