import React from "react";
import styled from "styled-components";
import { Heading } from "../../components/Heading";
import { Section } from "../../components/Section";
import { theme } from "../../components/theme";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { useTranslation } from "react-i18next";
import { Stack } from "../../components/Stack";
import { useUser } from "../../hooks/Users";

interface Props {
  title: string;
  datas: { icon: any; text: string }[];
  backgroundColor: string;
}

export const JoinUsMsl: React.FC<Props> = ({
  title,
  datas = [],
  backgroundColor,
}) => {
  const { t } = useTranslation();
  const { data: user } = useUser();

  return (
    <SectionStyled backgroundColor={backgroundColor}>
      <HeadingStyled size="xl">{t(title)}</HeadingStyled>

      <TextStyledIcon>
        <WrapperRow>
          {datas.map((data) => (
            <WrapperIcon>
              {data.icon}
              <TextStyled>{t(data.text)}</TextStyled>
            </WrapperIcon>
          ))}
        </WrapperRow>
      </TextStyledIcon>
      <ButtonStyled>
        <Stack>
          {user ? (
            <Button to="/dashboard">{t("Msl.explanation.button")}</Button>
          ) : (
            <Button to="/login">{t("Home.Hero.action")}</Button>
          )}
        </Stack>
      </ButtonStyled>
    </SectionStyled>
  );
};

const TextStyled = styled(Text)`
  text-align: center;
  font-size: ${theme.font.size.mdx};
  font-weight: ${theme.font.weight.bold};

  color: ${theme.colors.base.secondary};
`;

const SectionStyled = styled(Section)`
  display: flex;
  flex-direction: column;
`;

const HeadingStyled = styled(Heading)`
  align-self: center;
  text-align: center;
  /* color: ${theme.colors.accent.primary}; */
`;
const ButtonStyled = styled.div`
  align-self: center;
  text-align: center;
  @media (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const WrapperIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing[0.25]};
  align-self: flex-start;
  align-content: flex-start;
  width: 400px;
  @media (max-width: ${theme.breakpoints.sm}) {
    align-self: center;
    align-content: center;
  }
  svg {
    width: 60px;
    height: 60px;
    fill: ${theme.colors.base.primary};
  }
`;

const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${theme.spacing[2]};
  padding-left: ${theme.spacing[2]};
  padding-right: ${theme.spacing[2]};
  align-items: center;
  align-self: center;
  justify-content: center;
  gap: ${theme.spacing[2]};

  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const TextStyledIcon = styled(Text)`
  white-space: pre-line;
  text-align: center;
  font-size: ${theme.font.size.mdx};
  font-weight: ${theme.font.weight.bold};
`;
