import React from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Link } from "../../Link";
import { Text } from "../../Text";
import { theme, spacing } from "../../theme";
import config from "../../../config";

export const Info: React.FC = () => {
  const { t } = useTranslation();

  return (
    <InfoStyled>
      <RouterLink to="/">
        <ImgStyled
          src={`${config.CDN_URL}/common/images/logo1.svg`}
          alt={t(`Footer.info.logo-alt`)}
        />
      </RouterLink>
      <Text>{t(`Footer.info.days`)}</Text>
      <Text>{t(`Footer.info.hours`)}</Text>
      <Text>—</Text>
      <Link weight="light" href="mailto:info@second-life.ca">
        {t(`Footer.info.email`)}
      </Link>
      <Link weight="light" href="tel:15142469020">
        514 246-9020
      </Link>
    </InfoStyled>
  );
};

const InfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  grid-area: info;
`;

const ImgStyled = styled.img`
  display: none;
  height: ${spacing(6)};
  margin-bottom: ${theme.spacing[1]};
  color: ${theme.colors.base.dark};
  fill: currentColor;

  &:hover {
    color: ${theme.colors.accent.primary};
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    display: inline;
  }
`;
