import config from '../config';

export const getNavigatorLocale = (navigator: Navigator) => {
  const locale = navigator.language.substring(0, 2);

  if (locale === 'en') {
    return 'en-CA';
  }

  if (locale === 'fr') {
    return 'fr-CA';
  }

  return config.DEFAULT_LOCALE || 'en-CA';
}
