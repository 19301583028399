import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import styled from "styled-components";
import { AlertBanner } from "../../components/AlertBanner";
import { Button } from "../../components/Button";
import { Form } from "../../components/Form";
import { FormControl } from "../../components/FormControl";
import { FormInput } from "../../components/FormInput";
import { FormInputControl } from "../../components/FormInputControl";
import { FormLabel } from "../../components/FormLabel";
import { FormRadio } from "../../components/FormRadio";
import { SelectOption } from "../../components/FormSelect/FormSelect";
import { Hero } from "../../components/Hero";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { theme } from "../../components/theme";
import { useAppState } from "../../contexts";
import { useAddPublicUserToWaitingList } from "../../hooks/WaitingList";
import { AddressAutocomplete } from "../../components/Delivery/AddressForm/AddressAutocomplete";
import PhoneInput from "react-phone-input-2";
import { Snackbar } from "../../components/Snackbar";
export declare type TFormWaitingList = {
  first_name: string;
  last_name: string;
  email: string;
  zip_code: string;
  phone: string;
  entity_type: string;
  streetNumber: string;
  address: string;
  city: string;
  province: string;
  country: string;
};

export const WaitingList: React.FC = () => {
  const { t } = useTranslation();
  const methods = useForm();
  const { errors } = methods;
  const { errors: appErrors } = useAppState();

  const { mutate, isSuccess } = useMutation(useAddPublicUserToWaitingList);
  const [isValidPhone, setIsValidPhone] = useState(false);

  const [inputs, setInputs] = useState<TFormWaitingList>({
    first_name: "",
    last_name: "",
    email: "",
    zip_code: "",
    phone: "",
    entity_type: "LAD",
    streetNumber: "",
    address: "",
    city: "",
    province: "",
    country: "",
  });

  const setAddress = async (placeTerms: any) => {
    if (!placeTerms) return;

    const newAddress = {
      streetNumber: "",
      address: "",
      city: "",
      province: "",
      zip_code: "",
      country: "",
    };

    placeTerms.forEach((term: any) => {
      if (term.types.includes("street_number"))
        newAddress["streetNumber"] = term.long_name;
      if (term.types.includes("route")) newAddress["address"] = term.long_name;
      if (term.types.includes("locality")) newAddress["city"] = term.long_name;
      if (term.types.includes("administrative_area_level_1"))
        newAddress["province"] = term.short_name;
      if (term.types.includes("postal_code"))
        newAddress["zip_code"] = term.long_name;
      if (term.types.includes("country"))
        newAddress["country"] = term.short_name;
    });

    let addressfinal = `${newAddress.streetNumber} ${newAddress.address}`;
    if (addressfinal === " ") {
      addressfinal = "";
    }

    setInputs({
      ...inputs,
      ...newAddress,
      address: addressfinal,
    });
  };

  const options = [
    {
      label: `${t(`Dashboard.delivery.type.LAD`)}`,
      value: "LAD",
    },
    { label: `${t(`Dashboard.delivery.type.PUP`)}`, value: "PUP" },
  ] as SelectOption[];

  const onSubmitHandler = () => {
    mutate(inputs);
  };

  return (
    <Page centerX centerY>
      <Section>
        <Hero
          image="/common/images/hero-2"
          imageAlt={t("Becoming.Hero.imageAlt")}
          title={t("WaitingList.Hero.heading")}
        />
      </Section>
      <Section>
        <FormStyled useForm={methods} onSubmit={onSubmitHandler}>
          <FormControl columns={1}>
            <AddressAutocomplete
              setLatLng={(latLng) => {}}
              setValue={setAddress}
              locale="fr-CA"
              region="ca"
            />
          </FormControl>
          <FormControl columns={1}>
            <FormInputControl>
              <FormLabel target="address">
                {t("Signup.step3.form-address")}
              </FormLabel>
              <FormInput
                id="zipcode"
                type="text"
                disabled={true}
                name="address"
                value={inputs.address || ""}
                placeholder={t("Signup.step3.form-address")}
                register={methods.register}
                validation={require}
              />
            </FormInputControl>
            <FormInputControl>
              <FormLabel target="phone">
                {t("Signup.step3.form-city")}
              </FormLabel>
              <FormInput
                id="phone"
                type="text"
                name="city"
                disabled={true}
                value={inputs.city || ""}
                placeholder={t("Signup.step3.form-city")}
                error={errors.phone}
                onChange={(value) => setInputs({ ...inputs, phone: value })}
                register={methods.register}
                validation={require}
              />
            </FormInputControl>

            <FormInputControl>
              <FormLabel target="zip_code">
                {t("Signup.step2.zipCode.label")}
              </FormLabel>
              <FormInput
                id="zipcode"
                type="text"
                disabled={true}
                name="zipcode"
                value={inputs.zip_code || ""}
                placeholder={t("Signup.step2.zipCode.placeholder")}
                error={errors.zipcode}
                onChange={(value) => setInputs({ ...inputs, zip_code: value })}
                register={methods.register}
                validation={require}
              />
            </FormInputControl>
          </FormControl>

          <FormControl>
            {appErrors &&
              appErrors.map((error: any, i: number) => (
                <AlertBanner type="error" key={i}>
                  {error.code} : {error.message}
                </AlertBanner>
              ))}
          </FormControl>

          <FormControl columns={1}>
            <FormInputControl
              errors={
                errors.firstName && [
                  t(`Signup.step2.firstName.errors.${errors.firstName.type}`),
                ]
              }
            >
              <FormLabel target="firstName">
                {t("Signup.step2.firstName.label")}
              </FormLabel>
              <FormInput
                id="firstName"
                type="text"
                name="firstName"
                value={inputs.first_name}
                placeholder={t("Signup.step2.firstName.placeholder")}
                error={errors.firstName}
                onChange={(value) =>
                  setInputs({ ...inputs, first_name: value })
                }
                register={methods.register}
                validation={{
                  required: true,
                }}
              />
            </FormInputControl>

            <FormInputControl
              errors={
                errors.lastName && [
                  t(`Signup.step2.lastName.errors.${errors.lastName.type}`),
                ]
              }
            >
              <FormLabel target="lastName">
                {t("Signup.step2.lastName.label")}
              </FormLabel>
              <FormInput
                id="lastName"
                type="text"
                name="lastName"
                value={inputs.last_name}
                placeholder={t("Signup.step2.lastName.placeholder")}
                error={errors.lastName}
                onChange={(value) => setInputs({ ...inputs, last_name: value })}
                register={methods.register}
                validation={{
                  required: true,
                }}
              />
            </FormInputControl>
          </FormControl>

          <FormControl columns={1}>
            <FormInputControl
              errors={
                errors.email && [
                  t(`Signup.step2.email.errors.${errors.email.type}`),
                ]
              }
            >
              <FormLabel target="email">
                {t("Signup.step2.email.label")}
              </FormLabel>
              <FormInput
                id="email"
                type="text"
                name="email"
                value={inputs.email || ""}
                placeholder={t("Signup.step2.email.placeholder")}
                error={errors.email}
                onChange={(value) => setInputs({ ...inputs, email: value })}
                register={methods.register}
                validation={{
                  required: true,
                }}
              />
            </FormInputControl>
          </FormControl>
          <FormControl columns={1}>
            <PhoneInput
              preferredCountries={["ca"]}
              country={"ca"}
              onlyCountries={["ca", "us", "fr"]}
              value={inputs.phone}
              inputProps={{
                name: "phone",
                required: true,
              }}
              isValid={(value) => {
                if (value.length < 11) {
                  setIsValidPhone(false);
                  return false;
                } else {
                  setIsValidPhone(true);
                  return true;
                }
              }}
              onChange={(value) => setInputs({ ...inputs, phone: value })}
            />
          </FormControl>

          <FormControl columns={1}>
            <FormInputControl
              errors={
                errors.entity_type && [
                  t(
                    `Signup.step2.entity_type.errors.${errors.entity_type.type}`
                  ),
                ]
              }
            >
              <FormRadio
                id="entity_type"
                name="entity_type"
                value={inputs.entity_type || ""}
                error={errors.entity_type}
                options={options}
                onChange={(value) =>
                  setInputs({ ...inputs, entity_type: value })
                }
                register={methods.register}
                validation={{
                  required: true,
                }}
              />
            </FormInputControl>
          </FormControl>
          <FormControl>
            <Button disabled={!isValidPhone} type="submit">
              {t("Becoming.action")}
            </Button>
          </FormControl>
        </FormStyled>
        {isSuccess && <Snackbar type="success" text={t('WaitingList.success')}/>}
      </Section>
    </Page>
  );
};

const FormStyled = styled(Form)`
  background-color: ${theme.colors.accent.primary};
  padding: ${theme.spacing[2]};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
