import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppState } from "../../contexts";
import { TPlan, TPlanQuantity } from "../../types/Plan";
import { Button } from "../Button";
import { Heading } from "../Heading";
import { Label } from "../Label";
import { Text } from "../Text";
import { theme } from "../theme";
import { PlanPrice } from "./PlanPrice";
import { FormControl } from "../FormControl";
import { FormInputControl } from "../FormInputControl";
import { FormTagRadio } from "../FormTagRadio";

interface Props {
  plan: TPlan;
  quantity: TPlanQuantity;
  onValidate: (persons: TPlanQuantity) => void;
  onBack: () => void;
}

export const SelectQuantity: React.FC<Props> = ({
  plan,
  quantity,
  onValidate,
  onBack,
}) => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const [lang] = locale.split("-");
  const [quantityIndex, setQuantityIndex] = useState<TPlanQuantity>(quantity);
  const [status, setStatus] = useState<string>(`${quantity}`);

  const quantities: Array<number> = plan.options
    .map((option) => option.persons)
    .sort((a, b) => a - b);

  const setQuantity = () => onValidate(quantityIndex);

  let options = quantities.map((q) => ({
    label: `${q}`,
    value: `${q}`,
    complement: "",
  }));

  if (plan.pid === "1009") {
    options = quantities.map((q, i) => {
      let label;
      if (q === 10) {
        label = `-${q}`;
      } else if (q === 20) {
        label = `10-${q}`;
      } else if (q === 21) {
        label = `${q}+`;
      } else {
        // Handle any other case if needed
        label = `${q}`;
      }
      return {
        label,
        value: `${q}`,
        complement: "",
      };
    });
  }
  useEffect(() => {
    setQuantityIndex(quantity);
  }, [quantity]);

  return (
    <VerticalContainer>
      <HeadingStyled color size="lg">
        {t("Navigation.cart")} : {plan.copy[lang].name}
      </HeadingStyled>
      <ContentStyled>
        <QuantityStyled>{t("Signup.persons")} : </QuantityStyled>
        <FormControl columns={2}>
          <FormInputControl widthRatio={1}>
            <FormTagRadioStyled
              id="qty"
              name="qty"
              value={status}
              options={options}
              onChange={(value) => {
                if (value) {
                  setStatus(String(`${value}`));
                  setQuantityIndex(Number(value) as TPlanQuantity);
                }
              }}
              register={() => {}}
              validation={{
                required: true,
              }}
            />
          </FormInputControl>
        </FormControl>
      </ContentStyled>

      <PriceStyled>
        <PlanPrice plan={plan} persons={quantityIndex as TPlanQuantity} />
      </PriceStyled>
      <ButtonContainer>
        <ButtonStyled primary onClick={onBack}>
          {t("Common.action.back")}
        </ButtonStyled>
        <ButtonStyled secondary onClick={setQuantity}>
          {t("Signup.step1.bundles.quantity.validation")}
        </ButtonStyled>
      </ButtonContainer>
    </VerticalContainer>
  );
};
const HeadingStyled = styled(Heading)`
  color: ${theme.colors.base.secondary};
  margin: 0;
`;
const FormTagRadioStyled = styled(FormTagRadio)`

> div >

`;

const ContentStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${theme.spacing[1]};
  gap: ${theme.spacing[1]};
`;

const VerticalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiBox-root {
    width: 30%;

    .MuiSlider-track {
      color: ${theme.colors.base.primary};
    }

    .MuiSlider-rail {
      color: ${theme.colors.base.secondary};
    }

    .MuiSlider-markActive {
      width: 6px;
      height: 6px;
      background: ${theme.colors.base.primary};
      border-radius: 50%;
    }

    .MuiSlider-mark:not(.MuiSlider-markActive) {
      width: 6px;
      height: 6px;
      background: ${theme.colors.base.secondary};
      border-radius: 50%;
    }

    .MuiSlider-thumbColorPrimary {
      color: ${theme.colors.base.primary};
    }

    .MuiSlider-thumb.Mui-active {
      box-shadow: 0px 0px 0px 14px rgb(238 83 64 / 16%);
    }
  }
`;

const QuantityStyled = styled.p`
  color: ${theme.colors.base.primary};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.xxl};
  font-weight: ${theme.font.weight.black};
  line-height: ${theme.font.size.xxxl};
  text-align: center;

  @media (min-width: ${theme.breakpoints.sm}) {
    margin: ${theme.spacing[1]};
  }
`;

const PriceStyled = styled.p`
  color: ${theme.colors.base.primary};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.lg};
  font-weight: ${theme.font.weight.bold};
  line-height: ${theme.font.size.xl};
  text-align: center;
  @media (min-width: ${theme.breakpoints.sm}) {
    margin: ${theme.spacing[1.5]} ${theme.spacing[1.5]} ${theme.spacing[0.25]}
      ${theme.spacing[1.5]};
  }
`;

const ButtonStyled = styled(Button)``;
