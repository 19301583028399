import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { EmptyState } from '../../components/EmptyState';
import { Page } from '../../components/Page';
import { Section } from '../../components/Section';

export const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page centerX centerY>
      <SectionStyled centerX>
        <EmptyState
          title={{
            label: t('NotFound.heading'),
            element: 'h1'
          }}
          action={{
            label: t('NotFound.action'),
            to: "/"
          }}
        />
      </SectionStyled>
    </Page>
  );
}

const SectionStyled = styled(Section)`
  min-height: 60vh;
`;
