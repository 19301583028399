import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Label } from "../../components/Label";
import { Popup } from "../../components/Popup";
import { theme } from "../../components/theme";

interface Props {
  onClose: () => void;
  title: string;
  message: string;
}

export const SuspendPopup: React.FC<Props> = ({ onClose, title, message }) => {
  return (
    <Popup visible={true} onClose={onClose}>
      <StyledLabel size="xl" weight="bold">
        {title}
      </StyledLabel>
      <StyledText>{message}</StyledText>
    </Popup>
  );
};

const StyledLabel = styled(Label)`
  margin-bottom: ${theme.spacing[1]};
  color: ${theme.colors.base.primary};
  text-align: center;
`;

const StyledText = styled.p`
  font-size: ${theme.font.size.mdx};
  line-height: ${theme.font.size.lg};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.dark};
  text-align: center;
`;
