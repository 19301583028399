import React from "react";

const SaladsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="77"
      height="68"
      viewBox="0 0 77 68"
      fill="none"
    >
      <path
        d="M2.15503 26.9656C2.15503 42.4942 11.5343 57.4483 25.6033 60.948V65.6552C25.6033 66.303 26.128 66.8276 26.7757 66.8276H50.224C50.8717 66.8276 51.3964 66.303 51.3964 65.6552V60.948C65.4654 57.4483 74.8447 42.4971 74.8447 26.9656H2.15503Z"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.4999 60.9656H51.3964"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3619 22.2759C10.3619 15.8012 15.6114 10.5518 22.0861 10.5518C28.5607 10.5518 33.8102 15.8012 33.8102 22.2759"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0861 16.4138V17.5862"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3615 18.9316L27.5349 19.7581"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8108 18.9316L16.6373 19.7581"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.9387 8.20689C59.4656 5.67741 67.8103 5.86207 67.8103 5.86207C67.8103 5.86207 68.6925 14.5028 66.6379 22.2759"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.7758 4.68967V1.17242C26.7758 1.17242 44.362 1.17242 51.3964 8.20691C56.2854 13.0959 56.4701 18.5769 54.9137 22.2759"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.155 11.7242L46.7068 22.2759"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.1896 11.7242V18.7587"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SaladsIcon;
