import React from "react";
import styled from "styled-components";

import SaladsIcon from "./icons/Salads";
import WalletIcon from "./icons/Wallet";

import { theme } from "../theme";
import { Text } from "../../components/Text";
import { Heading } from "../../components/Heading";

import VegetablesIcon from "./icons/Vegetables";
import NutritionAppIcon from "./icons/NutritionApp";
import QualityVegiesIcon from "./icons/QualityVegies";
import ShippingIcon from "./icons/Shipping";
import i18next from "i18next";

const data = {
  fr: [
    {
      icon: <SaladsIcon />,
      title: "Mangez sainement",
      description:
        "Optez pour une alimentation plus saine en choisissant parmi notre sélection de produits frais et bio, tout en contribuant à réduire le gaspillage alimentaire.",
    },
    {
      icon: <WalletIcon />,
      title: "Faites des économies",
      description:
        "Profitez de prix réduits sur une variété de produits de qualité qui autrement seraient gaspillés, vous permettant ainsi d'économiser sur votre budget alimentaire.",
    },
    {
      icon: <VegetablesIcon />,
      title: "Réduisez les pertes alimentaires",
      description:
        "Contribuez à la réduction des pertes alimentaires en donnant une seconde vie à des produits retirés de la vente pour des raisons telles que la date limite courte, la malformation ou d'autres défauts mineurs.",
    },
    {
      icon: <NutritionAppIcon />,
      title: "Sans engagement",
      description:
        "Bénéficiez de la flexibilité avec notre service sans engagement. Commandez uniquement lorsque vous en avez besoin, sans aucun engagement à long terme.",
    },
    {
      icon: <QualityVegiesIcon />,
      title: "Produits de qualité",
      description:
        "Nous nous engageons à vous offrir des produits de qualité, même s'ils ne répondent pas aux normes esthétiques strictes des supermarchés traditionnels.",
    },
    {
      icon: <ShippingIcon />,
      title: "Cueillette ou livraison à domicile",
      description:
        "Choisissez entre la cueillette dans l'un de nos points de collecte ou profitez de la livraison pratique à domicile, selon ce qui vous convient le mieux.",
    },
  ],
  en: [
    {
      icon: <SaladsIcon />,
      title: "Eat Healthily",
      description:
        "Opt for a healthier diet by choosing from our selection of fresh and organic products, while also contributing to reducing food waste.",
    },
    {
      icon: <WalletIcon />,
      title: "Save Money",
      description:
        "Enjoy discounted prices on a variety of quality products that would otherwise be wasted, allowing you to save on your food budget.",
    },
    {
      icon: <VegetablesIcon />,
      title: "Reduce Food Waste",
      description:
        "Contribute to reducing food waste by giving a second life to products removed from sale for reasons such as short expiry dates, malformation, or other minor defects.",
    },
    {
      icon: <NutritionAppIcon />,
      title: "No Commitment",
      description:
        "Benefit from flexibility with our no-commitment service. Order only when you need it, with no long-term commitment.",
    },
    {
      icon: <QualityVegiesIcon />,
      title: "Quality Products",
      description:
        "We are committed to offering you quality products, even if they do not meet the strict aesthetic standards of traditional supermarkets.",
    },
    {
      icon: <ShippingIcon />,
      title: "Pick-up or Home Delivery",
      description:
        "Choose between picking up at one of our collection points or enjoy convenient home delivery, whichever suits you best.",
    },
  ],
};

export const AdvantagesSecondlife: React.FC = () => {
  
  const lang: string = i18next.language;
  let localeData  
  if(lang === "fr" || lang === "fr-CA") {
    localeData = data.fr
  } else {
    localeData = data.en
  }
  return (
    <SectionStyled>
      {localeData.map((advantage, i: number) => (
        <CardStyled key={i}>
          {advantage.icon}
          <div>
            <TitleStyled size="mdx" element="h3">
              {advantage.title}
            </TitleStyled>
            <TextStyled>{advantage.description}</TextStyled>
          </div>
        </CardStyled>
      ))}
    </SectionStyled>
  );
};

const CardStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${theme.spacing[2]};
  background-color: white;
  padding: 3rem 2rem 2rem 2rem;
  width: 100%;
  box-shadow: 0px 2px 3px 0px #0000001a;
`;

const SectionStyled = styled.div`
  max-width: 1350px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding: 1rem 2rem;
  gap: 2rem;

  @media (max-width: ${theme.breakpoints.md}) {
    & {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    & {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
`;
const TitleStyled = styled(Heading)`
  color: ${theme.colors.base.secondary};
  text-align: center;
`;

const TextStyled = styled(Text)`
  color: ${theme.colors.base.dark};
  text-align: center;
  font-size: 16px;
`;
