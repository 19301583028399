type WindowWithAnalytic = Window & {
  _kmq: Record<string, any>[];
  woopra?: {
    track: (event: string) => void;
  };
};

declare const window: WindowWithAnalytic;

export const pushEvent = (event: string) => {
  
  if (window?.woopra && typeof window._kmq !== "undefined") {
    window.woopra.track(event);
    console.log("Sent to Woopra", event);
  }
};
