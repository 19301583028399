import React from 'react';
import styled from 'styled-components';

import { Text } from '../../components/Text';
import { alpha, theme } from '../theme';

interface Props {
  target?: string;
  info?: string;
  className?: string;
}

const FormLabel: React.FC<Props> = ({
  target,
  info,
  children,
  className
}) => {
  return (
    <Text element="label" className={className} htmlFor={target}>
      {children} {info && <InfoStyled>{info}</InfoStyled>}
    </Text>
  );
};

export const FormLabelStyled = styled(FormLabel)`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: ${theme.font.size.md};
`;

const InfoStyled = styled.span`
  padding-left: ${theme.spacing[0.5]};
  color: ${alpha(theme.colors.base.dark, theme.transparency.medium)};
  font-size: ${theme.font.size.xs};
  line-height: ${theme.font.size.xs};
`;
