import clsx from "clsx";
import React from "react";
import styled, { css } from "styled-components";

import { alpha, theme, spacing } from "../theme";

export type SelectOption = {
  label: string;
  value: string | number;
};

interface Props {
  id?: string;
  name: string;
  options: SelectOption[];
  value?: string;
  defaultValue?: string | number;
  placeholder?: string;
  error?: boolean;
  inline?: boolean;
  secondary?: boolean;
  onChange?: (value: string) => void;
  className?: string;
  register: any;
  validation: any;
  warning?: boolean;
  background?: boolean;
}

export const FormSelect: React.FC<Props> = ({
  id,
  name,
  options,
  placeholder,
  value,
  defaultValue,
  error,
  inline = false,
  secondary = false,
  warning = false,
  background = false,
  onChange,
  className,
  register,
  validation,
}) => {
  const onValueChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    if (!onChange) return;
    onChange(evt.currentTarget.value);
  };

  if (options.length === 0) return null;

  return (
    <SelectWrapperStyled
      inline={inline}
      secondary={secondary}
      warning={warning}
      background={background}
    >
      <SelectStyled
        id={id}
        className={clsx("form-input", error && "error", className)}
        name={name}
        value={value}
        onChange={onValueChange}
        ref={register(validation)}
        defaultValue={defaultValue ? defaultValue : undefined}
        inline={inline}
        secondary={secondary}
        background={background}
        warning={warning}
      >
        {placeholder && (
          <option value="DEFAULT" disabled key="default">
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </SelectStyled>
    </SelectWrapperStyled>
  );
};

const SelectWrapperStyled = styled.div<
  Pick<Props, "secondary" | "inline" | "warning" | "background">
>`
  position: relative;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    border-radius: 2px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${theme.colors.accent.third};
  }

  ${(props) =>
    props.inline &&
    css`
      width: auto;
      display: inline-block;
    `}

  ${(props) =>
    props.secondary &&
    css`
      &:before {
        border-top: 8px solid ${theme.colors.base.dark};
      }
    `}

    ${(props) =>
    props.background &&
    css`
      &:before {
        border-top: 8px solid ${theme.colors.base.dark};
      }
    `}
  ${(props) =>
    props.warning &&
    css`
      &:before {
        border-top: 8px solid ${theme.colors.base.dark};
      }
    `}
`;

const SelectStyled = styled.select<
  Pick<Props, "secondary" | "inline" | "warning" | "background">
>`
  width: 100%;
  padding-top: ${theme.spacing[1]};
  padding-bottom: ${theme.spacing[1]};
  padding-left: ${spacing(1)};
  padding-right: ${spacing(3)};
  border: 1px solid ${theme.colors.base.secondary};
  box-shadow: inset 0 0 0 2px ${theme.colors.accent.third};
  border-radius: ${theme.border.radius.sm};
  background-color: ${theme.colors.base.light};
  font-size: ${theme.font.size.md};

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px ${theme.colors.base.secondary};
  }

  &.error {
    box-shadow: 0 0 0 2px ${theme.banner.error.color};
    background-color: ${alpha(
      theme.banner.error.color,
      theme.transparency.high
    )};
  }

  ${(props) =>
    props.background &&
    css`
      background-color: ${theme.colors.base.background};
      box-shadow: 0 0 0 2px ${theme.colors.base.background};
    `}

  ${(props) =>
    props.inline &&
    css`
      width: auto;
    `}

  ${(props) =>
    props.secondary &&
    css`
      background-color: ${theme.colors.accent.secondary};
      box-shadow: 0 0 0 2px ${theme.colors.accent.secondary};
    `}

    ${(props) =>
    props.warning &&
    css`
      background-color: ${theme.colors.base.third};
      box-shadow: 0 0 0 2px ${theme.colors.base.third};
    `}

  appearance: none;

  ::-ms-expand {
    display: none;
  }
`;
