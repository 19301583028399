import React, { useState } from "react";
import styled from "styled-components";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "../../Button";
import { Link } from "../../Link";
import { LocaleToggle } from "../LocaleToggle";
import { NavLinksStyled } from "../Navigation";
import { theme } from "../../theme";
import { useAppDispatch } from "../../../contexts";
import { useUser } from "../../../hooks/Users";
import { useQueryClient } from "react-query";
import { useSubscription } from "../../../hooks/Subscriptions";
import { heartBeat } from "../../../animations";
import { Cart } from "../Cart";

interface Props {
  className?: string;
}

export const NavigationMobile: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const path = useLocation();
  const appDispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { data: user } = useUser();
  const { data: subscription } = useSubscription();
  const [visible, setVisibility] = useState(false);

  const toggleHandler = (): void => {
    setVisibility(!visible);
  };

  const logoutHandler = (): void => {
    appDispatch({ type: "DELETE_TOKEN", payload: {} });
    queryClient.clear();
    history.push("/");
  };

  const MinimalList = () => (
    <MinimalListStyled>
      <ProductLink>
        <Link to="/products" size="lg">
          {t("Navigation.products")}
        </Link>
      </ProductLink>
      <div className={'mx-3 mr-5 cart-icon no-border'}>
        <Cart/>
      </div>
      <li>
        <ButtonStyled onClick={toggleHandler}>
          <svg width="24" height="24" viewBox="0 0 24 25" fill="currentColor">
            <path
              d="M23 22.4142C23.5523 22.4142 24 22.8619 24 23.4142V23.4142C24 23.9665 23.5523 24.4142 23 24.4142L1 24.4142C0.447715 24.4142 -6.78525e-08 23.9665 -4.37114e-08 23.4142V23.4142C-1.95703e-08 22.8619 0.447715 22.4142 1 22.4142L23 22.4142Z"/>
            <path
              d="M23 11.4142C23.5523 11.4142 24 11.8619 24 12.4142V12.4142C24 12.9665 23.5523 13.4142 23 13.4142L6 13.4142C5.44772 13.4142 5 12.9665 5 12.4142V12.4142C5 11.8619 5.44772 11.4142 6 11.4142L23 11.4142Z"/>
            <path
              d="M23 0.414215C23.5523 0.414215 24 0.861931 24 1.41422V1.41422C24 1.9665 23.5523 2.41422 23 2.41422L1 2.41422C0.447715 2.41422 -6.78525e-08 1.9665 -4.37114e-08 1.41421V1.41421C-1.95703e-08 0.86193 0.447715 0.414214 1 0.414214L23 0.414215Z"/>
          </svg>
        </ButtonStyled>
      </li>
    </MinimalListStyled>
  );

  const isSignup = path.pathname.startsWith("/sign-up");

  return (
    <div className={'navigation-mobile'}>
      <MinimalList/>
      <NavigationStyled className={clsx(className, visible && "open")} aria-hidden={!visible}>
        <ListStyled>
          {!isSignup && (
            <li>
              <Link to="/products" size="lg">
                {t("Navigation.products")}
              </Link>
            </li>
          )}
          <li>
            <Link to="/faq" size="lg">
              {t("Navigation.help")}
            </Link>
          </li>
          <li>
            <LocaleToggle size="lg"/>
          </li>
          {user && user?.email ? (
            <>
              <SeparatorStyled/>
              <li>
                <Link to="/dashboard" size="lg">
                  {t("Navigation.dashboard")}
                </Link>
              </li>
              {(subscription?.lastDigits ||
                subscription?.status === "cancel") && (
                <>
                  <li>
                    <Link to="/account" size="lg">
                      {t("Navigation.personal-infos")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/order-history" size="lg">
                      {t("Navigation.order-history")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/cart" size="lg">
                      {t("Navigation.cart")}
                    </Link>
                  </li>
                  <li>
                    <LinkStyled to="/adhesions" size="lg">
                      {t("Navigation.secondlife-prime")}
                    </LinkStyled>
                  </li>
                </>
              )}

              <li>
                <Button size="lg" secondary onClick={logoutHandler}>
                  {t("Navigation.logout")}
                </Button>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link size="lg" to="/login">
                  {t("Navigation.login")}
                </Link>
              </li>
              <li>
                <Button size="lg" to="/sign-up/step-1">
                  {t("Navigation.get-started")}
                </Button>
              </li>
            </>
          )}
        </ListStyled>
        <ActionsStyled>
          <ButtonStyled onClick={toggleHandler}>
            <svg width="24" height="24" viewBox="0 0 26 25" fill="currentColor">
              <path
                d="M1.3072 2.12132C0.916679 1.7308 0.91668 1.09763 1.3072 0.707108V0.707108C1.69773 0.316584 2.33089 0.316584 2.72142 0.707108L24.6417 22.6274C25.0323 23.0179 25.0323 23.6511 24.6417 24.0416V24.0416C24.2512 24.4322 23.618 24.4322 23.2275 24.0416L1.3072 2.12132Z"/>
              <path
                d="M23.293 0.721327C23.6835 0.330803 24.3167 0.330804 24.7072 0.721328V0.721328C25.0978 1.11185 25.0978 1.74502 24.7072 2.13554L2.78692 24.0559C2.39639 24.4464 1.76323 24.4464 1.37271 24.0559V24.0559C0.982181 23.6653 0.982181 23.0322 1.37271 22.6416L23.293 0.721327Z"/>
            </svg>
          </ButtonStyled>
        </ActionsStyled>
      </NavigationStyled>
    </div>
  );
};

const NAV_WIDTH = "480px";

export const NavigationStyled = styled.nav`
    position: fixed;
    top: 0;
    bottom: 0;
    right: -${NAV_WIDTH};
    width: 100%;
    max-width: ${NAV_WIDTH};
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: ${theme.spacing[1.5]} ${theme.spacing[2]};
    background-color: ${theme.colors.accent.primary};
    z-index: 9;
    transition: all ${theme.transition.regular};

    &.open {
        right: 0;
    }

    @media (min-width: 840px) {
        display: none;

        &.open {
            right: -${NAV_WIDTH};
        }
    }
`;

const ProductLink = styled.div`
    position: relative;

    a {
        font-size: 14px;
        color: #2b2525;
    }

`;


const ListStyled = styled.ul`
    width: 100%;

    & > li {
        margin-top: ${theme.spacing[0.5]};
        list-style: none;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-top: ${theme.spacing[2]};
        }
    }
`;

const SeparatorStyled = styled.hr`
    width: 100%;
    height: 2px;
    margin-top: ${theme.spacing[1]};
    margin-bottom: ${theme.spacing[1]};
    border: 0;
    background-color: ${theme.colors.base.dark};
`;

const ButtonStyled = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: ${theme.colors.base.dark};
    cursor: pointer;

    &:hover {
        color: ${theme.colors.accent.secondary};
    }
`;

const MinimalListStyled = styled(NavLinksStyled)`
    display: flex;

    @media (min-width: 840px) {
        display: none;
    }
`;

const ActionsStyled = styled.div`
    padding-top: ${theme.spacing[0.5]};
`;

const LinkStyled = styled(Link)`
    animation: ${heartBeat} 4s infinite;
    color: ${theme.colors.base.third};
`;
