import React from "react";
import styled from "styled-components";

import { Heading } from "../Heading";
import { Section } from "../Section";
import { Text } from "../Text";
import { theme } from "../theme";
import { useTranslation } from "react-i18next";
import { Image } from "../Image";

import nl2br from "react-nl2br";

export const MissionSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CommitmentStyled>
      <SectionStyled size="lg">
        <LayoutStyled>
          <HeadingStyled center element="h1" size="xl">
            {nl2br(t("Mission.section1.title"))}
          </HeadingStyled>
          <TextWrapper>
            <TextStyled>{t("Mission.section1.text1")}</TextStyled>
            <br />
            <TextStyled weight="bold">
              <b>{t("Mission.section1.text2")}</b>
            </TextStyled>
          </TextWrapper>
          <ImageStyled>
            <Image path="/common/images/mission-section1" alt={t("EmptyState.Image.alt")} />
          </ImageStyled>
        </LayoutStyled>
      </SectionStyled>
    </CommitmentStyled>
  );
};

const ImageStyled = styled.div``;

const SectionStyled = styled(Section)`
  position: relative;
  width: 100%;
  padding: 0;
  background-color: ${theme.colors.base.background};
`;

const CommitmentStyled = styled.ul`
  position: relative;
  width: 100%;
  margin-top: ${theme.spacing[2]};
  padding: ${theme.spacing[2]};

  &:before {
    content: "";
    position: absolute;
    top: calc(50% + ${theme.spacing[2]});
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${theme.colors.base.third};
  }
`;

const HeadingStyled = styled(Heading)`
  color: ${theme.colors.base.primary};
`;

const LayoutStyled = styled.div`
  padding: ${theme.spacing[2]};
`;

const TextWrapper = styled.div`
  padding: ${theme.spacing[2]};
  text-align: center;
  color: ${theme.colors.base.secondary};
`;

const TextStyled = styled(Text)`
  color: ${theme.colors.base.secondary};
`;
