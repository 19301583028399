import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../components/Button";
import { Form } from "../../components/Form";
import { FormControl } from "../../components/FormControl";
import { FormInput } from "../../components/FormInput";
import { FormInputControl } from "../../components/FormInputControl";
import { FormLabel } from "../../components/FormLabel";
import { FormSection } from "../../components/FormSection";
import { Heading } from "../../components/Heading";
import { Link } from "../../components/Link";
import { Loading } from "../../components/Loading";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { Snackbar } from "../../components/Snackbar";
import { theme } from "../../components/theme";
import { useAppDispatch, useAppState } from "../../contexts";
import { useSubscription } from "../../hooks/Subscriptions";
import { useLogin } from "../../hooks/Users";

interface Props {}

export const SignIn: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const methods = useForm();
  const appDispatch = useAppDispatch();
  const { errors } = methods;
  const { token } = useAppState();
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: subscription,
    error: subscriptionError,
    isSuccess,
    refetch,
  } = useSubscription();

  const {
    mutate,
    data: user,
    error: userError,
    isLoading: isUserLoading,
  } = useMutation(useLogin);

  useEffect(() => {
    if (user) {
      appDispatch({ type: "SET_TOKEN", payload: { token: user?.token } });
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token]);

  useEffect(() => {
    setIsLoading(false);
  }, [isSuccess, userError, subscriptionError]);

  useEffect(() => {
    if (subscription) {
      if (
        (subscription && !subscription?.plans) ||
        subscription?.plans?.length === 0
      ) {
        return history.push("/sign-up/step-2");
      }
      if (subscription && !subscription?.delivery) {
        return history.push("/sign-up/step-3");
      }
      if (subscription?.status === "paused") {
        return history.push("/sign-up/confirm");
      }
      return history.push("/dashboard");
    }
  }, [subscription]);

  const onSubmitHandler = () => {
    if (inputs) {
      setIsLoading(true);
      mutate({ ...inputs });
    }
  };

  return (
    <Page centerY centerX backgroundColor={theme.colors.base.background}>
      <Section centerX>
        <FormSectionStyled
          title={
            <Heading element="h1" size="md">
              {t("Signin.heading")}
            </Heading>
          }
        >
          {isLoading && <Loading />}

          <Form useForm={methods} onSubmit={onSubmitHandler}>
            <FormControl>
              <FormInputControl
                errors={
                  errors.email && [
                    t(`Signin.email.errors.${errors.email.type}`),
                  ]
                }
              >
                <FormLabel target="email">{t("Signin.email.label")}</FormLabel>
                <FormInput
                  id="email"
                  type="email"
                  name="email"
                  placeholder={t("Signin.email.placeholder")}
                  error={errors.email}
                  onChange={(value) => setInputs({ ...inputs, email: value })}
                  register={methods.register}
                  validation={{
                    required: true,
                    minLength: 2,
                  }}
                />
              </FormInputControl>

              <FormInputControl
                errors={
                  errors.password && [
                    t(`Signin.password.errors.${errors.password.type}`),
                  ]
                }
              >
                <FormLabel target="password">
                  {t("Signin.password.label")}
                </FormLabel>
                <FormInput
                  id="password"
                  type="password"
                  name="password"
                  placeholder={t("Signin.password.placeholder")}
                  error={errors.password}
                  onChange={(value) =>
                    setInputs({ ...inputs, password: value })
                  }
                  register={methods.register}
                  validation={{
                    required: true,
                    minLength: 2,
                  }}
                />
              </FormInputControl>
              <ActionStyled>
                <Button type="submit">{t("Signin.action")}</Button>
                <LinkStyled to="/request/password">
                  {t("Signin.forgotPassword")}
                </LinkStyled>
                <LinkStyled to="/sign-up/step-1">
                  {t("Signin.noAccount")}
                </LinkStyled>
              </ActionStyled>
            </FormControl>
          </Form>
        </FormSectionStyled>
        {(userError || subscriptionError) && (
          <Snackbar
            type="error"
            error={userError ? userError : subscriptionError}
          />
        )}
      </Section>
    </Page>
  );
};

const FormSectionStyled = styled(FormSection)`
  @media (min-width: ${theme.breakpoints.xs}) {
    width: 480px;
  }
`;

const ActionStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[2]};

  @media (min-width: ${theme.breakpoints.xs}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LinkStyled = styled(Link)`
  text-decoration: underline;
  margin-top: ${theme.spacing[1]};

  @media (min-width: ${theme.breakpoints.xs}) {
    margin-top: 0;
  }
`;
