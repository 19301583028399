import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { CardForm } from "../../../components/CardForm";
import { CreditCard } from "../../../components/CreditCard";
import { Loading } from "../../../components/Loading";
import { Snackbar } from "../../../components/Snackbar";
import { Text } from "../../../components/Text";
import { theme } from "../../../components/theme";
import {
  useAddCard,
  useChangeCard,
  useReactivateSubscription,
  useSubscription,
} from "../../../hooks/Subscriptions";
interface Props {
  onChangingCard: (status: boolean) => void;
  couponName: string;
}

export const Billing: React.FC<Props> = ({
  onChangingCard,
  couponName = "",
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const {
    data: subscription,
    isLoading: IsLoadingSubscription,
    refetch,
  } = useSubscription();
  const { mutate: mutateStatus, isSuccess: isSuccessStatus } = useMutation(
    useReactivateSubscription
  );
  const {
    mutate: mutateChangeCard,
    isSuccess: isSuccessChangeCard,
    isLoading: isLoadingChangeCard,
  } = useMutation(useChangeCard);
  const {
    mutate: mutateAddCard,
    isSuccess: isSuccessAddCard,
    isLoading: isLoadingAddCard,
    error,
  } = useMutation(useAddCard);
  const [changeCard, setChangeCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onActivate = () => mutateStatus();

  const onChangeCard = () => {
    setChangeCard(true);
    onChangingCard(true);
  };

  useEffect(() => {
    if (isSuccessAddCard || isSuccessChangeCard) {
      refetch();
      onChangingCard(false);
      setChangeCard(false);
    }
  }, [isSuccessAddCard, isSuccessChangeCard]);

  const onSubmitCard = useCallback(async () => {
    setIsLoading(true);

    if (!stripe || !elements) {
      setIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      setIsLoading(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setIsLoading(false);
      return;
    }

    if (paymentMethod) {
      if (subscription && subscription?.status === "active") {
        mutateChangeCard({ paymentMethodId: paymentMethod.id });
      } else if (!subscription || !subscription.lastDigits) {
        mutateAddCard({
          paymentMethodId: paymentMethod.id,
          code: couponName,
        });
      }
      setIsLoading(false);
      return;
    }
  }, [elements, stripe]);

  return (
    <CreditCardWrapper>
      <FormWrapper>
        {subscription &&
          (subscription?.status === "paused" ||
            subscription?.status === "cancel") &&
          subscription.lastDigits && (
            <Text size="mdx">{t(`Subscription.reactive_subscription`)}</Text>
          )}
        {subscription && subscription?.status === "active" && !changeCard && (
          <CreditCard subscription={subscription} />
        )}
        {!subscription || (!subscription.lastDigits && <CardForm />)}
        {changeCard && <CardForm />}
        {IsLoadingSubscription ||
        isLoadingAddCard ||
        isLoadingChangeCard ||
        isLoading ? (
          <Loading />
        ) : null}
      </FormWrapper>
      <ActionWrapper>
        {subscription &&
          (subscription?.status === "paused" ||
            subscription?.status === "cancel") &&
          subscription.lastDigits && (
            <Button secondary onClick={onActivate}>
              {t(`Subscription.active_subcription`)}
            </Button>
          )}
        {subscription && subscription?.status === "active" && !changeCard && (
          <Button third onClick={onChangeCard} size="sm">
            {t("CreditCard.change")}
          </Button>
        )}
        {(!subscription || !subscription.lastDigits) && (
          <Button secondary onClick={onSubmitCard} size="sm">
            {t("CreditCard.add")}
          </Button>
        )}
        {changeCard && (
          <WrapperButton>
            <Button
              primary
              onClick={() => {
                setChangeCard(false);
                onChangingCard(false);
              }}
              size="sm"
            >
              {t("Common.action.cancel")}
            </Button>
            <Button secondary onClick={onSubmitCard} size="sm">
              {t("CreditCard.change")}
            </Button>
          </WrapperButton>
        )}
      </ActionWrapper>
      {isSuccessStatus && (
        <Snackbar type="success" text={t(`Subscription.activated`)} />
      )}
    </CreditCardWrapper>
  );
};

const CreditCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing[2]};
`;
const FormWrapper = styled.div`
  position: relative;
  padding: ${theme.spacing[1.5]} ${theme.spacing[1]};
  border-radius: 4px;
  background-color: ${theme.colors.accent.secondary};
  border-color: ${theme.colors.base.dark};

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[3]} ${theme.spacing[6]} ${theme.spacing[2]}
      ${theme.spacing[6]};
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${theme.spacing[2]};

  > button {
    width: 200px;
  }
`;
