import React from "react";
import { Label } from "../Label";
import styled from "styled-components";
import { theme } from "../theme";
import { icons } from "../../assets/icons";
import { TPlanQuantity } from "../../types/Plan";
import { formatCurrency } from "../../utils/i18n";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  locale: string;
  tag: string;
  quantity: TPlanQuantity;
  min_price: number;
  max_price: number;
}

function displayQty(q: number) {
  let label;
  if (q === 10) {
    label = `-${q}`;
  } else if (q === 20) {
    label = `10-${q}`;
  } else if (q === 21) {
    label = `${q}+`;
  } else {
    // Handle any other case if needed
    label = `${q}`;
  }

  return label;
}
export const PlanItem: React.FC<Props> = ({
  name,
  tag,
  locale,
  quantity,
  min_price,
  max_price,
}) => {
  const { t } = useTranslation();
  const price =
    min_price === max_price
      ? formatCurrency(locale, min_price / 100)
      : `${formatCurrency(locale, min_price / 100)} - ${formatCurrency(
          locale,
          max_price / 100
        )}`;

  const quantityDisplay = quantity > 4 ? `${displayQty(quantity)}` : quantity;

  return (
    <>
      <RowWrapper>
        <ColumnWrapper>
          <PlanNameLabel size="lg" weight="black">
            {name}
          </PlanNameLabel>
          <PlanTagLabel>{tag}</PlanTagLabel>
          <PlanPriceLabel>{price}</PlanPriceLabel>
        </ColumnWrapper>
        <PlanQuantityContainer>
          <icons.person fill={theme.colors.base.secondary} />
          <PlanQuantityLabel>
            {quantityDisplay} {t("Signup.persons")}
          </PlanQuantityLabel>
        </PlanQuantityContainer>
      </RowWrapper>
    </>
  );
};

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: ${theme.spacing[0.25]};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanQuantityContainer = styled(RowWrapper)`
  margin-left: ${theme.spacing[1]};
  padding: ${theme.spacing[1]};
  background: ${theme.colors.base.third};
  border: 1px solid ${theme.colors.base.third};
  border-radius: 3px;
`;

const PlanNameLabel = styled(Label)`
  line-height: ${theme.font.size.xl};
  font-weight: ${theme.font.weight.bold};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.secondary};
`;

const PlanTagLabel = styled(Label)`
  line-height: ${theme.font.size.mdx};
  font-weight: ${theme.font.weight.bold};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.primary};
`;

const PlanPriceLabel = styled(PlanTagLabel)`
  color: ${theme.colors.base.secondary};
  margin-top: ${theme.spacing[1]};
`;

const PlanQuantityLabel = styled(PlanTagLabel)`
  margin-left: ${theme.spacing[0.5]};
  color: ${theme.colors.base.secondary};
`;
