import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Button } from "../../Button";
import { Heading } from "../../Heading";
import { alpha, theme } from "../../theme";

import { FormInput } from "../../FormInput";
import { useForm } from "react-hook-form";

export interface Props {
  visible: boolean;
  onClose: () => void;
  onUserInput: (input: string) => void;
}

export const ModalPassword: React.FC<Props> = ({
  visible,
  onClose,
  onUserInput,
}) => {
  const { t } = useTranslation();
  const methods = useForm();
  const [verify, setVerify] = useState<string>("");

  if (!visible) return null;

  return (
    <ModalStyled tabIndex={visible ? 0 : -1}>
      <ContainerStyled>
        <Heading size="lg">{t("Home.map.Modal.password_required")}</Heading>
        <FormInput
          color="green"
          id="password"
          type="text"
          name="password"
          register={methods.register}
          value={verify}
          onChange={(input: string) => setVerify(input)}
          validation={{
            required: true,
          }}
        />

        <ActionsWrapper>
          <ButtonStyled secondary size="sm" onClick={onClose}>
            {t("Home.map.Modal.close")}
          </ButtonStyled>
          <ButtonStyled
            secondary
            size="sm"
            onClick={() => {
              if (verify) onUserInput(verify);
            }}
          >
            {t("Home.map.Modal.valid")}
          </ButtonStyled>
        </ActionsWrapper>
      </ContainerStyled>
    </ModalStyled>
  );
};

const ModalStyled = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${theme.spacing[1.5]};
  background-color: ${alpha(theme.colors.base.light, theme.transparency.low)};
  z-index: 2;
`;

const ButtonStyled = styled(Button)`
  align-items: center;
  margin-top: ${theme.spacing[2]};
`;

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${theme.spacing[2]};

  > * {
    width: 100%;
    margin-top: ${theme.spacing[1]};
    margin-bottom: ${theme.spacing[1]};

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;

    > * {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: ${theme.spacing[1]};
      margin-right: ${theme.spacing[1]};

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;
