import { useQuery } from "react-query";
import { stringify } from "query-string";
import { TPlan } from "../../types/Plan";
import config from "../../config";
import axios from "redaxios";
import { TProduct } from "../../types/Product";
import { TChoice } from "../../components/ChoiceList/ChoiceList";

type Params = Record<
  string,
  string | number | boolean | null | undefined | string[]
>;

const apiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/plans`;

/**
 * Fetch all plans
 * @param locale - The locale of the authenticated user
 * @returns A lisy of all plans
 */

export const usePlans = (locale: string) => {
  const params: Params = {};

  if (locale) {
    params.locale = locale;
  }
  return useQuery<TPlan[]>(["usePlans"], () =>
    axios
      .get(`${apiUrl}?${stringify(params)}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data.payload)
  );
};

/**
 * Fetch all plans
 * @param homepage - is from home page called?
 * @returns A lisy of all plans
 */

export const usePlansToDisplay = (homepage?: string, signup?: string) => {
  const params: Params = {};

  if (homepage) {
    params.homepage = homepage;
  }
  if (signup) {
    params.signup = signup;
  }
  return useQuery<(TPlan & { products: TProduct[]; price: string })[]>(
    ["usePlansDisplay", homepage, signup],
    () =>
      axios
        .get(`${apiUrl}/display?${stringify(params)}`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => res.data.payload)
  );
};

/**
 * Fetch all plans
 * @param homepage - is from home page called?
 * @returns A lisy of all plans
 */

export const usePerfectPlan = (choices?: TChoice[]) => {
  const params: Params = {};

  if (choices && choices?.length > 0) {
    params.choices = choices.map((c) => c.value);
  }
  return useQuery<(TPlan & { products: TProduct[]; price: string })[]>(
    ["usePerfectPlan", choices],
    () =>
      axios
        .get(`${apiUrl}/users/choice?${stringify(params)}`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => res.data.payload),
    {
      // The query will not execute until the choice === 3 exists
      enabled: choices?.length === 3,
    }
  );
};
