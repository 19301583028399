import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

import { useAppDispatch } from "../../contexts";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { theme } from "../theme";

interface Props {
  centerX?: boolean;
  centerY?: boolean;
  backgroundColor?: string;
  className?: string;
}

export const Page: React.FC<Props> = ({
  centerX = false,
  centerY = false,
  backgroundColor,
  className,
  children,
}) => {
  const appDispatch = useAppDispatch();

  useEffect(() => {
    appDispatch({
      type: "EMPTY_ERRORS",
      payload: {},
    });
  }, [appDispatch]);

  return (
    <Container className={className} backgroundColor={backgroundColor}>
      <Header />
      <Content centerX={centerX} centerY={centerY}>
        {children}
      </Content>
      <Footer />
      <ScrollToTop />
    </Container>
  );
};

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Container = styled.div<Pick<Props, "backgroundColor">>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: ${theme.colors.accent.secondary};

  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
`;

const Content = styled.main<Pick<Props, "centerX" | "centerY">>`
  // overflow-x: hidden;

  ${(props) =>
    props.centerX &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}

  ${(props) =>
    props.centerY &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}
`;
