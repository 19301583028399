import React from "react";
import styled from "styled-components";
import config from "../../config";
import { Heading } from "../Heading";
import { Section } from "../Section";
import { theme } from "../theme";
import { Text } from "../Text";
import { useTranslation } from "react-i18next";

import { icons } from "../../assets/icons";
interface Props {
  quotes: { author: string; text: string; link: string }[];
}
export const Quotes: React.FC<Props> = ({ quotes = [] }) => {
  const { t } = useTranslation();

  if (quotes.length === 0) return <></>;
  return (
    <>
      {quotes.map((quote, key) => (
        <BlackQuoteStyled key={key}>
          {t(quote.text)}
          <StarsStyled>
            <icons.socialstars />
            <icons.socialstars />
            <icons.socialstars />
            <icons.socialstars />
            <icons.socialstars />
          </StarsStyled>
          <Text>
            <a target="_blank" href={quote.link}>
              {quote.author}
            </a>
          </Text>
        </BlackQuoteStyled>
      ))}
    </>
  );
};

const BlackQuoteStyled = styled.blockquote`
  /* border: 1px solid ${theme.colors.base.secondary}; */
  padding-left: ${theme.spacing[4]};
  position: relative;
  @media (max-width: ${theme.breakpoints.sm}) {
    margin: 0;
  }
  ::before,
  ::after {
    position: absolute;
    color: ${theme.colors.base.primary};
    font-size: 8rem;
    width: 4rem;
    height: 4rem;
  }

  ::before {
    content: "“";
    left: 0.25rem;
    top: -3rem;
  }

  /* ::after {
    content: "”";
    right: -2rem;
    bottom: 1rem;
  } */
`;
const StarsStyled = styled.div`
  padding-top: ${theme.spacing[2]};
  svg {
    width: 20px;
    height: 20px;
    fill: ${theme.colors.base.third};
  }
`;
