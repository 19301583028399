import { darken, rem, rgba } from "polished";

const BASE_SIZE = 14;

type Color = string;

interface Theme {
  banner: {
    success: {
      background: Color;
      color: Color;
    };
    info: {
      background: Color;
      color: Color;
    };
    error: {
      background: Color;
      color: Color;
    };
    warning: {
      background: Color;
      color: Color;
    };
  };
  border: {
    radius: {
      sm: string;
      md: string;
      lg: string;
      xl: string;
      xxl: string;
      round: string;
    };
  };
  breakpoints: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  colors: {
    base: {
      green: Color;
      grey: Color;
      dark: Color;
      light: Color;
      white: Color;
      primary: Color;
      secondary: Color;
      third: Color;
      background: Color;
    };
    accent: {
      primary: Color;
      secondary: Color;
      third: Color;
    };
    neutral: {
      primary: Color;
      secondary: Color;
    };
    status: {
      success: Color;
      info: Color;
      error: Color;
      warning: Color;
      disabled: Color;
    };
  };
  font: {
    family: {
      primary: string;
      secondary: string;
    };
    size: {
      xxxs: string;
      xxs: string;
      xs: string;
      sm: string;
      md: string;
      mdx: string;
      lg: string;
      xl: string;
      xxl: string;
      xxxl: string;
      bigxl: string;
    };
    weight: {
      light: number;
      normal: number;
      bold: number;
      black: number;
    };
  };
  spacing: {
    0.25: string;
    0.5: string;
    1: string;
    1.5: string;
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
    7: string;
  };
  transition: {
    fast: string;
    regular: string;
    slow: string;
  };
  transparency: {
    high: number;
    medium: number;
    low: number;
  };
}

export const theme: Theme = {
  banner: {
    success: {
      background: "#badcc4",
      color: "#466d51",
    },
    info: {
      background: "#b6d0de",
      color: "#246c95",
    },
    error: {
      background: "#ffd1d1",
      color: "#d34646",
    },
    warning: {
      background: "#f3ceac",
      color: "#b96d27",
    },
  },
  border: {
    radius: {
      sm: "3px",
      md: "4px",
      lg: "8px",
      xl: "16px",
      xxl: "32px",
      round: "50%",
    },
  },
  breakpoints: {
    xs: rem("520px"),
    sm: rem("768px"),
    md: rem("1024px"),
    lg: rem("1280px"),
    xl: rem("1420px"),
  },
  colors: {
    base: {
      green: "#56D791",
      grey: "#A9A9A9",
      dark: "#424242",
      light: "#fffdf8",
      white: "#ffffff",
      primary: "#EE5340", // dark red
      secondary: "#00594F", // dark green
      third: "#FCC330", // dark yello
      background: "#FFF9EA",
    },
    accent: {
      primary: "#FFEDE1", // light red/yellow
      secondary: "#D1E7D7", // light blue
      third: "#FFFFFF", // red
    },
    neutral: {
      primary: "#e2e2e2",
      secondary: darken(0.2, "#e2e2e2"),
    },
    status: {
      success: "#97a959",
      info: "#71a1bc",
      error: "#ec7854",
      warning: "#e79042",
      disabled: "#F5F5F5",
    },
  },
  font: {
    family: {
      primary:
        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
      secondary: "Radnika, sans-serif",
    },
    size: {
      xxxs: rem(0.375 * BASE_SIZE),

      xxs: rem(0.575 * BASE_SIZE),
      xs: rem(0.725 * BASE_SIZE),
      sm: rem(0.875 * BASE_SIZE),
      md: rem(1 * BASE_SIZE),
      mdx: rem(1.2 * BASE_SIZE),
      lg: rem(1.45 * BASE_SIZE),
      xl: rem(1.65 * BASE_SIZE),
      xxl: rem(2.4 * BASE_SIZE),
      xxxl: rem(3.2 * BASE_SIZE),
      bigxl: rem(4 * BASE_SIZE),
    },
    weight: {
      light: 300,
      normal: 400,
      bold: 700, 
      black: 900,
    },
  },
  spacing: {
    0.25: rem(0.25 * BASE_SIZE),
    0.5: rem(0.5 * BASE_SIZE),
    1: rem(1 * BASE_SIZE),
    1.5: rem(1.5 * BASE_SIZE),
    2: rem(2 * BASE_SIZE),
    3: rem(3 * BASE_SIZE),
    4: rem(4 * BASE_SIZE),
    5: rem(5 * BASE_SIZE),
    6: rem(6 * BASE_SIZE),
    7: rem(7 * BASE_SIZE),
  },
  transition: {
    fast: "0.1s linear",
    regular: "0.2s linear",
    slow: "0.3s linear",
  },
  transparency: {
    low: 0.9,
    medium: 0.5,
    high: 0.2,
  },
};

export const spacing = (indice: number): string => rem(indice * BASE_SIZE);
export const alpha = (color: string, alpha?: number | undefined): string =>
  rgba(color, alpha);
export const darker = (amount: number | string, color: string): string =>
  darken(amount, color);
