import React from "react";
import styled from "styled-components";
import config from "../../config";
import { Section } from "../Section";
import { theme } from "../theme";
import { useTranslation } from "react-i18next";

import { Quotes } from "./Quotes";
export const Testimonials: React.FC = () => {
  const { t } = useTranslation();

  const quotes = [
    {
      text: "Home.Quotes.home-1",
      author: "Clo -",
      link: "https://g.co/kgs/vkNxzn",
    },
    {
      text: "Home.Quotes.home-2",
      author: "Raphaël Tremblay",
      link: "https://g.co/kgs/eib2nc",
    },
    {
      text: "Home.Quotes.home-3",
      author: "Philippe Ostiguy",
      link: "https://g.co/kgs/wGLR77",
    },
  ];
  return (
    <SectionStyled size="lg" backgroundColor={theme.colors.base.background}>
      <Wrapper>
        <GifStyled>
          <img
            src={`${config.CDN_URL}/common/images/abous-us-testimonial.gif`}
          />
        </GifStyled>

        <ContentStyled>
          <Quotes quotes={quotes} />
        </ContentStyled>
      </Wrapper>
    </SectionStyled>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 500px 2fr;

  @media (max-width: ${theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;
const SectionStyled = styled(Section)``;

const GifStyled = styled.picture`
  img,
  source {
    max-width: 100%;
  }
  max-width: 500px;

  @media (max-width: ${theme.breakpoints.sm}) {
    display: flex;
    transform: translateX(0%);
  }

  flex: 1;
`;

const ContentStyled = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: ${theme.spacing[2]};
`;
