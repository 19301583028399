import { subDays } from "date-fns";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { svgImages } from "../../assets/images";
import { AlternateSection } from "../../components/AlternateSection";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loading";
import { Page } from "../../components/Page";
import { Text } from "../../components/Text";
import { theme } from "../../components/theme";
import { WithAuth } from "../../components/WithAuth";
import { useAppState } from "../../contexts";
import {
  removeProductFromPreOrder,
  updateOrCreateProductFromPreOrder,
  usePreOrder,
  useProcessingOrders,
} from "../../hooks/Orders";
import { useSubscription } from "../../hooks/Subscriptions";
import { useUser } from "../../hooks/Users";
import { TProduct } from "../../types/Product";

import { Product } from "./Product";
import { CarouselProducts } from "../../components/CarousellProducts/CarouselProducts";
import { usePromoProducts } from "../../hooks/Products";
import { Hero } from "../../components/Hero";
import { Stack } from "../../components/Stack";

export const NextCart: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { locale } = useAppState();
  const [lang] = locale.split("-");
  const { data: user } = useUser();
  const { data: subscription } = useSubscription();
  const { data: promoProducts = [], refetch } = usePromoProducts();

  const {
    data: products = [],
    refetch: refetchPreOrder,
    isLoading: isLoadingOrder,
  } = usePreOrder();

  useEffect(() => {
    if (subscription?.status === "cancel") {
      return history.push("/dashboard");
    }
  }, [subscription]);

  const onSelectProduct = (product: TProduct) =>
    history.push({
      pathname: `/products/${product.pid}`,
      state: { prevPath: "/cart" },
    });

  const onRemoveProduct = (product: TProduct) => {
    if (!user || !product?.pid) {
      return;
    }
    removeProductFromPreOrder({
      pid: product.pid,
    }).then(() => {
      refetchPreOrder();
      refetch();
    });
  };

  const onKeepProduct = (product: TProduct) => {
    if (!user || !product?.pid) {
      return;
    }
    updateOrCreateProductFromPreOrder({
      pid: product.pid,
      keep: !product.keep,
      quantity: product.quantity,
    }).then(() => {
      refetchPreOrder();
      refetch();
    });
  };

  const productss = promoProducts.map((p) => {
    const productFound = products.find((p1) => p1.id === p.id);

    if (productFound) {
      return {
        ...productFound,
      };
    }
    return { ...p };
  });
  return (
    <WithAuth>
      <Page centerX centerY backgroundColor={theme.colors.base.background}>
        {isLoadingOrder && <Loading />}

        <AlternateSection>
          <GlobalWrapper>
            <CartWrapper>
              <HeroWrapper>
                <Hero
                  image="/common/images/schedule-next-cart"
                  imageAlt="Vegetables basket"
                  title={t("NextCart.title")}
                  subtitle={t("NextCart.subtitle")}
                  wide={true}
                  centerWhenSmall={true}
                  imageOnTopWhenSmall={true}
                >
                  <Stack>
                    <Button to="/products" secondary>
                      {t("Cart.add.products")}
                    </Button>
                  </Stack>
                </Hero>
              </HeroWrapper>
              {isMobile && (
                <>
                  <ActionButton to="/products" secondary>
                    {t("Cart.add.products")}
                  </ActionButton>
                </>
              )}

              {subscription?.status === "active" && (
                <>
                  {products.length > 0 ? (
                    <ProductsWrapper>
                      {products.map((product: TProduct) => {
                        return (
                          <Product
                            isPreOrder={true}
                            key={product.id}
                            product={product}
                            quantity={product.quantity}
                            onSelectProduct={onSelectProduct}
                            onRemoveProduct={onRemoveProduct}
                            onKeepProduct={onKeepProduct}
                          />
                        );
                      })}
                    </ProductsWrapper>
                  ) : (
                    <EmptyWrapper>
                      <svgImages.emptyCart />
                      <Button center to="/products" secondary>
                        {t("Cart.add.products")}
                      </Button>
                    </EmptyWrapper>
                  )}
                </>
              )}
            </CartWrapper>
          </GlobalWrapper>

          <div>
            <Heading>
              <h2>{t("PromoProducts")}</h2>
            </Heading>
            {productss && productss.length > 0 && (
              <CarouselProducts
                isPreOrder={true}
                products={productss}
                withBannerProduct={true}
              />
            )}
          </div>
        </AlternateSection>
      </Page>
    </WithAuth>
  );
};

const GlobalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${theme.spacing[4]};

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

const Heading = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const CartWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing[4]};
  @media (min-width: ${theme.breakpoints.sm}) {
    margin-right: ${theme.spacing[2]};
    gap: ${theme.spacing[0.25]};
  }
`;

const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[1]};
`;

const HeroWrapper = styled.div`
  padding: ${theme.spacing[2]};
`;

const ActionButton = styled(Button)`
  width: 100%;
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  margin-top: ${theme.spacing[1.5]};
  margin-bottom: ${theme.spacing[1.5]};
  text-align: center;

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 340px;
  }
`;

const EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: ${theme.spacing[2]} ${theme.spacing[4]};

  svg {
    width: 100%;
    height: auto;
    margin-bottom: ${theme.spacing[2]};
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 350px;
    padding: ${theme.spacing[2]} 0;
  }
`;

const EmptyText = styled(Text)`
  font-size: ${theme.font.size.mdx};
  line-height: ${theme.font.size.xl};
  color: ${theme.colors.base.dark};
  text-align: center;

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.lg};
    line-height: ${theme.font.size.xl};
    text-align: left;
  }
`;
