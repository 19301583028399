import React from "react";
import { useTranslation } from "react-i18next";

import { Link } from "../../Link";
import { TextSizes } from "../../Link/Link";
import { useAppState, useAppDispatch } from "../../../contexts";

interface Props {
  size?: TextSizes;
}

const LocaleToggle: React.FC<Props> = ({ size = "md" }) => {
  const appDispatch = useAppDispatch();
  const { locale } = useAppState();
  const { i18n } = useTranslation();

  const onClickHandler = (lang: string) => {
    i18n.changeLanguage(lang === "en-CA" ? "en" : "fr");

    appDispatch({
      type: "SET_LOCALE",
      payload: {
        locale: lang,
      },
    });

    window.location.reload();
  };

  return locale === "fr-CA" ? (
    <Link size={size} onClick={() => onClickHandler("en-CA")}>
      English
    </Link>
  ) : (
    <Link size={size} onClick={() => onClickHandler("fr-CA")}>
      Français
    </Link>
  );
};

export const LocaleToggleMemoized = React.memo(LocaleToggle);
