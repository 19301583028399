import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Link } from "../../Link";
import { Text } from "../../Text";
import { theme } from "../../theme";

export const Legal: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LegalStyled>
      <LeftSection>
        <Text size="sm">{t(`Footer.legal.ecocert`)}</Text>
      </LeftSection>

      <RightSection>
        <Text size="sm">
          © {new Date().getFullYear()} {t(`Footer.legal.secondlife`)}
        </Text>

        <Link to="/terms-and-conditions" size="sm" className="nostate">
          {t(`Footer.legal.terms`)}
        </Link>
      </RightSection>
    </LegalStyled>
  );
};

const LegalStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: ${theme.spacing[1]};

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-bottom: 0;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: flex-end;

  & > * + * {
    margin-left: ${theme.spacing[2]};
  }
`;
