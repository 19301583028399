import { useInfiniteQuery } from "react-query";
import { useQuery } from "react-query";
import config from "../../config";
import axios from "redaxios";
import { TProduct } from "../../types//Product";
import { stringify } from "query-string";

type Params = Record<string, string | number | boolean | null | undefined>;

const apiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/products`;

export const getProductsByCategory = (
  cid: string,
  page: number = 0,
  lang: string
) => {
  let limit = 20;
  const offset = 0;
  limit = page > 0 ? limit * page : limit;
  return axios
    .get(
      `${apiUrl}/categories/find?category=${cid}&offset=${offset}&limit=${limit}&lang=${lang}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data.payload);
};

export const useCategoriesProducts = (
  pageSize = 1,
  category: string,
  tag: string,
  lang: string,
  orderBy?: string
) => {
  return useInfiniteQuery<{ nextPage: number | null; products: TProduct[] }>(
    ["useCategoriesProducts", pageSize, category, tag, orderBy],
    async ({ pageParam = 0 }) => {
      const offset = pageParam === 0 ? 0 : pageParam * pageSize;

      const res = await axios.get(
        `${apiUrl}/categories/find?offset=${offset}&limit=${pageSize}&category=${category}&tag=${tag}&lang=${lang}&orderBy=${orderBy}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const nextPage =
        res.data.payload.length === pageSize
          ? parseInt(pageParam, 10) + 1
          : null;

      return {
        products: res.data.payload as TProduct[],
        nextPage,
      };
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.nextPage === null) {
          // we've reached the end of the data
          return null;
        }
        // return the next page number
        return lastPage.nextPage;
      },
    }
  );
};

export const useProduct = (productId: string) => {
  return useQuery<TProduct>(
    ["useProduct", productId],
    () =>
      axios
        .get(`${apiUrl}/${productId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => res.data.payload),
    {
      enabled: !!productId,
      retryDelay: 1000,
      refetchInterval: 1000,
      refetchOnMount: true,
    }
  );
};

export const getProduct = (productId: string) => {
  return axios
    .get(`${apiUrl}/${productId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data.payload);
};

/**
 * Get all grocerie products to display
 * @param locale - The locale of the authenticated user
 * @returns The featured products list
 */
export const useHighlightedProducts = (type: string) => {
  const params: Params = {};

  if (type) {
    params.type = type;
  }
  return useQuery<TProduct[]>(["useHighlightedProducts", type], () =>
    axios
      .get(`${apiUrl}/highlighted/displays?${stringify(params)}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data.payload as TProduct[])
  );
};

export const usePromoProducts = () => {
  return useQuery<TProduct[]>(["usePromoProducts"], () =>
    axios
      .get(`${apiUrl}/categories/find?offset=0&limit=30`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data.payload as TProduct[])
  );
};
