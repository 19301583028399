import { useQuery } from "react-query";
import config from "../../config";
import { stringify } from "query-string";
import { TCartPrices, TOrder } from "../../types/Order";
import axios from "redaxios";
import { TUserWaterCO2Saved } from "../../types/User";
import { TProduct } from "../../types/Product";

type Params = Record<string, string | number | boolean | null | undefined>;

const apiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/orders`;
const apiUrlProducts = `${config.SERVICES.MARKETPLACE_API_URL}/products`;

/**
 * Fetch all orders history
 * @param locale - The locale of the authenticated user
 * @returns A list of all orders
 */
export const useOrders = (locale?: string) => {
  const params: Params = {};
  const token = localStorage.getItem("token");

  if (locale) {
    params.locale = locale;
  }

  return useQuery<TOrder[]>(
    ["useOrders", JSON.stringify(params)],
    () =>
      axios
        .get(`${apiUrl}/history?${stringify(params)}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data.payload),
    {
      // The query will not execute until the userId exists
      enabled: !!token,
    }
  );
};

/**
 * Fetch specific order history
 * @param oid - order id
 * @returns TOrder
 */
export const useOrder = (oid: string) => {
  const params: Params = {};
  const token = localStorage.getItem("token");
  return useQuery<TOrder>(
    ["useOrders", JSON.stringify(params)],
    () =>
      axios
        .get(`${apiUrl}/history/${oid}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data.payload),
    {
      // The query will not execute until the userId exists
      enabled: !!token || !!oid,
    }
  );
};

/**
 * Fetch the current processing order for a user
 * This order is paid or tomorrow will be paid
 * @param locale - The locale of the user
 * @returns The current active order
 */
export const useProcessingOrders = () => {
  const token = localStorage.getItem("token");
  return useQuery<TOrder>(
    ["useProcessingOrders", token],
    async () => {
      const res = await axios.get(`${apiUrl}/current/processing`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data.payload;
    },
    {
      // The query will not execute until the userId exists
      enabled: !!token,
    }
  );
};

/**
 * Fetch the pre order products
 * This order is paid or tomorrow will be paid
 * @param locale - The locale of the user
 * @returns The current active order
 */
export const usePreOrder = () => {
  const token = localStorage.getItem("token");
  return useQuery<(TProduct & { quantity: number })[]>(
    ["usePreOrder", token],
    async () => {
      const res = await axios.get(`${apiUrlProducts}/preorder`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data.payload;
    },
    {
      // The query will not execute until the userId exists
      enabled: !!token,
    }
  );
};
/**
 * Remove the product from pre order
 * @returns
 */
export const removeProductFromPreOrder = (inputs: { pid: string }) => {
  const token = localStorage.getItem("token");
  const { pid } = inputs;

  return axios
    .delete(`${apiUrlProducts}/${pid}/preorder`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data.payload);
};
/**
 * update or create the product from pre-order
 * @returns
 */
export const updateOrCreateProductFromPreOrder = (inputs: {
  pid: string;
  quantity: number;
  keep?: boolean;
}) => {
  const token = localStorage.getItem("token");
  const { quantity, pid, keep } = inputs;
  const body = {
    keep,
    quantity,
  };
  return axios
    .post(`${apiUrlProducts}/${pid}/preorder`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data.payload);
};

/**
 * Fetch the current order for a user
 * @param locale - The locale of the user
 * @returns The current active order
 */
export const useCart = (oid?: string) => {
  const token = localStorage.getItem("token");

  return useQuery<TCartPrices>(
    ["useCart", oid],
    async () => {
      const res = await axios.get(`${apiUrl}/cart/${oid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data.payload;
    },
    {
      // The query will not execute until the oid exists
      enabled: !!token && !!oid,
    }
  );
};

/**
 * Inscrease product count
 * @param oid - The oid of the order
 * @param uid - The uid of the user
 * @param pid - The pid of the product
 * @param quantity - The quantity to be updated
 * @returns The Order object
 */
export const updateInventory = (inputs: {
  oid: string;
  uid: string;
  pid: string;
  quantity: number;
  locale: string;
}) => {
  const token = localStorage.getItem("token");
  const { uid, quantity, locale, pid, oid } = inputs;
  const body = {
    uid,
    quantity,
    locale,
  };
  return axios
    .put(`${apiUrl}/${oid}/products/${pid}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data.payload);
};

/**
 * Decrease product count
 * @param oid - The oid of the order
 * @param uid - The uid of the user
 * @param pid - The pid of the product
 * @param quantity - The quantity to be updated
 * @returns The Order object
 */
export const removeProductFromOrder = (inputs: {
  oid: string;
  uid: string;
  pid: string;
}) => {
  const token = localStorage.getItem("token");
  const { pid, oid } = inputs;

  return axios
    .delete(`${apiUrl}/${oid}/products/${pid}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data.payload);
};

/**
 * Get all economy that user saved
 * @param locale - The locale of the authenticated user
 * @returns The local products list
 */
export const useSavings = () => {
  const token = localStorage.getItem("token");

  return useQuery<TUserWaterCO2Saved>(
    ["useSavings", token],
    () =>
      axios
        .get(`${apiUrl}/saved`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data.payload),
    {
      // The query will not execute until the userId exists
      enabled: !!token,
    }
  );
};

/**
 * Update order status "skip" or "open"
 * @param oid - The oid of the order
 * @param status - The status of the order
 * @returns The Order object
 */
export const useUpdateStatus = (payload: { oid: string; status: string }) => {
  const token = localStorage.getItem("token");
  const { oid, status } = payload;
  return axios
    .put(
      `${apiUrl}/${oid}/status`,
      { status },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data.payload);
};
