import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { theme } from '../../../components/theme';
import { useTags } from '../../../hooks/Tags';
import { TTag } from '../../../types/Tag';

interface Props {
  tagId: string;
  tagLabel: string;
  isActive: boolean;
  onClick: () => void;
}

export const Tag: React.FC<Props> = ({ tagId, tagLabel, isActive, onClick }) => {
  return <TagWrapper isActive={isActive} onClick={onClick}>{tagLabel}</TagWrapper>;
};

const TagWrapper = styled.li<Pick<Props, "isActive">>`
  display: flex;
  flex-direction: row;
  margin: 2px;
  padding: ${theme.spacing[0.25]} ${theme.spacing[1]};
  background: ${theme.colors.base.white};;
  border: 1px solid ${theme.colors.accent.secondary};
  border-radius: 64px;
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.md};
  line-height: ${theme.font.size.mdx};
  color: ${theme.colors.base.secondary};
  white-space: nowrap;
  cursor: pointer;
  transition: all ${theme.transition.fast};

  &:hover {
    background: ${theme.colors.accent.primary};;
    border: 1px solid ${theme.colors.accent.primary};
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[0.5]} ${theme.spacing[1]};
  }

  ${(props) =>
    props.isActive &&
    css`
      background: ${theme.colors.base.primary};
      border: 1px solid ${theme.colors.base.primary};
      color: ${theme.colors.base.white};
      cursor: default;
      
      &:hover {
        background: ${theme.colors.base.primary};
        border: 1px solid ${theme.colors.base.primary};
      }
    `}
`;
