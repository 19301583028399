import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import styled, { css } from "styled-components";

import { Marker } from "./Marker";
import { Modal } from "./Modal";
import { Props as ModalProps } from "./Modal/Modal";
import { TGeolocation } from "../../types/Geolocation";
import { TPickupLocation } from "../../types/PickupPoint";
import { theme } from "../theme";
import config from "../../config";
import style from "./Map.style";

type TMapSizes = "md" | "lg";

interface Props {
  center: {
    lat: number;
    lng: number;
  };
  defaultCenter?: {
    lat: number;
    lng: number;
  };
  zoom: number;
  defaultZoom?: number;
  markers: TPickupLocation[];
  current?: TPickupLocation | null | undefined;
  currentPosition?: TGeolocation;
  draggable?: boolean;
  size?: TMapSizes;
  onSelectedPup: (pup: TPickupLocation) => void;
}

export const Map: React.FC<Props> = ({
  defaultCenter,
  center,
  defaultZoom,
  zoom,
  markers,
  currentPosition,
  draggable = true,
  size = "md",
  onSelectedPup,
}) => {
  const [modal, setModal] = useState<ModalProps>({
    visible: false,
    content: null,
  });

  const onClickHandler = (_: any, childProps: any) => {
    if (childProps?.data) {
      onSelectedPup(childProps.data);
    }
    setModal({
      visible: true,
      content: childProps.data,
    });
  };

  const onModalClose = () => {
    setModal({
      ...modal,
      visible: false,
    });
  };

  return (
    <MapStyled role="presentation" aria-hidden="true" size={size}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.GOOGLE_MAP_KEY as string }}
        defaultCenter={defaultCenter}
        center={center}
        defaultZoom={defaultZoom}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onChildClick={onClickHandler}
        hoverDistance={24}
        options={{ styles: style }}
        draggable={draggable}
      >
        {currentPosition && (
          <Marker
            lat={currentPosition.lat}
            lng={currentPosition.lng}
            current={true}
            key={`marker-user`}
          />
        )}
        {markers?.length &&
          markers.map((marker, index) => (
            <Marker
              lat={marker.lat}
              lng={marker.lng}
              data={marker}
              full={marker.availability === 0}
              key={`marker-${index}`}
            />
          ))}
      </GoogleMapReact>
      {modal?.visible && (
        <Modal
          visible={modal.visible}
          content={modal.content}
          onClose={onModalClose}
        />
      )}
    </MapStyled>
  );
};

const MapStyled = styled.div<Pick<Props, "size">>`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  ${(props) =>
    props.size === "lg" &&
    css`
      height: 50vh;

      @media (min-width: ${theme.breakpoints.sm}) {
        height: 70vh;
      }
    `}
`;
