import React from "react";

const WalletIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="73"
      height="68"
      viewBox="0 0 73 68"
      fill="none"
    >
      <path
        d="M69.7093 33.2093V23.7209C69.7093 22.8511 68.9977 22.1395 68.1279 22.1395H61.8023V14.2325C61.8023 13.3628 61.0907 12.6511 60.221 12.6511H46.6368L34.46 0.458591C33.8433 -0.158153 32.847 -0.158153 32.2303 0.458591L20.0377 12.6511H6.45351C2.91119 12.6511 0.12793 15.4344 0.12793 18.9767V61.6744C0.12793 65.2167 2.91119 68 6.45351 68H68.1279C68.9977 68 69.7093 67.2884 69.7093 66.4186V54.4632C71.5912 53.3721 72.8721 51.3479 72.8721 49.0232V36.3721C72.8721 34.6325 71.4489 33.2093 69.7093 33.2093ZM58.6396 15.8139V22.1395H56.1251L49.7996 15.8139H58.6396ZM33.3372 3.81115L44.8656 15.3395L51.6498 22.1237H45.0554L37.8126 14.8493C37.5121 14.5488 37.1168 14.3907 36.6898 14.3907C36.2628 14.3907 35.8675 14.5646 35.567 14.8493C34.7605 15.6558 34.0014 16.067 33.3372 16.067C32.6572 16.067 31.914 15.6558 31.1075 14.8493C30.5065 14.2484 29.4628 14.2484 28.8777 14.8493L21.6033 22.1237H15.0247L21.8089 15.3395L33.3372 3.81115ZM40.5958 22.1395H26.0628L30.0796 18.1228C31.1391 18.866 32.2303 19.2298 33.3372 19.2298C34.4442 19.2298 35.5354 18.8502 36.5949 18.1228L40.5958 22.1395ZM6.45351 15.8139H16.8749L10.5493 22.1395H6.45351C4.65072 22.1395 3.29072 20.7795 3.29072 18.9767C3.29072 17.1739 4.65072 15.8139 6.45351 15.8139ZM66.5465 64.8372H6.45351C4.65072 64.8372 3.29072 63.4772 3.29072 61.6744V24.48C4.20793 25.0018 5.29909 25.3023 6.45351 25.3023H66.5465V34.7907C66.5465 35.5814 65.5344 36.3721 64.9651 36.3721H49.1512C43.6479 36.3721 39.6628 40.3572 39.6628 45.8605C39.6628 51.3637 43.6479 55.3488 49.1512 55.3488H66.5465V64.8372ZM69.7093 49.0232C69.7093 50.7628 68.2861 52.186 66.5465 52.186H49.1512C45.4349 52.186 42.8256 49.5925 42.8256 45.8605C42.8256 42.1284 45.4349 39.5349 49.1512 39.5349H64.9651C66.7047 39.5349 68.6656 38.2381 69.4089 36.3721H69.7093V49.0232Z"
        fill="#10594D"
      />
      <path
        d="M50.7326 44.2791H47.5698V47.4418H50.7326V44.2791Z"
        fill="#10594D"
      />
    </svg>
  );
};

export default WalletIcon;
