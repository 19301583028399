import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { icons } from "../../assets/icons";
import { AlternateSection } from "../../components/AlternateSection";
import { Button } from "../../components/Button";
import { FlashBannerReactivate } from "../../components/FlashBannerReactivate";
import { Label } from "../../components/Label";
import { Loading } from "../../components/Loading";
import { Page } from "../../components/Page";
import { Plans as PlansContainer } from "../../components/Plans";
import { Snackbar } from "../../components/Snackbar";
import { theme } from "../../components/theme";
import { WithAuth } from "../../components/WithAuth";
import { usePlansToDisplay } from "../../hooks/Plans";
import { useSetPlans, useSubscription } from "../../hooks/Subscriptions";
import { TPlan, TPlanQuantity } from "../../types/Plan";
import { Heading } from "../../components/Heading";
import { Image } from "../../components/Image";

interface LocationState {
  prevPath?: string;
}

export const Plans: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: locationState } = useLocation();
  const { mutate, isLoading, error, isSuccess } = useMutation(useSetPlans);
  const [userPlans, setUserPlans] = useState<Array<TPlan>>([]);
  const { data: subscription } = useSubscription();
  const { data: plans = [] } = usePlansToDisplay("");

  useEffect(() => {
    if (subscription && subscription?.plans?.length === 0) {
      return history.push("/sign-up/step-2");
    }

    if (subscription && !subscription?.lastDigits) {
      return history.push("/sign-up/confirm");
    }

    if (subscription?.status === "cancel") {
      return history.push("/dashboard");
    }

    if (subscription?.plans && userPlans.length === 0) {
      setUserPlans(subscription.plans);
    }
  }, [subscription]);

  const handleBack = () => {
    if (locationState && (locationState as LocationState).prevPath) {
      history.goBack();
    } else {
      history.push("/account");
    }
  };

  const onAddPlan = (plan: TPlan, persons: TPlanQuantity) => {
    const userPlanCopy = JSON.parse(JSON.stringify(userPlans));
    const index = userPlanCopy.findIndex((p: TPlan) => p.pid === plan.pid);
    if (index >= 0) {
      userPlanCopy.splice(index, 1, { ...userPlanCopy[index], persons });
    } else {
      userPlanCopy.push({ pid: plan.pid, persons, default_persons: persons });
    }
    setUserPlans(userPlanCopy);
  };

  const onDeletePlan = (plan: TPlan) => {
    const userPlanCopy = JSON.parse(JSON.stringify(userPlans));

    const index = userPlanCopy.findIndex((p: TPlan) => p.pid === plan.pid);
    if (index >= 0) {
      userPlanCopy.splice(index, 1);
    }

    setUserPlans(userPlanCopy);
  };

  const onCancel = () => {
    subscription?.plans && setUserPlans(subscription.plans);
  };

  const onSave = () => {
    mutate({
      plans: userPlans.map((p) => ({
        pid: p.pid,
        persons: p.default_persons,
      })),
    });
  };

  return (
    <WithAuth>
      <PageStyled
        centerY
        centerX
        backgroundColor={theme.colors.base.background}
      >
        {subscription?.status === "cancel" && (
          <FlashBannerReactivate times={10} type="normal">
            {t("Subscription.cancelled")}
          </FlashBannerReactivate>
        )}

        <AlternateSection>
          <BackWrapper>
            <BackButton onClick={handleBack}>
              <icons.arrowTop />
              <BackLabel size="mdx" weight="bold">
                {t("Common.action.back")}
              </BackLabel>
            </BackButton>
            <RouteLabel size="mdx" weight="bold">
              {t("Navigation.personal-infos")}
            </RouteLabel>
          </BackWrapper>
          <PlansWrapper>
            <LayoutStyled>
              <SectionStyled>
                <HeadingStyled>
                  {t(`Signup.step1.customize.plan.heading`)}
                </HeadingStyled>
                <SpanStyled>{t("Signup.step1.customize.plan.text")}</SpanStyled>
              </SectionStyled>
              <ItemPictureStyled>
                <Image
                  path="/common/images/hero-1"
                  alt="secondlife-basket-fruit"
                />
              </ItemPictureStyled>
            </LayoutStyled>
            <ActionsWrapper>
              <StyleButton primary onClick={onCancel}>
                {t("Common.action.cancel")}
              </StyleButton>
              <StyleButton
                secondary
                disabled={userPlans.length < 1}
                onClick={onSave}
              >
                {t("Common.action.save")}
              </StyleButton>
            </ActionsWrapper>
            {subscription && (
              <PlansContainer
                userPlans={userPlans}
                plans={plans}
                onAddPlan={onAddPlan}
                onDeletePlan={onDeletePlan}
              />
            )}

            {isLoading && (
              <LoadingWrapper>
                <Loading />
              </LoadingWrapper>
            )}
          </PlansWrapper>
          {error && (
            <Snackbar type="error" text={t("Generic.error")} error={error} />
          )}
          {isSuccess && (
            <Snackbar type={"success"} text={t("Snackbar.changed.plan")} />
          )}
        </AlternateSection>
      </PageStyled>
    </WithAuth>
  );
};

const PageStyled = styled(Page)`
  padding-bottom: ${theme.spacing[4]};
`;
const SectionStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: ${theme.breakpoints.sm}) {
    align-items: center;

    h2 {
      margin-bottom: ${theme.spacing[0.5]};
    }
  }
`;
const ItemPictureStyled = styled.div`
  width: 324px;

  div,
  img,
  source {
    width: 100%;
    display: flex;
  }
`;

const HeadingStyled = styled(Heading)`
  @media (max-width: ${theme.breakpoints.sm}) {
    text-align: center;
  }
`;
const LayoutStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const SpanStyled = styled.span`
  color: ${theme.colors.base.dark};
  font-size: ${theme.font.size.md};
  line-height: ${theme.font.size.lg};
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.normal};

  @media (max-width: ${theme.breakpoints.sm}) {
    text-align: center;
  }
`;

const BackWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    margin-right: ${theme.spacing[1]};
    fill: ${theme.colors.base.primary};
    transform: rotate(-90deg);
  }

  &:hover {
    svg {
      fill: ${theme.colors.base.dark};
    }

    div {
      color: ${theme.colors.base.dark};
    }
  }
`;

const BackLabel = styled(Label)`
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.primary};
`;

const RouteLabel = styled(Label)`
  margin-left: ${theme.spacing[1]};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.mdx};
  line-height: ${theme.font.size.lg};
  font-weight: ${theme.font.weight.bold};
`;

const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing[4]};
`;

const ActionsWrapper = styled.div`
  display: flex; // or use 'grid' if you prefer
  gap: ${theme.spacing[2]}; // Adjust the gap size as needed
  margin-top: ${theme.spacing[2]};
  margin-bottom: ${theme.spacing[2]};
`;

const StyleButton = styled(Button)`
  width: 200px;
`;

const LoadingWrapper = styled.div`
  > div {
    position: fixed;
  }
`;
