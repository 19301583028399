import clsx from "clsx";
import React from "react";
import styled from "styled-components";

import { alpha, theme, spacing } from "../theme";

interface Props {
  id: string;
  name: string;
  rows: string;
  cols: string;
  value: string;
  error: boolean;
  className?: string;
  register: any;
  validation: any;
  placeholder: string;
  onChange: (value: string) => void;
}

export const TextArea: React.FC<Props> = ({
  id,
  name,
  rows,
  cols,
  className,
  value,
  error,
  register,
  placeholder,
  onChange,
  validation,
}) => {
  const onValueChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!onChange) return;
    onChange(evt.currentTarget.value);
  };
  return (
    <InputStyled
      id={id}
      onChange={onValueChange}
      className={clsx("form-input", error && "error", className)}
      value={value}
      placeholder={placeholder}
      name={name}
      ref={register(validation)}
      rows={Number(rows)}
      cols={Number(cols)}
    />
  );
};

export const inputStyles = `
  width: 100%;
  padding: ${spacing(0.7)} ${spacing(0.5)};
  border: 0;
  box-shadow: inset 0 0 0 2px ${theme.colors.accent.third};
  border-radius: ${theme.border.radius.sm};
  background-color: ${theme.colors.base.light};
  font-size: ${theme.font.size.md};

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px ${theme.colors.accent.secondary};
  }

  &.error {
    box-shadow: 0 0 0 2px ${theme.banner.error.color};
    background-color: ${alpha(
      theme.banner.error.color,
      theme.transparency.high
    )};

    ::placeholder {
      color: ${alpha(theme.banner.error.color, theme.transparency.medium)};
    }
  }

  ::placeholder {
    padding-left: ${theme.spacing[0.5]};
    color: ${theme.colors.neutral.primary};
    opacity: 1;
  }
`;

const InputStyled = styled.textarea`
  ${inputStyles}
`;
