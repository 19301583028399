import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { icons } from "../../assets/icons";
import { theme } from "../theme";
import { Button } from "../../components/Button";

interface Props {
  visible: boolean;
  onClose: () => void;
  onSubmit: (coupon: string) => void;
}

export const CouponCB: React.FC<Props> = ({
  children,
  visible = false,
  onClose,
  onSubmit,
}) => {
  let scroll = 0;
  const body = document.body;

  useEffect(() => {
    toggleScroll(visible);
  }, [visible]);

  useEffect(() => {
    return () => {
      toggleScroll(false);
    };
  }, []);

  const toggleScroll = (deactivateScroll: boolean) => {
    if (deactivateScroll) {
      scroll = window.scrollY;
      body.style.position = "fixed";
    } else {
      body.style.position = "static";
      window.scroll(0, scroll);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <PopupWrapper>
      <Container>
        <>
          <ButtonStyled onClick={handleClose}>
            <icons.close fill={theme.colors.base.dark} />
          </ButtonStyled>
          {children}
        </>
      </Container>
    </PopupWrapper>
  );
};

const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: ${theme.spacing[1]};
  backdrop-filter: blur(2px);
  z-index: 50;

  @media (min-width: ${theme.breakpoints.sm}) {
    background: rgba(74, 77, 75, 0.9);
  }
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: ${theme.spacing[1.5]};
  background: ${theme.colors.accent.secondary};
  border: 3px dashed ${theme.colors.base.secondary};
  border-radius: ${theme.border.radius.lg};

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 600px;
    height: auto;
  }
`;

const ButtonStyled = styled.button`
  align-self: flex-end;
  top: 0;
  right: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;
