export type TCoupon = {
  id: number;
  type: TypeEnum;
  code: string;
  amount: number;
  expiry_date: Date | null;
  name: string;
  is_active: boolean;
  limit_per_user: number;
  max_usage: number;
};

export enum TypeEnum {
  FIXED_AMOUNTS = "FIXED_AMOUNTS",
  PERCENTAGES = "PERCENTAGES",
}

export type TCouponUser = {
  id: number;
  user_id: number;
  coupon_id: number;
};

export type TUsersCoupon = {
  coupon: TCoupon;
  users: string[];
};

export type TCouponTransaction = {
  id: number;
  user_id: number;
  coupon_id: number;
  order_id: number;
};
