import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ProductQuantitySelector } from "../../../components/ProductQuantitySelector";
import { Text } from "../../../components/Text";
import { theme } from "../../../components/theme";
import { useAppState } from "../../../contexts";
import { origins } from "../../../data/origins";
import { TProduct } from "../../../types/Product";
import { formatNumber, formatDate } from "../../../utils/i18n";
import { MSLTag } from "../../../components/MSLTag";
import Slider from "react-slick";
import { TOrder } from "../../../types/Order";
import { useSubscription } from "../../../hooks/Subscriptions";
import { Button } from "../../../components/Button";
import { useHistory } from "react-router-dom";

const settings = {
  dots: true,
  slidesToShow: 1,
  infinite: true,
  autoplay: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },

    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};

interface Props {
  product: TProduct;
  quantity: number;
  order?: TOrder;
  showQuantityPicker?: boolean;
}

export const Card: React.FC<Props> = ({ product, quantity, order }) => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const [lang] = locale.split("-");
  // @ts-ignore
  const origin = origins[lang][product?.origin];
  const { data: subscription } = useSubscription();
  const history = useHistory();

  return (
    <ProductCard>
      <WrapperSlider>
        {product?.images && product?.images.length === 1 && (
          <img src={product.images[0]} loading={"lazy"} />
        )}
        {product?.images && product?.images.length > 1 && (
          <Slider {...settings}>
            {product.images.map((image, index) => (
              <img key={index} src={image} loading={"lazy"} />
            ))}
          </Slider>
        )}
      </WrapperSlider>

      <PresentationWrapper>
        <LocationText size="mdx">
          {`${product.brand.copy[`${lang}`].name}${
            origin ? ` | ${origin}` : ""
          }`}
        </LocationText>
        <ProductLabel size="lg" weight={"bold"}>
          {product?.copy[lang]?.name} ({product.volume / 100} {product.unit})
        </ProductLabel>
        {product?.best_before && (
          <BestBefore size="md" weight={"light"}>
            {t("Products.expirationDate")} :&nbsp;
            {formatDate(locale, new Date(product.best_before))}
          </BestBefore>
        )}

        {/* Tags vege, bio, etc */}
        <TagsWrapper>
          {product.tags?.map((tag) => (
            <Tag key={tag.tid}>{tag.copy[lang]?.name}</Tag>
          ))}
        </TagsWrapper>

        <Description size="mdx">{product?.copy[lang]?.description}</Description>

        {/* Icons produits sauveés, etc */}
        <IconAndPriceWrapper>
          <WrapperIcons>
            {product.is_saved && <SavedTag key="saved">{t("Saved")}</SavedTag>}
            {!product.is_saved && (
              <NotSavedTag key="notSaved">{t("Regular")}</NotSavedTag>
            )}
            {product.is_featured && <NewTag key="new">{t("New")}</NewTag>}
            {product.is_local && <LocalTag key="local">{t("Quebec")}</LocalTag>}
          </WrapperIcons>
          <MemberPriceWrapper>
            <MSLTag
              mobile={false}
              browser={false}
              price={product.member_price}
              size="xl"
            />
          </MemberPriceWrapper>
        </IconAndPriceWrapper>
        <Separator />
        <BottomWrapper>
          <PriceWrapper>
            <UnitPriceText size="lg">{`${formatNumber(
              locale,
              product.unit_price / 100
            )}$ / ${product.unit_price_unit}`}</UnitPriceText>
            <PriceText size="lg" weight="bold">
              {product.is_promo && (
                <s>{`${formatNumber(
                  locale,
                  (product.regular_price * (quantity || 1)) / 100
                )}$`}</s>
              )}
              <PriceTextReal>
                {`${formatNumber(locale, product.price / 100)}$`}
              </PriceTextReal>
            </PriceText>
          </PriceWrapper>
        </BottomWrapper>
        <ActionWrapper>
          {subscription && (
            <ProductQuantitySelector
              isPreOrder={order?.status === "open" ? false : true}
              productId={product.pid}
              quantity={quantity}
              order={order}
              withBorder={true}
            />
          )}
          {!subscription && (
            <StyledButton
              thirdReverse
              onClick={() => {
                history.push("/sign-up/step-1");
              }}
            >
              {t("GenericCTA.add")}
            </StyledButton>
          )}
        </ActionWrapper>
      </PresentationWrapper>
    </ProductCard>
  );
};

const BestBefore = styled(Text)`
  position: relative;
  display: flex;
  align-items: flex-start;
  text-align: start;
  align-self: flex-start;
  justify-content: flex-start;
  line-height: ${theme.font.size.xs};
  border: 1px solid ${theme.colors.accent.secondary};
  border-radius: 64px;
  background: ${theme.colors.base.background};
  color: ${theme.colors.base.secondary};
  padding: 8px;
  max-width: 70%;
  margin: ${theme.spacing[0.25]} 0 ${theme.spacing[0.25]} 0;

  @media (min-width: ${theme.breakpoints.sm}) {
    max-width: 50%;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items:center @media (max-width: ${theme.breakpoints.sm}) {
    padding: 0;
    height: 48px;
  }
`;
const WrapperSlider = styled.div`
  align-items: center;
  max-height: 100%;
  max-width: 100%;

  img {
    max-height: 324px;
    object-fit: center;
    width: 300px;
  }

  @media (min-width: ${theme.breakpoints.lg}) {
    flex-direction: row;

    img {
      max-width: 100%;
      max-height: 490px;
    }
  }

  @media (min-width: ${theme.breakpoints.md}) {
    flex-direction: row;
    max-width: auto;

    img {
      max-width: 500px;
      max-height: 490px;
    }
  }

  .slick-track {
    @media (min-width: ${theme.breakpoints.sm}) {
      min-height: 450px;
    }
  }

  .slick-dots {
    position: static;
    bottom: 0;
    margin-bottom: 1rem;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    align-items: center;
  }
  @media (min-width: ${theme.breakpoints.md}) {
    max-width: 500px;
  }
`;

const WrapperIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: end;
`;
const PriceTextReal = styled.span`
  color: ${theme.colors.base.primary};
`;

const IconAndPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${theme.spacing[0.25]};
  margin: ${theme.spacing[0.25]} 0;
`;

const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing[1]};
  margin-top: ${theme.spacing[1.5]};
  background: ${theme.colors.base.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  img {
    max-height: 324px;
    object-fit: contain;
  }

  @media (min-width: ${theme.breakpoints.lg}) {
    flex-direction: row;

    img {
      max-width: 324px;
      max-height: 490px;
    }
  }

  @media (min-width: ${theme.breakpoints.md}) {
    flex-direction: row;

    img {
      max-width: none;
      max-height: 490px;
    }
  }
`;

const MemberPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const PresentationWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  @media (min-width: ${theme.breakpoints.sm}) {
    margin-left: ${theme.spacing[1.5]};
  }
`;

const LocationText = styled(Text)`
  margin-top: ${theme.spacing[1]};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.sm};
  line-height: ${theme.font.size.mdx};
  color: ${theme.colors.base.primary};

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-top: ${theme.spacing[2]};
    font-size: ${theme.font.size.mdx};
  }
`;

const ProductLabel = styled(Text)`
  margin: ${theme.spacing[0.25]} 0 ${theme.spacing[0.25]} 0;
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.mdx};
  line-height: ${theme.font.size.mdx};

  @media (min-width: ${theme.breakpoints.sm}) {
    margin: ${theme.spacing[1]} 0 ${theme.spacing[1.5]} 0;
    font-size: ${theme.font.size.xxl};
    line-height: ${theme.font.size.xxl};
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: ${theme.spacing[0.25]} 0;
`;

const Tag = styled.span`
  align-self: flex-start;
  margin: 2px;
  padding: ${theme.spacing[0.25]} ${theme.spacing[0.5]};
  white-space: nowrap;
  border: 1px solid ${theme.colors.accent.secondary};
  border-radius: 64px;
  background: ${theme.colors.base.background};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.sm};
  line-height: ${theme.font.size.mdx};
  color: ${theme.colors.base.secondary};
`;

const SavedTag = styled.span`
  align-self: flex-start;
  margin: 2px;
  margin-left: 0;
  padding: ${theme.spacing[0.25]} ${theme.spacing[0.5]};
  white-space: nowrap;
  border: 0;
  border-radius: 64px;
  background: ${theme.colors.base.secondary};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.sm};
  line-height: ${theme.font.size.mdx};
  color: ${theme.colors.base.white};
`;

const NotSavedTag = styled.span`
  align-self: flex-start;
  margin: 2px;
  margin-left: 0;
  padding: ${theme.spacing[0.25]} ${theme.spacing[0.5]};
  white-space: nowrap;
  border: 0;
  border-radius: 64px;
  background: ${theme.colors.status.info};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.sm};
  line-height: ${theme.font.size.mdx};
  color: ${theme.colors.base.white};
`;

const LocalTag = styled.span`
  align-self: flex-start;
  margin: 2px;
  margin-left: 0;
  padding: ${theme.spacing[0.25]} ${theme.spacing[0.5]};
  white-space: nowrap;
  border: 0;
  border-radius: 64px;
  background: #003da5;
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.sm};
  line-height: ${theme.font.size.mdx};
  color: ${theme.colors.base.white};
`;

const NewTag = styled.span`
  align-self: flex-start;
  margin: 2px;
  margin-left: 0;
  padding: ${theme.spacing[0.25]} ${theme.spacing[0.5]};
  white-space: nowrap;
  border: 0;
  border-radius: 64px;
  background: ${theme.colors.base.third};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.sm};
  line-height: ${theme.font.size.mdx};
  color: ${theme.colors.base.white};
`;

const Description = styled(Text)`
  display: flex;
  flex: 1;
  margin: ${theme.spacing[1]} 0;
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.lg};
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin-top: ${theme.spacing[1]};
  background: ${theme.colors.accent.secondary};
  @media (min-width: ${theme.breakpoints.sm}) {
    margin: ${theme.spacing[1]} 0 ${theme.spacing[0.5]} 0;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${theme.spacing[1]};
  margin-top: ${theme.spacing[1]};
`;

const ActionWrapper = styled.div`
  align-self: flex-end;
  padding-top: ${theme.spacing[2]};
  width: 100%;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

const UnitPriceText = styled(Text)`
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.grey};
  line-height: ${theme.font.size.lg};
`;

const PriceText = styled(Text)`
  line-height: ${theme.font.size.lg};
  font-size: ${theme.font.size.lg};
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.colors.base.secondary};
  display: flex;
  gap: ${theme.spacing[0.5]};

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.xxl};
    line-height: ${theme.font.size.xxl};
  }
`;
