import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { icons } from "../../../assets/icons";
import { Label } from "../../../components/Label";
import { Link } from "../../../components/Link";
import { theme } from "../../../components/theme";
import { useAppState } from "../../../contexts";
import { TOrder } from "../../../types/Order";
import { formatCurrency } from "../../../utils/i18n";

interface Props {
  order: TOrder;
}

export const Cell: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation();
  const { locale } = useAppState();

  const orderDate = new Intl.DateTimeFormat(locale, {
    weekday: "long",
    day: "numeric",
    month: "short",
    year: "numeric",
  }).format(new Date(order.delivery_date));
  return (
    <OrderCell>
      <DateLabel size="mdx">
        {orderDate.charAt(0).toUpperCase() + orderDate.slice(1)}
      </DateLabel>
      <BaseLabel size="md">{}</BaseLabel>
      <PriceWrapper>
        <PriceLabel size="lg">
          {formatCurrency(locale, order.total_price / 100)}
        </PriceLabel>
        <BillWrapper>
          <Link to={`/order-history/${order.oid}`}>
            <BaseLabel size="md">{t("History.receipt")}</BaseLabel>
          </Link>
          <icons.download fill={theme.colors.base.secondary} />
        </BillWrapper>
      </PriceWrapper>
    </OrderCell>
  );
};

const OrderCell = styled.li`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing[1]} 0;
  border-bottom: 1px solid ${theme.colors.base.grey};

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
    padding: ${theme.spacing[1.5]} ${theme.spacing[1]};
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-left ${theme.spacing[1.5]};
  }
`;

const BillWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${theme.spacing[1.5]};
  border-left: 1px solid #c4c4c4;

  > div {
    margin-right: ${theme.spacing[1.5]};
  }

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:hover {
      fill: ${theme.colors.base.primary};
    }
  }
`;

const BaseLabel = styled(Label)`
  line-height: ${theme.font.size.mdx};
  font-family: ${theme.font.family.secondary};
`;

const DateLabel = styled(BaseLabel)`
  width: 100%;
  margin-bottom: ${theme.spacing[1]};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.secondary};

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 200px;
    margin-bottom: 0;
    border-right: 1px solid #c4c4c4;
  }
`;

const PriceLabel = styled(BaseLabel)`
  flex: 1;
  margin-right ${theme.spacing[1.5]};
  line-height: ${theme.font.size.xl};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.colors.base.primary};
  text-align: left;

  @media (min-width: ${theme.breakpoints.sm}) {
    text-align: right;
  }
`;
