import { ReactComponent as add } from "./add.svg";
import { ReactComponent as arrowRight } from "./arrow-right.svg";
import { ReactComponent as arrowTop } from "./arrow-top.svg";
import { ReactComponent as check } from "./check.svg";
import { ReactComponent as checkboxChecked } from "./checkbox-checked.svg";
import { ReactComponent as checkboxUnchecked } from "./checkbox-unchecked.svg";
import { ReactComponent as close } from "./close.svg";
import { ReactComponent as co2 } from "./co2.svg";
import { ReactComponent as download } from "./download.svg";
import { ReactComponent as eye } from "./eye.svg";
import { ReactComponent as food } from "./food.svg";
import { ReactComponent as heart } from "./heart.svg";
import { ReactComponent as lock } from "./lock.svg";
import { ReactComponent as money } from "./money.svg";
import { ReactComponent as newest } from "./new.svg";
import { ReactComponent as pause } from "./pause.svg";
import { ReactComponent as person } from "./person.svg";
import { ReactComponent as plainChevronDown } from "./plain-chevron-down.svg";
import { ReactComponent as radioSelected } from "./radio-selected.svg";
import { ReactComponent as radioUnselected } from "./radio-unselected.svg";
import { ReactComponent as saved } from "./saved.svg";
import { ReactComponent as search } from "./search.svg";
import { ReactComponent as socialstars } from "./socialstars.svg";
import { ReactComponent as star } from "./star.svg";
import { ReactComponent as trash } from "./trash.svg";
import { ReactComponent as timer } from "./timer.svg";
import { ReactComponent as water } from "./water.svg";
import { ReactComponent as info } from "./info.svg";
import { ReactComponent as mls } from "./mls.svg";
import { ReactComponent as basket } from "./basket.svg";
import { ReactComponent as truck } from "./truck-fast-solid.svg";
import { ReactComponent as user } from "./circle-user-regular.svg";
import { ReactComponent as quoteLeft } from "./quote-left.svg";
import { ReactComponent as quoteRight } from "./quote-right.svg";
import { ReactComponent as worldFruit } from "./worldfruit.svg";

export const icons = {
  timer,
  worldFruit,
  quoteLeft,
  quoteRight,
  user,
  truck,
  basket,
  info,
  mls,
  money,
  check,
  arrowRight,
  add,
  arrowTop,
  checkboxChecked,
  checkboxUnchecked,
  close,
  download,
  eye,
  heart,
  lock,
  pause,
  person,
  plainChevronDown,
  radioSelected,
  radioUnselected,
  search,
  star,
  trash,
  saved,
  newest,
  food,
  co2,
  water,
  socialstars,
};
