import React from "react";
import styled from "styled-components";
import clsx from "clsx";

import { TPickupLocation } from "../../../types/PickupPoint";
import { theme } from "../../theme";

export interface Props {
  lat: number;
  lng: number;
  data?: TPickupLocation;
  current?: boolean;
  full?: boolean;
  $hover?: boolean;
}

export const Marker: React.FC<Props> = ({ current, full, $hover }) => {
  return (
    <MarkerStyled className={clsx($hover && "hover", current && "current", full && "full")}>
      <svg viewBox="0 0 26 32">
        <path
          d="M25 13C25 19.6274 13 30 13 30C13 30 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z"
          fill="currentColor"
          stroke="#2b2525"
          strokeWidth="2"
        />
      </svg>
    </MarkerStyled>
  );
};

const MarkerStyled = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  transform: translate(-12px);
  color: ${theme.colors.accent.primary};
  cursor: pointer;

  &.hover {
    color: ${theme.colors.accent.primary};
  }

  &.current,
  &.current:hover {
    border-radius: 50%;
    color: ${theme.colors.status.info};
    animation: pulse-black 2s infinite;
  }

  &.full {
    color: ${theme.colors.accent.secondary};
  }

  @keyframes pulse-black {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${theme.colors.status.info};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 16px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;
