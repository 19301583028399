import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { icons } from "../../../assets/icons";
import { AlternateSection } from "../../../components/AlternateSection";
import { Button } from "../../../components/Button";
import { CardForm } from "../../../components/CardForm";
import { Heading } from "../../../components/Heading";
import { Loading } from "../../../components/Loading";
import { OrderSummary } from "../../../components/OrderSummary";
import { Page } from "../../../components/Page";
import { Snackbar } from "../../../components/Snackbar";
import { Quotes } from "../../../components/Testimonials/Quotes";
import { Text } from "../../../components/Text";
import { theme } from "../../../components/theme";
import { WithAuth } from "../../../components/WithAuth";
import { useAddCard, useSubscription } from "../../../hooks/Subscriptions";
import { useUser } from "../../../hooks/Users";
import { SignUpProgress } from "../SignUpProgress";
import { Coupon } from "../../../components/Coupon";
import { CouponCB } from "../../../components/CouponCB";
import ReactHtmlParser from "react-html-parser";
import { formatDateShort } from "../../../utils/i18n";
import { useAppState } from "../../../contexts";
import { useNextDeliveries } from "../../../hooks/NextDeliveries";
import { pushEvent } from "../../../gtm";
import { EVENTS } from "../../../data/events";

const TIME_MINUTE_BEFORE_POPUP = 2;
export const Confirm: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const path = useLocation();
  const { locale } = useAppState();

  const stripe = useStripe();
  const elements = useElements();
  const { data: user } = useUser();
  const { data: subscription } = useSubscription();
  const { data: NextDelivery } = useNextDeliveries();
  const {
    mutate,
    isSuccess,
    isLoading: isLoadingAddCard,
    error,
  } = useMutation(useAddCard);
  const [coupon, setCoupon] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("token");

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, TIME_MINUTE_BEFORE_POPUP * 60 * 1000);
  }, []);

  useEffect(() => {
    if (!token) {
      history.push("/sign-up/step-1");
    }
  }, []);

  useEffect(() => {
    if (isSuccess && path.pathname === "/sign-up/confirm") {
      pushEvent(EVENTS.SIGNUP_COMPLETE_CREDIT_CARD_FORM);
      history.push("/dashboard");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (
      subscription &&
      (!subscription?.plans || subscription?.plans?.length === 0)
    ) {
      return history.push("/sign-up/step-1");
    }
    if (subscription && !subscription.delivery) {
      return history.push("/sign-up/step-3");
    }
    if (subscription?.status === "active") {
      return history.push("/dashboard");
    }
  }, [subscription]);

  const onSubmitPayment = useCallback(async () => {
    setIsLoading(true);

    if (!stripe || !elements || !user) {
      setIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      setIsLoading(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setIsLoading(false);
      return;
    }

    if (!paymentMethod) {
      setIsLoading(false);

      return;
    }

    if (user && paymentMethod) {
      setIsLoading(false);
      mutate({ paymentMethodId: paymentMethod.id, code: coupon });
    }
  }, [elements, stripe, user, coupon]);

  const redirectTo = (path: string) => () => history.push(path);
  const quotes = [
    {
      author: "Beverly Petit-frere",
      text: `Home.Quotes.signup-1`,
      link: "https://g.co/kgs/EAdCUK",
    },
    {
      author: "Annie Primeau",
      text: `Home.Quotes.signup-2`,
      link: "https://g.co/kgs/SBqV9n",
    },
    {
      author: "Mat G",
      text: `Home.Quotes.signup-3`,
      link: "https://g.co/kgs/NLxr6G",
    },
  ];

  return (
    <WithAuth>
      <Page centerY centerX backgroundColor={theme.colors.base.background}>
        <AlternateSectionStyled>
          <SignUpProgress current={5} title={t("Signup.heading.step5")} />

          {/* <HeadingStyled size="sm">
            {t("Signup.confirm.products")}
          </HeadingStyled> */}
          {/* <ProductsWrapper>
            {products.slice(0, 8).map((product) => {
              return (
                <Product
                  isPreOrder={false} //TODO
                  withBanner={false}
                  key={product.pid}
                  onSelectProduct={() => {}}
                  product={product}
                  order={undefined}
                />
              );
            })}
          </ProductsWrapper> */}
          {/* <HeadingStyled size="sm">
            {t("Signup.confirm.payment.coordinates")}
          </HeadingStyled> */}

          <ConfirmWrapper>
            <OrderSummary
              onModifyPlans={redirectTo("/sign-up/step-1")}
              onModifyDelivery={redirectTo("/sign-up/step-3")}
              showCreationDate={false}
              showDeliveryPrice={false}
              showAction={false}
            />
            <PaymentWrapper>
              <TextWrapper>
                {NextDelivery && (
                  <>
                    <Wrapper>
                      <icons.check />
                      <Text size="lg">
                        {ReactHtmlParser(
                          t("Signup.confirm.payment.text1", {
                            date: formatDateShort(
                              new Date(NextDelivery?.next_populating_date),
                              locale
                            ),
                          })
                        )}
                      </Text>
                    </Wrapper>
                    <Wrapper>
                      <icons.check />
                      <Text size="lg">
                        {ReactHtmlParser(
                          t("Signup.confirm.payment.text2", {
                            date: formatDateShort(
                              new Date(NextDelivery?.next_processing_date),
                              locale
                            ),
                          })
                        )}
                      </Text>
                    </Wrapper>
                    <Wrapper>
                      <icons.check />
                      <Text size="lg">
                        {ReactHtmlParser(
                          t("Signup.confirm.payment.text3", {
                            date: formatDateShort(
                              new Date(NextDelivery?.next_delivery_date),
                              locale
                            ),
                          })
                        )}
                      </Text>
                    </Wrapper>
                    <br />
                    <Text size="lg">
                      {ReactHtmlParser(
                        t("Signup.confirm.payment.text6", {
                          date: formatDateShort(
                            new Date(NextDelivery?.next_processing_date),
                            locale
                          ),
                        })
                      )}
                    </Text>
                  </>
                )}
              </TextWrapper>
              <CardWrapper>
                <CardForm />
                <CouponStyled
                  bgColor={`${theme.colors.accent.secondary}`}
                  onChangeCoupon={(couponName) => {
                    setCoupon(couponName);
                  }}
                  name={coupon}
                />
              </CardWrapper>
            </PaymentWrapper>
          </ConfirmWrapper>
          {isVisible && (
            <CouponCB
              visible={isVisible}
              onClose={() => {
                setIsVisible(false);
              }}
              onSubmit={(coupon) => {}}
            >
              <Heading element="h1" size="xl">
                {ReactHtmlParser(t("Signup.confirm.payment.coupon.message"))}
              </Heading>
              <Text style={{ textAlign: "left" }}>
                {t("Signup.confirm.payment.coupon.submessage")}
                <br />
                <br />
              </Text>

              <Button
                size="sm"
                third
                onClick={() => {
                  setCoupon("BIENVENUE");
                  setIsVisible(false);
                }}
              >
                {t("Signup.confirm.payment.coupon.yes")}
              </Button>
            </CouponCB>
          )}
          <BottomSectionStyled>
            <Button primary size="lg" onClick={redirectTo("/sign-up/step-3")}>
              {t("Signup.previous")}
            </Button>
            <Button
              size="lg"
              secondary
              onClick={onSubmitPayment}
              disabled={!stripe || !elements}
            >
              {t("Signup.confirm")}
            </Button>
          </BottomSectionStyled>
          {isLoadingAddCard || isLoading ? <Loading /> : null}
          {error && (
            <Snackbar
              type="error"
              // text={t("GenericError.card")}
              error={error}
            />
          )}
          <HeadingStyled size="sm">
            {t("Signup.confirm.testimonials")}
          </HeadingStyled>
          <ContentStyled>
            <Quotes quotes={quotes} />
          </ContentStyled>
        </AlternateSectionStyled>
      </Page>
    </WithAuth>
  );
};
const CouponStyled = styled(Coupon)`
  padding: 0;
`;

const ContentStyled = styled.div`
  display: grid;
  margin-top: ${theme.spacing[2]};
  grid-template-columns: 1fr;
  @media (min-width: ${theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const AlternateSectionStyled = styled(AlternateSection)`
  position: relative;
  @media (max-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[1]} 0;
  }
`;

const HeadingStyled = styled(Heading)`
  margin-top: ${theme.spacing[2]};
  line-height: ${theme.font.size.lg};
  text-align: center;
  letter-spacing: normal;

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.xl};
    line-height: ${theme.font.size.xxl};
    text-align: left;
    letter-spacing: 1px;
  }
`;

const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing[0.5]} ${theme.spacing[1]} ${theme.spacing[1]}
    // eslint-disable-next-line
    ${theme.spacing[1]};

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing[0.25]};
`;
const TextWrapper = styled.div`
  white-space: pre-line;

  svg {
    width: 30px;
    height: 30px;
    fill: ${theme.colors.base.secondary};
  }
`;

const BottomSectionStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${theme.spacing[2]};

  @media (max-width: ${theme.breakpoints.sm}) {
    margin: ${theme.spacing[2]} ${theme.spacing[1]};
  }
`;

const CardWrapper = styled.div`
  margin-right: 0;

  @media (min-width: ${theme.breakpoints.md}) {
    /* margin-right: ${theme.spacing[2]}; */
  }
`;

const ConfirmWrapper = styled.div`
  margin-top: ${theme.spacing[2]};
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing[0.5]};
  background: ${theme.colors.accent.secondary};

  @media (min-width: ${theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${theme.spacing[5]};
    padding: ${theme.spacing[2]};
  }

  @media (min-width: ${theme.breakpoints.md}) {
    grid-template-columns: 3fr 5fr;
    grid-gap: ${theme.spacing[5]};
  }
`;
