import { isMonday, isThursday, subDays } from "date-fns";
import { isToday, isWednesday } from "date-fns/esm";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Button } from "../../../components/Button";
import { Snackbar } from "../../../components/Snackbar";
import config from "../../../config";
import { useAppState } from "../../../contexts";
import { useNextDeliveries } from "../../../hooks/NextDeliveries";
import { useProcessingOrders, useUpdateStatus } from "../../../hooks/Orders";
import {
  useReactivateSubscription,
  useSubscription,
} from "../../../hooks/Subscriptions";
import { formatDate } from "../../../utils/i18n";
import { NextDeliveryCard } from "./NextDeliveryCard";

export const OrderStatus: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const { data: subscription, refetch: refetchSubscription } =
    useSubscription();
  const { data: order, refetch: refetchOrder } = useProcessingOrders();
  const { data: nextDelivery, refetch: refetchNextDelivery } =
    useNextDeliveries();
  const {
    mutate: mutateReactivate,
    isSuccess: isSuccessReactivate,
    error: errorReactivate,
  } = useMutation(useReactivateSubscription);
  const {
    mutate: mutateStatus,
    isLoading: isLoadingStatus,
    isSuccess: isSuccessStatus,
    error: errorStatus,
  } = useMutation(useUpdateStatus);

  useEffect(() => {
    refetchSubscription();
    refetchOrder();
    refetchNextDelivery();
  }, [isSuccessReactivate, isSuccessStatus]);

  const onSkipHandler = () => {
    if (order && nextDelivery) {
      mutateStatus({ oid: order?.oid, status: "skipped" });
    }
  };

  const onUnskipHandler = () => {
    if (order && nextDelivery) {
      mutateStatus({ oid: order?.oid, status: "open" });
    }
  };

  let title = "";
  let comment = "";
  let date;
  let highlight;
  let children;
  let validCondition = false;
  const deliveryDate = order?.delivery_date || nextDelivery?.next_delivery_date;
  const processingDate =
    order?.processing_date || nextDelivery?.next_processing_date;
  if (subscription && subscription.status !== "active") {
    title = t("Dashboard.subscription.paused.welcome");
    comment = t("Subscription.cancelled");
    children = (
      <Button onClick={mutateReactivate}>
        {t("Subscription.active_subcription")}
      </Button>
    );
    validCondition = true;
  } else if (order?.status === "cancel") {
    title = t("Dashboard.order.cancel");
    comment = t("Dashboard.order.cancel.content");
    children = <Button to="/products">{t("Dashboard.see.products")}</Button>;
    validCondition = true;
  } else if (
    order?.status === "paid" &&
    processingDate &&
    deliveryDate &&
    isToday(new Date(processingDate))
  ) {
    title = t("Dashboard.next.delivery");
    date = formatDate(locale, new Date(deliveryDate));
    comment = t("Dashboard.order.billed.today");
    children = <Button to="/products">{t("Dashboard.see.products")}</Button>;
    validCondition = true;
  } else if (
    order?.status === "paid" &&
    deliveryDate &&
    isToday(new Date(deliveryDate))
  ) {
    title = t("Dashboard.next.delivery");
    date = formatDate(locale, new Date(deliveryDate));
    comment = t("Dashboard.order.delivery.today");
    children = <Button to="/products">{t("Dashboard.see.products")}</Button>;
    validCondition = true;
  } else if ((!order || order.status === "skipped") && nextDelivery) {
    const nextDeliveryDate = new Date(nextDelivery.next_delivery_date);
    const skipDate = subDays(nextDeliveryDate, config.OPENING_TIME_DAYS);

    title = t("Dashboard.next.delivery");
    date = formatDate(locale, nextDeliveryDate);

    if (order?.status === "skipped") {
      children = (
        <>
          <Button to="/products">{t("Dashboard.see.products")}</Button>
          <Button
            isLoading={isLoadingStatus}
            onClick={onUnskipHandler}
            secondary
          >
            {t("Deliveries.unskip")}
          </Button>
        </>
      );
    } else {
      children = <Button to="/products">{t("Dashboard.see.products")}</Button>;
    }

    validCondition = true;

    if (isMonday(new Date()) && isWednesday(nextDeliveryDate)) {
      comment = t("Dashboard.come.monday", {
        date: formatDate(
          locale,
          order?.status === "skipped" ? skipDate : new Date()
        ),
      });
    } else if (isThursday(nextDeliveryDate)) {
      comment = t("Dashboard.come.monday", {
        date: formatDate(locale, new Date(skipDate)),
      });
    } else if (isMonday(nextDeliveryDate)) {
      comment = t("Dashboard.come.friday", {
        date: formatDate(locale, new Date(skipDate)),
      });
    } else {
      comment = t("Dashboard.come.other", {
        date: formatDate(locale, new Date(skipDate)),
      });
    }
  } else if (order && deliveryDate && processingDate) {
    title = t("Dashboard.next.delivery");
    date = formatDate(locale, new Date(deliveryDate));
    comment = t("Dashboard.order.open.date", {
      date: formatDate(locale, new Date(subDays(new Date(processingDate), 1))),
    });
    highlight = t("Dashboard.order.billing.date", {
      date: formatDate(locale, new Date(processingDate)),
    });
    children = (
      <>
        <Button to="/products">{t("Dashboard.see.products")}</Button>
        <Button
          isLoading={isLoadingStatus}
          onClick={onSkipHandler}
          thirdReverse
        >
          {t("Deliveries.skip")}
        </Button>
      </>
    );
    validCondition = true;
  }

  return (
    <>
      {validCondition && (
        <NextDeliveryCard
          title={title}
          date={date}
          comment={comment}
          highlight={highlight}
        >
          {children}
        </NextDeliveryCard>
      )}
      {isSuccessStatus && (
        <Snackbar type={"success"} text={t("Deliveries.skip.success")} />
      )}
      {errorStatus && <Snackbar type={"error"} error={errorStatus} />}
      {isSuccessReactivate && (
        <Snackbar
          type={"success"}
          text={t("Subscription.reactivate.success")}
        />
      )}
      {errorReactivate && (
        <Snackbar
          type={"error"}
          text={t("Subscription.reactivate.error")}
          error={errorReactivate}
        />
      )}
    </>
  );
};
