import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { useUpdateCouponOrder } from "../../hooks/Coupons";
import { Button } from "../Button";
import { Form } from "../Form";
import { FormControl } from "../FormControl";
import { FormInput } from "../FormInput";
import { FormLabel } from "../FormLabel";
import { FormSection } from "../FormSection";
import { Snackbar } from "../Snackbar";
import { theme } from "../theme";

interface Props {
  name?: string;
  bgColor?: string;
  onChangeCoupon: (couponName: string) => void;
}

export const Coupon: React.FC<Props> = ({
  name = "",
  onChangeCoupon,
  bgColor,
}) => {
  const methods = useForm();
  const { errors } = methods;
  const [inputs, setInputs] = useState({ code: name });
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    mutate: mutateUpdate,
    isSuccess: isSuccessUpdate,
    error: errorUpdate,
  } = useMutation(useUpdateCouponOrder);

  const onSubmitFormUpdate = () => {
    if (inputs.code) mutateUpdate(inputs);
  };

  useEffect(() => {
    setInputs({ ...inputs, code: name || "" });
  }, [name]);

  useEffect(() => {
    if (errorUpdate && !isSuccessUpdate) {
      setInputs({ ...inputs, code: "" });
    }
  }, [errorUpdate]);

  useEffect(() => {
    onChangeCoupon(inputs.code || "");
  }, [inputs]);

  useEffect(() => {
    if (isSuccessUpdate) {
      queryClient.refetchQueries(["useCouponUser"], { active: true });
      setInputs({ ...inputs, code: "" });
    }
  }, [isSuccessUpdate]);
  return (
    <>
      {isSuccessUpdate && (
        <Snackbar type="success" text={t(`Coupons.success`)} />
      )}
      {errorUpdate && <Snackbar type="error" error={errorUpdate} />}
      <Form useForm={methods} onSubmit={onSubmitFormUpdate}>
        <FormSectionControl bgColor={bgColor}>
          <LayoutStyled>
            <FormControlStyled>
              <FormLabelStyled target="name">
                {t(`Coupons.enter`)}
              </FormLabelStyled>
              <FormInputStyle
                id="coupon"
                type="text"
                name="coupon"
                placeholder={t(`Coupons.code`)}
                value={inputs?.code || ""}
                error={errors.name}
                onChange={(value) => {
                  setInputs({ ...inputs, code: value });
                }}
                register={methods.register}
                validation={{
                  required: false,
                }}
              />
            </FormControlStyled>
            <ButtonStyled third size="md" type="submit">
              {t(`Coupons.addme`)}
            </ButtonStyled>
          </LayoutStyled>
        </FormSectionControl>
      </Form>
    </>
  );
};

const ButtonStyled = styled(Button)`
  width: 100%;
  height: 46px;
  align-self: end;
`;
const FormLabelStyled = styled(FormLabel)`
  /* margin-bottom: -1rem; */
`;

const FormInputStyle = styled(FormInput)`
  margin: 0;
  width: 100%;
`;

const FormSectionControl = styled(FormSection)`
  padding: 0;
  margin: 0;
`;

const FormControlStyled = styled(FormControl)`
  margin: 0;
  padding: 0;
`;
const LayoutStyled = styled.div`
  @media (min-width: ${theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: ${theme.spacing[0.5]};
  }
`;
