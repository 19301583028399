import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { MissionSection } from "../../components/MissionSection";
import { Hero } from "../../components/Hero";
import { MissionAlternative } from "../../components/MissionAlternative";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { theme } from "../../components/theme";
import { Heading } from "../../components/Heading";
import { Text } from "../../components/Text";
import nl2br from "react-nl2br";
import { Button } from "../../components/Button";
import { FlashBanner } from "../../components/FlashBanner/FlashBanner";

export const Mission: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page centerX centerY backgroundColor={theme.colors.base.background}>
      {/* <FlashBanner times={10} type="warning">
        {t("Announcement")}
      </FlashBanner> */}
      <Section size="lg">
        <Hero
          image="/common/images/mission-hero"
          imageAlt={t("About.Hero.imageAlt")}
          title={t("Mission.hero.title")}
          subtitle={t("Mission.hero.subtitle")}
        />
      </Section>
      <MissionSection />
      <SectionStyled size="lg" backgroundColor={theme.colors.base.third}>
        <Heading center element="h1" size="xl">
          {t("Mission.section2.title")}
        </Heading>
      </SectionStyled>

      <MissionAlternative />
      <SectionStyled size="md" backgroundColor={theme.colors.base.background}>
        <HeadingStyled center element="h1" size="xl">
          {nl2br(t("Mission.section4.title"))}
        </HeadingStyled>
        <TextWrapper>
          <TextStyled>{nl2br(t("Mission.section4.text1"))}</TextStyled>
          <br />
          <TextStyled>{nl2br(t("Mission.section4.text2"))}</TextStyled>
          <br />

          <Button size="lg" to="/sign-up/step-1">
            {t(`About.Testimonial.cta2`)}
          </Button>
        </TextWrapper>
      </SectionStyled>
    </Page>
  );
};

const SectionStyled = styled(Section)``;

const HeadingStyled = styled(Heading)`
  color: ${theme.colors.base.primary};
`;
const TextStyled = styled(Text)`
  color: ${theme.colors.base.secondary};
`;

const TextWrapper = styled.div`
  padding-bottom: ${theme.spacing[2]};
  text-align: center;

  color: ${theme.colors.base.secondary};
`;
