export const terms = {
  fr: [
    {
      big_title: "Etre membre du Marché SecondLife ",
      contents: [
        "Lorsque vous créez un compte sur second-life.ca, vous êtes invitée à choisir des Plans. Ces plans nous permettent de vous construire un panier de base qui correspond à vos envies.\nTrois jours avant votre facturation, un panier de base vous sera proposé en fonction des plans choisis. Vous pourrez alors modifier le contenu (ajouter ou supprimer des produits) ou annuler la livraison, depuis votre compte client. Vous aurez jusqu'à la facturation pour faire ces changements.\nAvoir un compte au Marché SecondLife implique que toutes les semaines ou toutes les deux semaines, nous allons vous préparer un panier de base. Si vous ne modifiez pas ce panier, le contenu par défaut vous sera facturé et livré. Si vous décidez d'annuler votre livraison à venir, aucun panier de base ne sera construit pour vous.\nVous pouvez gérer vos plans si vous désirez les modifier au courant des saisons. Par exemple, si vous préférez recevoir des produits biologiques du Québec et des fruits, vous pourrez avoir ces deux plans à votre compte.\n Vous pouvez annuler une livraison à venir ainsi que déterminer une période de vacances, pendant laquelle aucun panier de base ne sera construit pour vous.\nSi vous décidez de quitter le Marché SecondLife, et ne plus recevoir de commandes de notre part, il faudra nous écrire un courriel pour demander une annulation. Nous répondons sous 24h avec une confirmation.\nIl n'y a pas d'engagement sur la durée lorsque vous devenez cliente chez nous",
      ],
    },
    {
      title: "Plan",
      contents: [
        "Les préférences et options de votre abonnement (les 'Plans') nous permettent de vous construire un panier de base qui ressemble\n le plus à vos envies",
        "Vos plans sélectionnés sont modifiables en tout temps depuis votre compte client",
        "Chaque plan correspond à un ensemble de produits qui est automatiquement mis dans votre panier de base",
        "Les fourchettes de prix des plans vous permettent d'avoir une idée de combien votre panier de base vous coûtera",
      ],
    },
    {
      title: "Panier de base",
      contents: [
        "Le panier de base est construit 4 jours avant la livraison. Un courriel de rappel vous est envoyé pour vous laisser savoir qu'un panier de base a été construit pour vous",
        "Si vous ne modifiez pas ce panier, vous recevrez le contenu par défaut du panier de base",
        "Vous pouvez modifier le panier de base jusqu'à votre facturation. Le montant du panier (avant rabais, taxes et frais) doit être égal ou supérieur à 20$",
        "La facturation se fera automatiquement, la veille de votre facturation, sans qu'une confirmation de votre part soit nécessaire",
        "Le client est par défaut débité chaque semaine ou aux deux semaines la veille de la livraison au matin",
        "Une fois la commande facturée, aucun ajout, annulation ou modification de commande peut être fait",
        "Si le client veut annuler une livraison, modifier son panier, il doit le faire depuis son compte client directement",
        "Deux courriels sont envoyés au client lorsqu'il a une commande prévue :",
        "1) Trois jours avant sa facturation, pour lui rappeler que son panier de base a été construit ",
        "2) Le jour de la facturation pour confirmer la commande",
      ],
    },
    {
      title: "Facturation",
      contents: [
        "La facturation se fait 1 jour avant la livraison prévue du panier",
        "Si la facturation n’a pas fonctionnée parce que la carte est invalide ou n’a pas les fonds suffisants, il est de la responsabilité du client d’en faire la modification depuis son compte client. Un courriel sera envoyé au client dans cette situation.",
        "Lors de l’échec de paiement, aucune commande ne sera envoyée avant la prochaine date de livraison.",
      ],
    },
    {
      title: "Fréquence de livraison",
      contents: [
        "La livraison est faite toutes les semaines ou toutes les deux semaines. ",
        "Vous pouvez choisir la fréquence au moment de choisir un point de chute ou une livraison à domicile. Elle peut être modifiée en tout temps depuis votre compte client.",
      ],
    },
    {
      title: "Vacances",
      contents: [
        "En cas de vacances, le client peut annuler ses livraisons pour une durée maximale de 6 semaines.",
        "Si vous désirez mettre en pause votre abonnement au-delà de 6 semaines, contactez-nous par courriel et nous ferons l'ajustement à votre compte.",
      ],
    },
    {
      title: "Annulation",
      contents: [
        "Si le client souhaite annuler définitivement son abonnement, il doit nous contacter par courriel avec la raison pour laquelle il veut annuler, cela nous aide beaucoup à nous améliorer.",
        "Nous suggérons aux clients qui désirent annuler leur abonnement d'annuler premièrement la ou leurs prochaines livraisons en attendant de nous contacter. Nous répondons sous 24h avec une confirmation.",
      ],
    },
    {
      title: "Prix",
      contents: [
        "Les fourchettes de prix des plans sont approximatives. Il se peut que le montant du panier de base soit un peu inférieur ou un peu supérieur à la fourchette de prix présenté sur le site.",
        "Les prix des produits sur notre site varient toutes les semaines et peuvent varier jusqu`à la facturation malgré que les produits soient dans votre panier. Nous évitons les changements de prix et il est rare que cela arrive. Les changements peuvent être à la hausse comme à la baisse.",
      ],
    },
    {
      big_title: "Livraison en point de chute",
      title: "Le point de chute",
      contents: [
        "Le point de chute étant un lieu public, nous demandons aux clients de respecter les lieux et le personnel y travaillant. Si un client n'agit pas avec respect vis-à-vis des personnes travaillant dans le point de chute ou d'autres clients, nous nous réservons le droit de lui interdire le service.",
        "Il est important de respecter les horaires de cueillette. Celles-ci ont été définies avec le point de chute pour l'accommoder.",
        "Il est important de garder la place propre et rangée et d’empiler votre bac vide sur la pile de bacs vides.",
        "Une fois le panier récupéré, il est important de signer à côté de votre nom sur la liste-clients disponible au point de chute.",
      ],
    },
    {
      title: "Frais de livraison",
      contents: [
        "Des frais de livraison sont facturés pour chaque livraison. Ces frais peuvent être modifiés de temps à autre à la discrétion du Marché SecondLife. Actuellement ils sont de 1.99 + taxes.",
        "Si des changements ont lieu, ceux-ci sont toujours communiqués par courriel mais il se peut que le client ne reçoive pas le courriel s'il est dans les indésirables, si le client s'est trompé dans l'orthographe de son courriel ou si le client s'est désabonné de toute communication courriel de notre part ou pour d'autres raisons.",
        "L'information concernant les frais de livraison est mise à jour sur le site et dans la FAQ. Il est de la responsabilité du client d'en prendre connaissance. ",
      ],
    },
    {
      title: "Les bacs de livraison en point de chute",
      contents: [
        "Les paniers sont livrés en points de chute dans des bacs de plastique identifiés avec le nom du client ou des boîtes de carton.",
        "Lorsque le client reçoit sa commande dans un bac de plastique, celui-ci doit laisser le bac vide sur place. Si un bac apparaît manquant, celui-ci sera facturé au client au frais de 15$, sur sa carte de crédit",
        "Lorsque le client reçoit sa commande dans une boîte de carton, celui-ci peut partir avec.",
        "Les points de chute ne fournissent pas de sac de transport, nous recommandons à nos clients de venir avec leurs propres sacs d’épicerie.",
        "Selon la taille des commandes il peut arriver que la commande soit divisée en deux bacs, il est de la responsabilité du client de vérifier si deux bacs sont livrés en son nom. Ils sont identifiés sur la liste-clients en point de chute et sur les étiquettes des bacs : 1/2 et 2/2.",
      ],
    },
    {
      title: "Empêchement de dernière minute en point de chute",
      contents: [
        "Si vous ne pouvez pas aller récupérer votre panier dans les heures de cueillette, il faudra communiquer avec le point de chute directement.",
        "Prenez note que les paniers ne sont pas toujours gardés le lendemain de la cueillette, cela varie d'un point de chute à l'autre.",
        "Prenez note que la qualité des aliments peut avoir été dégradée si vous n'êtes pas passé dans les heures prévues à cet effet. Malheureusement nous ne pouvons pas être responsables de la mauvaise qualité des aliments laissés en point de chute hors des horaires.",
        "Aucun remboursement ou crédit ne sera émis si vous n’avez pas récupéré vos paniers dans les heures limites de récupération.",
      ],
    },
    {
      title: "Horaires de livraison en point de chute",
      contents: [
        "Les heures de livraison communiquées aux clients sont estimées. Elles peuvent être modifiées pour des raisons indépendantes de notre volonté (intempéries, traffic, accident...). Nous faisons bien sûr notre possible pour que vos livraisons soient toujours à l'heure.  ",
        "Dans le cas d'une livraison à domicile, un message est envoyé au client à l'approche du livreur. L'horaire inscrit dans ce message peut lui aussi être modifié pour des raisons indépendantes de notre volonté (intempéries, traffic, accident...).",
        "",
        "",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "Livraison à domicile",
      contents: [
        "Les frais de livraison à domicile sont de 5.99$ + taxes. La livraison se fait à l'adresse décrite dans votre compte client. Veillez que celle-ci soit la bonne. ",
        "La livraison de votre commande à votre domicile se fait des bacs en plastiques réutilisables (que nous récupérons au moment de la livraison) ou dans des sacs en papier recyclable. ",
        "La commande est déposée devant chez vous. Vous pouvez donner des indications au livreur au besoin. Le livreur vous appelle ou sonne à la porte pour vous prévenir qu'il est là. Vous recevez un courriel 20 minutes avant l'arrivée de votre panier et un courriel de confirmation lorsque votre commande a été déposée.",
        "En cas d'absence, la commande sera laissée devant votre porte. Si vous ne pouvez pas être présent(e), vous pouvez contacter notre équipe service clientèle à info@second-life.ca ou nous appeler au (514) 246-9020 pour donner des indications spécifiques au livreur (Par exemple: déposer mon panier chez le voisin, déposer mon panier sur le balcon arrière etc.). ",
        "Si la température extérieure est inférieure à 5 degrés ou supérieure à 25 degrés, nous suggérons très fortement de laisser une glacière devant votre porte où nous pourrons y déposer votre commande !",
      ],
    },
    {
      big_title: "Les commandes",
      title: "Qualité des aliments",
      contents: [
        "Nous faisons notre possible pour que vos aliments soient dans le meilleur état possible lorsque vous les récupérez.",
        "Si ce n'est pas le cas prenez une photo du produit concerné et envoyez-la nous par courriel, un crédit sera appliqué à votre prochaine commande.",
        "Prenez note que nous ne pouvons pas assurer la qualité des produits qui nécessitent d’être réfrigérés après la plage horaire de ramassage des paniers.",
        "Vous devez inspecter tous les produits de votre panier afin de prendre connaissance s'il y a des dommages ou d'autres problèmes qui viendraient diminuer l'intégrité du produit. ",
        "Si vous avez l'impression que la chaîne de froid des produits qui doivent être réfrigérés ou congelés n'a pas été maintenue, veuillez nous contacter et ne pas consommer le produit.",
        "Une fois un panier livré ou une fois les heures de cueillette passées, il est de votre responsabilité de conserver les produits correctement au frais ou au congélateur. Vous pouvez utiliser cette ressource gouvernementale pour vous aider ici. ",
      ],
    },
    {
      title: "Allergies",
      contents: [
        "Nos paniers sont tous faits dans des entrepôts où sont entreposés ou emballés des produits contenant des allergènes tels que et sans s'y limiter : noix, arachides et soya. Les personnes souffrant de sensibilité en présence d'allergènes quels qu'ils soient, doivent savoir que nous ne pouvons garantir l'absence totale d'allergènes, quels qu'ils soient.",
      ],
    },
    {
      title: "Disponibilité",
      contents: [
        "Bien que nous fassions de notre mieux pour assurer un approvisionnement suffisant afin de préparer vos commandes sans qu'il manque de produits, il peut arriver, pour des raisons indépendantes de notre volonté (transport, conditions agricoles), que certains produits disponibles sur notre site et placés dans votre panier en ligne, soient manquant le jour de votre livraison. Si cela se produit, nous remplacerons le produit par un produit équivalent ou émettrons un avoir qui sera utilisé sur votre prochaine facturation. Si un remplacement doit être effectué, il sera effectué selon nos disponibilités. produits pour un produit équivalent en valeur.",
      ],
    },
    {
      big_title: "Programme MSL+",
      title: "",
      contents: [
        "L'abonnement MSL+ vous donne accès jusqu'à 20% de rabais sur l'ensemble de nos produits. L'abonnement est valide pendant 1 an et sera automatiquement renouvelé chaque année. Si vous désirez annuler votre abonnement avant la fin de la période de validité, veuillez noter que celui-ci restera actif jusqu'à la date d'échéance prévue. L'abonnement MSL+ est non transférable et non remboursable. On se reserve le droit de modifier le prix avant taxes de l'abonnement annuel en tout temps.",
      ],
    },
    {
      big_title: "Garantie satisfait",
      title: "",
      contents: [
        "Nous nous engageons à ce que vous soyez entièrement satisfait de notre et de nos produits. Ainsi si c'est un produit qui ne correspond à nos critères de qualité s'est glissé dans votre boite, envoyez nous une photo par courriel. Nous ferons un suivi avec l'équipe de production afin de s'assurer que le problème soit identifié et maîtrisé et nous émettrons un remboursement ou un crédit à votre future commande.",
      ],
    },
    {
      big_title: "Utilisation des codes rabais et des crédits",
      title: "",
      contents: [
        "Le client détenant un code rabais promotionnel pourra l’utiliser au moment du paiement de sa première commande.",
        "Si un crédit vous est offert, celui-ci sera mis par nos soins sur votre compte client automatiquement.",
      ],
    },
    {
      big_title: "Modes de paiement",
      contents: [
        "Les modes de paiement disponibles sont Visa crédit, Visa débit, MasterCard crédit et MasterCard débit. Aucun paiement en espèce n'est accepté. Aucun paiement n'est fait en point de chute. ",
      ],
    },
    {
      big_title: "Moyens de communication",
      contents: [
        "Tous nos contacts avec les clients se font par courriel ou messages textes ou téléphone. Différents courriels sont envoyés au courant d’une semaine normale :",
        "1) Courriel de rappel de facturation qui contient un lien de la composition des paniers de la semaine",
        "2) Courriel de confirmation de commande le jour de la facturation",
        "Certaines communications majeures (changement dans notre offre, annonces importantes…) sont aussi faites par courriel et envoyées à l’adresse courriel rattachée au compte du client.",
        "",
      ],
    },
    {
      big_title: "Service à la clientèle",
      contents: [
        "Nous souhaitons que vous soyez pleinement satisfait de nos services. Si pour quelques raisons que ce soit vous ne l'êtes pas, contactez-nous. Si les produits que vous avez reçus vous semblent insalubres ou impropres à la consommation, envoyez-nous une photo par courriel. Si un produit vous est manquant, nous ferons un crédit applicable sur votre prochaine commande.",
      ],
    },
    {
      big_title: "Utilisation de votre numéro de téléphone",
      contents: [
        "Le numéro de téléphone qui vous est demandé lors de votre inscription est utilisé uniquement pour assurer la qualité de notre service. Dans ce sens nous vous envoyons un message texte lorsque votre panier vient d’arriver en point de chute et en cas d’urgence s’il y a eu un problème avec votre commande. En aucun cas votre numéro n’est transmis ou vendu à des entités externes à SecondLife et aucun usage commercial n’en est fait.",
      ],
    },
    {
      big_title: "Taxes",
      contents: [
        "La majorité de nos produits sont exempts de taxe. Si des taxes (TPS et TVQ) doivent être perçues, le montant prélevé sera indiqué dans la confirmation de votre commande.",
      ],
    },
    {
      big_title: "Changement des termes et conditions",
      contents: [
        "SecondLife a la possibilité en tout temps de modifier le contenu de cette politique de confidentialité. Il est de la responsabilité de l'utilisateur de se renseigner périodiquement de la politique de confidentialité en vigueur.",
      ],
    },
    {
      big_title: "Certification biologique",
      contents: [
        "Nos paniers biologiques 'Bio-locaux' et 'Biologiques' et leurs contenus sont certifiés par Ecocert Canada.",
        "Le numéro du certificat est le CA-211283-Z-144038-2019.",
        "Pour de plus amples informations, visitez www.ecocertcanada.com",
      ],
    },
    {
      big_title: "Politique de confidentialité",
      title: "Informations légales",
      contents: [
        "Le site second-life.ca est la propriété de SecondLife Inc. entreprise immatriculée dont le siège social est situé au 6325 blv des Grandes Prairies, Montreal, H1P1A5. Nous joindre: info@second-life.ca",
      ],
    },
    {
      title: "Droits d’auteur et de reproduction",
      contents: [
        "Ce site est soumis à la législation canadienne et internationale sur les droits d’auteur. Tous les droits de reproduction, sur quelque support que ce soit, sont réservés. La reproduction et l’utilisation du contenu de tout ou partie de ce site sont interdites sans l'autorisation de SecondLife Inc.",
        "Vous ne pouvez pas utiliser nos marques commerciales (y compris notre logo) ou vous représenter en tant qu'affilié au Marché SecondLife, sans autorisation préalable.",
      ],
    },
    {
      title: "Informations personnelles",
      contents: [
        "Les informations recueillies par le biais de nos formulaires sur le site public n’ont d’autre but que de renseigner et de pouvoir répondre aux interrogations des internautes. Aucune information personnelle n’est cédée à des tiers. Si vous souhaitez obtenir communication ou suppression des informations vous concernant (vous pouvez, pour des motifs légitimes, vous opposer au traitement des données vous concernant), veuillez nous contacter.",
        "Vos renseignements personnels peuvent être utilisés ou stockés à l'extérieur du Canada par nous ou l'un de nos fournisseurs de service. Si tel était le cas, ces renseignements personnels seraient soumis aux lois du pays dans lequel elles sont utilisées ou stockées.",
      ],
    },
    {
      title: "Google Analytics et Facebook Pixel et témoins cookie",
      contents: [
        "Ce site utilise Google Analytics, un service d’analyse de site internet fourni par Alphabet Inc. («Google»). Google Analytics utilise des cookies, qui sont des fichiers texte placés sur votre ordinateur, pour aider le site internet à analyser l’utilisation du site par ses utilisateurs. Les données générées par les cookies concernant votre utilisation du site (y compris votre adresse IP) seront transmises et stockées par Google sur des serveurs situés aux Etats-Unis. Google utilisera cette information dans le but d’évaluer votre utilisation du site, de compiler des rapports sur l’activité du site à destination de son éditeur et de fournir d’autres services relatifs à l’activité du site et à l’utilisation d’Internet. Google est susceptible de communiquer ces données à des tiers en cas d’obligation légale ou lorsque ces tiers traitent ces données pour le compte de Google, y compris notamment l’éditeur de ce site. Google ne recoupera pas votre adresse IP avec toute autre donnée détenue par Google. Vous pouvez désactiver l’utilisation de cookies en sélectionnant les paramètres appropriés de votre navigateur. Cependant, une telle désactivation pourrait empêcher l’utilisation de certaines fonctionnalités de ce site. En utilisant ce site internet, vous consentez expressément au traitement de vos données nominatives par Google dans les conditions et pour les finalités décrites ci-dessus.",
      ],
    },
    {
      title: "Communications",
      contents: [
        "Toute personne créant un compte sur le commerce en ligne de SecondLife se verra automatiquement ajoutée à nos listes d'envoi. Ces courriels sont envoyés à des fins d'assistance, d'amélioration de nos services et de promotion. Toutefois, si vous ne désirez plus recevoir de courriels de SecondLife, veuillez nous le signaler en nous contactant par courriel au info@second-life.ca. Nous vous retirerons alors de nos listes d'envois. Toute personne qui ne souhaite pas être contactée au numéro de téléphone rattaché au compte doit nous le mentionner dans un courriel ou par téléphone. Ces appels et envois de messages textes sont envoyés à des fins d'assistance, d'amélioration de nos services et de promotion.",
      ],
    },
    {
      title: "Changements",
      contents: [
        "SecondLife a la possibilité en tout temps de modifier le contenu de cette politique de confidentialité. Il est de la responsabilité de l'utilisateur de se renseigner périodiquement de la politique de confidentialité en vigueur.",
      ],
    },
    {
      title: "Questions",
      contents: [
        "Pour toutes questions par rapport à l'énoncé de notre politique de confidentialité veuillez entrer en contact avec notre équipe au info@second-life.ca ",
      ],
    },
  ],
  en: [
    {
      big_title: "Being a member of the Marché SecondLife ",
      contents: [
        "When you create an account on second-life.ca, you are invited to choose 'Plans'. These plans allow us to build you a base basket that matches your desires. Three days before your billing, a base basket will be proposed to you according to the chosen plans. You will then be able to modify the content (add or delete products) or cancel the delivery, from your customer account. You will have until billing to make these changes. Having an account at the Marché SecondLife means that every week or two we will prepare a basic shopping cart for you. If you do not change this basket, you will be charged for the default content and delivered. If you decide to cancel your upcoming delivery, no basic cart will be built for you. You can manage your plans if you wish to change them seasonally. For example, if you prefer to receive organic products from Quebec and fruits, you can have both plans on your account. You can cancel an upcoming delivery as well as set a vacation period, during which no basic basket will be built for you. If you decide to leave the Marché SecondLife, and no longer receive orders from us, you will need to write us an email to request a cancellation. We will reply within 24 hours with a confirmation. There is no long-term commitment when you become a client.",
      ],
    },
    {
      big_title: "",
      title: "Plan",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: [
        "Your subscription preferences and options (the 'Plans') allow us to build a base basket that best suits your desires ",
        "Your selected plans can be modified at any time from your customer account",
        "Each plan corresponds to a set of products that is automatically put in your basic basket",
        "The price ranges in the plans give you an idea of how much your base basket will cost",
      ],
    },
    {
      big_title: "",
      title: "Basic basket",
      contents: [
        "The base basket is built 4 days before delivery. A reminder email is sent to let you know that a base basket has been built for you",
        "If you do not modify this basket, you will receive the default content of the base basket ",
        "You can modify the base basket until you are billed. The amount of the basket (before discounts, taxes and fees) must be equal or superior to 20$.",
        "The billing will be done automatically, the day before your billing, without any confirmation from you.",
        "The customer is by default debited weekly or bi-weekly the day before the delivery in the morning.",
        "Once the order has been invoiced, no additions, cancellations or changes to the order can be made.",
        "If the customer wants to cancel a delivery, modify his basket, he must do it directly from his customer account.",
        "Two emails are sent to the customer when they have a scheduled order: ",
        "1) Three days before the billing, to remind him/her that his/her basic basket was built ",
        "2) The day of the billing to confirm the order",
        "",
        "",
      ],
    },
    {
      big_title: "",
      title: "Billing",
      contents: [
        "The billing is done 1 day before the expected delivery of the basket",
        "If the billing did not work because the card is invalid or does not have sufficient funds, it is the customer's responsibility to make the change from their customer account. An email will be sent to the customer in this situation.",
        "Upon failure of payment, no order will be sent until the next delivery date.",
        "",
      ],
    },
    {
      big_title: "",
      title: "Frequency of delivery",
      contents: [
        "Delivery is made every week or two.",
        "You can choose the frequency when you select a drop-off location or home delivery. It can be changed at any time from your customer account.",
        "",
        "",
      ],
    },
    {
      big_title: "",
      title: "Vacations",
      contents: [
        "In case of vacations, the customer can cancel his deliveries for a maximum period of 6 weeks.",
        "If you wish to pause your subscription beyond 6 weeks, contact us by email and we will make the adjustment to your account. ",
        "",
        "",
      ],
    },
    {
      big_title: "",
      title: "Cancellation",
      contents: [
        "If the customer wants to cancel their subscription permanently, they should contact us by email with the reason why they want to cancel, this helps us a lot to improve.",
        "We suggest that customers who wish to cancel their subscription first cancel their next delivery(s) while waiting to contact us. We will respond within 24 hours with a confirmation. ",
        "",
        "",
      ],
    },
    {
      big_title: "",
      title: "Price",
      contents: [
        "The price ranges in the plans are approximate. The base basket amount may be slightly lower or higher than the price range presented on the site.",
        "The prices of the products on our site vary every week and may vary up to the point of billing even though the products are in your shopping cart. We avoid price changes and it is rare that this happens. Changes can be up or down. ",
        "",
        "",
      ],
    },
    {
      big_title: "Drop-off delivery",
      title: "The drop point",
      contents: [
        "As the drop-off point is a public place, we ask our clients to respect the premises and the staff working there. If a client does not act with respect to the people working in the drop-off point or to other clients, we reserve the right to forbid him/her to use the service.",
        "It is important to respect the pick-up schedule. These have been defined with the drop-off point to accommodate it.",
        "It is important to keep the place neat and tidy and to stack your empty bin on top of the stack of empty bins.",
        "Once the basket is collected, it is important to sign next to your name on the customer list available at the drop-off point. ",
      ],
    },
    {
      big_title: "",
      title: "Delivery fees",
      contents: [
        "A delivery fee is charged for each delivery. These fees may change from time to time at the discretion of SecondLife Marketplace. Currently they are at 1,99$ + taxes.",
        "If changes occur, they will always be communicated by email, but the customer may not receive the email if it is in the junk folder, if the customer misspelled their email, or if the customer unsubscribed from any email communication from us, or for other reasons.",
        "Information concerning delivery charges is updated on the site and in the FAQ. It is the customer's responsibility to be aware of this information.",
      ],
    },
    {
      big_title: "",
      title: "Delivery bins at drop-off points",
      contents: [
        "Baskets are delivered to drop-off points in plastic bins identified with the customer's name or in cardboard boxes.",
        "When the customer receives his order in a plastic bin, he must leave the empty bin on site. If a bin appears to be missing, it will be charged to the customer's credit card at a cost of $15.",
        "The drop-off locations do not provide a carry bag, we recommend that our clients bring their own grocery bags.",
        "Depending on the size of the order it may happen that the order is divided into two bins, it is the responsibility of the customer to check if two bins are delivered on his behalf. They are identified on the customer list at the drop-off point and on the bin labels: 1/2 and 2/2. ",
      ],
    },
    {
      big_title: "",
      title: "Last minute inconvenience at the drop-off point",
      contents: [
        "If you are unable to pick up your basket within the pick-up hours, you will need to contact the drop-off location directly.",
        "Please note that the baskets are not always kept the day after picking, this varies from one drop-off point to another.",
        "Please note that the quality of the food may have been degraded if you did not stop by during the designated hours. Unfortunately we cannot be responsible for the poor quality of food left at the drop off point outside of the scheduled times.",
        "No refunds or credits will be issued if you do not pick up your baskets within the pick-up time limit. ",
      ],
    },
    {
      big_title: "",
      title: "Delivery schedules at drop-off points",
      contents: [
        "The delivery times communicated to customers are estimated. They can be modified for reasons beyond our control (bad weather, traffic, accident...). We do our best to ensure that your deliveries are always on time.",
        "In the case of a home delivery, a message is sent to the customer when the delivery man approaches. The schedule in this message can also be modified for reasons beyond our control (bad weather, traffic, accident ...). ",
        "",
        "",
      ],
    },
    {
      big_title: "Home delivery",
      title: "",
      contents: [
        "Home delivery is $5.99 + tax. The delivery is made to the address you entered in your customer account. Please make sure that it is the right one. ",
        "The delivery of your order to your home is done in reusable plastic bins (which we collect at the time of delivery) or in recyclable paper bags. ",
        "The order is dropped off in front of your house. You can give directions to the driver if needed. The delivery person will call you or ring the doorbell to let you know he is there. You will receive an email 20 minutes before your cart arrives and a confirmation email when your order has been dropped off.",
        "In case of absence, the order will be left at your door. If you cannot be there, you can contact our customer service team at info@second-life.ca or call us at (514) 246-9020 to give specific instructions to the delivery person (For example: drop my basket at the neighbor's house, drop my basket on the back balcony etc.).",
        "If the temperature outside is below 5 degrees or above 25 degrees, we strongly suggest leaving a cooler outside your door where we can drop off your order! ",
        "",
      ],
    },
    {
      big_title: "The orders",
      title: "Food quality",
      contents: [
        "We do our best to make sure your food is in the best possible condition when you pick it up.",
        "If it is not the case, take a picture of the product concerned and send it to us by email, a credit will be applied to your next order.",
        "Please note that we cannot guarantee the quality of products that need to be refrigerated after the basket pick-up time.",
        "You must inspect all products in your cart to see if there are any damages or other problems that would diminish the integrity of the product.",
        "If you feel that the cold chain for products that need to be refrigerated or frozen has not been maintained, please contact us and do not consume the product.",
        "Once a basket has been delivered or pickup hours have passed, it is your responsibility to store the produce properly in the cooler or freezer. You can use this government resource to help you here. ",
      ],
    },
    {
      big_title: "",
      title: "Weight",
      contents: [
        "The weight range we communicate on our product pages is an average range. It is possible that the contents of your cart may weigh less or more than this range. Large products would increase the weight of the cart while small products would decrease it. ",
        "The weights indicated on our market are to be considered with a margin of error. The nature of the product sometimes makes it difficult to obtain an actual weight equal to the advertised weight. Obviously, we do our best to ensure that the actual weights are those indicated and that our customers benefit from this process. ",
        "",
        "",
      ],
    },
    {
      big_title: "",
      title: "Allergies",
      contents: [
        "Our baskets are all made in warehouses where products containing allergens such as, but not limited to: nuts, peanuts and soy are stored or packaged. Those with sensitivities to any allergens should be aware that we cannot guarantee the complete absence of allergens of any kind. ",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "",
      title: "Availability ",
      contents: [
        "Although we do our best to ensure a sufficient supply to prepare your orders without missing products, it may happen, for reasons beyond our control (transportation, agricultural conditions), that some products available on our site and placed in your online cart, are missing on the day of your delivery. If this happens, we will replace the product for an equivalent product or issue a credit that will be used on your next billing. If a replacement has to be made, it will be done according to our available products for an equivalent product in value.",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "MSL+ Membership",
      title: "",
      contents: [
        "The MSL+ subscription gives you access to up to 20% discount on all our products. The subscription is valid for 1 year and will be automatically renewed every year. If you wish to cancel your subscription before the end of the validity period, please note that it will remain active until the scheduled expiration date. The MSL+ subscription is non-transferable and non-refundable",
        "We reserve the right to change the pre-tax price of the annual subscription at any time.",
        "",
        "",
      ],
    },
    {
      big_title: "Satisfaction guaranteed",
      title: "",
      contents: [
        "We are committed to your complete satisfaction with our products. So if a product that doesn't meet our quality standards has found its way into your box, send us a picture by email. We will follow up with the production team to ensure that the problem is identified and contained and we will issue a refund or credit to your future order.",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "Use of discount codes and credits",
      title: "",
      contents: [
        "Customers with a promotional discount code will be able to use it at the time of payment for their first order.",
        "If a credit is offered to you, it will be put by us on your customer account automatically. ",
        "",
        "",
      ],
    },
    {
      big_title: "Methods of payment",
      title: "",
      contents: [
        "Payment methods available are Visa credit, Visa debit, MasterCard credit and MasterCard debit. No cash payments are accepted. No payments are made at the drop-off point. ",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "Means of communication",
      title: "",
      contents: [
        "All our contacts with customers are done by email, text message or phone. Different emails are sent during a normal week:",
        "1) Billing reminder email containing a link to the weekly basket composition",
        "2) Order confirmation email on the day of billing",
        "Some major communications (changes in our offer, important announcements...) are also made by email and sent to the email address attached to the customer's account.",
      ],
    },
    {
      big_title: "Customer Service",
      title: "",
      contents: [
        "We want you to be fully satisfied with our services. If for any reason you are not, please contact us. If the products you have received seem unsanitary or unfit for consumption, send us a picture by email. If a product is missing, we will give you a credit on your next order.",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "Use of your phone number",
      title: "",
      contents: [
        "The phone number you are asked to provide when you register is used only to ensure the quality of our service. In this sense we send you a text message when your cart has just arrived at the drop off point and in case of emergency if there was a problem with your order. In no case your number is transmitted or sold to entities outside SecondLife and no commercial use is made of it. ",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "Taxes",
      title: "",
      contents: [
        "The majority of our products are tax free. If taxes (GST and QST) must be collected, the amount will be indicated in your order confirmation.",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "Change of terms and conditions",
      title: "",
      contents: [
        "SecondLife may change the content of this privacy policy at any time. It is the user's responsibility to periodically review the current privacy policy. ",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "Organic certification",
      title: "",
      contents: [
        "Our 'Bio-local' and 'Organic' baskets and their contents are certified by Ecocert Canada.",
        "The certificate number is CA-211283-Z-144038-2019.",
        "For more information, visit www.ecocertcanada.com ",
        "",
      ],
    },
    {
      big_title: "Privacy Policy ( Updated June 3, 2021 )",
      title: "Legal information",
      contents: [
        "The second-life.ca website is owned by SecondLife Inc., a company registered at 6325 blv des Grandes Prairies, Montreal, H1P1A5. Contact us: info@second-life.ca",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "",
      title: "Copyright and reproduction rights",
      contents: [
        "This site is subject to Canadian and international copyright laws. All rights of reproduction, in any medium, are reserved. Reproduction and use of the content of this site, in whole or in part, is prohibited without the permission of SecondLife Inc.",
        "You may not use our trademarks (including our logo) or represent yourself as an affiliate of SecondLife Marketplace without prior permission. ",
        "",
        "",
      ],
    },
    {
      big_title: "",
      title: "Personal information",
      contents: [
        "The information collected through our forms on the public site has no other purpose than to inform and answer the questions of Internet users. No personal information is given to third parties. If you wish to obtain communication or deletion of information concerning you (you can, for legitimate reasons, oppose the treatment of data concerning you), please contact us.",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "",
      title: "Personal information ",
      contents: [
        "Your personal information may be used or stored outside of Canada by us or one of our service providers. If this were to happen, such personal information would be subject to the laws of the country in which it is used or stored.",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "",
      title: "Google Analytics and Facebook Pixel and cookie",
      contents: [
        "This website uses Google Analytics, a web analysis service provided by Alphabet Inc. ('Google'). Google Analytics uses cookies, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser. However, such deactivation may prevent the use of certain features of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "",
      title: "Communications",
      contents: [
        "Anyone who creates an account on the SecondLife online store will automatically be added to our mailing lists. These emails are sent for support, service improvement and promotional purposes. However, if you no longer wish to receive emails from SecondLife, please let us know by emailing us at info@second-life.ca. We will then remove you from our mailing lists. Anyone who does not wish to be contacted at the phone number attached to the account should let us know in an email or by phone. These calls and text messages are sent for support, service improvement and promotional purposes.",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "",
      title: "Changes",
      contents: [
        "SecondLife may change the content of this privacy policy at any time. It is the user's responsibility to periodically review the current privacy policy.",
        "",
        "",
        "",
      ],
    },
    {
      big_title: "Questions",
      title:
        "If you have any questions about our privacy policy, please contact our team at info@second-life.ca ",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
    {
      big_title: "",
      title: "",
      contents: ["", "", "", ""],
    },
  ],
};
