import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { AlternateSection } from "../../../components/AlternateSection";

import { Page } from "../../../components/Page";
import { theme } from "../../../components/theme";
import { useAppDispatch, useAppState } from "../../../contexts";
import { SignUpProgress } from "../SignUpProgress";
import { SelectQuantity } from "../../../components/Plan/SelectQuantity";
import { TPlan, TPlanQuantity } from "../../../types/Plan";
import { useSetPlans, useSubscription } from "../../../hooks/Subscriptions";
import { useMutation } from "react-query";
import { pushEvent } from "../../../gtm";
import { EVENTS } from "../../../data/events";

export const SelectQuantityForPlan: React.FC = () => {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const { search } = useLocation();

  const history = useHistory();
  const { data: subscription, refetch } = useSubscription();
  const { plans } = useAppState();
  const [userPlans, setUserPlans] = useState<Array<TPlan>>([]);
  const [qty, setQty] = useState<TPlanQuantity>();
  const { mutate, isSuccess } = useMutation(useSetPlans);

  useEffect(() => {
    if (subscription && subscription?.plans?.length > 0) {
      setUserPlans(subscription.plans);
      setQty(subscription.plans[0]?.persons);
      return;
    }

    if (plans?.length > 0) {
      setUserPlans(plans);
      setQty(plans[0]?.persons);
      return;
    } else {
      history.push("/sign-up/step-1");
    }
  }, [subscription, plans]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
      history.push(`/sign-up/step-3`);
    }
  }, [isSuccess]);
  const redirectTo = (path: string) => () => history.push(path);

  const handleRedirect = () => {
    // If the plan is corpo, add a parameter to the next step url (to show an entreprise name field)
    let params = new URLSearchParams(search);
    let corpo = params.get("corpo");

    history.push(`/sign-up/step-3${corpo ? "?corpo=true" : ""}`);
  };

  return (
    <Page centerY centerX backgroundColor={theme.colors.base.background}>
      <AlternateSectionStyled>
        <HeaderWrapperStyled>
          <SignUpProgress
            current={2}
            title={t("Signup.step1.bundles.quantity.select")}
          />

          {userPlans.length > 0 && qty && (
            <Container>
              <SelectQuantity
                plan={userPlans[0]}
                quantity={qty}
                onValidate={(personsFound) => {
                  const presetPlansCopy = JSON.parse(
                    JSON.stringify(userPlans)
                  ).map((p: TPlan) => ({
                    ...p,
                    persons: personsFound,
                  }));

                  appDispatch({
                    type: "SET_PLANS",
                    payload: {
                      plans: presetPlansCopy,
                    },
                  });
                  if (!subscription) {
                    pushEvent(EVENTS.SIGNUP_COMPLETE_STEP_CHOOSE_AMOUNT);
                    handleRedirect();
                  }

                  if (subscription) {
                    mutate({
                      plans: presetPlansCopy.map((p: TPlan) => ({
                        ...p,
                        persons: personsFound,
                      })),
                    });
                  }
                }}
                onBack={redirectTo("/sign-up/step-1")}
              />
            </Container>
          )}
        </HeaderWrapperStyled>

        {/* {error && <Snackbar type="error" error={error} />} */}
      </AlternateSectionStyled>
    </Page>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: ${theme.spacing[3]};

  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing[3]};
  background: ${theme.colors.base.white};
`;

const AlternateSectionStyled = styled(AlternateSection)`
  position: relative;
  @media (max-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[1]} 0;
  }
`;

const HeaderWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${theme.spacing[2]};
`;
