import React, { useEffect, useState } from "react";
import { TPickupLocation } from "../../types/PickupPoint";
import { TChoice } from "../ChoiceList/ChoiceList";
import { Options } from "../Options";
import { OptionPUP } from "../Options/Option";
import { LocationContent } from "./LocationContent";

interface Props {
  id: string;
  viewOnly?: boolean;
  locationSelected?: TPickupLocation;
  pickupPoints: TPickupLocation[];
  timeSelected?: TChoice;
  onLocationSelect: (index: number, isOpen: boolean, password: string) => void;
  onTimeSelect: (timeSlot: TChoice, isOpen: boolean, password: string) => void;
}

export const LocationList: React.FC<Props> = ({
  id,
  viewOnly,
  locationSelected,
  pickupPoints,
  timeSelected,
  onLocationSelect,
  onTimeSelect,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>();

  useEffect(() => {
    if (locationSelected && pickupPoints) {
      const selectedPoint = pickupPoints.findIndex(
        (point) => point.id === locationSelected.id
      );
      if (selectedPoint >= 0) {
        setActiveIndex(selectedPoint);
      }
    }
  }, [locationSelected, pickupPoints]);

  const onLocationClickHandler = (
    index: number,
    isOpen: boolean,
    password: string
  ): void => {
    setActiveIndex(index);
    onLocationSelect(index, isOpen, password);
  };

  const onTimeClickHandler = (
    choice: TChoice,
    isOpen: boolean,
    password: string
  ): void => {
    onTimeSelect(choice, isOpen, password);
  };

  return (
    <Options id={id} current={activeIndex}>
      {pickupPoints.map((pickupPoint: TPickupLocation, index: number) => {
        const optionId = `${id}-options-${index}`;
        const isActive = activeIndex !== null && activeIndex === index;

        if (!pickupPoint.open) {
          return null;
        }

        return (
          <OptionPUP
            id={optionId}
            pickupPoint={pickupPoint}
            index={index}
            select={isActive}
            onSelect={(index, isAvailable, password) => {
              onLocationClickHandler(index, isAvailable, password);
            }}
            key={optionId}
          >
            {
              <>
                <LocationContent
                  isSelected={isActive}
                  viewOnly={viewOnly}
                  pickupPoint={pickupPoint}
                  timeSelected={timeSelected}
                  timeslots={pickupPoint.timeslots}
                  onSelectTime={(choice) => {
                    onTimeClickHandler(
                      choice,
                      pickupPoint.open,
                      pickupPoint.password
                    );
                  }}
                />
              </>
            }
          </OptionPUP>
        );
      })}
    </Options>
  );
};
