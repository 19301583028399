import React from "react";
import styled, { css } from "styled-components";

import { Heading } from "../../components/Heading";
import { Section } from "../../components/Section";
import { theme, spacing } from "../../components/theme";
import config from "../../config";
import { Text } from "../../components/Text";

interface Props {
  title: string;
  subtitle?: string;
  text?: string;
  image: string;
  imageAlt: string;
  wide?: boolean;
  imageOnTopWhenSmall?: boolean;
  centerWhenSmall?: boolean;
}

export const Hero: React.FC<Props> = ({
  title,
  subtitle,
  text,
  image,
  imageAlt,
  imageOnTopWhenSmall = false,
  centerWhenSmall = false,
  wide = false,
  children,
}) => {
  return (
    <HeroSection wide={wide}>
      <ContentStyled>
        <TaglineSection imageOnTopWhenSmall={imageOnTopWhenSmall}>
          <HeadingStyled
            element="h1"
            size="xl"
            centerWhenSmall={centerWhenSmall}
          >
            {title}
          </HeadingStyled>
          <SubtitleStyled
            element="h2"
            size="md"
            centerWhenSmall={centerWhenSmall}
          >
            {subtitle}
          </SubtitleStyled>
          {text ? (
            <TextStyled size="mdx" centerWhenSmall={centerWhenSmall}>{text}</TextStyled>
          ) : null}
          <AdditionalContentStyled>{children}</AdditionalContentStyled>
        </TaglineSection>

        <ImageContainer imageOnTopWhenSmall={imageOnTopWhenSmall}>
          <PictureStyled>
            <source
              srcSet={`${config.CDN_URL}${image}.webp`}
              type="image/webp"
            />
            <source srcSet={`${config.CDN_URL}${image}.png`} type="image/png" />
            <img
              loading="lazy"
              src={`${config.CDN_URL}${image}.png`}
              alt={imageAlt}
            />
          </PictureStyled>
        </ImageContainer>
      </ContentStyled>
    </HeroSection>
  );
};

const HeadingStyled = styled(Heading)`
  color: ${theme.colors.base.secondary};

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: 40px;
  }

  ${(props) =>
    props.centerWhenSmall &&
    css`
      @media (max-width: ${theme.breakpoints.md}) {
        text-align: center;
      }
    `}
`;

const SubtitleStyled = styled(Heading)`
  color: ${theme.colors.base.primary};

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: 24px;
  }

  @media (max-width: ${theme.breakpoints.xs}) {
    max-width: 280px;
    margin: auto;
  }

  ${(props) =>
    props.centerWhenSmall &&
    css`
      @media (max-width: ${theme.breakpoints.md}) {
        text-align: center;
      }
    `}
`;

const TextStyled = styled(Text)`
  ${(props) =>
    props.centerWhenSmall &&
    css`
      @media (max-width: ${theme.breakpoints.md}) {
        text-align: center;
      }
    `}
`;

const HeroSection = styled(Section)<Pick<Props, "wide">>`
  position: relative;
  padding: 0;

  ${(props) =>
    props.wide &&
    css`
      @media (min-width: ${theme.breakpoints.md}) {
        padding: ${theme.spacing[1]} 0 ${theme.spacing[2]};
      }
    `}
`;

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.md}) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

const TaglineSection = styled.div<Pick<Props, "imageOnTopWhenSmall">>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
  ${(props) =>
    props.imageOnTopWhenSmall &&
    css`
      order: 2;
    `} @media (
    min-width: ${theme.breakpoints.xs}) {
    max-width: 90%;
    margin-right: ${theme.spacing[2]};
  }

  @media (min-width: ${theme.breakpoints.md}) {
    max-width: 55%;
  }
`;

const AdditionalContentStyled = styled.div`
  margin-top: ${theme.spacing[1.5]};
`;

const ImageContainer = styled.div<Pick<Props, "imageOnTopWhenSmall">>`
  display: flex;
  justify-content: center;

  ${(props) =>
    props.imageOnTopWhenSmall &&
    css`
      order: 1;
    `}
`;

const PictureStyled = styled.picture`
  source,
  img {
    width: auto;
    max-width: 100%;
    max-height: 500px;

    @media (min-width: ${theme.breakpoints.xs}) {
      margin-top: -${theme.spacing[1]};
      margin-right: -${theme.spacing[2]};
    }

    @media (min-width: ${theme.breakpoints.md}) {
      position: absolute;
      top: 50%;
      right: -${spacing(5)};
      transform: translateY(-50%);
      max-width: 720px;
      margin-top: -${theme.spacing[1]};
      margin-right: -${theme.spacing[5]};
    }
  }
`;
