import React from "react";
import styled from "styled-components";
import config from "../../config";
import { Heading } from "../Heading";
import { Section } from "../Section";
import { theme } from "../theme";
import { Text } from "../../components/Text";
import { useTranslation } from "react-i18next";

export const OurStory: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SectionStyled size="lg" backgroundColor={theme.colors.accent.secondary}>
      <Randomstyled>
        <Divstyled>
          <Heading size="lg">{t("About.OurStory.heading")}</Heading>
          <Text size="md">
            <span>{t("About.OurStory.text1")}</span>
            <span>{t("About.OurStory.text2")}</span>
          </Text>
        </Divstyled>
        <Divstyled>
          <ItemPictureStyled>
            <source
              srcSet={`${config.CDN_URL}/common/images/about-us-story.webp`}
              type="image/webp"
            />
            <source
              srcSet={`${config.CDN_URL}/common/images/about-us-story.png`}
              type="image/png"
            />
            <img
              src={`${config.CDN_URL}/common/images/about-us-story.png`}
              alt="Placeholder"
              loading="lazy"
            />
          </ItemPictureStyled>
        </Divstyled>
      </Randomstyled>
    </SectionStyled>
  );
};

const SectionStyled = styled(Section)`
  display: flex;
  flex-wrap: wrap;
`;

const Randomstyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[2]};
  }
  justify-content: space-between;
  p > span + span {
    display: block;
    padding-top: ${theme.spacing[2]};
  }
`;

const ItemPictureStyled = styled.picture`
  img,
  source {
    max-width: 85%;
  }
  display: flex;
  justify-content: center;

  @media (max-width: ${theme.breakpoints.sm}) {
    max-width: 100%;
    img,
    source {
      max-width: 85%;
    }
  }
`;

const Divstyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
