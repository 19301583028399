import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { AlternateSection } from "../../components/AlternateSection";
import { Button } from "../../components/Button";
import { FlashBannerReactivate } from "../../components/FlashBannerReactivate";
import { Page } from "../../components/Page";
import { theme } from "../../components/theme";
import { WithAuth } from "../../components/WithAuth";
import { useSubscription } from "../../hooks/Subscriptions";
import { Navigation } from "../Navigation";
import { Holidays } from "./Holidays";
import { Info } from "./Info";
import { Plans } from "./Plans";
import { SuspendPopup } from "./SuspendPopup";

export const Account: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: subscription } = useSubscription();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (subscription && subscription?.plans?.length === 0) {
      return history.push("/sign-up/step-1");
    }

    if (subscription && !subscription?.lastDigits) {
      return history.push("/sign-up/confirm");
    }

    if (subscription?.status === "cancel") {
      return history.push("/dashboard");
    }
  }, [subscription]);

  const onToggleSuspend = () => {
    setShowPopup(!showPopup);
  };

  return (
    <WithAuth>
      <Page centerY centerX backgroundColor={theme.colors.base.background}>
        {subscription?.status === "cancel" && (
          <FlashBannerReactivate times={10} type="normal">
            {t("Subscription.cancelled")}
          </FlashBannerReactivate>
        )}
        <AlternateSection>
          <Navigation>
            <Button thirdReverse onClick={onToggleSuspend}>
              {t("Dashboard.suspend")}
            </Button>
          </Navigation>
          <AccountWrapper>
            <Plans />
            <Info />
          </AccountWrapper>
          <Holidays />
          <ButtonWrapper>
            <Button thirdReverse onClick={onToggleSuspend}>
              {t("Dashboard.suspend")}
            </Button>
          </ButtonWrapper>
          {showPopup && (
            <SuspendPopup
              message={t("Dashboard.suspend.text")}
              title={t("Dashboard.suspend")}
              onClose={onToggleSuspend}
            />
          )}
        </AlternateSection>
      </Page>
    </WithAuth>
  );
};

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[1.5]};
  margin-bottom: ${theme.spacing[1]};

  @media (max-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[1.5]} 0;
    background: ${theme.colors.base.white};
    border: 2px solid ${theme.colors.base.white};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-top: ${theme.spacing[2]};

    > div {
      margin-top: ${theme.spacing[2]};

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${theme.spacing[1]};

  > button {
    width: 100%;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    display: none;
  }
`;
