import { isPast } from "date-fns";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { AlternateSection } from "../../components/AlternateSection";
import { Button } from "../../components/Button";
import { FlashBanner } from "../../components/FlashBanner";
import { FlashBannerReactivate } from "../../components/FlashBannerReactivate";
import { OrderSummary } from "../../components/OrderSummary";
import { Page } from "../../components/Page";
import { Snackbar } from "../../components/Snackbar";
import { theme } from "../../components/theme";
import { WithAuth } from "../../components/WithAuth";
import { useDelivery } from "../../hooks/Delivery";
import { useNextDeliveries } from "../../hooks/NextDeliveries";
import { useProcessingOrders, useUpdateStatus } from "../../hooks/Orders";
import { useSavings } from "../../hooks/Orders";
import { useSubscription } from "../../hooks/Subscriptions";
import { Navigation } from "../Navigation";
import { OrderStatus } from "./OrderStatus";
import { StatsSaved } from "./StatsSaved";
export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: subscription, error: errorSub } = useSubscription();
  const { data: elementSaved } = useSavings();
  const { data: order, refetch, error: errorOrder } = useProcessingOrders();
  const { data: delivery, error: errorDelivery } = useDelivery();
  const { data: nextDeliveries } = useNextDeliveries();
  const { mutate, isLoading, isSuccess, error } = useMutation(useUpdateStatus);
  useEffect(() => {
    if (subscription && subscription?.plans?.length === 0) {
      return history.push("/sign-up/step-2");
    }

    if (subscription && !subscription?.lastDigits) {
      return history.push("/sign-up/confirm");
    }

    if (subscription?.status === "cancel") {
      return history.push("/dashboard");
    }
  }, [subscription]);

  useEffect(() => {
    refetch();
  }, [isSuccess]);

  const onSkipHandler = () => {
    if (order && nextDeliveries) {
      mutate({ oid: order?.oid, status: "skipped" });
    }
  };

  const onUnSkipHandler = () => {
    if (order && nextDeliveries) {
      mutate({ oid: order?.oid, status: "open" });
    }
  };

  const redirectTo = (path: string) => () =>
    history.push({ pathname: path, state: { prevPath: "/dashboard" } });

  return (
    <WithAuth>
      <Page centerX centerY backgroundColor={theme.colors.base.background}>
        {subscription?.status === "cancel" && (
          <FlashBannerReactivate times={10} type="normal">
            {t("Subscription.cancelled")}
          </FlashBannerReactivate>
        )}
        {delivery?.entity_type === "PUP" && !delivery.open && (
          <FlashBanner times={10} type="yellow">
            {t("Dashboard.pup-closed")}
          </FlashBanner>
        )}
        <AlternateSection>
          <Navigation />

          <StatsSaved
            food_saved={elementSaved?.food_saved}
            co2_saved={elementSaved?.co2_saved}
            water_saved={elementSaved?.water_saved}
            money_saved={elementSaved?.water_saved} //carefull
          />
          <DashboardWrapper>
            <StatusWrapper>
              <OrderStatus />
            </StatusWrapper>
            <SummaryWrapper>
              {
                <OrderSummary
                  onModifyPlans={redirectTo("/account/plans")}
                  onModifyDelivery={redirectTo("/account/delivery")}
                  showCreationDate={true}
                  showDeliveryPrice={true}
                  showAction={true}
                />
              }
            </SummaryWrapper>
          </DashboardWrapper>
          <ButtonWrapper>
            {order?.status === "open" &&
              !isPast(new Date(order?.processing_date)) && (
                <Button
                  thirdReverse
                  isLoading={isLoading}
                  onClick={onSkipHandler}
                >
                  {t(`Deliveries.skip`)}
                </Button>
              )}
            {order?.status === "skipped" &&
              !isPast(new Date(order?.processing_date)) && (
                <Button
                  secondary
                  isLoading={isLoading}
                  onClick={onUnSkipHandler}
                >
                  {t(`Deliveries.unskip`)}
                </Button>
              )}
          </ButtonWrapper>
          {isSuccess && (
            <Snackbar type={"success"} text={t("Deliveries.skip.success")} />
          )}
          {error && <Snackbar type={"error"} text={t("Info.holidays.clear")} />}
          {errorSub && <Snackbar type="error" error={errorSub} />}
          {errorOrder && <Snackbar type="error" error={errorOrder} />}
          {errorDelivery && <Snackbar type="error" error={errorDelivery} />}
        </AlternateSection>
      </Page>
    </WithAuth>
  );
};

const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[1.5]};

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
    margin-top: ${theme.spacing[2]};
  }
`;

const StatusWrapper = styled.div`
  flex: 65;
`;

const SummaryWrapper = styled.div`
  flex: 35;
  margin-top: ${theme.spacing[0.5]};

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-left: ${theme.spacing[1.5]};
    margin-top: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${theme.spacing[1]};

  > button {
    width: 100%;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    display: none;
  }
`;
