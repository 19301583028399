import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import styled from "styled-components";
import { icons } from "../../../assets/icons";
import { useAppState } from "../../../contexts";
import { useSpotLeft } from "../../../hooks/PickupPoints";
import { useAddWaitingList } from "../../../hooks/WaitingList";
import { TPickupLocation } from "../../../types/PickupPoint";
import { TTimeSlot } from "../../../types/TimeSlot";
import { toDistance } from "../../../utils";
import { formatTimeslotDay, formatTimeslotHours } from "../../../utils/Date";
import { ChoiceList } from "../../ChoiceList";
import { TChoice } from "../../ChoiceList/ChoiceList";
import { Button } from "../../Button";
import { Label } from "../../Label";
import { alpha, theme } from "../../theme";

interface Props {
  isSelected: boolean;
  viewOnly?: boolean;
  pickupPoint: TPickupLocation;
  timeSelected?: TChoice;
  timeslots?: TTimeSlot[];
  onSelectTime: (freq: TChoice) => void;
}

export const LocationContent: React.FC<Props> = ({
  isSelected,
  viewOnly,
  pickupPoint,
  timeSelected,
  timeslots,
  onSelectTime,
}) => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const { data: spots } = useSpotLeft(pickupPoint.ppid);
  const [slots, setSlots] = useState<TChoice[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const Address = () => (
    <AddressStyled element="address" size="sm">
      <span>
        {pickupPoint.address}
        {pickupPoint.address2 && `, ${pickupPoint.address2}`}
      </span>
      <span>
        {pickupPoint.city}, {pickupPoint.province}, {pickupPoint.country}
      </span>
    </AddressStyled>
  );

  const Availability = () => (
    <AvailabilityStyled size="sm">
      {activeDay(pickupPoint.timeslots || [], 1, t(`Days.monday.troncate`))}
      {activeDay(pickupPoint.timeslots || [], 2, t(`Days.tuesday.troncate`))}
      {activeDay(pickupPoint.timeslots || [], 3, t(`Days.wednesday.troncate`))}
      {activeDay(pickupPoint.timeslots || [], 4, t(`Days.thurday.troncate`))}
      {activeDay(pickupPoint.timeslots || [], 5, t(`Days.friday.troncate`))}
      {activeDay(pickupPoint.timeslots || [], 6, t(`Days.saturday.troncate`))}
      {activeDay(pickupPoint.timeslots || [], 0, t(`Days.sunday.troncate`))}
    </AvailabilityStyled>
  );

  const Distance = () => {
    if (!pickupPoint.distance) return null;
    return (
      <DistanceLabel size="mdx">
        {toDistance(pickupPoint.distance, "km")}
      </DistanceLabel>
    );
  };

  const frequency = pickupPoint.frequence; // frequence of the PUP
  const planFrequencies = Number(frequency) > 7 ? [14] : [7, 14]; // what the user can select
  const { mutate: mutateAddWaitingList, isSuccess: isSuccessWaitingList } =
    useMutation(useAddWaitingList);

  const handleClick = () => {
    mutateAddWaitingList({ ppid: pickupPoint.ppid });
  };

  useEffect(() => {
    if (timeslots && timeslots.length > 0 && spots) {
      const days = spots
        ?.map((spot) => {
          const timeslotFound = timeslots.find(
            (timeslot) => timeslot.day === spot.day
          );
          if (timeslotFound && spot.spot_left) {
            return timeslotFound;
          }
        })
        .filter((item): item is TTimeSlot => !!item);

      if (days) {
        setSlots(
          days.map((timeslot) => {
            return {
              day: `${formatTimeslotDay(timeslot?.day, locale, "short")}`,
              time: `${formatTimeslotHours(
                timeslot?.day,
                timeslot?.start_time,
                timeslot?.end_time,
                locale,
                "short"
              )}`,
              value: `${timeslot.id}`,
            };
          })
        );
      }
    }
  }, [locale, timeslots, spots]);

  useEffect(() => {
    if (spots) {
      const spotFound = spots?.find((spot) => spot.spot_left > 0);
      if (spotFound) setIsOpen(true);
    }
  }, [spots]);

  return (
    <LocationWrapper>
      <InfoSectionStyled>
        <Distance />
        <LabelStyled weight="bold" size="lg">
          {pickupPoint.name}
          {pickupPoint.password ? `(${t("Signup.step3.private")})` : ""}
        </LabelStyled>
        <Address />
        <Availability />
      </InfoSectionStyled>
      {!viewOnly && (
        <SlotsSectionStyled>
          <SlotsWrapper>
            <SlotsHeaderStyled size="sm">
              {t("LocationList.ChoiceList.heading")}
            </SlotsHeaderStyled>
            {slots && slots.length > 0 && isOpen ? (
              <ChoiceList
                shouldReset={!isSelected}
                selectedChoice={timeSelected}
                choices={slots}
                onSelect={onSelectTime}
              />
            ) : (
              <CompleteWrapper>
                <CompleteTag>
                  <icons.lock />
                  <CompleteLabel size="sm">
                    {t("Signup.step3.complete")}
                  </CompleteLabel>
                </CompleteTag>
                <ButtonStyled
                  primary
                  size="sm"
                  disabled={isSuccessWaitingList}
                  onClick={handleClick}
                >
                  <>
                    <icons.add />
                    {isSuccessWaitingList
                      ? `${t("Signup.step3.button-added")}`
                      : `${t("Signup.step3.button-waitlist")}`}
                  </>
                </ButtonStyled>
              </CompleteWrapper>
            )}
          </SlotsWrapper>
        </SlotsSectionStyled>
      )}
    </LocationWrapper>
  );

  function activeDay(timeslots: TTimeSlot[], day: number, dayString: string) {
    const isActive = !!timeslots.filter((t) => t.day === day && t.active)
      .length;
    return <span className={clsx(isActive && "active")}>{dayString}</span>;
  }
};

const DistanceLabel = styled(Label)`
  color: ${theme.colors.base.grey};
  margin-bottom: ${theme.spacing[0.25]};
`;

const AddressStyled = styled(Label)`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[0.5]};
`;

const LabelStyled = styled(Label)`
  line-height: ${theme.font.size.xl};
`;

const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

const InfoSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: ${theme.spacing[0.25]};

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-right: ${theme.spacing[0.5]};
  }
`;

const SlotsSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  margin-top: ${theme.spacing[0.25]};

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-left: ${theme.spacing[0.5]};
  }

  & > * {
    margin-top: ${theme.spacing[0.5]};
  }

  & :first-child {
    margin-top: 0;
  }
`;

const AvailabilityStyled = styled(Label)`
  margin-top: ${theme.spacing[1]};
  color: ${alpha(theme.colors.base.dark, theme.transparency.high)};

  & > * {
    margin-left: ${theme.spacing[0.5]};
    text-decoration: line-through;

    &.active {
      color: ${theme.colors.base.dark};
      text-decoration: none;
    }

    :first-child {
      margin-left: 0;
    }
  }
`;

const SlotsWrapper = styled.div``;

const SlotsHeaderStyled = styled(Label)`
  margin-bottom: ${theme.spacing[0.5]};
`;

const CompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin-top: ${theme.spacing[0.25]};
    margin-bottom: ${theme.spacing[0.25]};

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;

    & > * {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: ${theme.spacing[0.25]};
      margin-right: ${theme.spacing[0.25]};

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const CompleteTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${theme.spacing[0.5]} ${theme.spacing[1]} ${theme.spacing[0.5]}
    ${theme.spacing[0.5]};
  background: ${theme.colors.base.background};
  border: 1px solid ${theme.colors.base.primary};
  box-sizing: border-box;
  border-radius: 64px;

  svg {
    path {
      fill: ${theme.colors.base.primary};
    }
  }
`;

const CompleteLabel = styled(Label)`
  margin-left: ${theme.spacing[0.5]};
  line-height: ${theme.font.size.mdx};
  color: ${theme.colors.base.primary};
  text-transform: uppercase;
`;

const ButtonStyled = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: ${theme.font.weight.normal};
  border-radius: 64px;

  svg {
    margin-right: ${theme.spacing[0.5]};

    path {
      fill: ${theme.colors.base.white};
    }
  }
`;
