import clsx from "clsx";
import React from "react";
import styled from "styled-components";

import { alpha, theme, spacing } from "../theme";

interface Props {
  id?: string;
  name: string;
  type: "text" | "email" | "password" | "number";
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  error?: boolean;
  onChange?: (value: string) => void;
  className?: string;
  register: any;
  validation: any;
  disabled?: boolean;
  color?: string;
}

export const FormInput: React.FC<Props> = ({
  id,
  name,
  type,
  placeholder,
  value,
  defaultValue,
  error,
  onChange,
  className,
  register,
  validation,
  disabled,
  color,
}) => {
  const onValueChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    onChange(evt.currentTarget.value);
  };

  return (
    <InputStyled
      id={id}
      disabled={disabled}
      className={clsx("form-input", error && "error", className)}
      type={type}
      color={color}
      name={name}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onValueChange}
      ref={register(validation)}
    />
  );
};

export const inputStyles = `
  width: 100%;
  padding: ${theme.spacing[1]} ${theme.spacing[0.5]};
  border: 1px solid ${theme.colors.base.secondary};
  box-shadow: inset 0 0 0 2px ${theme.colors.base.light};
  border-radius: ${theme.border.radius.sm};
  background-color: ${theme.colors.base.light};
  font-size: ${theme.font.size.md};

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px ${theme.colors.base.secondary};
  }

  &.error {
    box-shadow: 0 0 0 2px ${theme.banner.error.color};
    background-color: ${alpha(
      theme.banner.error.color,
      theme.transparency.high
    )};

    ::placeholder {
      color: ${alpha(theme.banner.error.color, theme.transparency.medium)};
    }
  }

   &[disabled] {
    background-color:${theme.colors.neutral.primary};
    opacity: 0.5;
  } 
  
  &[color] {
    background-color: ${theme.colors.accent.secondary};
  }
  
  ::placeholder {
    padding-left: ${theme.spacing[0.5]};
    color: color: ${alpha(theme.colors.base.dark, theme.transparency.medium)};;
    opacity: 1;
  }
`;

const InputStyled = styled.input`
  ${inputStyles}
`;
