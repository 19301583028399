import React from 'react';
import styled, { css } from 'styled-components';

import { theme } from '../theme';

type ColumnType = 1 | 2 | 3 | 4;
type SizeType = 1 | 2 | 3 | 4;

interface Props {
  columns?: ColumnType;
  widthRatio?: SizeType;
  className?: string;
}

const FormControl: React.FC<Props> = ({
  className,
  columns = 1,
  widthRatio = 1,
  children
}) => {
  return (
    <div className={className}>{children}</div>
  );
}

export const FormControlStyled = styled(FormControl)`
  & + *,
  & > * {
    margin-top: ${theme.spacing[1]};
  }

  ${props => props.columns && css`
    @media (min-width: ${theme.breakpoints.sm}) {
      display: flex;

      & > * {
        flex: 1;
        margin-top: 0;
        margin-left: ${theme.spacing[1]}
      }

      & > :first-child {
        margin-left: 0;
      }
    }
  `}

  ${props => props.widthRatio && css`
    width: 100%;

    @media (min-width: ${theme.breakpoints.sm}) {
      width: ${(props.widthRatio / 4) * 100}%;
    }
  `}
`;
