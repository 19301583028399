import React from "react";
import styled, { css } from "styled-components";

import { spacing, theme } from "../theme";

type SizeType = "sm" | "md" | "lg";

interface Props {
  size?: SizeType;
  loose?: boolean;
  centerX?: boolean;
  backgroundColor?: string;
  className?: string;
}

export const Section: React.FC<Props> = ({
  size = "md",
  loose = false,
  centerX = false,
  backgroundColor,
  className,
  children,
}) => {
  return (
    <SectionStyled backgroundColor={backgroundColor}>
      <ContentStyled
        className={className}
        size={size}
        loose={loose}
        centerX={centerX}
      >
        {children}
      </ContentStyled>
    </SectionStyled>
  );
};

const SectionStyled = styled.div<Pick<Props, "backgroundColor">>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
`;

const ContentStyled = styled.div<Pick<Props, "size" | "loose" | "centerX">>`
  width: 100%;
  padding: ${theme.spacing[4]} ${theme.spacing[2]};

  @media (min-width: ${theme.breakpoints.md}) {
    max-width: 1120px;
  }

  ${(props) =>
    props.size === "sm" &&
    css`
      @media (min-width: ${theme.breakpoints.md}) {
        max-width: none;
      }

      @media (min-width: ${theme.breakpoints.sm}) {
        max-width: 820px;
      }
    `}

  ${(props) =>
    props.size === "lg" &&
    css`
      @media (min-width: ${theme.breakpoints.md}) {
        max-width: none;
      }

      @media (min-width: ${theme.breakpoints.xl}) {
        max-width: ${theme.breakpoints.lg};
      }
    `}

  ${(props) =>
    props.loose &&
    css`
      @media (min-width: ${theme.breakpoints.lg}) {
        padding: ${spacing(6)} ${theme.spacing[2]};
      }
    `}

  ${(props) =>
    props.centerX &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `}
`;
