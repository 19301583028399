export type Locale = string;

export type TCategoryCopy = Record<
  Locale,
  {
    name: string;
  }
>;

export type TCategory = {
  id: number;
  cid: string;
  name: string;
  locale: string;
  published: boolean;
  parent_category_id?: number;
  copy: TCategoryCopy;
};

export interface ProductCategory {
  product_id: number;
  categories_ids: number[];
}

export type TCategoriesTree = ({
  subcategories: TCategory[];
} & TCategory)[];

export const SpecialCategories = {
  PROMO: "is_promo",
  LOCALS: "is_local",
  SAVED: "is_saved",
  NEW: "is_featured",
};
