import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { List } from "../../components/List";
import { Text } from "../../components/Text";
import { alpha, theme } from "../theme";

interface Props {
  type: "success" | "info" | "warning" | "error" | "neutral";
  center?: boolean;
  list?: string[];
  className?: string;
  onDismiss?: () => void;
}

const AlertBanner: React.FC<Props> = ({
  type,
  center,
  list,
  className,
  onDismiss,
  children,
}) => {
  const [showIt, setShowIt] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShowIt(false);
      onDismiss && onDismiss();
    }, 5000);
  }, [showIt]);

  if (!showIt) {
    return null;
  }
  return (
    <div className={className}>
      {children}
      {list && list.length > 0 && (
        <ListStyled>
          {list.map((item, i) => (
            <li key={i}>
              <Text>{item}</Text>
            </li>
          ))}
        </ListStyled>
      )}
    </div>
  );
};

export const AlertBannerStyled = styled(AlertBanner)`
  padding: ${theme.spacing[1]};
  border-radius: ${theme.border.radius.md};
  border: 2px solid transparent;

  ${(props) =>
    props.type === "success" &&
    css`
      border-color: ${alpha(
        theme.banner.success.color,
        theme.transparency.high
      )};
      background-color: ${theme.banner.success.background};
      color: ${theme.banner.success.color};
    `}

  ${(props) =>
    props.type === "info" &&
    css`
      border-color: ${alpha(theme.banner.info.color, theme.transparency.high)};
      background-color: ${theme.banner.info.background};
      color: ${theme.banner.info.color};
    `}

  ${(props) =>
    props.type === "warning" &&
    css`
      border-color: ${alpha(
        theme.banner.warning.color,
        theme.transparency.high
      )};
      background-color: ${theme.banner.warning.background};
      color: ${theme.banner.warning.color};
    `}

  ${(props) =>
    props.type === "error" &&
    css`
      border-color: ${alpha(theme.banner.error.color, theme.transparency.high)};
      background-color: ${theme.banner.error.background};
      color: ${theme.banner.error.color};
    `}

  ${(props) =>
    props.type === "neutral" &&
    css`
      border-color: ${alpha(
        theme.colors.accent.third,
        theme.transparency.high
      )};
      background-color: ${theme.colors.accent.third};
    `}

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}
`;

const ListStyled = styled(List)`
  padding-left: 20px;
`;
