import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Button } from "../Button";
import { Heading } from "../Heading";
import { Image } from "../Image";
import { Section } from "../Section";
import { theme } from "../theme";
import { Stack } from "../Stack";

interface Props {
  title: {
    label: string;
    element?: "h1" | "h2" | "h3";
  };
  action?: {
    label: string;
    to: string;
  };
}

export const ThankYouState: React.FC<Props> = ({ title, action }) => {
  const { t } = useTranslation();

  return (
    <Section>
      <SectionContentStyled>
        <HeadingStyled size="lg" element={title.element ? title.element : "h2"}>
          {title.label}
        </HeadingStyled>
        <ImageStyled>
          <Image
            path="/common/images/thank-you"
            alt={t("EmptyState.Image.alt")}
          />
        </ImageStyled>
        <SectionContentStyled1>
          <Stack>
            {action && <Button to={`/cart`}>{t(`Thanks.viewBasket`)}</Button>}
          </Stack>
        </SectionContentStyled1>
      </SectionContentStyled>
    </Section>
  );
};

const SectionContentStyled = styled.div`
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SectionContentStyled1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeadingStyled = styled(Heading)`
  margin-bottom: 0;
`;

const ImageStyled = styled.div`
  @media (min-width: ${theme.breakpoints.md}) {
    max-width: 60%;
  }
`;
