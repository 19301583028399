import React from "react";
import styled from "styled-components";
import clsx from "clsx";

import { Label } from "../Label";
import { TChoice } from "./ChoiceList";
import { alpha, theme } from "../theme";

interface ItemProps {
  choice: TChoice;
  index: number;
  isSignup: boolean;
  onSelect?: (index: number) => void;
}

export const ChoiceListItem: React.FC<ItemProps> = ({
  choice,
  index,
  isSignup,
  onSelect = (index: number) => {},
}) => {
  if (isSignup) {
    return (
      <ChoiceListItemStyled
        className={clsx(choice.active && "active")}
        onClick={() => onSelect(index)}
        role="radio"
        aria-checked={choice.active}
        tabIndex={choice.active ? 0 : -1}
      >
        <LabelStyled size="mdx">
          <Checkmark />
          <span className="day">{choice.text}</span>
        </LabelStyled>
      </ChoiceListItemStyled>
    );
  }

  return (
    <ChoiceListItemStyled
      className={clsx(choice.active && "active")}
      onClick={() => onSelect(index)}
      role="radio"
      aria-checked={choice.active}
      tabIndex={choice.active ? 0 : -1}
    >
      <LabelStyled size="sm">
        <Checkmark />
        <span className="day">{choice.day}</span>
        <span className="slot">{choice.time}</span>
      </LabelStyled>
    </ChoiceListItemStyled>
  );
};

const ChoiceListItemStyled = styled.li`
  margin: ${theme.spacing[0.25]};
  padding: ${theme.spacing[0.5]};
  border: 1px solid ${theme.colors.base.secondary};
  border-radius: 64px;
  background-color: ${theme.colors.base.background};
  user-select: none;
  cursor: pointer;
  outline: none;

  &:hover,
  &.active {
    & span:after {
      display: block;
    }
    background-color: ${theme.colors.accent.secondary};
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: 10px ${theme.spacing[1]};
  }
`;

const LabelStyled = styled(Label)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.secondary};

  span {
    white-space: pre;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    span.day {
      display: flex;
    }
  }
`;

const Checkmark = styled.span`
  position: relative;
  display: inline-block;
  height: 17px;
  width: 17px;
  margin-right: ${theme.spacing[0.5]};
  border-radius: ${theme.border.radius.round};
  background-color: ${alpha(
    theme.colors.accent.secondary,
    theme.transparency.low
  )};
  transition: all ${theme.transition.fast};

  &:after {
    content: "";
    display: none;
    position: absolute;
    left: 6px;
    top: 3px;
    width: 3px;
    height: 7px;
    border: solid ${theme.colors.base.secondary};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;
