import config from "../../config";
import axios from "redaxios";
import {useQuery} from "react-query";
import {TUser} from "../../types//User";

const apiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/users`;
const apiAuthUrl = `${config.SERVICES.MARKETPLACE_API_URL}/auth`;
const apiUrlEmail = `${config.SERVICES.MARKETPLACE_API_URL}/emails`;

/**
 * fetch next deliveries from user
 * @returns The TNextDeliveries object
 */
export const useUser = () => {
    const token = localStorage.getItem("token");
    return useQuery<TUser>(
        ["useUser", token],
        () =>
            axios
                .get(`${apiUrl}/current?token=${token}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => res.data.payload),
        {
            // The query will not execute until the userId exists
            enabled: !!token,
        }
    );
};

/**
 * Update user
 * @param partialUser - A partial user object
 * @returns The user object
 */
export const useUpdateUser = (payload: Partial<TUser>) => {
    const token = localStorage.getItem("token");

    return axios
        .put(
            `${apiUrl}`,
            {...payload},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then((res) => res.data.payload);
};

/**
 * Log a user in
 * @param email - The email of the user
 * @param password - The password of the user
 * @returns JWT token
 */
export const useLogin = (payload: { email: string; password: string }) => {
    const {email, password} = payload;
    return axios
        .put(`${apiAuthUrl}/basic`, JSON.stringify({email, password}), {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((res) => res.data.payload);
};

/**
 * Create a new user
 * @param first_name - The first name of the user
 * @param last_name - The last name of the user
 * @param email - The email address of the user
 * @param password - The password of the user
 * @param password_confirmation - The password confirmation of the user
 * @param zipCode - The zip code of the user
 * @param phone - The phone of the user
 * @param locale - The locale of the user
 * @param referral - The referral of the user
 * @param plans - The list of the user plans
 * @returns The user object
 */
export const useCreateUser = (payload: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    password_confirmation: string;
    company_name: string;
    zip_code: string;
    phone: string;
    locale: string;
    referral: string | null;
    plans: { pid: string; quantity: number }[];
}) => {
    return axios
        .post(`${apiUrl}`, payload, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((res) => res.data.payload);
};

/**
 * Update user
 * @param current_password - The current password of the user
 * @param password - The password of the user
 * @param password_confirmation - The password confirmation of the user
 * @returns The confirmation
 */
export const useUpdatePassword = (payload: {
    current_password: string;
    password: string;
    password_confirmation: string;
}) => {
    const {current_password, password, password_confirmation} = payload;
    return axios
        .put(
            `${apiUrl}/security`,
            {current_password, password, password_confirmation},
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        .then((res) => res.data.payload);
};

/**
 * Request reset user password
 * @param email - The email of the user
 */
export const useRequestResetPassword = (email: string) => {
    return axios
        .post(
            `${apiAuthUrl}/password/reset/request`,
            {email},
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        .then((res) => res.data.payload);
};

/**
 * Reset user password request
 * @param token - The token of the reset request
 * @param newPassword - The new password
 * @param newPasswordConfirmation - The new password confirmation
 */
export const useResetPassword = (payload: {
    token: string;
    newPassword: string;
    newPasswordConfirmation: string;
}) => {
    const {token, newPassword, newPasswordConfirmation} = payload;
    return axios
        .put(
            `${apiAuthUrl}/password/reset`,
            {token, newPassword, newPasswordConfirmation},
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        .then((res) => res.data.payload);
};

/**
 * Validate a new user
 * @param token - The token of the new user
 * @returns JWT token
 */
export const useValidateUser = (token: string) => {
    return axios
        .get(`${apiUrl}/validate?token=${token}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((res) => res.data.payload);
};

/**
 * Add user to newsletters
 * @param email - The email of the user
 * @returns The confirmation
 */
export const useSignupNewsletter = (payload: { email: string }) => {
    const {email} = payload;
    return axios
        .post(
            `${apiUrlEmail}/newsletter`,
            {email},
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        .then((res) => res.data.payload);
};
