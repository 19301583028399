import React, { useEffect } from "react";
import { I18nextProvider } from "react-i18next";

import i18n from "../../i18n";

import { useAppState, useAppDispatch } from "../../contexts";
import { useUser } from "../../hooks/Users";
import { useQueryClient } from "react-query";
import "react-phone-input-2/lib/style.css";

export const App: React.FC = ({ children }) => {
  const appDispatch = useAppDispatch();
  const { token } = useAppState();
  const { data: user, error } = useUser();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!token) {
      queryClient.removeQueries("useUser");
      appDispatch({ type: "GET_TOKEN_FROM_STORAGE", payload: {} });
    }
    if (error && !user) {
      queryClient.removeQueries("useUser");
      appDispatch({ type: "DELETE_TOKEN", payload: {} });
    }
  }, [token, user, error]);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
