import React from "react";
import styled from "styled-components";

import { theme } from "../theme";

export const Divider: React.FC = () => {
  return <DividerStyled />;
};

const DividerStyled = styled.hr`
  width: 100%;
  height: 2px;
  margin-top: ${theme.spacing[2]};
  margin-bottom: ${theme.spacing[2]};
  border: 0;
  background-color: ${theme.colors.base.secondary};
`;
