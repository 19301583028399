import clsx from "clsx";
import React from "react";
import styled, { css } from "styled-components";

import { List } from "../../components/List";
import { Text } from "../../components/Text";
import { theme } from "../theme";

type SizeType = 1 | 2 | 3 | 4;

interface Props {
  widthRatio?: SizeType;
  errors?: string[];
  className?: string;
}

export const FormInputControl: React.FC<Props> = ({
  widthRatio = 1,
  errors,
  className,
  children,
}) => {
  return (
    <div className={clsx(className)}>
      {children}
      {errors && errors.length > 0 && (
        <ListStyled>
          {errors.map((error, i) => (
            <li key={i}>
              <ErrorMessage>{error}</ErrorMessage>
            </li>
          ))}
        </ListStyled>
      )}
    </div>
  );
};

export const FormInputControlStyled = styled(FormInputControl)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > label {
    margin-bottom: ${theme.spacing[0.5]};
  }

  & > input {
    width: 100%;
  }

  ${(props) =>
    props.widthRatio &&
    css`
      width: 100%;

      @media (min-width: ${theme.breakpoints.sm}) {
        width: ${(props.widthRatio / 4) * 100}%;
        flex: 0 1 auto;
      }
    `}
`;

const ListStyled = styled(List)`
  margin-top: ${theme.spacing[0.5]};
  list-style: none;
`;

const ErrorMessage: React.FC = ({ children }) => {
  return (
    <ErrorMessageStyled size="sm" className="error">
      {children}
    </ErrorMessageStyled>
  );
};

export const ErrorMessageStyled = styled(Text)`
  margin-bottom: 0;
  color: ${theme.banner.error.color};
`;
