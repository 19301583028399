/**
 * Convert number to distance
 * @param distance - The distance to format
 * @param unit - The distance unit
 * @returns A well formatted distance string
 */
export function toDistance(
  distance: number | string,
  unit: "km" | "m"
): string {
  const value = Number(distance);
  return `${round(value)}${unit.toUpperCase()}`;
}

/**
 * Convert number to currency
 * @param distance - The amount to format
 * @param currency - The currency unit
 * @returns A well formatted currency string
 */
export function toCurrency(amount: number | string, currency: "$"): string {
  const value = Number(amount);
  return `${round(value)}${currency.toUpperCase()}`;
}

/**
 * Round a number up
 * @param value - The value to round
 * @returns A rounded value
 */
export function round(value: number): number {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

/**
 * Get tax amount from a price
 * @param value - The initial price
 * @param tax - The tax number
 * @returns The tax
 */
export function getTax(price: number, tax: number): number {
  return (price * tax) / 100;
}

/**
 * Format phone number
 * @param rawNumber - The phone Number
 * @returns The tax
 */
export function formatPhoneNumber(rawNumber: string): string {
  return `+1${rawNumber.replace(/[^0-9.]/g, "")}`;
}
