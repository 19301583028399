import { stringify } from "query-string";
import config from "../../config";
import { useQuery } from "react-query";
import axios from "redaxios";
import { TTag } from "../../types//Tag";
type Params = Record<string, string | number | boolean | null | undefined>;

const apiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/tags`;

/**
 * Fetch tags
 * @param locale - The locale of the authenticated user
 * @returns The featured products list
 */
export const useTags = (locale?: string) => {
  const params: Params = {};

  if (locale) {
    params.locale = locale;
  }

  return useQuery<TTag[]>(["useTags"], () =>
    axios
      .get(`${apiUrl}?${stringify(params)}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data.payload),
  );
};
