import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { icons } from "../../assets/icons";
import { AlternateSection } from "../../components/AlternateSection";
import { Label } from "../../components/Label";
import { Loading } from "../../components/Loading";
import { Page } from "../../components/Page";
import { Product as ProductCell } from "../../components/Product";
import { theme } from "../../components/theme";
import { useProcessingOrders } from "../../hooks/Orders";
import { getProduct, getProductsByCategory } from "../../hooks/Products";
import { TProduct } from "../../types/Product";
import { Card } from "./Card";

interface LocationState {
  prevPath?: string;
}

export const Product: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { productId } = useParams<{ productId: string }>();

  const [product, setProduct] = useState<TProduct>();
  const [products, setProducts] = useState<TProduct[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCat, setIsLoadingCat] = useState(false);

  const { data: order } = useProcessingOrders();
  const handleBack = () => {
    return history.goBack();
  };

  useEffect(() => {
    const fetCat = async (cid: string) => {
      setIsLoading(true);
      const result = await getProductsByCategory(cid, 0, "fr");
      setProducts(result);
      setIsLoading(false);
    };

    const fetchProduct = async () => {
      setIsLoadingCat(true);
      const result = await getProduct(productId);
      setProduct(result);
      setIsLoadingCat(false);
    };
    if (!product || product.pid !== productId) fetchProduct();
    if (product?.category?.cid) fetCat(product?.category.cid);
  }, [product, productId]);

  const handleProductSelected = (product: TProduct) => () =>
    history.push({ pathname: `/products/${product.pid}` });

  const productInCart = order?.products?.find(
    (p: TProduct) => p.pid === product?.pid
  );
  const otherProducts =
    products && products.length > 0
      ? products
          .filter((p) => p.pid !== product?.pid)
          .slice(0, Math.min(4, products.length))
      : [];
  return (
    <Page centerX centerY backgroundColor={theme.colors.base.background}>
      <AlternateSectionStyled>
        <ContentWrapper>
          <BackButton onClick={handleBack}>
            <icons.arrowTop />
            <BackLabel size="mdx" weight="bold">
              {t("Common.action.back")}
            </BackLabel>
          </BackButton>
          {product && (
            <Card
              showQuantityPicker={true}
              key={product.id}
              product={product}
              quantity={productInCart ? productInCart.quantity : 0}
              order={order}
            />
          )}
          {otherProducts.length > 0 && (
            <>
              <OtherProductsSection>
                <SectionLabel size="xl" weight="black">
                  {t("Product.same.alleys")}
                </SectionLabel>
                <ProductsWrapper>
                  {otherProducts.map((p) => {
                    return (
                      <ProductCell
                        isPreOrder={order?.status === "open" ? false : true}
                        withBanner={true}
                        key={p.pid}
                        product={p}
                        order={order}
                        onSelectProduct={handleProductSelected(p)}
                      />
                    );
                  })}
                </ProductsWrapper>
              </OtherProductsSection>
              <SecondaryBackButton onClick={handleBack}>
                <icons.arrowTop />
                <BackLabel size="mdx" weight="bold">
                  {t("Common.action.back")}
                </BackLabel>
              </SecondaryBackButton>
            </>
          )}
        </ContentWrapper>
        {isLoading && <Loading />}
        {isLoadingCat && <Loading />}
      </AlternateSectionStyled>
    </Page>
  );
};

const AlternateSectionStyled = styled(AlternateSection)`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing[2]} 0 ${theme.spacing[5]} 0;

  @media (min-width: ${theme.breakpoints.md}) {
    max-width: 100%;
  }
`;

const ContentWrapper = styled(AlternateSection)`
  display: flex;
  flex-direction: column;
  padding-top: 0;
`;

const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    margin-right: ${theme.spacing[1]};
    fill: ${theme.colors.base.primary};
    transform: rotate(-90deg);
  }

  &:hover {
    svg {
      fill: ${theme.colors.base.dark};
    }

    div {
      color: ${theme.colors.base.dark};
    }
  }
`;

const BackLabel = styled(Label)`
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.primary};
`;

const OtherProductsSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionLabel = styled(Label)`
  margin-top: ${theme.spacing[4]};
  line-height: ${theme.font.size.xxl};
  color: ${theme.colors.base.secondary};
  text-align: center;

  @media (min-width: ${theme.breakpoints.sm}) {
    text-align: left;
  }
`;

const ProductsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${theme.spacing[0.5]};
  align-self: center;
  margin-top: ${theme.spacing[1]};

  @media (min-width: ${theme.breakpoints.sm}) {
    width: calc(2 * 256px + 20px);
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${theme.breakpoints.md}) {
    width: calc(3 * 256px + 2 * 20px);
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${theme.breakpoints.lg}) {
    width: calc(4 * 256px + 3 * 20px);
    grid-template-columns: repeat(4, 1fr);
  }
`;

const SecondaryBackButton = styled(BackButton)`
  display: flex;
  margin-top: ${theme.spacing[2]};

  @media (min-width: ${theme.breakpoints.sm}) {
    display: none;
  }
`;
