import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { getNavigatorLocale } from "./AppServices";
import { StateType, ActionType } from "./AppContextType";
import { TError } from "../types/Error";
import { TPlan } from "../types/Plan";
const client = new QueryClient();

const initialState: StateType = {
  token: null,
  locale: localStorage.getItem("locale") || getNavigatorLocale(navigator),
  errors: [] as TError[],
  plans: [] as TPlan[],
};

export const DispatchContext = React.createContext(
  (() => {}) as React.Dispatch<ActionType>
);
export const StateContext = React.createContext(initialState);

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <QueryClientProvider client={client}>
      <DispatchContext.Provider value={dispatch}>
        <StateContext.Provider value={state}>{children}</StateContext.Provider>
      </DispatchContext.Provider>
    </QueryClientProvider>
  );
};

export const useAppDispatch = () => {
  return React.useContext(DispatchContext);
};

export const useAppState = () => {
  return React.useContext(StateContext);
};

const reducer = (
  state: StateType = initialState,
  action: ActionType
): StateType => {
  switch (action.type) {
    case "GET_TOKEN_FROM_STORAGE":
      const token = localStorage.getItem("token");
      if (token) {
        return {
          ...state,
          token: localStorage.getItem("token"),
        };
      }
      return {
        ...state,
      };
    case "SET_TOKEN":
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        token: action.payload.token,
      };
    case "DELETE_TOKEN":
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
      };
    case "SET_LOCALE":
      localStorage.setItem("locale", action.payload.locale);
      return {
        ...state,
        locale: action.payload.locale,
      };
    case "SET_ERRORS":
      return {
        ...state,
        errors: action.payload.errors,
      };
    case "ADD_ERROR":
      return {
        ...state,
        errors: [...state.errors, action.payload.error],
      };
    case "EMPTY_ERRORS":
      return {
        ...state,
        errors: [],
      };
    case "SET_PLANS":
      return {
        ...state,
        plans: [...action.payload.plans],
      };
    default:
      return state;
  }
};
