import React from "react";
import ReactDOM from "react-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import * as serviceWorker from "./serviceWorker";
import ReactGa from "react-ga";
import { App } from "./components/App";
import {
  AppContextProvider,
  DeliveryContextProvider,
  TagContextProvider,
} from "./contexts";
import { Routes } from "./routes";
import config from "./config";
import "./reset.css";
import "./index.css";
import "react-day-picker/lib/style.css";
import { InstantSearch } from "react-instantsearch-hooks-web";
import algoliasearch from "algoliasearch/lite";

const algoliaClient = algoliasearch(
  config.ALGOLIA.APPLICATION_ID,
  config.ALGOLIA.PUBLIC_KEY
);
const searchClient = {
  ...algoliaClient,
  search(requests: any) {
    //@ts-ignore
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

const stripePromise = loadStripe(`${config.STRIPE.PUBLIC_KEY || ""}`);
ReactGa.initialize("GTM-5C94B57");
const app = (
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <InstantSearch
        indexName={config.ALGOLIA.INDEX_NAME}
        //@ts-ignore
        searchClient={searchClient}
      >
        <AppContextProvider>
          <TagContextProvider>
            <DeliveryContextProvider>
              <Routes>
                <App />
              </Routes>
            </DeliveryContextProvider>
          </TagContextProvider>
        </AppContextProvider>
      </InstantSearch>
    </Elements>
  </React.StrictMode>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
