import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';
import { Heading } from '../Heading';
import { Image } from '../Image';
import { Section } from '../Section';
import { theme } from '../theme';

interface Props {
  title: {
    label: string;
    element?: 'h1' | 'h2' | 'h3';
  };
  action?: {
    label: string;
    to: string;
  }
}

export const EmptyState: React.FC<Props> = ({ title, action }) => {
  const { t } = useTranslation();

  return (
    <Section>
      <SectionContentStyled>
        <HeadingStyled
          size="lg"
          element={title.element ? title.element : 'h2'}
        >
          {title.label}
        </HeadingStyled>
        {action && <Button to={action.to}>{action.label}</Button>}
        <ImageStyled>
          <Image
            path="/common/images/empty-state"
            alt={t('EmptyState.Image.alt')}
          />
        </ImageStyled>
      </SectionContentStyled>
    </Section>
  );
}

const SectionContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeadingStyled = styled(Heading)`
  margin-bottom: ${theme.spacing[2]};
`;

const ImageStyled = styled.div`
  @media (min-width: ${theme.breakpoints.md}) {
    max-width: 60%;
  }
`;
