import React from "react";
import { TPlan, TPlanQuantity } from "../../types/Plan";
import { Popup } from "../Popup";
import { SelectQuantity } from "./SelectQuantity";

interface Props {
  plan: TPlan;
  quantity: TPlanQuantity;
  onValidate: (persons: TPlanQuantity) => void;
  onClose: () => void;
}

export const QuantityPopup: React.FC<Props> = ({
  plan,
  quantity,
  onValidate,
  onClose,
}) => {
  return (
    <Popup visible={true} onClose={onClose}>
      <SelectQuantity
        plan={plan}
        quantity={quantity}
        onValidate={onValidate}
        onBack={onClose}
      />
    </Popup>
  );
};
