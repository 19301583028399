import { isMonday, isThursday, subDays } from "date-fns";
import { isToday, isWednesday } from "date-fns/esm";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { Label } from "../../../components/Label";
import { Text } from "../../../components/Text";
import { theme } from "../../../components/theme";
import config from "../../../config";
import { useAppState } from "../../../contexts";
import { useNextDeliveries } from "../../../hooks/NextDeliveries";
import { useProcessingOrders, useUpdateStatus } from "../../../hooks/Orders";
import { formatDate } from "../../../utils/i18n";

export const OrderStatus: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const { data: order, refetch: refetchOrder } = useProcessingOrders();
  const { data: nextDelivery, refetch: refetchNextDelivery } =
    useNextDeliveries();
  const {
    mutate: mutateStatus,
    isLoading: isLoadingStatus,
    isSuccess: isSuccessStatus,
  } = useMutation(useUpdateStatus);

  useEffect(() => {
    refetchOrder();
    refetchNextDelivery();
  }, [isSuccessStatus]);

  const onUnskipHandler = () => {
    if (order && nextDelivery) {
      mutateStatus({ oid: order?.oid, status: "open" });
    }
  };

  let title = "";
  let comment = "";
  let date;
  let validCondition = false;
  const deliveryDate = order?.delivery_date || nextDelivery?.next_delivery_date;
  const processingDate =
    order?.processing_date || nextDelivery?.next_processing_date;
  if (order?.status === "cancel") {
    title = t("Dashboard.order.cancel");
    comment = t("Dashboard.order.cancel.content");
    validCondition = true;
  } else if (
    order?.status === "paid" &&
    deliveryDate &&
    processingDate &&
    isToday(new Date(processingDate))
  ) {
    title = t("Dashboard.next.delivery");
    date = formatDate(locale, new Date(deliveryDate));
    comment = t("Dashboard.order.billed.today");
    validCondition = true;
  } else if (
    order?.status === "paid" &&
    deliveryDate &&
    isToday(new Date(deliveryDate))
  ) {
    title = t("Dashboard.next.delivery");
    date = formatDate(locale, new Date(deliveryDate));
    comment = t("Dashboard.order.delivery.today");
    validCondition = true;
  } else if ((!order || order.status === "skipped") && nextDelivery) {
    const nextDeliveryDate = new Date(nextDelivery.next_delivery_date);
    const skipDate = subDays(nextDeliveryDate, config.OPENING_TIME_DAYS);

    title = t("Dashboard.next.delivery");
    date = formatDate(locale, nextDeliveryDate);

    validCondition = true;
    if (isMonday(new Date()) && isWednesday(nextDeliveryDate)) {
      comment = t("Dashboard.come.monday", {
        date: formatDate(
          locale,
          order?.status === "skipped" ? skipDate : new Date()
        ),
      });
    } else if (isThursday(nextDeliveryDate)) {
      comment = t("Dashboard.come.monday", {
        date: formatDate(locale, new Date(skipDate)),
      });
    } else if (isMonday(nextDeliveryDate)) {
      comment = t("Dashboard.come.friday", {
        date: formatDate(locale, new Date(skipDate)),
      });
    } else {
      comment = t("Dashboard.come.other", {
        date: formatDate(locale, new Date(skipDate)),
      });
    }
  } else if (order && deliveryDate && processingDate) {
    title = t("Dashboard.next.delivery");
    date = formatDate(locale, new Date(deliveryDate));
    comment = t("Dashboard.order.open.date", {
      date: formatDate(locale, new Date(subDays(new Date(processingDate), 1))),
    });
    validCondition = true;
  }

  return (
    <>
      {validCondition && (
        <StatusWrapper>
          <StatusTitle size="xxl" weight="black">
            {title}
          </StatusTitle>
          {date && (
            <DateLabel size="xl" weight="black">
              {date}
            </DateLabel>
          )}
          <CommentText size="lg" weight="black">
            {comment}
          </CommentText>
          {order && order.status === "skipped" && nextDelivery && (
            <ButtonWrapper>
              <Button
                isLoading={isLoadingStatus}
                onClick={onUnskipHandler}
                primary
              >
                {t("Deliveries.unskip")}
              </Button>
            </ButtonWrapper>
          )}
        </StatusWrapper>
      )}
    </>
  );
};

const StatusWrapper = styled.div``;

const StatusTitle = styled(Label)`
  font-size: ${theme.font.size.xl};
  line-height: ${theme.font.size.xxl};
  font-family: ${theme.font.family.secondary};
  text-align: center;
  color: ${theme.colors.base.secondary};

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.xxl};
    line-height: ${theme.font.size.xxl};
    text-align: left;
  }
`;

const DateLabel = styled(Label)`
  font-size: ${theme.font.size.mdx};
  line-height: ${theme.font.size.lg};
  font-family: ${theme.font.family.secondary};
  text-align: center;
  color: ${theme.colors.base.primary};

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.xl};
    line-height: ${theme.font.size.xxl};
    text-align: left;
  }
`;

const CommentText = styled(Text)`
  margin-top: ${theme.spacing[1]};
  font-size: ${theme.font.size.mdx};
  line-height: ${theme.font.size.lg};
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.black};
  text-align: center;
  color: ${theme.colors.base.secondary};

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.xl};
    line-height: ${theme.font.size.xxl};
    text-align: left;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: ${theme.spacing[1]};
`;
