import { TTimeSlot } from "./TimeSlot";
export enum TFrequenceEnum {
  WEEKLY = 7,
  BI_WEEKLY = 14,
}
export enum TParity {
  ODD = "ODD",
  EVEN = "EVEN",
}
export type TPickupLocation = {
  id: number;
  ppid: string;
  name: string;
  address: string;
  address2?: string;
  city: string;
  province: string;
  zip_code: string;
  country: string;
  lat: number;
  lng: number;
  max_customers: number;
  password: string;
  open: boolean;
  phone?: string;
  note?: string;
  distance?: string;
  availability: number;
  timeslots?: TTimeSlot[];
  frequence: TFrequenceEnum;
  parity_week: TParity;
};

export type TUserPup = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  uid: string;
  id: number;
  subscription_status: string;
  pup_name: string;
  open: string;
  day: 0 | 1 | 2 | 3 | 4 | 5 | 6;
};
