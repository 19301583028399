import React from "react";
import styled from "styled-components";
import config from "../../config";
import { Heading } from "../Heading";
import { Section } from "../Section";
import { theme } from "../theme";
import { Text } from "../Text";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";

export const CollectPoints: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SectionStyled size="lg" backgroundColor={theme.colors.base.background}>
      <Wrapper>
        <WrapperText>
          <HeadingStyled size="lg">
            {t("About.CollectsPoint.title")}
          </HeadingStyled>
          <TextStyled size="md">
            <span> {t("About.CollectsPoint.text1")}</span>
            <span>{t("About.CollectsPoint.text2")}</span>
          </TextStyled>
          <ButtonStyled>
            <Button to="/becoming-pup">{t("About.Testimonial.cta1")}</Button>
          </ButtonStyled>
        </WrapperText>

        <GifStyled>
          <source
            srcSet={`${config.CDN_URL}/common/images/about-us-collect-point.webp`}
            type="image/webp"
          />
          <source
            srcSet={`${config.CDN_URL}/common/images/about-us-collect-point.jpg`}
            type="image/jpg"
          />
          <img
            src={`${config.CDN_URL}/common/images/about-us-collect-point.jpg`}
            alt="Placeholder"
            loading="lazy"
          />
        </GifStyled>
      </Wrapper>
    </SectionStyled>
  );
};

const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing[2]};
  flex: 1;

  @media (min-width: ${theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[2]};
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding-bottom: ${theme.spacing[2]};
  @media (max-width: ${theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[2]};
  }
`;
const SectionStyled = styled(Section)`
  padding-top: 0;
  padding-bottom: 0;
`;

const TextStyled = styled(Text)`
  color: ${theme.colors.base.secondary};
`;
const HeadingStyled = styled(Heading)`
  color: ${theme.colors.base.primary};
  padding: 0;
`;

const ButtonStyled = styled.div``;

const GifStyled = styled.picture`
  img,
  source {
    max-width: 100%;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    display: flex;
    margin-left: ${theme.spacing[4]};
  }

  flex: 1;
`;
