import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSubscription } from "../../hooks/Subscriptions";
import { Button } from "../../components/Button";
import { Text } from "../../components/Text";
import { theme } from "../../components/theme";
import { Card } from "./Card";
import { Heading } from "../../components/Heading";
import { useAppState } from "../../contexts";
import { formatDate } from "../../utils/i18n";
import ReactHtmlParser from "react-html-parser";
import { SuspendPopup } from "../Account/SuspendPopup";

interface Props {
  onSelect: (lookup_key: string) => void;
}

export const PrimeSelection: React.FC<Props> = ({ onSelect }) => {
  const { t } = useTranslation();
  const { locale } = useAppState();

  const { data: subscription } = useSubscription();
  const [selection, setSelection] = useState("yearly-prime");
  const [primes, setPrime] = useState([
    // {
    //   lookup_key: "monthly-prime", // stripe name
    //   selected: false,
    // },
    {
      lookup_key: "yearly-prime", // stripe name
      selected: true,
    },
  ]);

  const lookupKeys = {
    year: "yearly-prime",
    month: "monthly-prime",
  };
  const [showPopup, setShowPopup] = useState(false);

  const onToggleSuspend = () => {
    setShowPopup(!showPopup);
  };
  return (
    <SummaryContainer>
      <Heading center size="lg">
        {t("Prime.subscribe")}
      </Heading>

      <ColumnWrapper>
        {subscription && !subscription?.is_prime && (
          <TextWrapper>
            <TextStyled size="mdx" element="p">
              {ReactHtmlParser(t("Prime.status.inactive"))}
            </TextStyled>
          </TextWrapper>
        )}
        {showPopup && (
          <SuspendPopup
            message={t("Prime.suspend.message")}
            title={t("Prime.suspend.title")}
            onClose={onToggleSuspend}
          />
        )}

        {!subscription?.is_prime &&
          primes.map((prime) => (
            <Card
              key={prime.lookup_key}
              isActive={prime.selected}
              lookup_key={prime.lookup_key}
              onSelect={(lookup_key) => {
                const primeSelected = primes.map((prime) => {
                  if (prime.lookup_key === lookup_key) {
                    return { ...prime, selected: true };
                  } else {
                    return { ...prime, selected: false };
                  }
                });
                onSelect(lookup_key);
                setSelection(lookup_key);
                setPrime(primeSelected);
              }}
            />
          ))}
        {subscription?.is_prime && (
          <>
            <TextWrapper>
              <TextStyled size="mdx" element="p">
                {ReactHtmlParser(t("Prime.status.actif"))}{" "}
                <LabelStyled onClick={onToggleSuspend}>
                  ({t("Prime.status.modify")})
                </LabelStyled>
              </TextStyled>
              {subscription?.prime_current_period_end && (
                <TextStyled size="mdx" element="p">
                  {t("Prime.subscription.end", {
                    date: formatDate(
                      locale,
                      new Date(subscription?.prime_current_period_end)
                    ),
                  })}
                </TextStyled>
              )}
            </TextWrapper>
            <Card
              key={subscription.prime_interval}
              isActive={true}
              lookup_key={lookupKeys[subscription?.prime_interval]}
              onSelect={(lookup_key) => {}}
            />
          </>
        )}

        {!subscription?.is_prime && (
          <Button
            to={`/adhesions/signup/${selection}`}
            size="md"
            center
            secondary
          >
            {t(`Prime.continue`)}
          </Button>
        )}
      </ColumnWrapper>
    </SummaryContainer>
  );
};

const TextWrapper = styled.div`
  background-color: ${theme.colors.accent.secondary};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: ${theme.spacing[0.5]};
  text-align: center;
`;
const TextStyled = styled(Text)`
  color: ${theme.colors.base.dark};
`;
const LabelStyled = styled.a`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  cursor: pointer;
`;
const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: ${theme.spacing[1.5]} ${theme.spacing[1]};
  background: ${theme.colors.base.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  @media (min-width: ${theme.breakpoints.sm}) {
    min-height: 243px;
  }
  flex-direction: column;
  gap: ${theme.spacing[1.5]};
`;
