/**
 * Format a timeslot
 * @param day - The day of the week (starts at 0)
 * @param start_at - The distance unit
 * @param end_at - The distance unit
 * @param locale - The locale
 * @param format - The format (short or long)
 * @returns A well formatted timeslot string
 */
export function formatTimeslot(day: number, startAt: string, endAt: string, locale: string, format: 'short' | 'long'): string {
  const startAtHours = parseInt(startAt.substring(0, 2), 10);
  const startMinutes = parseInt(startAt.substring(3, 5), 10);

  const endAtHours = parseInt(endAt.substring(0, 2), 10);
  const endMinutes = parseInt(endAt.substring(3, 5), 10);

  const date = new Date();
  const currentDay = date.getDay();
  const distance = (day + 7 - currentDay) % 7;
  date.setDate(date.getDate() + distance);

  const startDateParts = new Intl.DateTimeFormat(locale, {
    weekday: format,
    hour: 'numeric',
    second: 'numeric',
    minute: 'numeric',
  }).formatToParts(date.setHours(startAtHours, startMinutes, 0));

  const endDateParts = new Intl.DateTimeFormat(locale, {
    weekday: format,
    hour: 'numeric',
    second: 'numeric',
    minute: 'numeric',
  }).formatToParts(date.setHours(endAtHours, endMinutes, 0));

  const weekday = startDateParts.filter((p) => p.type === 'weekday');
  const startAthour = startDateParts.filter((p) => p.type === 'hour');
  const startAtMinute = startDateParts.filter((p) => p.type === 'minute');
  const startAtDayPeriod = startDateParts.filter((p) => p.type === 'dayPeriod');

  const endAthour = endDateParts.filter((p) => p.type === 'hour');
  const endAtMinute = endDateParts.filter((p) => p.type === 'minute');
  const endAtDayPeriod = endDateParts.filter((p) => p.type === 'dayPeriod');

  if (locale === 'en-CA') {
    const dateEn = `${weekday[0].value}  ${startAthour[0].value}:${startAtMinute[0].value} ${startAtDayPeriod[0].value.toLocaleUpperCase()} — ${
      endAthour[0].value
    }:${endAtMinute[0].value} ${endAtDayPeriod[0].value.toLocaleUpperCase()}`;
    return dateEn.charAt(0).toUpperCase() + dateEn.slice(1);
  }

  const dateFr = `${weekday[0].value}  ${startAthour[0].value}:${startAtMinute[0].value} — ${endAthour[0].value}:${endAtMinute[0].value}`;
  return dateFr.charAt(0).toUpperCase() + dateFr.slice(1);
}

/**
 * Format a timeslot day
 * @param day - The day of the week (starts at 0)
 * @param locale - The locale
 * @returns A well formatted timeslot day string
 */
export function formatTimeslotDay(day: number, locale: string, format: 'short' | 'long'): string {
  const date = new Date();
  const currentDay = date.getDay();
  const distance = (day + 7 - currentDay) % 7;
  date.setDate(date.getDate() + distance);

  const dateParts = new Intl.DateTimeFormat(locale, { weekday: format }).formatToParts(date);

  const weekday = dateParts.filter((p) => p.type === 'weekday');
  return `${weekday[0].value.charAt(0).toUpperCase()}${weekday[0].value.slice(1)} `;
}

/**
 * Format a timeslot hours
 * @param day - The day of the week (starts at 0)
 * @param start_at - The distance unit
 * @param end_at - The distance unit
 * @param locale - The locale
 * @param format - The format (short or long)
 * @returns A well formatted timeslot hours string
 */
export function formatTimeslotHours(day: number, startAt: string, endAt: string, locale: string, format: 'short' | 'long'): string {
  const startAtHours = parseInt(startAt.substring(0, 2), 10);
  const startMinutes = parseInt(startAt.substring(3, 5), 10);

  const endAtHours = parseInt(endAt.substring(0, 2), 10);
  const endMinutes = parseInt(endAt.substring(3, 5), 10);

  const date = new Date();
  const currentDay = date.getDay();
  const distance = (day + 7 - currentDay) % 7;
  date.setDate(date.getDate() + distance);

  const startDateParts = new Intl.DateTimeFormat(locale, {
    weekday: format,
    hour: 'numeric',
    second: 'numeric',
    minute: 'numeric',
  }).formatToParts(date.setHours(startAtHours, startMinutes, 0));

  const endDateParts = new Intl.DateTimeFormat(locale, {
    weekday: format,
    hour: 'numeric',
    second: 'numeric',
    minute: 'numeric',
  }).formatToParts(date.setHours(endAtHours, endMinutes, 0));

  const startAthour = startDateParts.filter((p) => p.type === 'hour');
  const startAtMinute = startDateParts.filter((p) => p.type === 'minute');
  const startAtDayPeriod = startDateParts.filter((p) => p.type === 'dayPeriod');

  const endAthour = endDateParts.filter((p) => p.type === 'hour');
  const endAtMinute = endDateParts.filter((p) => p.type === 'minute');
  const endAtDayPeriod = endDateParts.filter((p) => p.type === 'dayPeriod');

  if (locale === 'en-CA') {
    const dateEn = `${startAthour[0].value}${format === "long" ? `:${startAtMinute[0].value}` : ''} ${startAtDayPeriod[0].value.toLocaleUpperCase()} — ${endAthour[0].value}${format === "long" ? `:${endAtMinute[0].value}` : ''} ${endAtDayPeriod[0].value.toLocaleUpperCase()}`;
    return dateEn.charAt(0).toUpperCase() + dateEn.slice(1);
  }

  const dateFr = `${startAthour[0].value}${format === "long" ? `:${startAtMinute[0].value}` : ''} — ${endAthour[0].value}:${endAtMinute[0].value}`;
  return dateFr.charAt(0).toUpperCase() + dateFr.slice(1);
}