// @ts-nocheck
import React from "react";
import { NavHashLink } from "react-router-hash-link";
import { NavLink as RouterLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { theme } from "../theme";

export type TextTags = "p" | "div" | "span";
export type TextSizes = "sm" | "md" | "mdx" | "lg" | "xl";
export type TextWeight = "light" | "normal" | "bold";

interface Props {
  href?: string;
  to?: string;
  onClick?: () => void;
  isHash?: boolean;
  size?: TextSizes;
  weight?: TextWeight;
  target?: string;
  direction?: "left" | "right";
  className?: string;
}

const Link: React.FC<Props> = ({
  href,
  to,
  onClick,
  isHash = false,
  size = "md",
  weight = "normal",
  target,
  direction,
  className,
  children,
}) => {
  if (isHash && to) {
    return (
      <NavHashLink
        className={className}
        to={to}
        onClick={onClick}
        activeClassName="active"
        exact
        tabIndex={0}
      >
        {children}
      </NavHashLink>
    );
  }

  return to ? (
    <RouterLink
      className={className}
      to={to}
      onClick={onClick}
      activeClassName="active"
      exact
      target={target}
      tabIndex={0}
    >
      {children}
    </RouterLink>
  ) : (
    <a
      className={className}
      href={href}
      onClick={onClick}
      target={target}
      tabIndex={0}
    >
      {children}
    </a>
  );
};

export const LinkStyled = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: ${theme.font.size.md};
  line-height: ${theme.font.size.xl};
  cursor: pointer;

  &:hover {
    color: ${theme.colors.base.primary};
  }

  &:not(.nostate).active {
    color: ${theme.colors.base.dark};
  }

  &:focus {
    outline-offset: ${theme.spacing[0.25]};
  }

  ${(props) =>
    props.size === "sm" &&
    css`
      font-size: ${theme.font.size.sm};
      line-height: ${theme.font.size.lg};
    `}

  ${(props) =>
    props.size === "mdx" &&
    css`
      font-size: ${theme.font.size.mdx};
      line-height: ${theme.font.size.xl};
    `}

  ${(props) =>
    props.size === "lg" &&
    css`
      font-size: ${theme.font.size.lg};
      line-height: ${theme.font.size.xxl};
    `}

  ${(props) =>
    props.size === "xl" &&
    css`
      font-size: ${theme.font.size.xl};
      line-height: ${theme.font.size.xxxl};
    `}

  ${(props) =>
    props.weight === "light" &&
    css`
      font-weight: ${theme.font.weight.light};
    `}

  ${(props) =>
    props.weight === "bold" &&
    css`
      font-weight: ${theme.font.weight.bold};
    `}

  img,
  svg {
    width: 12px;
    height: 12px;
    margin-right: ${theme.spacing[0.5]};
    fill: currentColor;
  }

  ${(props) =>
    props.direction === "left" &&
    css`
      img,
      svg {
        margin-right: ${theme.spacing[0.5]};
      }
    `}

  ${(props) =>
    props.direction === "right" &&
    css`
      img,
      svg {
        margin-left: ${theme.spacing[0.5]};
      }
    `}
`;

export const LinkMemoized = React.memo(LinkStyled);
