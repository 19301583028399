import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import styled from "styled-components";
import { useUpdateFrequency } from "../../hooks/NextDeliveries";
import { TFrequenceEnum } from "../../types/PickupPoint";
import { TSubscriptionComplete } from "../../types/Subscription";
import { FormTagRadio } from "../FormTagRadio";
import { SelectOption } from "../FormTagRadio/FormTagRadio";
import { Label } from "../Label";
import { theme } from "../theme";
import { AddressForm } from "./AddressForm";
import { MapSection } from "./MapSection";

interface Props {
  isSignup: boolean;
  subscription?: TSubscriptionComplete;
  onDone: () => void;
}

const DeliveryModes = {
  COLLECT: "PUP",
  HOUSE: "LAD",
};

export const Delivery: React.FC<Props> = ({
  isSignup,
  subscription,
  onDone,
}) => {
  const { t } = useTranslation();
  const { mutate: mutateFrequency } = useMutation(useUpdateFrequency);
  const [deliveryMode, setDeliveryMode] = useState<string>(
    subscription?.delivery?.entity_type || DeliveryModes.HOUSE
  );
  const [frequency, setFrequency] = useState<number>(7);

  const deliveryOptions = [
    {
      label: `${t(`Signup.step3.delivery.collect`)}`,
      complement: `${t(`Signup.step3.delivery.collect.price`)}`,
      value: DeliveryModes.COLLECT,
    },
    {
      label: `${t(`Signup.step3.delivery.house`)}`,
      complement: `${t(`Signup.step3.delivery.house.price`)}`,
      value: DeliveryModes.HOUSE,
    },
  ] as SelectOption[];

  const frequencyOptions = [
    {
      label: t("Plan.frequency.7"),
      value: 7,
    },
    {
      label: t("Plan.frequency.14"),
      value: 14,
    },
  ] as SelectOption[];

  useEffect(() => {
    if (subscription && subscription.delivery) {
      setDeliveryMode(subscription.delivery.entity_type);
      setFrequency(subscription.frequence);
    }
  }, [subscription]);

  const onCancelChanges = () => {
    if (subscription && subscription.delivery) {
      setFrequency(subscription.frequence);
      setDeliveryMode(subscription.delivery.entity_type);
    }
  };

  const onFrequencyChange = (value: number) => {
    setFrequency(value);
  };

  const onHandleDone = () => {
    if (!isSignup) {
      mutateFrequency({ frequency: frequency as TFrequenceEnum });
    }
    onDone();
  };
  return (
    <SectionStyled>
      <RadioSectionStyled>
        <LabelStyled size="mdx">{t("Signup.step3.delivery.label")}</LabelStyled>

        <FormTagRadio
          id="entity_type"
          name="entity_type"
          error
          type={"string"}
          value={deliveryMode}
          options={deliveryOptions}
          onChange={(value) => setDeliveryMode(value as string)}
          register={null}
          validation
        />
      </RadioSectionStyled>
      {/* <SpanStyled>{t("Cart.freeShipping")}</SpanStyled> */}

      <RadioSectionStyled>
        <LabelStyled size="mdx">
          {t("Signup.step3.frequency.label")}
        </LabelStyled>
        <FormTagRadio
          id="entity_type"
          name="entity_type"
          error
          type={"number"}
          value={frequency}
          options={frequencyOptions}
          onChange={(value) => onFrequencyChange(value as number)}
          register={null}
          validation
        />
      </RadioSectionStyled>
      <>
        {deliveryMode === DeliveryModes.COLLECT && (
          <MapSection
            isSignup={isSignup}
            subscription={subscription}
            frequency={frequency as TFrequenceEnum}
            showLocations={true}
            showForm={true}
            onCancelChanges={onCancelChanges}
            onDone={onHandleDone}
          />
        )}
        {deliveryMode === DeliveryModes.HOUSE && (
          <AddressForm
            isSignup={isSignup}
            subscription={subscription}
            frequency={frequency as TFrequenceEnum}
            onCancelChanges={onCancelChanges}
            onDone={onHandleDone}
          />
        )}
      </>
    </SectionStyled>
  );
};

const SectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-top: 2px;
  }
`;

const RadioSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing[1.5]} ${theme.spacing[1]};
  background-color: ${theme.colors.accent.secondary};

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
    align-items: center;
    padding: ${theme.spacing[1.5]} ${theme.spacing[2]};
  }
`;

const LabelStyled = styled(Label)`
  width: 170px;
  margin-right: ${theme.spacing[2]};
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.bold};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.secondary};
`;
