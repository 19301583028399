import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { AlternateSection } from "../../components/AlternateSection";
import { Button } from "../../components/Button";
import { Filter } from "../../components/Filter";
import { FlashBanner } from "../../components/FlashBanner";
import { Heading } from "../../components/Heading";
import { Page } from "../../components/Page";
import { theme } from "../../components/theme";
import { useProcessingOrders } from "../../hooks/Orders";
import { useSubscription } from "../../hooks/Subscriptions";
import { SpecialCategories } from "../../types/Category";
import { Results } from "./Results";
import { Tags } from "./Tags";
import { icons } from "../../assets/icons";
import { Label } from "../../components/Label";
import { SideFilter } from "../../components/SideFilter";
import { Dropdown } from "../../components/Dropdown";

export const Catalog: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const defaultSearchQuery = new URLSearchParams(search).get("search");
  const { data: subscription } = useSubscription();
  const { data: order } = useProcessingOrders();
  const [orderByFilter, setOrderByFilter] = useState("promo")
  const [selectedTag, setSelectedTag] = useState("ALL");
  const [selectedCategory, setSelectedCategory] = useState<string>(
    SpecialCategories.PROMO
  );
  const [searchQuery, setSearchQuery] = useState<any>(
    defaultSearchQuery ? defaultSearchQuery : ""
  );

  useEffect(() => {
    const params = new URLSearchParams(search);
    const category = params.get("category");
    const query = params.get("search");
    const orderBy = params.get("order_by")
    if (category) setSelectedCategory(category);
    setOrderByFilter(orderBy ? orderBy : "promo")
    setSearchQuery(query ? query : "");
  }, [search]);

  const handleFinishSignup = () => history.push("/sign-up/confirm");

  const onTagSelected = (tag: string) => {
    const currentCategories = new URLSearchParams(search).get("category");
    const currentOrder = new URLSearchParams(search).get("order_by");
    const params = new URLSearchParams();
    if (currentCategories) params.set("category", currentCategories);
    if (currentOrder) params.set("order_by", currentOrder);
    params.set("tag", tag);
    setSelectedTag(tag);
    history.push({ pathname: `/products`, search: params.toString() });
  };

  const onOrderBySelected = (orderByFilter: string) => {
    const currentCategories = new URLSearchParams(search).get("category");
    const params = new URLSearchParams();
    if (currentCategories) params.set("category", currentCategories);
    params.set("order_by", orderByFilter);
    history.push({ pathname: "/products", search: params.toString() });
  };

  return (
    <Page centerX centerY backgroundColor={theme.colors.base.background}>
      <AlternateSectionStyledDesktop>
        {subscription?.status !== "active" ? (
          <FlashBanner type={"warning"} times={10}>
            {t("Signup.not.finish")}
            <Spacer />
            <Button thirdReverseTextGreen onClick={handleFinishSignup}>
              {t("Signup.finish.it")}
            </Button>
          </FlashBanner>
        ) : (
          <Separator />
        )}
        <CatalogStyled>
          <SideFilter
            selectedCategory={selectedCategory}
            searchQuery={searchQuery}
            showSearch={true}
            isConnectedUser={true}
          />
          <ResultWrapper>
            <ResultFilterWrapper>
              <Tags selectedTag={selectedTag} onTagSelected={onTagSelected} />
              <div>
                <Dropdown
                  name="Filtre"
                  preLabel={t("Catalog.filter.preLabel")}
                  options={[
                    { label: "Promotions", value: "promo", selected: orderByFilter === "promo" },
                    { label: t("Catalog.filter.productName"), value: "name", selected: orderByFilter === "name" },
                  ]}
                  onSelect={(value) => onOrderBySelected(value.value)}
                />
              </div>
            </ResultFilterWrapper>
            <Results
              isConnectedUser={true}
              order={order}
              selectedTag={selectedTag}
              selectedCategory={selectedCategory}
              searchQuery={searchQuery}
              orderByFilter={orderByFilter}
            />
            {subscription?.status !== "active" && (
              <HeadingStyled size="lg" element="h2" center>
                {t("Not.connected.andmore.products")}
              </HeadingStyled>
            )}
          </ResultWrapper>
        </CatalogStyled>
      </AlternateSectionStyledDesktop>

      <AlternateSectionStyled>
        {subscription?.status !== "active" && (
          <FlashBanner type={"warning"} times={10}>
            {t("Signup.not.finish")}
            <Spacer />
            <Button thirdReverseTextGreen onClick={handleFinishSignup}>
              {t("Signup.finish.it")}
            </Button>
          </FlashBanner>
        )}
        <Filter
          selectedCategory={selectedCategory}
          searchQuery={searchQuery}
          showSearch={true}
          isConnectedUser={true}
          orderByFilter={orderByFilter}
          onOrderBySelected={onOrderBySelected}
        />
        <ContentWrapper>
          <Tags selectedTag={selectedTag} onTagSelected={onTagSelected} />
          <Results
            isConnectedUser={true}
            order={order}
            selectedTag={selectedTag}
            selectedCategory={selectedCategory}
            searchQuery={searchQuery}
            orderByFilter={orderByFilter}
          />
        </ContentWrapper>
      </AlternateSectionStyled>
    </Page>
  );
};

const CatalogStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const FilterContainerMobile = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: ${theme.breakpoints.md}) {
    display: none;
    width: calc(2 * 256px + 20px);
  }

  @media (min-width: ${theme.breakpoints.lg}) {
    width: calc(3 * 256px + 2 * 20px);
  }

  @media (min-width: ${theme.breakpoints.xl}) {
    width: calc(4 * 256px + 3 * 20px);
  }
`;

const ResultWrapper = styled.div`
  padding: ${theme.spacing[2]} 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${theme.breakpoints.md}) {
    width: 100%;
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.colors.accent.secondary};
`;

const ResultFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (min-width: ${theme.breakpoints.md}) {
    width: calc(2 * 256px + 20px);
  }

  @media (min-width: ${theme.breakpoints.lg}) {
    width: calc(3 * 256px + 2 * 20px);
  }

  @media (min-width: ${theme.breakpoints.xl}) {
    width: calc(4 * 256px + 3 * 20px);
  }
`;

const SpecialCategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-bottom: ${theme.spacing[1]};

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
    gap: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

const CategoryLabel = styled(Label)`
  flex: 1;
  margin: 0 ${theme.spacing[1]};
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.secondary};
  white-space: nowrap;
`;

const SpecialCategory = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &.selected-category > div {
    color: ${theme.colors.base.primary};
  }

  &.pointer {
    cursor: pointer;
  }

  svg,
  svg path {
    fill: ${theme.colors.base.primary};
  }

  .arrow {
    display: block;
    transform: rotate(-90deg);

    path {
      fill: ${theme.colors.base.secondary};
    }
  }

  &:hover {
    > div {
      color: ${theme.colors.base.primary};
    }
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    .arrow {
      display: none;
    }
  }
`;

const AlternateSectionStyledDesktop = styled.div`
  display: none;
  flex-direction: column;
  max-width: 100vw;
  width: 100%;

  @media (min-width: ${theme.breakpoints.md}) {
    display: flex;
  }
`;

const AlternateSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  width: 100%;

  @media (min-width: ${theme.breakpoints.md}) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
  width: 100% @media (min-width: ${theme.breakpoints.md}) {
    padding: 2rem;
  }
`;

const Spacer = styled.span`
  padding: 0.5rem;
`;

const HeadingStyled = styled(Heading)`
  padding: 4rem 0rem;
`;
