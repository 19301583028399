const local = {
    STRIPE: {
        PUBLIC_KEY: "pk_test_czKSBdY7UD1XhkXHtCIO3jA2",
    },
    ALGOLIA: {
        PUBLIC_KEY: "ad047e407df9b0e1386790a8b91dd349",
        APPLICATION_ID: "B388G9J0K5",
        INDEX_NAME: "local",
    },
    GOOGLE_MAP_KEY: "AIzaSyDwsbqAYyDRdUeWJqTGenFUz2v_bC8-7Jg",
    SERVICES: {
        MARKETPLACE_API_URL: "http://localhost:8002/api",
        WORKERS_API_URL: "http://localhost:8002/api/api/workers",
    },
    ENV_NAME: "local"
};

const production = {
    STRIPE: {
        PUBLIC_KEY: "pk_live_fWcAjKoOXog1U2jYUs5FKQ9v",
    },
    GOOGLE_MAP_KEY: "AIzaSyDwsbqAYyDRdUeWJqTGenFUz2v_bC8-7Jg",
    SERVICES: {
        MARKETPLACE_API_URL: "/api",
        WORKERS_API_URL: "/api/workers",
    },
    ALGOLIA: {
        PUBLIC_KEY: "ad047e407df9b0e1386790a8b91dd349",
        APPLICATION_ID: "B388G9J0K5",
        INDEX_NAME: "production",
    },
    ENV_NAME: "production"
};

const staging = {
    STRIPE: {
        PUBLIC_KEY: "pk_test_czKSBdY7UD1XhkXHtCIO3jA2",
    },
    ALGOLIA: {
        PUBLIC_KEY: "ad047e407df9b0e1386790a8b91dd349",
        APPLICATION_ID: "B388G9J0K5",
        INDEX_NAME: "local",
    },
    GOOGLE_MAP_KEY: "AIzaSyDwsbqAYyDRdUeWJqTGenFUz2v_bC8-7Jg",
    SERVICES: {
        MARKETPLACE_API_URL: "/api",
        WORKERS_API_URL: "/api/workers",
    },
    ENV_NAME: "staging"
};

let envConfig;

// console.log({ env: window.__RUNTIME_CONFIG__ });
if (window.__RUNTIME_CONFIG__) {
    switch (window.__RUNTIME_CONFIG__ && window.__RUNTIME_CONFIG__.ENV) {
        case "staging": {
            envConfig = staging;
            break;
        }
        case "production": {
            envConfig = production;
            break;
        }
        default: {
            envConfig = local;
            break;
        }
    }
} else {
    envConfig = local;
}

const config = {
    DEFAULT_LOCALE: "fr-CA",
    TIMEZONE: "America/Toronto",
    SENDGRID_FROM: "info@second-life.ca",
    CURRENCY: "CAD",
    OPENING_TIME_DAYS: 3,
    MIN_ORDER_PRICE: 2000,
    FREE_SHIPPING_AT: 7000,
    CDN_URL: "https://second-life-cdn.nyc3.cdn.digitaloceanspaces.com",
    ...envConfig,
};

export default config;
