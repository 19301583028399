import React from "react";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import {
  useCategoriesProducts,
  useHighlightedProducts,
} from "../../hooks/Products";
import { Button } from "../Button";
import { Heading } from "../Heading";
import { Text } from "../Text";
import { theme } from "../theme";
import { CarouselProducts } from "../CarousellProducts/CarouselProducts";

export const Epicerie: React.FC = () => {
  const { t } = useTranslation();
  const {
    data: products,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useCategoriesProducts(8, "is_featured", "ALL", "fr");

  return (
    <SectionStyled>
      {/* <Heading className="heading" element="h1" size="xl">
        {t("Home.Grocerie")}
      </Heading> */}
      <TextContainer>
        <Heading className="slug">{t("Home.StylingBasket")}</Heading>
        <TextStyled size="mdx">
          {t("Home.StylingBasket.Description")}
        </TextStyled>
      </TextContainer>

      {products && (
        <CarouselProducts
          products={products.pages[0].products}
          withBannerProduct={false}
          isPreOrder={false}
        />
      )}
      <div className="button-wrapper">
        <ButtonStyled to="/sign-up/step-1" className="button-centered">
          {t("Home.Grocerie.All")}
        </ButtonStyled>
      </div>
    </SectionStyled>
  );
};

const ButtonStyled = styled(Button)`
  margin-top: 2rem;
  @media (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  max-width: 50rem;
  text-align: center;
  margin: auto;
  padding-bottom: 3rem;
`;

const TextStyled = styled(Text)`
  max-width: 38rem;
  margin: auto;
`;

const SectionStyled = styled.section`
  .slick-dots {
    position: static;
    bottom: 0;
    margin-bottom: 1rem;
  }

  .copy {
    display: flex;
  }

  .heading {
    color: ${theme.colors.base.secondary};
  }

  .slug {
    padding-right: 20px;
    color: ${theme.colors.base.primary};
  }

  .button-wrapper {
    text-align: center;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    .heading {
      text-align: center;
    }

    .copy {
      display: flex;
      text-align: center;
      flex-direction: column;
      margin-bottom: 25px;
    }
  }
`;

export default Epicerie;
