import { TPickupLocation } from "../../../types/PickupPoint";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSpotLeft } from "../../../hooks/PickupPoints";
import { alpha, theme } from "../../theme";

export interface Props {
  id: string;
  pickupPoint: TPickupLocation;
  index: number;
  select: boolean;
  onSelect: (index: number, isOpen: boolean, password: string) => void;
}

export const OptionPUP: React.FC<Props> = ({
  id,
  index,
  select,
  pickupPoint,
  children,
  onSelect,
}) => {
  const { data: spots } = useSpotLeft(pickupPoint.ppid);
  const [isOpen, setIsOpen] = useState(false);

  const onClickHandler = () => {
    onSelect(index, isOpen, pickupPoint.password);
  };

  useEffect(() => {
    if (spots) {
      const spotFound = spots?.find((spot) => spot.spot_left > 0);
      if (spotFound) setIsOpen(true);
    }
  }, [spots]);
  return (
    <OptionStyled
      id={id}
      onClick={onClickHandler}
      role="option"
      tabIndex={0}
      aria-label={select ? "Selected" : "Not selected"}
      key={id}
      className={clsx(select && "active")}
    >
      <OptionContentStyled>
        <Checkmark className={clsx(select && "active")} />
        {children}
      </OptionContentStyled>
    </OptionStyled>
  );
};

const OptionStyled = styled.li`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: ${theme.spacing[0.5]};
  background-color: ${theme.colors.base.light};
  cursor: pointer;

  &:hover,
  &:focus {
    outline: 2px solid ${theme.colors.base.primary};
    outline-offset: -2px;
  }

  &.active {
    border: 2px solid ${theme.colors.base.primary};
  }

  &:first-child {
    margin-top: 0;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-right: ${theme.spacing[1]};
  }
`;

const OptionContentStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: ${theme.spacing[1]};

  @media (max-width: ${theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing[1.5]} ${theme.spacing[1]};
  }
`;

const Checkmark = styled.label`
  position: relative;
  display: block;
  width: ${theme.spacing[1.5]};
  height: ${theme.spacing[1.5]};
  margin-right: ${theme.spacing[1]};
  border-radius: ${theme.border.radius.xxl};
  border: 3px solid ${theme.colors.base.secondary};
  background-color: transparent;
  user-select: none;
  cursor: pointer;

  &:after {
    content: "";
    display: none;
    position: absolute;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid ${theme.colors.base.primary};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &.active {
    border: 3px solid ${theme.colors.base.primary};
  }

  &.active:after {
    display: block;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    position: absolute;
    top: ${theme.spacing[1.5]};
    right: ${theme.spacing[1]};
    margin-right: 0;
  }
`;
