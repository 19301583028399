import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { About } from "./pages/About";
import { Account } from "./pages/Account";
import { Becoming } from "./pages/Becoming";
import { Cart, NextCart } from "./pages/Cart";
import { Confirmation } from "./pages/Confirmation";
import { Dashboard } from "./pages/Dashboard";
import { Help } from "./pages/Help";
import { Home } from "./pages/Home";
import { Mission } from "./pages/Mission";
import { NotFound } from "./pages/NotFound";
import { Privacy } from "./pages/Privacy";
import { Product } from "./pages/Product";
import { ResetPassword } from "./pages/ResetPassword";
import { ResetPasswordRequest } from "./pages/ResetPasswordRequest";
import { SignIn } from "./pages/SignIn";
import {
  SignUpStep1,
  SignUpStep2,
  SignUpStep4,
  SignUpStep3,
  SignUpConfirm,
} from "./pages/SignUp";
import { OrderHistory } from "./pages/OrderHistory";
import { Terms } from "./pages/Terms";
import { ThankYou } from "./pages/ThankYou";
import { WaitingList } from "./pages/WaitingList";
import { Catalog } from "./pages/Catalog";
import { Plans } from "./pages/Plans";
import { UserInfo } from "./pages/UserInfo";
import { Delivery } from "./pages/Delivery";
import { SpecificOrder } from "./pages/SpecificOrder";
import { Prime } from "./pages/Prime";
import { PrimeSignup } from "./pages/PrimeSignup";
import { Msl } from "./pages/Msl";
import { PickUpPoints } from "./components/PickUpPoints";

export const Routes: React.FC = ({ children }) => {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route exact path="/" component={Maintenance} /> */}
        <Route exact path="/" component={Home} />
        <Route exact path="/pick-up-points" component={PickUpPoints} />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/thank-you" component={ThankYou} />
        <Route exact path="/faq" component={Help} />
        <Route exact path="/becoming-pup" component={Becoming} />
        <Route exact path="/waiting-list" component={WaitingList} />{" "}
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms-and-conditions" component={Terms} />{" "}
        <Route exact path="/login" component={SignIn} />
        <Route path="/login/:redirect" component={SignIn} />
        <Route path="/reset/password" component={ResetPassword} />
        <Route path="/request/password" component={ResetPasswordRequest} />
        <Route exact path="/mission" component={Mission} />
        <Route exact path="/msl" component={Msl} />
        <Route exact path="/products" component={Catalog} />
        <Route exact path="/products/:productId" component={Product} />
        <Route path="/auth/confirmation/:token" component={Confirmation} />
        <Route exact path="/sign-up/step-1" component={SignUpStep1} />
        <Route exact path="/sign-up/step-2" component={SignUpStep2} />
        <Route exact path="/sign-up/step-3" component={SignUpStep3} />
        <Route exact path="/sign-up/step-4" component={SignUpStep4} />
        <Route exact path="/sign-up/confirm" component={SignUpConfirm} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/next-cart" component={NextCart} />
        <Route exact path="/account" component={Account} />
        <Route exact path="/account/plans" component={Plans} />
        <Route exact path="/account/info" component={UserInfo} />
        <Route exact path="/account/delivery" component={Delivery} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/order-history" component={OrderHistory} />
        <Route exact path="/order-history/:oid" component={SpecificOrder} />
        <Route exact path="/adhesions" component={Prime} />
        <Route
          exact
          path="/adhesions/signup/:lookup_key"
          component={PrimeSignup}
        />
        <Route component={NotFound} />
      </Switch>
      {children}
    </BrowserRouter>
  );
};
