import React from "react";
import styled, { css } from "styled-components";

import { theme } from "../theme";

export type TextTags = "p" | "div" | "span" | "label";
export type TextSizes =
  | "xxxs"
  | "xxs"
  | "xs"
  | "sm"
  | "md"
  | "mdx"
  | "lg"
  | "xl"
  | "xxl";
export type TextWeight = "normal" | "bold";

interface Props {
  size?: TextSizes;
  weight?: TextWeight;
  center?: boolean;
  element?: TextTags;
  htmlFor?: string;
  children?: React.ReactNode;
  className?: string;
}

const Text: React.FC<Props> = ({
  size = "md",
  weight = "normal",
  center = false,
  element: Element = "p",
  children,
  className,
}) => {
  return <Element className={className}>{children}</Element>;
};

export const TextStyled = styled(Text)`
  font-size: ${theme.font.size.md};
  font-weight: ${theme.font.weight.light};
  line-height: ${theme.font.size.xl};
  color: ${theme.colors.base.dark};
  ${(props) =>
    props.size === "xxxs" &&
    css`
      font-size: ${theme.font.size.xxxs};
      line-height: ${theme.font.size.md};
    `}
  ${(props) =>
    props.size === "xxs" &&
    css`
      font-size: ${theme.font.size.xxs};
      line-height: ${theme.font.size.md};
    `}
  ${(props) =>
    props.size === "xs" &&
    css`
      font-size: ${theme.font.size.xs};
      line-height: ${theme.font.size.md};
    `}
  ${(props) =>
    props.size === "sm" &&
    css`
      font-size: ${theme.font.size.sm};
      line-height: ${theme.font.size.lg};
    `}
    ${(props) =>
    props.size === "mdx" &&
    css`
      font-size: ${theme.font.size.mdx};
      line-height: ${theme.font.size.xl};
    `}
    ${(props) =>
    props.size === "lg" &&
    css`
      font-size: ${theme.font.size.lg};
      line-height: ${theme.font.size.xxl};
    `}
    ${(props) =>
    props.size === "xxl" &&
    css`
      font-size: ${theme.font.size.xxl};
      line-height: ${theme.font.size.xxl};
    `}
    ${(props) =>
    props.size === "xl" &&
    css`
      font-size: ${theme.font.size.xl};
      line-height: ${theme.font.size.xxxl};
    `}
    ${(props) =>
    props.weight === "bold" &&
    css`
      font-weight: ${theme.font.weight.black};
    `}
    ${(props) =>
    props.center &&
    css`
      text-align: center;
    `};
`;

export const TextMemoized = React.memo(TextStyled);
