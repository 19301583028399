import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { theme } from '../theme';

export const Loading: React.FC = () => {
  return (
    <LoadingStyled />
  );
}

const LoadingStyled = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 0 !important;
  background-color: ${rgba(theme.colors.base.light, 0.8)};
  z-index: 1;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid ${theme.colors.base.dark};
    border-color: ${theme.colors.base.dark} transparent ${theme.colors.base.dark} transparent;
    animation: ring 1.2s linear infinite;
  }

  @keyframes ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;