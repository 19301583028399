import { parse, format } from "date-fns";
import config from "../config";

import { enUS, fr } from "date-fns/locale";

export function formatDateShort(input: Date, locale: string): string {
  // Determine the format string dynamically based on locale
  let formatString: string;
  switch (locale) {
    case "en-CA":
      // Use 'do MMMM' for English to get '18th March'
      formatString = "do MMMM";
      return format(input, formatString, { locale: enUS });
    case "fr-CA":
      // Use 'd MMMM' for French to get '18 Mars', no change needed for day suffix
      formatString = "d MMMM";
      return format(input, formatString, { locale: fr });
    default:
      // Default to English format if no locale matches
      formatString = "do MMMM";
      return format(input, formatString);
  }
}

export function formatDate(locale: string, date: Date): string {
  return new Intl.DateTimeFormat(locale, {
    dateStyle: "medium",
  } as Intl.DateTimeFormatOptions).format(date);
}

export function formatShortDate(locale: string, date: Date): string {
  return new Intl.DateTimeFormat(locale, {
    month: "2-digit",
    day: "2-digit",
  }).format(date);
}

export function formatTime(locale: string, date: Date): string {
  return new Intl.DateTimeFormat(locale, {
    timeStyle: "short",
  } as Intl.DateTimeFormatOptions).format(date);
}

export function formatNumber(locale: string, number: number): string {
  return new Intl.NumberFormat(locale, {
    style: "decimal",
  } as Intl.NumberFormatOptions).format(number);
}

export function formatCurrency(locale: string, amount: number): string {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: config.CURRENCY,
  } as Intl.NumberFormatOptions).format(amount);
}

export function formatName(firstName: string, lastName: string): string {
  return `${firstName} ${lastName}`;
}
