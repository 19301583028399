import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { Page } from '../../components/Page';
import { Section } from '../../components/Section';
import { theme } from '../../components/theme';
import { useValidateUser } from '../../hooks/Users';

interface Props {
  match: {
    params: {
      token?: string,
    },
  };
}

export const Confirmation: React.FC<Props> = ({ match }) => {
  const { t } = useTranslation();
  const { token } = match.params;
  const { mutate } = useMutation(useValidateUser);

  useEffect(() => {
    if (token) {
      mutate(token);
    }
  }, [token]);

  return (
    <Page centerX centerY>
      <SectionStyled centerX>
        <Heading element='h1' size='xl'>
          {t(`Confirmation.heading`)}
        </Heading>
        <ButtonStyled to='/products'>{t(`Confirmation.action`)}</ButtonStyled>
      </SectionStyled>
    </Page>
  );
};

const SectionStyled = styled(Section)`
  min-height: 60vh;
`;

const ButtonStyled = styled(Button)`
  margin-top: ${theme.spacing[1.5]};
`;
