import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import styled, { css } from "styled-components";
import { useReactivateSubscription } from "../../hooks/Subscriptions";
import { Button } from "../Button";

import { theme } from "../theme";

interface Props {
  times: number;
  type?: "normal" | "warning" | "info";
}

export const FlashBannerReactivate: React.FC<Props> = ({
  times,
  type,
  children,
}) => {
  const messages = [];
  const { mutate, isSuccess } = useMutation(useReactivateSubscription);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  for (let i = 0; i < times; ++i) {
    messages.push(
      <span aria-hidden={i > 0}>
        {children}
        <SpacerStyled aria-hidden={true} />
      </span>
    );
  }

  useEffect(() => {
    if (isSuccess) {
      queryClient.refetchQueries(["useSubscription"], { active: true });
    }
  }, [isSuccess]);

  return (
    <FlashBannerStyled type={type}>
      <ContentStyled>
        {messages.map((message, index) => (
          <Fragment key={index}>
            <Button onClick={() => mutate()} secondary>
              {t("Subscription.active_subcription")}
            </Button>
            <SpacerStyled aria-hidden={true} />

            {message}
          </Fragment>
        ))}
      </ContentStyled>
    </FlashBannerStyled>
  );
};

const FlashBannerStyled = styled.div<Pick<Props, "type">>`
  width: 100%;
  padding: ${theme.spacing[0.5]};
  background-color: ${theme.colors.accent.primary};
  overflow-x: hidden;

  ${(props) =>
    props.type === "warning" &&
    css`
      background-color: ${theme.colors.accent.primary};
    `}

  ${(props) =>
    props.type === "info" &&
    css`
      background-color: ${theme.colors.accent.primary};
    `}
  ${(props) =>
    props.type === "normal" &&
    css`
      background-color: ${theme.colors.status.info};
    `}
`;

const ContentStyled = styled.p`
  margin-left: -${theme.spacing[3]};
  color: ${theme.colors.base.dark};
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  animation: rtl-moving 25s infinite linear;

  @keyframes rtl-moving {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }
`;

const SpacerStyled = styled.span`
  padding-left: ${theme.spacing[1]};
  padding-right: ${theme.spacing[1]};

  &:before {
    content: "▪";
  }
`;
