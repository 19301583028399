import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Heading } from '../../Heading';
import { Text } from '../../Text';
import { Input } from './Input';
import { Social } from './Social';
import { theme } from '../../theme';

export const Newsletter: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading size="sm">{t(`Footer.newsletter.heading`)}</Heading>
      <TextStyled>{t(`Footer.newsletter.text`)}</TextStyled>
      <Input />
      <SocialStyled>
        <Social />
      </SocialStyled>
    </>
  );
}

const TextStyled = styled(Text)`
  margin-bottom: ${theme.spacing[2]};
`;

const SocialStyled = styled.div`
  margin-top: ${theme.spacing[2]};
`;
