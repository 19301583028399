import { TFrequenceEnum } from "../../../types/PickupPoint";
import clsx from "clsx";
import React from "react";
import styled from "styled-components";
import { alpha, theme } from "../../theme";

export interface Props {
  id: string;
  index: number;
  active: boolean;
  frequence?: TFrequenceEnum;
  onSelect: (index: number, freq?: TFrequenceEnum) => void;
  additionalContent?: React.ReactNode;
}

export const Option: React.FC<Props> = ({
  id,
  index,
  frequence,
  active,
  children,
  onSelect,
  additionalContent,
}) => {
  const onClickHandler = () => {
    onSelect(index, frequence);
  };

  return (
    <OptionStyled
      id={id}
      onClick={onClickHandler}
      role="option"
      tabIndex={0}
      aria-label={active ? "Selected" : "Not selected"}
      key={id}
    >
      <OptionContentStyled>
        <Checkmark className={clsx(active && "active")} />
        {children}
      </OptionContentStyled>

      {active && additionalContent ? (
        <OptionAdditionalStyled>{additionalContent}</OptionAdditionalStyled>
      ) : null}
    </OptionStyled>
  );
};

const OptionStyled = styled.li`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: ${theme.spacing[0.5]};
  margin-right: ${theme.spacing[1]};
  background-color: ${theme.colors.base.light};
  cursor: pointer;

  &:hover,
  &:focus {
    outline: 2px solid ${theme.colors.base.secondary};
    outline-offset: -2px;
  }

  &:first-child {
    margin-top: 0;
  }
`;

const OptionContentStyled = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${theme.spacing[1]};

  & > :nth-child(2) {
    flex: 1;
    padding-right: ${theme.spacing[1]};
  }
`;

const OptionAdditionalStyled = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${theme.spacing[0.5]} ${theme.spacing[3]};
  background-color: ${alpha(theme.colors.base.secondary, theme.transparency.low)};
`;

const Checkmark = styled.label`
  position: relative;
  display: block;
  width: ${theme.spacing[1.5]};
  height: ${theme.spacing[1.5]};
  margin-right: ${theme.spacing[1]};
  border-radius: ${theme.border.radius.xxl};
  border: 3px solid ${theme.colors.accent.secondary};
  background-color: transparent;
  user-select: none;
  cursor: pointer;

  &:after {
    content: "";
    display: none;
    position: absolute;
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid ${theme.colors.base.secondary};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &:hover {
    background-color: ${alpha(theme.colors.base.light, theme.transparency.medium)};
  }

  &.active {
    border-color: ${theme.colors.accent.secondary};
    background-color: ${theme.colors.accent.secondary};
  }

  &.active:after {
    display: block;
  }
`;
