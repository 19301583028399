import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import styled from "styled-components";
import { AlertBanner } from "../../components/AlertBanner";
import { Button } from "../../components/Button";
import { Form } from "../../components/Form";
import { FormControl } from "../../components/FormControl";
import { FormInput } from "../../components/FormInput";
import { FormInputControl } from "../../components/FormInputControl";
import { FormLabel } from "../../components/FormLabel";
import { Hero } from "../../components/Hero";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { Snackbar } from "../../components/Snackbar";
import { TextArea } from "../../components/TextArea";
import { theme } from "../../components/theme";
import { useAppState } from "../../contexts";
import { useBecomePickupPoint } from "../../hooks/PickupPoints";

export declare type TFormBecoming = {
  first_name: string;
  last_name: string;
  email: string;
  zip_code: string;
  phone: string;
  message: string;
};

export const Becoming: React.FC = () => {
  const { t } = useTranslation();
  const methods = useForm();
  const { errors } = methods;
  const { errors: appErrors } = useAppState();

  const { mutate, isSuccess } = useMutation(useBecomePickupPoint);

  const [inputs, setInputs] = useState<TFormBecoming>({
    first_name: "",
    last_name: "",
    email: "",
    zip_code: "",
    phone: "",
    message: "",
  });

  const onSubmitHandler = () => {
    mutate(inputs);
  };

  return (
    <Page centerX centerY>
      <Section>
        <Hero
          image="/common/images/hero-2"
          imageAlt={t("Becoming.Hero.imageAlt")}
          title={t("Becoming.Hero.heading")}
        />
      </Section>
      <Section>
        <FormStyled useForm={methods} onSubmit={onSubmitHandler}>
          <FormControl>
            {appErrors &&
              appErrors.map((error: any, i: number) => (
                <AlertBanner type="error" key={i}>
                  {error.code} : {error.message}
                </AlertBanner>
              ))}
          </FormControl>

          <FormControl columns={1}>
            <FormInputControl
              errors={
                errors.firstName && [
                  t(`Signup.step2.firstName.errors.${errors.firstName.type}`),
                ]
              }
            >
              <FormLabel target="firstName">
                {t("Signup.step2.firstName.label")}
              </FormLabel>
              <FormInput
                id="firstName"
                type="text"
                name="firstName"
                value={inputs.first_name}
                placeholder={t("Signup.step2.firstName.placeholder")}
                error={errors.firstName}
                onChange={(value) =>
                  setInputs({ ...inputs, first_name: value })
                }
                register={methods.register}
                validation={{
                  required: true,
                  minLength: 2,
                }}
              />
            </FormInputControl>

            <FormInputControl
              errors={
                errors.lastName && [
                  t(`Signup.step2.lastName.errors.${errors.lastName.type}`),
                ]
              }
            >
              <FormLabel target="lastName">
                {t("Signup.step2.lastName.label")}
              </FormLabel>
              <FormInput
                id="lastName"
                type="text"
                name="lastName"
                value={inputs.last_name}
                placeholder={t("Signup.step2.lastName.placeholder")}
                error={errors.lastName}
                onChange={(value) => setInputs({ ...inputs, last_name: value })}
                register={methods.register}
                validation={{
                  required: true,
                  minLength: 2,
                }}
              />
            </FormInputControl>
          </FormControl>

          <FormControl columns={1}>
            <FormInputControl
              errors={
                errors.phone && [
                  t(`Signup.step2.phone.errors.${errors.phone.type}`),
                ]
              }
            >
              <FormLabel target="phone">
                {t("Signup.step2.phone.label")}
              </FormLabel>
              <FormInput
                id="phone"
                type="text"
                name="phone"
                value={inputs.phone || ""}
                placeholder={t("Signup.step2.phone.placeholder")}
                error={errors.phone}
                onChange={(value) => setInputs({ ...inputs, phone: value })}
                register={methods.register}
                validation={{
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                }}
              />
            </FormInputControl>
            <FormInputControl
              errors={
                errors.zipcode && [
                  t(`Signup.step2.zipCode.errors.${errors.zipcode.type}`),
                ]
              }
            >
              <FormLabel target="zip_code">
                {t("Signup.step2.zipCode.label")}
              </FormLabel>
              <FormInput
                id="zipcode"
                type="text"
                name="zipcode"
                value={inputs.zip_code || ""}
                placeholder={t("Signup.step2.zipCode.placeholder")}
                error={errors.zipcode}
                onChange={(value) => setInputs({ ...inputs, zip_code: value })}
                register={methods.register}
                validation={{
                  validate: {
                    checkPostal: (value: string) => {
                      const regex = new RegExp(
                        /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i
                      );
                      if (regex.test(value)) return true;
                      else return false;
                    },
                  },
                }}
              />
            </FormInputControl>
          </FormControl>
          <FormControl columns={1}>
            <FormInputControl
              errors={
                errors.email && [
                  t(`Signup.step2.email.errors.${errors.email.type}`),
                ]
              }
            >
              <FormLabel target="email">
                {t("Signup.step2.email.label")}
              </FormLabel>
              <FormInput
                id="email"
                type="text"
                name="email"
                value={inputs.email || ""}
                placeholder={t("Signup.step2.email.placeholder")}
                error={errors.email}
                onChange={(value) => setInputs({ ...inputs, email: value })}
                register={methods.register}
                validation={{
                  required: true,
                }}
              />
            </FormInputControl>
          </FormControl>
          <FormControl columns={1}>
            <FormInputControl
              errors={
                errors.message && [
                  t(`Signup.step2.message.errors.${errors.message.type}`),
                ]
              }
            >
              <FormLabel target="message">
                {t("Signup.step2.message.label")}
              </FormLabel>

              <TextArea
                id="w3review"
                name="w3review"
                value={inputs.message || ""}
                placeholder={t("Signup.step2.email.placeholder")}
                error={errors.message}
                onChange={(value) => setInputs({ ...inputs, message: value })}
                register={methods.register}
                rows="10"
                cols="50"
                validation={{
                  required: true,
                }}
              ></TextArea>
            </FormInputControl>
          </FormControl>

          <FormControl>
            <Button type="submit">{t("Becoming.action")}</Button>
          </FormControl>
        </FormStyled>
        {isSuccess && <Snackbar type={'success'} text={t('Becoming.success')} />}
      </Section>
    </Page>
  );
};

const FormStyled = styled(Form)`
  background-color: ${theme.colors.accent.primary};
  padding: ${theme.spacing[2]};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AlertBannerStyled = styled(AlertBanner)`
  padding: ${theme.spacing[1]};
`;
