import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Heading } from "../../components/Heading";
import ReactHtmlParser from "react-html-parser";

import { theme } from "../../components/theme";
import { icons } from "../../assets/icons";
import { Label } from "../../components/Label";

interface Props {
  selectedPrime?: string;
  withTitle?: boolean;
  isShown?: boolean;
  backgroundColor?: string;
  datas: { text: string }[];
  title?: string;
}

export const PrimePitch: React.FC<Props> = ({
  selectedPrime,
  withTitle = false,
  isShown = false,
  backgroundColor,
  datas,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <StatusWrapper>
        <ColumnWrapper>
          {withTitle && title && (
            <HeadingStyled center element="h1">
              {ReactHtmlParser(t(title))}
            </HeadingStyled>
          )}
          <GridSection>
            <ColumnWrapper backgroundColor={backgroundColor}>
              {datas.map((data) => (
                <SpecialCategory>
                  <icons.star />
                  <CategoryLabel size="mdx" weight="bold">
                    {t(data.text)}
                  </CategoryLabel>
                </SpecialCategory>
              ))}

              {isShown && (
                <SpecialCategory>
                  <icons.star />
                  {selectedPrime === "yearly-prime" ? (
                    <CategoryLabel size="mdx" weight="bold">
                      {t("Prime.offer.2.yearly")}
                    </CategoryLabel>
                  ) : (
                    <CategoryLabel size="mdx" weight="bold">
                      {t("Prime.offer.2.monthly")}
                    </CategoryLabel>
                  )}
                </SpecialCategory>
              )}
            </ColumnWrapper>
          </GridSection>
        </ColumnWrapper>
      </StatusWrapper>
    </>
  );
};

const HeadingStyled = styled(Heading)`
  svg {
    min-width: 22px;
    max-width: 22px;
    max-height: 22px;
    min-height: 22px;
    fill: ${theme.colors.base.secondary};
    margin-right: 10px;
  }
  @media (min-width: ${theme.breakpoints.sm}) {
    margin-bottom: ${theme.spacing[2]};
  }
`;
const GridSection = styled.div`
  display: grid;
  border-radius: 8px;
  grid-auto-flow: dense;
  border: 1px solid ${theme.colors.base.secondary};

  @media (min-width: ${theme.breakpoints.sm}) {
    /* grid-template-columns: 2fr 1fr; */
  }
`;
const CategoryLabel = styled(Label)`
  flex: 1;
  margin: 0 ${theme.spacing[1]};
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.secondary};
`;

const SpecialCategory = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${theme.spacing[1]} 0;
  border-bottom: 1px solid ${theme.colors.accent.secondary};
  /* cursor: pointer; */

  svg,
  svg path {
    fill: ${theme.colors.base.primary};
  }

  .arrow {
    display: block;
    transform: rotate(-90deg);

    path {
      fill: ${theme.colors.base.secondary};
    }
  }

  &:hover {
    > div {
      color: ${theme.colors.base.primary};
    }

    svg,
    svg path {
      fill: ${theme.colors.accent.primary};
    }

    .arrow,
    .arrow path {
      fill: ${theme.colors.accent.secondary};
    }
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    .arrow {
      display: none;
    }
  }
`;

const ColumnWrapper = styled.div<Pick<Props, "backgroundColor">>`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing[1]};
  border-radius: 8px;
  @media (min-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[2]};
  }
  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
`;

const StatusWrapper = styled.div`
  flex: 65;
  .slick-slider {
    margin: 0 -10px;
  }

  .slick-dots {
    position: static;
    bottom: 0;
    margin-bottom: 1rem;
  }

  .copy {
    display: flex;
  }

  .heading {
    color: ${theme.colors.base.secondary};
  }

  .slug {
    padding-right: 20px;
    color: ${theme.colors.base.primary};
  }

  .button-wrapper {
    text-align: center;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    .heading {
      text-align: center;
    }

    .copy {
      display: flex;
      text-align: center;
      flex-direction: column;
      margin-bottom: 25px;
    }
  }
`;
