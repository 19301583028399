import React from "react";

import { TPlan, TUserPlan, TPlanQuantity } from "../../types/Plan";

type TPlanPartial = Partial<TPlan & TUserPlan>;

interface Props {
  plan: TPlan | TPlanPartial;
  persons: TPlanQuantity;
}

export const PlanPrice: React.FC<Props> = ({ plan, persons }) => {
  if (!plan.options) return null;
  const priceRange = plan.options.filter((o) => {
    //@ts-ignore
    return o.persons === persons;
  });
  if (!priceRange || !priceRange[0]) return null;
  return (
    <>
      {priceRange[0].min_price === priceRange[0].max_price
        ? `${priceRange[0].min_price / 100}$`
        : `${priceRange[0].min_price / 100}$ - ${
            priceRange[0].max_price / 100
          }$`}
    </>
  );
};
