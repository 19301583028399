import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Label } from '../../../components/Label';
import { SummaryPlanCell } from '../../../components/SummaryPlanCell';
import { theme } from '../../../components/theme';
import { useAppState } from '../../../contexts';
import { useSubscription } from '../../../hooks/Subscriptions';

export const Plans: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const [lang] = locale.split('-');
  const history = useHistory();
  const { data: subscription } = useSubscription();

  const redirectTo = (path: string) => () => history.push({ pathname: path, state: { prevPath: '/account' } });

  return (
    <Card>
      <ColumnWrapper>
        <TitleLabel size='mdx'>{t('Signup.confirm.basket')}</TitleLabel>
        <ModifyLabel size='md' onClick={redirectTo('/account/plans')}>
          {t('Signup.confirm.modify')}
        </ModifyLabel>
      </ColumnWrapper>
      {subscription && subscription.plans && (
        <PlanWrapper>
          {subscription.plans.map((plan) => (
            <CellWrapper key={plan.id}>
              <SummaryPlanCell name={plan.copy[lang]?.name} tag={plan.copy[lang]?.tagline} quantity={plan.persons} />
            </CellWrapper>
          ))}
        </PlanWrapper>
      )}
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 ${theme.spacing[1.5]};
  
  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
    padding: ${theme.spacing[1.5]};
    background: ${theme.colors.base.white};
    border: 2px solid ${theme.colors.base.white};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleLabel = styled(Label)`
  margin-bottom: ${theme.spacing[0.25]};
  line-height: ${theme.font.size.lg};
  font-weight: ${theme.font.weight.bold};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.secondary};
`;

const ModifyLabel = styled(Label)`
  line-height: ${theme.font.size.mdx};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.primary};
  cursor: pointer;
`;

const PlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[2]};

  @media (min-width: ${theme.breakpoints.sm}) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 0;
  }
`;

const CellWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: ${theme.spacing[1]} 0;

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 280px;
    padding: 0 ${theme.spacing[2]};
    margin: ${theme.spacing[0.25]} 0;

    &:nth-child(2n + 1) {
      border-right: 1px solid ${theme.colors.base.grey};
    }

    &:last-of-type {
      border-right: none;
    }
  }

  @media (min-width: ${theme.breakpoints.md}) {
    width: 330px;
  }
`;
