import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { Label } from "../../../components/Label";
import { Loading } from "../../../components/Loading";
import { Select, TSelectChoice } from "../../../components/Select/Select";
import { Snackbar } from "../../../components/Snackbar";
import { theme } from "../../../components/theme";
import { useAppState } from "../../../contexts";
import { useDelivery } from "../../../hooks/Delivery";
import { useNextDeliveries } from "../../../hooks/NextDeliveries";
import { useProcessingOrders } from "../../../hooks/Orders";
import {
  useReactivateSubscription,
  useSubscription,
} from "../../../hooks/Subscriptions";
import { useUpdateUser, useUser } from "../../../hooks/Users";
import { formatNumber } from "../../../utils/i18n";

export const Info: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const [lang] = locale.split("-");
  const history = useHistory();
  const { data: user } = useUser();
  const { data: subscription, refetch } = useSubscription();
  const { data: delivery } = useDelivery();
  const { data: order } = useProcessingOrders();
  const { data: nextDeliveries } = useNextDeliveries();
  const { mutate: mutateReactivation, isSuccess: IsSuccessReactivation } =
    useMutation(useReactivateSubscription);
  const {
    mutate: mutateTip,
    isSuccess: isSuccessTip,
    isLoading,
  } = useMutation(useUpdateUser);

  const Tips = [
    { label: `${formatNumber(locale, 0)} $`, value: "0" },
    { label: `${formatNumber(locale, 0.5)} $`, value: "50" },
    { label: `${formatNumber(locale, 1)} $`, value: "100" },
    { label: `${formatNumber(locale, 2)} $`, value: "200" },
    { label: `${formatNumber(locale, 3)} $`, value: "300" },
    { label: `${formatNumber(locale, 4)} $`, value: "400" },
    { label: `${formatNumber(locale, 5)} $`, value: "500" },
  ] as TSelectChoice[];

  const [tip, setTip] = useState(Tips[0]);

  useEffect(() => {
    if (user) {
      const userTip = Tips.find(
        (tip) => Number(tip.value) === user.default_tip
      );
      userTip && setTip(userTip);
    }
  }, [user]);

  useEffect(() => {
    refetch();
  }, [IsSuccessReactivation]);

  const redirectTo = (path: string) => () =>
    history.push({ pathname: path, state: { prevPath: "/account" } });

  const onSelectTip = (tip: TSelectChoice) => {
    setTip(tip);
    mutateTip({ default_tip: parseInt(tip.value, 10) });
  };

  const onActivate = () => mutateReactivation();

  const pupDelivery = delivery && delivery?.entity_type === "PUP";
  const nextDeliveryDate =
    order && order.status !== "skipped"
      ? order.delivery_date
      : nextDeliveries?.next_delivery_date;
  const nextDeliveryDateFormatted = nextDeliveryDate
    ? new Intl.DateTimeFormat(locale, {
        weekday: "long",
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(new Date(nextDeliveryDate))
    : null;

  return (
    <Card>
      <DeliveryWrapper>
        <DeliveryDetailWrapper>
          <ColumnHeaderWrapper>
            <TitleLabel size="mdx">{t("Signup.confirm.delivery")}</TitleLabel>
            <ModifyLabel size="md" onClick={redirectTo("/account/delivery")}>
              {t("Signup.confirm.modify")}
            </ModifyLabel>
          </ColumnHeaderWrapper>
          <ColumnWrapper>
            <TitleLabel size="mdx">
              {pupDelivery
                ? t("Info.delivery.pup")
                : t("Signup.step3.delivery.house")}
            </TitleLabel>
            {pupDelivery && <BoldLabel size="md">{delivery?.name}</BoldLabel>}
            <BaseLabel size="md">
              {pupDelivery ? delivery!.address : user?.address}
            </BaseLabel>
            <BaseLabel size="md">
              {pupDelivery ? delivery!.address2 : user?.address2}
            </BaseLabel>
            <BaseLabel size="md">
              {pupDelivery
                ? `${delivery?.zip_code ? delivery?.zip_code : " "}${
                    delivery?.province
                  }`
                : `${user?.zip_code} ${user?.province}`}
            </BaseLabel>
          </ColumnWrapper>
        </DeliveryDetailWrapper>
        <FrequencyWrapper>
          <ColumnHeaderWrapper>
            <TitleLabel size="mdx">
              {t("Signup.step3.frequency.label")}
            </TitleLabel>
            <ModifyLabel size="md" onClick={redirectTo("/account/delivery")}>
              {t("Signup.confirm.modify")}
            </ModifyLabel>
          </ColumnHeaderWrapper>
          <ColumnWrapper>
            <TitleLabel size="mdx">
              {" "}
              {subscription?.frequence === 7
                ? t("Signup.step3.frequency.weekly")
                : t("Signup.step3.frequency.bimonthly")}
            </TitleLabel>
            {nextDeliveryDateFormatted && (
              <>
                <BoldLabel size="md">
                  {t("Signup.confirm.delivery.next")}
                </BoldLabel>
                <DateLabel size="md">
                  {nextDeliveryDateFormatted.charAt(0).toUpperCase() +
                    nextDeliveryDateFormatted.slice(1)}
                </DateLabel>
              </>
            )}
          </ColumnWrapper>
        </FrequencyWrapper>
        <TipWrapper>
          <RowWrapper>
            <TitleLabel size="mdx">{t("Info.delivery.tip")}</TitleLabel>
            <Select
              selectedChoice={tip}
              choices={Tips}
              onSelect={onSelectTip}
            />
          </RowWrapper>
          <BaseLabel size="md">{t("Info.delivery.tip.text")}</BaseLabel>
        </TipWrapper>
      </DeliveryWrapper>
      <UserInfoWrapper>
        <ColumnHeaderWrapper>
          <TitleLabel size="mdx">{t("Signup.step2.heading")}</TitleLabel>
          <ModifyLabel size="md" onClick={redirectTo("/account/info")}>
            {t("Signup.confirm.modify")}
          </ModifyLabel>
        </ColumnHeaderWrapper>
        {user && (
          <ColumnWrapper>
            <BoldLabel size="md">{`${user.first_name} ${user.last_name}`}</BoldLabel>
            <BaseLabel size="md">{user.email}</BaseLabel>
            <BaseLabel size="md">{user.phone}</BaseLabel>
            <AdressWrapper>
              <BoldLabel size="md">{t("Signup.step3.form-address")}</BoldLabel>
              {user.address && (
                <BaseLabel size="md">{`${user?.address} ${user?.address2}`}</BaseLabel>
              )}
              <BaseLabel size="md">{`${user?.zip_code} ${
                user?.province || ""
              }`}</BaseLabel>
            </AdressWrapper>
          </ColumnWrapper>
        )}
      </UserInfoWrapper>
      <CreditCardWrapper>
        <ColumnHeaderWrapper>
          <TitleLabel size="mdx">{t("Info.payment")}</TitleLabel>
          <ModifyLabel size="md" onClick={redirectTo("/account/info")}>
            {t("Signup.confirm.modify")}
          </ModifyLabel>
        </ColumnHeaderWrapper>
        {subscription && subscription.lastDigits && (
          <ColumnWrapper>
            <RowWrapper>
              <BoldLabel size="md">
                {t(`${subscription.brand.toLocaleUpperCase()}`)}
              </BoldLabel>
              <BaseLabel size="md">{`**** **** **** ${subscription.lastDigits}`}</BaseLabel>
            </RowWrapper>
            <BaseLabel size="md">{`${subscription.exp_month}/${subscription.exp_year}`}</BaseLabel>
          </ColumnWrapper>
        )}
        {(subscription?.status === "paused" ||
          subscription?.status === "cancel") &&
          subscription?.lastDigits && (
            <ActionWrapper>
              <Button onClick={onActivate}>
                {t("Subscription.active_subcription")}
              </Button>
            </ActionWrapper>
          )}
        {!subscription && (
          <ActionWrapper>
            <Button onClick={redirectTo("/account/info")}>
              {t("CreditCard.add")}
            </Button>
          </ActionWrapper>
        )}
      </CreditCardWrapper>
      {isLoading && <Loading />}
      {isSuccessTip && (
        <Snackbar type={"success"} text={t("Snackbar.changed.tips")} />
      )}
    </Card>
  );
};

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 ${theme.spacing[1.5]};

  @media (min-width: ${theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: ${theme.spacing[2]} ${theme.spacing[1.5]};
    background: ${theme.colors.base.white};
    border: 2px solid ${theme.colors.base.white};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnHeaderWrapper = styled(ColumnWrapper)`
  margin-bottom: ${theme.spacing[1]};
`;

const DeliveryWrapper = styled(ColumnWrapper)`
  display: flex;
  flex: direction: column;

  @media (min-width: ${theme.breakpoints.sm}) {
    display: grid;
    grid-column: 1 / 3;
    grid-template-columns: repeat(2, 1fr);
    grid-gap : ${theme.spacing[1.5]} 0;
    padding-right: ${theme.spacing[2]};
    border-right: 1px solid ${theme.colors.base.grey};
  }
`;

const UserInfoWrapper = styled(ColumnWrapper)`
  padding: ${theme.spacing[1.5]} 0;
  border-top: 1px solid ${theme.colors.base.grey};

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: 0 ${theme.spacing[2]};
    border-top: none;
  }
`;

const CreditCardWrapper = styled(ColumnWrapper)`
  padding: ${theme.spacing[1.5]} 0 0 0;
  border-top: 1px solid ${theme.colors.base.grey};

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: 0 0 0 ${theme.spacing[2]};
    border-left: 1px solid ${theme.colors.base.grey};
    border-top: none;
  }
`;

const DeliveryDetailWrapper = styled(ColumnWrapper)`
  padding: ${theme.spacing[1.5]} 0;
  border-top: 1px solid ${theme.colors.base.grey};

  @media (min-width: ${theme.breakpoints.sm}) {
    padding-top: 0;
    padding-right: ${theme.spacing[2]};
    border-right: 1px solid ${theme.colors.base.grey};
    border-top: none;
  }
`;

const FrequencyWrapper = styled(ColumnWrapper)`
  padding: ${theme.spacing[1.5]} 0;
  border-top: 1px solid ${theme.colors.base.grey};

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: 0 ${theme.spacing[2]};
    border-top: none;
  }
`;

const TipWrapper = styled(ColumnWrapper)`
  padding: ${theme.spacing[1.5]} 0;
  grid-row: 2;
  grid-column: 1/3;
  border-top: 1px solid ${theme.colors.base.grey};

  > div:first-of-type {
    margin-bottom: ${theme.spacing[1.5]};
  }

  .select-value {
    width: 40px;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[2]} 0 0 0;

    > div:first-of-type {
      margin-bottom: ${theme.spacing[0.5]};
    }
  }
`;

const AdressWrapper = styled(ColumnWrapper)`
  margin-top: ${theme.spacing[0.5]};
`;

const ActionWrapper = styled(ColumnWrapper)`
  margin-top: ${theme.spacing[1.5]};
`;

const TitleLabel = styled(Label)`
  flex: 1;
  margin-bottom: ${theme.spacing[0.25]};
  line-height: ${theme.font.size.lg};
  font-weight: ${theme.font.weight.bold};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.secondary};
`;

const ModifyLabel = styled(Label)`
  line-height: ${theme.font.size.mdx};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.primary};
  cursor: pointer;
`;

const BaseLabel = styled(Label)`
  line-height: ${theme.font.size.mdx};
  font-family: ${theme.font.family.secondary};
`;

const DateLabel = styled(BaseLabel)`
  color: ${theme.colors.base.secondary};
`;

const BoldLabel = styled(BaseLabel)`
  font-weight: ${theme.font.weight.bold};
`;
