import React, { Fragment } from "react";
import styled, { css } from "styled-components";

import { Label } from "../Label";
import { theme } from "../theme";

type StepType = {
  current?: boolean;
};

interface Props {
  current?: number;
  steps: {
    index: number;
    label?: string;
  }[];
  center?: boolean;
  secondary?: boolean;
}

export const Progress: React.FC<Props> = ({
  current = 1,
  steps,
  center = false,
  secondary = false,
}) => {
  return (
    <Container center={center}>
      <Bar>
        {steps.map((_, index) => {
          const isCurrent = current - 1 === index;
          return (
            <Fragment key={index}>
              <Step current={isCurrent} secondary={secondary}>
                {index + 1}
                {steps[index].label && (
                  <LabelStyled current={isCurrent}>
                    {steps[index].label}
                  </LabelStyled>
                )}
              </Step>
            </Fragment>
          );
        })}
      </Bar>
    </Container>
  );
};

const Container = styled.div<Pick<Props, "center">>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: ${theme.spacing[2]};

  @media (min-width: ${theme.breakpoints.xs}) {
    padding-left: ${theme.spacing[2]};
    padding-right: ${theme.spacing[2]};
  }

  ${(props) =>
    props.center &&
    css`
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 0;

      @media (min-width: ${theme.breakpoints.md}) {
        max-width: 80%;
      }
    `}
`;

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Step = styled.span<StepType & Pick<Props, "secondary">>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${theme.spacing[2]};
  height: ${theme.spacing[2]};
  margin-left: ${theme.spacing[1]};
  margin-right: ${theme.spacing[1]};
  border-radius: ${theme.border.radius.xl};
  background-color: ${theme.colors.base.third};
  color: ${theme.colors.base.white};
  font-size: ${theme.font.size.md};
  line-height: ${theme.font.size.lg};
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.normal};

  ${(props) =>
    props.current &&
    css`
      background-color: ${theme.colors.base.secondary};
    `}

  ${(props) =>
    props.secondary &&
    css`
      background-color: ${theme.colors.accent.secondary};
      color: ${theme.colors.base.dark};
    `}
`;

const LabelStyled = styled(Label)<StepType>`
  display: none;
  position: absolute;
  bottom: -${theme.spacing[3]};
  width: 200px;
  left: 50%;
  transform: translateX(-40%);

  @media (min-width: ${theme.breakpoints.xs}) {
    display: inline;
  }

  ${(props) =>
    props.current &&
    css`
      font-weight: bold;
    `}
`;
