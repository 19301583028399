import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Heading } from '../../components/Heading';
import { Page } from '../../components/Page';
import { Section } from '../../components/Section';

export const Privacy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page centerX centerY>
      <SectionStyled centerX>
        <Heading element="h1" size="xl">{t(`Privacy.heading`)}</Heading>
      </SectionStyled>
    </Page>
  );
}

const SectionStyled = styled(Section)`
  min-height: 60vh;
`;
