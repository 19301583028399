import config from "../../config";
import axios from "redaxios";
import { TFormBecoming } from "../../pages/Becoming/Becoming";
import { useQuery } from "react-query";

const apiUrl = `${config.SERVICES.WORKERS_API_URL}/mails`;
const apiUrlDelivery = `${config.SERVICES.MARKETPLACE_API_URL}/pickup-points`;

/**
 * Send a email to marketing to become an PUP
 * @param inputs - paymentMethodId from stripe
 * @returns The Subscription object
 */
export const useBecomePickupPoint = (input: TFormBecoming) => {
  const payload = {
    template: "becoming",
    to: config.SENDGRID_FROM,
    locale: "en",
    locals: {
      ...input,
      locale: "en",
    },
    type: "mail",
  };
  return axios.post(`${apiUrl}/pickup-points`, payload, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

/**
 * Fetch spot left in specific PUP
 * @param locale - The locale of the authenticated user
 * @returns A list of all orders
 */
export const useSpotLeft = (ppid: string | undefined) => {
  const token = localStorage.getItem("token");

  return useQuery<
    {
      day: number;
      spot_left: number;
    }[]
  >(
    ["useSpotLeft", ppid],
    () =>
      axios
        .get(`${apiUrlDelivery}/${ppid}/availabilities`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data.payload),
    {
      // The query will not execute until the userId exists
      enabled: !!ppid && !!token,
    }
  );
};
