import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { AlertBanner } from "../../../components/AlertBanner";
import { AlternateSection } from "../../../components/AlternateSection";
import { FormControl } from "../../../components/FormControl";
import { Page } from "../../../components/Page";
import { theme } from "../../../components/theme";
import { useAppDispatch, useAppState } from "../../../contexts";
import {
  useRemovePlan,
  useSetPlans,
  useSubscription,
} from "../../../hooks/Subscriptions";
import { TPendingPlan, TPlan, TPlanQuantity } from "../../../types/Plan";
import { SignUpProgress } from "../SignUpProgress";
import { Plans } from "../../../components/Plans";
import { TreePlans } from "./TreePlans";
import { Heading } from "../../../components/Heading";

import { Link } from "../../../components/Link";
import { icons } from "../../../assets/icons";
import { pushEvent } from "../../../gtm";
import { EVENTS } from "../../../data/events";

export const Step1: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const appDispatch = useAppDispatch();
  const { token, errors: appErrors, plans: presetPlans } = useAppState();
  const { data: subscription, refetch } = useSubscription();
  const [showPlan, setShowPlan] = useState(false);
  const path = useLocation();
  const { mutate, isSuccess } = useMutation(useSetPlans);
  const [userPlans, setUserPlans] = useState<Array<TPlan>>([]);
  const eventPushedRef = useRef(false);

  const [plans, setPlans] = useState<Array<TPlan>>([]);

  const { mutate: mutateRemove, isSuccess: isSuccessDel } =
    useMutation(useRemovePlan);
  useEffect(() => {
    if (subscription?.status === "active") {
      history.push("/dashboard");
    }
  }, [subscription]);

  useEffect(() => {
    if (!eventPushedRef.current) {
      pushEvent(EVENTS.SIGNUP_START_STEP_ASK_QUESTIONS);
      eventPushedRef.current = true;
    }
  }, []);

  useEffect(() => {
    if (subscription?.plans) {
      setUserPlans(subscription.plans);
      return;
    }
    if (presetPlans.length > 0) {
      setUserPlans(presetPlans);
      return;
    }
  }, [subscription, userPlans, presetPlans, token]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
      history.push("/sign-up/step-2");
    }

    if (isSuccessDel) refetch();
  }, [isSuccess, isSuccessDel]);

  const onAddPlan = (plan: TPlan, persons: TPlanQuantity) => {
    appDispatch({ type: "SET_PLANS", payload: { plans: [plan] } });

    // If the plan is corpo, add a parameter to the next step url (to show an entreprise name field)
    let corpo = false
    if(plan.pid === "1009") corpo = true

    if (subscription) {
      mutate({ plans: [{ pid: plan.pid, persons }] });
    }
    if (path.pathname === "/account/plans") {
      return;
    }

    if (!subscription) {
      pushEvent(EVENTS.SIGNUP_COMPLETE_STEP_CHOOSE_PLAN);
      history.push(`/sign-up/step-2${corpo ? "?corpo=true" : ""}`);
    }
  };

  const onDeletePlan = (plan: TPlan) => {
    const userPlanCopy = JSON.parse(JSON.stringify(userPlans));

    const index = userPlanCopy.findIndex(
      (p: TPendingPlan) => p.pid === plan.pid
    );
    if (index >= 0) {
      userPlanCopy.splice(index, 1);
    }

    setUserPlans(userPlanCopy);
    appDispatch({
      type: "SET_PLANS",
      payload: {
        plans: userPlanCopy,
      },
    });
    if (subscription) {
      mutateRemove({ pid: plan.pid });
      return;
    }
  };

  return (
    <Page centerX centerY backgroundColor={theme.colors.base.background}>
      <AlternateSection>
        <SignUpProgress current={1} title={t("Signup.heading.step1")} />
        <HeadingStyled element="h2" size="md">
          {!showPlan
            ? t("Signup.subheading.step1")
            : t("Signup.subheading2.step1")}
        </HeadingStyled>

        <FormControl>
          {appErrors &&
            appErrors.map((error: any, i: number) => (
              <AlertBanner type="error" key={i}>
                {error.code} : {error.message}
              </AlertBanner>
            ))}
        </FormControl>
        {showPlan && (
          <BackButton>
            <LinkStyled onClick={() => setShowPlan(!showPlan)}>
              <icons.arrowTop />
              {t("Signup.subheading2.back")}
            </LinkStyled>
          </BackButton>
        )}
        <WrapperStyled>
          {!showPlan && (
            <Container>
              <TreePlans
                onPlans={(plans) => {
                  setPlans(plans);
                  setShowPlan(!showPlan);
                }}
              />
            </Container>
          )}

          {showPlan && (
            <PaddingTop centerX>
              <Plans
                userPlans={userPlans}
                plans={plans}
                onAddPlan={onAddPlan}
                onDeletePlan={onDeletePlan}
              />
            </PaddingTop>
          )}
        </WrapperStyled>
      </AlternateSection>
    </Page>
  );
};

const LinkStyled = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-items: center;
`;

const WrapperStyled = styled.div``;
const PaddingTop = styled(AlternateSection)`
  padding-top: ${theme.spacing[1]};
`;

const Container = styled.div``;

const HeadingStyled = styled(Heading)`
  margin-top: ${theme.spacing[3]};
  color: ${theme.colors.base.primary};
  text-align: center;
  font-size: ${theme.font.size.lg};

  @media (min-width: ${theme.breakpoints.sm}) {
  }
`;
const BackButton = styled.div`
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    transform: rotate(-90deg);
  }
`;
