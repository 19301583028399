import { TypeEnum } from "../types/Coupon";
import { TPlan, TPlanQuantity } from "../types/Plan";

/**
 * Calculate the default quantity for a plan
 * @param plan - The plan to calculate default quantity for
 * @returns The default quantity for the given plan
 */
export function getDefaultQuantity(plan: TPlan): TPlanQuantity {
  const quantities = plan.options
    .filter((o) => o.plan_id === plan.id)
    .sort((o1, o2) => o1.persons - o2.persons)
    .map((o) => o.persons);

  return quantities[0];
}

export function getCouponType(type: TypeEnum) {
  if (type === TypeEnum.FIXED_AMOUNTS) {
    return "$";
  }
  if (type === TypeEnum.PERCENTAGES) {
    return "%";
  }
  return "";
}
