import React from "react";

import { StateType, ActionType } from "../Tag/TagContextType";
import { TTag } from "../../types/Tag";

const initialState = {
  tags: [] as TTag[],
  actives: [] as TTag[],
};

const DispatchContext = React.createContext((() => {}) as React.Dispatch<ActionType>);
const StateContext = React.createContext(initialState);

export const TagContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useTagDispatch = () => {
  return React.useContext(DispatchContext);
};

export const useTagState = () => {
  return React.useContext(StateContext);
};

const reducer = (state: StateType = initialState, action: ActionType): StateType => {
  switch (action.type) {
    case "SET_TAGS":
      return {
        ...state,
        tags: action.payload.tags,
      };
    case "ADD_ACTIVE_TAG":
      return {
        ...state,
        actives: [...state.actives, action.payload.tag],
      };
    case "REMOVE_ACTIVE_TAG":
      return {
        ...state,
        actives: state.actives.filter((a) => a.tid !== action.payload.tag.tid),
      };
    default:
      return state;
  }
};
