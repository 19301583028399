export const origins = {
  en: {
    QC: "Quebec",
    FR: "French",
    CA: "Canada",
    ON: "Ontario",
    US: "United States",
    BR: "Brazil",
    CL: "Chile",
    CN: "China",
    MX: "Mexico",
    MD: "Moldova, Republic of",
    MA: "Maroc",
    IS: "Israel",
    EG: "Egypt",
    NP: "Nepal",
    NE: "Niger",
    NG: "Nigeria",
    NO: "Norway",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    RE: "Réunion",
    RO: "Romania",
    RU: "Russian Federation",
    MF: "Saint Martin",
    ZA: "South Africa",
    ES: "Spain",
    SE: "Sweden",
    CH: "Switzerland",
    TN: "Tunisia",
    TR: "Turkey",
    GB: "United Kingdom",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    PE: "Peru",
    CR: "Costa Rica",
    NZ: "New Zealand",
    GU: "Guatemala",
    GR: "Grèce",
    CO: "Colombie",
  },
  fr: {
    IS: "Israël",
    EG: "Égypte",
    QC: "Québec",
    FR: "France",
    CA: "Canada",
    ON: "Ontario",
    US: "État unis",
    MX: "Mexique",
    BR: "Brésil",
    CL: "Chile",
    CN: "Chine",
    MD: "Moldavie",
    MA: "Maroc",
    NP: "Nepal",
    NE: "Niger",
    NG: "Nigeria",
    NO: "Norway",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    RE: "Réunion",
    RO: "Roumanie",
    RU: "Russie",
    MF: "Saint Martin",
    ZA: "Afrique du Sud",
    ES: "Espagne",
    SE: "Sweden",
    CH: "Switzerland",
    TN: "Tunisie",
    TR: "Turquie",
    GB: "United Kingdom",
    IT: "Italie",
    JM: "Jamaica",
    JP: "Japan",
    PE: "Perou",
    AR: "Argentine",
    CR: "Costa Rica",
    NZ: "Nouvelle Zélande",
    EU: "Europe",
    GU: "Guatemala",
    GR: "Greece",
    CO: "Colombia",
  },
};
