import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AlertBanner } from "../../components/AlertBanner";
import { Button } from "../../components/Button";
import { Form } from "../../components/Form";
import { FormControl } from "../../components/FormControl";
import { FormInput } from "../../components/FormInput";
import { FormInputControl } from "../../components/FormInputControl";
import { FormLabel } from "../../components/FormLabel";
import { FormSection } from "../../components/FormSection";
import { Heading } from "../../components/Heading";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { theme } from "../../components/theme";
import { useAppState, useAppDispatch } from "../../contexts";
import { Snackbar } from "../../components/Snackbar";
import { useMutation } from "react-query";
import { useRequestResetPassword } from "../../hooks/Users";
import { Loading } from "../../components/Loading";

export const ResetPasswordRequest: React.FC = () => {
  const { t } = useTranslation();
  const methods = useForm();
  const appDispatch = useAppDispatch();
  const { errors } = methods;
  const { errors: appErrors } = useAppState();
  const { data, mutate, isLoading, isSuccess, error } = useMutation(useRequestResetPassword);
  const [email, setEmail] = useState<string>("");
  const [token, setToken] = useState<string>();

  useEffect(() => {
    if (isSuccess && data) {
      setToken(data.token)
    }
  }, [isSuccess, data]);

  const onSubmitHandler = useCallback(() => {
    mutate(email);
  }, [email, appDispatch]);

  return (
    <Page centerY centerX backgroundColor={theme.colors.base.background}>
      <Section centerX>
        <FormSectionStyled
          title={
            <Heading element="h1" size="md">
              {t("ResetPasswordRequest.heading")}
            </Heading>
          }
        >
          <Form useForm={methods} onSubmit={onSubmitHandler}>
            <FormControl>
              {appErrors.map((error: any, i: number) => (
                <AlertBanner type="error" key={i}>
                  {error.code} : {error.message}
                </AlertBanner>
              ))}

              {!token && (
                <>
                  <FormInputControl
                    errors={
                      errors.email && [
                        t(
                          `ResetPasswordRequest.email.errors.${errors.email.type}`
                        ),
                      ]
                    }
                  >
                    <FormLabel target="email">
                      {t("ResetPasswordRequest.email.label")}
                    </FormLabel>
                    <FormInput
                      id="email"
                      type="email"
                      name="email"
                      placeholder={t("ResetPasswordRequest.email.placeholder")}
                      error={errors.email}
                      onChange={(value) => setEmail(value)}
                      register={methods.register}
                      validation={{
                        required: true,
                        minLength: 2,
                      }}
                    />
                  </FormInputControl>
                  <ButtonStyled type="submit">
                    {t("ResetPasswordRequest.action")}
                  </ButtonStyled>
                </>
              )}
              {isLoading && <Loading/>}
            </FormControl>
          </Form>
        </FormSectionStyled>
        {error && <Snackbar type="error" error={error} />}
        {isSuccess && <Snackbar type="success" text={t('ResetPasswordRequest.success')} />}
      </Section>
    </Page>
  );
};

const FormSectionStyled = styled(FormSection)`
  @media (min-width: ${theme.breakpoints.xs}) {
    width: 480px;
  }
`;

const ButtonStyled = styled(Button)`
  margin-top: ${theme.spacing[2]};
`;
