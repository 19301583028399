import React from "react";

import { StateType, ActionType } from "../Delivery/DeliveryContextType";
import { TPickupLocation } from "../../types/PickupPoint";

const initialState: StateType = {
  delivery: null,
  locations: [] as TPickupLocation[],
};

const DispatchContext = React.createContext((() => {}) as React.Dispatch<ActionType>);
const StateContext = React.createContext(initialState);

export const DeliveryContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useDeliveryDispatch = () => {
  return React.useContext(DispatchContext);
};

export const useDeliveryState = () => {
  return React.useContext(StateContext);
};

const reducer = (state: StateType = initialState, action: ActionType): StateType => {
  switch (action.type) {
    case "SET_DELIVERY":
      return {
        ...state,
        delivery: action.payload.delivery,
      };
    case "SET_LOCATIONS":
      return {
        ...state,
        locations: action.payload.locations,
      };
    default:
      return state;
  }
};
