import { keyframes } from "styled-components";
export const heartBeat = keyframes`
  0%
  {
    transform: scale( 1 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( 1.15 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( 1.15 );
  }
  100%
  {
    transform: scale( 1 );
  }
`;
