import React, { useState } from 'react';
import styled from 'styled-components';
import { icons } from '../../assets/icons';
import { Label } from '../Label';
import { theme } from '../theme';

export type TSelectChoice = {
  label: string,
  value: string,
};

interface Props {
  selectedChoice: TSelectChoice;
  choices: TSelectChoice[];
  onSelect: (choice: TSelectChoice) => void;
}

export const Select: React.FC<Props> = ({ selectedChoice, choices, onSelect }) => {
  const [showChoices, setShowChoices] = useState(false);

  const toggleChoices = () => setShowChoices(!showChoices);

  const onSelectChoice = (choice: TSelectChoice) => () => {
    onSelect(choice);
    toggleChoices();
  }

  return (
    <SelectWrapper>
      <SelectValue onClick={toggleChoices} expanded={showChoices}>
        <ChoiceItem className={'select-value'} size='md'>{selectedChoice.label}</ChoiceItem>
        <icons.plainChevronDown />
      </SelectValue>
      {choices.length > 0 && (
        <ChoiceSelect expanded={showChoices}>
          {choices.map((choice) => (
            <ChoiceItem key={choice.value} size='md' onClick={onSelectChoice(choice)}>
              {choice.label}
            </ChoiceItem>
          ))}
        </ChoiceSelect>
      )}
    </SelectWrapper>
  );
};

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const SelectValue = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${theme.spacing[0.5]} ${theme.spacing[1]};
  border: 1px solid ${theme.colors.base.secondary};
  border-radius: 3px;
  cursor: pointer;

  > div {
    padding: 0;
  }

  &:hover {
    background: ${theme.colors.accent.secondary};
  }

  svg {
    transition: transform ${theme.transition.fast};
  }

  ${(props) =>
    props.expanded &&
    `
    svg {
      transform: rotate(-180deg);
    }
  `}
`;

const ChoiceSelect = styled.div<{ expanded: boolean }>`
  position: absolute;
  display: none;
  flex-direction: column;
  background: ${theme.colors.base.white};
  border: 1px solid ${theme.colors.base.secondary};
  border-radius: 3px;
  top: ${theme.spacing[3]};
  left: 0;
  right: 0;
  z-index: 25;

  ${(props) =>
    props.expanded &&
    `
    display: flex;
  `}
`;

const ChoiceItem = styled(Label)`
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.mdx};
  color: ${theme.colors.base.secondary};
  padding: ${theme.spacing[0.5]} ${theme.spacing[1]};
  cursor: pointer;

  &:hover {
    background: ${theme.colors.accent.secondary};
  }
`;
