import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Heading } from "../Heading";
import { theme } from "../theme";

export const Press: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PressStyled>
      {/* <Heading size="xl">{t(`Press.heading`)}</Heading> */}
      <PressListStyled>
        <PressItemStyled>
          <PictureStyled>
            <img
              src={`/images/la-presse.png`}
              alt={t(`Press.logo-1`)}
              loading="lazy"
            />
          </PictureStyled>
        </PressItemStyled>
        <PressItemStyled>
          <PictureStyled>
            <img
              src={`/images/le-journal-de-montreal.png`}
              alt={t(`Press.logo-1`)}
              loading="lazy"
            />
          </PictureStyled>
        </PressItemStyled>
        <PressItemStyled>
          <PictureStyled>
            <img
              src={`/images/huffpost.png`}
              alt={t(`Press.logo-1`)}
              loading="lazy"
            />
          </PictureStyled>
        </PressItemStyled>
        <PressItemStyled>
          <PictureStyled>
            <img
              src={`/images/metro.png`}
              alt={t(`Press.logo-1`)}
              loading="lazy"
            />
          </PictureStyled>
        </PressItemStyled>
        <PressItemStyled>
          <PictureStyled>
            <img
              src={`/images/TVA.png`}
              alt={t(`Press.logo-1`)}
              loading="lazy"
            />
          </PictureStyled>
        </PressItemStyled>
        <PressItemStyled>
          <PictureStyled>
            <img
              src={`/images/les-affaires.png`}
              alt={t(`Press.logo-1`)}
              loading="lazy"
            />
          </PictureStyled>
        </PressItemStyled>
      </PressListStyled>
    </PressStyled>
  );
};

const PressStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PressListStyled = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  & > * {
    margin: ${theme.spacing[2]};
  }
`;

const PressItemStyled = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PictureStyled = styled.picture`
  source,
  img {
    max-width: 130px;
    max-height: 80px;
    opacity: 0.8;
  }
`;
