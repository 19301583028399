import { addYears } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { AlternateSection } from "../../components/AlternateSection";
import { FlashBanner } from "../../components/FlashBanner";
import { FlashBannerReactivate } from "../../components/FlashBannerReactivate";
import { Page } from "../../components/Page";
import { Snackbar } from "../../components/Snackbar";
import { theme } from "../../components/theme";
import { WithAuth } from "../../components/WithAuth";
import { useDelivery } from "../../hooks/Delivery";
import { primeSubscription, useSubscription } from "../../hooks/Subscriptions";
import { Navigation } from "../Navigation";
import { PrimePitch } from "../Prime/PrimePitch";

import { Button } from "../../components/Button";
import { Billing } from "../UserInfo/Billing";

import { useMutation } from "react-query";
import { Heading } from "../../components/Heading";
import { Label } from "../../components/Label";
import { SavingDone } from "../../components/SavingDone/SavingDone";
import { useAppState } from "../../contexts";
import { formatDate } from "../../utils/i18n";
import { Card } from "../Prime/Card";

export const PrimeSignup: React.FC = () => {
  const { t } = useTranslation();
  const { lookup_key } = useParams<{
    lookup_key: "yearly-prime" | "monthly-prime";
  }>();
  const history = useHistory();
  const {
    mutate,
    isSuccess,
    isLoading,
    error: errorPrime,
  } = useMutation(primeSubscription);
  const { locale } = useAppState();

  const {
    data: subscription,
    error: errorSub,
    refetch,
    isLoading: isLoadSub,
  } = useSubscription();
  const { data: delivery, error: errorDelivery } = useDelivery();
  const [changingCard, setChangingCard] = useState(false);

  useEffect(() => {
    if (subscription && subscription.is_prime) {
      return history.push("/adhesions");
    }
  }, [subscription]);

  useEffect(() => {
    refetch();
    if (isSuccess) return history.push("/adhesions");
  }, [isSuccess]);

  return (
    <WithAuth>
      <Page centerX centerY backgroundColor={theme.colors.base.background}>
        {subscription?.status === "cancel" && (
          <FlashBannerReactivate times={10} type="normal">
            {t("Subscription.cancelled")}
          </FlashBannerReactivate>
        )}
        {delivery?.entity_type === "PUP" && !delivery.open && (
          <FlashBanner times={10} type="yellow">
            {t("Dashboard.pup-closed")}
          </FlashBanner>
        )}
        <AlternateSection>
          <MarginTopBottom>
            <Navigation />
          </MarginTopBottom>
          {subscription?.is_prime && <SavingDone />}

          <ConfirmWrapper>
            <Border>
              <Heading center element="h1">
                {t("Prime.offer")}
              </Heading>
              <Card
                key={lookup_key}
                isActive={true}
                lookup_key={lookup_key}
                onSelect={(lookup_key) => {}}
              />

              <CategoryLabel size="mdx" weight="bold">
                {t("Prime.offer.explain", {
                  date: formatDate(locale, new Date(addYears(new Date(), 1))),
                })}
              </CategoryLabel>
            </Border>
            <PaymentWrapper>
              <PrimePitch
                isShown
                backgroundColor={theme.colors.base.white}
                selectedPrime={lookup_key}
                datas={[{ text: "Prime.offer.1" }, { text: "Prime.offer.3" }]}
                title={"Prime.offer"}
              />
              <Padding>
                <Billing
                  couponName=""
                  onChangingCard={(status) => {
                    setChangingCard(status);
                  }}
                />
              </Padding>
              {!changingCard && !subscription?.is_prime && (
                <>
                  <ButtonStyled
                    onClick={() => {
                      mutate({ lookup_key });
                    }}
                    secondary
                    size="lg"
                    disabled={isLoading || isLoadSub}
                    isLoading={isLoading || isLoadSub}
                  >
                    {t("Prime.pay")}
                  </ButtonStyled>
                </>
              )}
            </PaymentWrapper>
          </ConfirmWrapper>
          {errorSub && <Snackbar type="error" error={errorSub} />}
          {errorDelivery && <Snackbar type="error" error={errorDelivery} />}
          {errorPrime && <Snackbar type="error" error={errorPrime} />}
          {isSuccess && (
            <Snackbar type={"success"} text={t("Snackbar.prime.success")} />
          )}
        </AlternateSection>
      </Page>
    </WithAuth>
  );
};
const MarginTopBottom = styled.div`
  margin-top: ${theme.spacing[1.5]};
  margin-bottom: ${theme.spacing[1.5]};
`;
const CategoryLabel = styled(Label)`
  flex: 1;
  margin: ${theme.spacing[2]} ${theme.spacing[1]};
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.secondary};
`;

const Border = styled.div`
  border-radius: ${theme.border.radius.md};
  border: 1px solid ${theme.colors.base.secondary};
  padding: ${theme.spacing[2]};
`;
const Padding = styled.div`
  padding: 0;
  @media (min-width: ${theme.breakpoints.sm}) {
    padding: 0 ${theme.spacing[2]} 0 ${theme.spacing[2]};
  }
`;
const ButtonStyled = styled(Button)`
  display: flex;
  align-self: flex-end;
`;
const ConfirmWrapper = styled.div`
  display: flex;

  flex-direction: column;
  padding: ${theme.spacing[0.5]};
  background: ${theme.colors.base.white};

  @media (min-width: ${theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: ${theme.spacing[2]};
  }

  @media (min-width: ${theme.breakpoints.md}) {
    grid-template-columns: 3fr 5fr;
  }
`;

const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing[1]};
  @media (min-width: ${theme.breakpoints.sm}) {
    padding: 0;
  }
`;
