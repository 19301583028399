import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import { useAppState } from "../../contexts";
import {
  removeProductFromOrder,
  updateInventory,
  updateOrCreateProductFromPreOrder,
  removeProductFromPreOrder,
} from "../../hooks/Orders";
import useDebounce from "../../hooks/useDebounce";
import { useUser } from "../../hooks/Users";
import { Button } from "../Button";
import { Loading } from "../Loading";
import { theme } from "../theme";
import { TOrder } from "../../types/Order";
import { Snackbar } from "../Snackbar";
import { useHistory } from "react-router-dom";

interface Props {
  isPreOrder: boolean;
  productId: string;
  quantity: number;
  order?: TOrder;
  withBorder: boolean;
}

export const ProductQuantitySelector: React.FC<Props> = ({
  isPreOrder,
  productId,
  quantity,
  order,
  withBorder,
}) => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const queryClient = useQueryClient();
  const { data: user } = useUser();
  const history = useHistory();

  const [inputValue, setInputValue] = useState(quantity.toString());
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [productQuantity, setProductQuantity] = useState(quantity);
  const debounceValue = useDebounce(inputValue, 500);

  useEffect(() => {
    if (quantity) {
      setProductQuantity(quantity);
      setInputValue(quantity.toString());
    }
  }, [quantity]);
  useEffect(() => {
    setLoading(false);
    setError(undefined);
    const debouncedValueAsNumber = Number(debounceValue);
    if (debouncedValueAsNumber !== productQuantity) {
      setProductQuantity(debouncedValueAsNumber);
    }

    if (!user) {
      return;
    }
    const isUpdating =
      debouncedValueAsNumber > 0 && debouncedValueAsNumber !== quantity;

    const isDeleting = debouncedValueAsNumber === 0 && quantity > 0;
    if (isPreOrder && isUpdating) {
      setLoading(true);
      updateOrCreateProductFromPreOrder({
        quantity: debouncedValueAsNumber,
        pid: productId,
      })
        .then(() => {
          setLoading(false);
          queryClient.refetchQueries(["useProcessingOrders"]);
          queryClient.refetchQueries(["useProduct", productId]);
          queryClient.refetchQueries(["usePreOrder"]);
          queryClient.refetchQueries(["useCart"], { active: true });
          return;
        })
        .catch((e) => {
          setError(e);
          setLoading(false);
        });
    } else if (isPreOrder && isDeleting) {
      setLoading(true);
      removeProductFromPreOrder({
        pid: productId,
      })
        .then(() => {
          setLoading(false);
          queryClient.refetchQueries(["useProcessingOrders"]);
          queryClient.refetchQueries(["useProduct", productId]);
          queryClient.refetchQueries(["useCart"], { active: true });
          queryClient.refetchQueries(["usePreOrder"]);
        })
        .catch((e) => {
          setError(e);
          setLoading(false);
        });

      return;
    }

    if (isUpdating && order?.status === "open") {
      setLoading(true);
      updateInventory({
        uid: user.uid,
        quantity: debouncedValueAsNumber,
        pid: productId,
        oid: order?.oid,
        locale,
      })
        .then(() => {
          setLoading(false);
          queryClient.refetchQueries(["useProcessingOrders"]);
          queryClient.refetchQueries(["useCart"], { active: true });
          queryClient.refetchQueries(["useProduct", productId]);
          queryClient.refetchQueries(["useCart"], { active: true });
        })
        .catch((e) => {
          setError(e);
          setLoading(false);
        });
    } else if (isDeleting && order?.status === "open") {
      setLoading(true);
      removeProductFromOrder({
        uid: user?.uid,
        pid: productId,
        oid: order?.oid,
      })
        .then(() => {
          setLoading(false);
          queryClient.refetchQueries(["useProcessingOrders"]);
          queryClient.refetchQueries(["useCart"], { active: true });
          queryClient.refetchQueries(["useProduct", productId]);
          queryClient.refetchQueries(["useCart"], { active: true });
        })
        .catch((e) => {
          setError(e);
          setLoading(false);
        });
    }
  }, [debounceValue]);

  const onAddProduct = (event: Event) => {
    event.stopPropagation();
    setProductQuantity(1);
    setInputValue("1");
  };

  const updateQuantity = (value: number) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setProductQuantity(productQuantity + value);
    setInputValue((productQuantity + value).toString());
  };

  const onClickInput = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const onQuantityChanged = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value !== inputValue) {
      setInputValue(event.currentTarget.value);
    }
  };

  return (
    <>
      {error && <Snackbar type="error" error={error} />}

      {productQuantity > 0 ? (
        <QuantityPicker withBorder={withBorder}>
          <QuantityButton onClick={updateQuantity(-1)}>{"-"}</QuantityButton>
          <Separator className={"separator"} />
          <QuantityInput
            type="number"
            min="1"
            value={inputValue}
            onChange={onQuantityChanged}
            onClick={onClickInput}
          />
          <Separator className={"separator"} />
          <QuantityButton onClick={updateQuantity(+1)}>{"+"}</QuantityButton>
        </QuantityPicker>
      ) : (
        <>
          {isPreOrder && (
            <StyledButton thirdReverse size="md" onClick={onAddProduct} center>
              {t("PreOrderCTA.add")}
            </StyledButton>
          )}

          {order?.status === "open" && (
            <StyledButton thirdReverse size="md" onClick={onAddProduct} center>
              {t("GenericCTA.add")}
            </StyledButton>
          )}
        </>
      )}
      {loading && <Loading />}
    </>
  );
};

const QuantityPicker = styled.div<{ withBorder: boolean }>`
  width: 100%;
  height: 48px;
  display: flex;

  ${(props) =>
    props.withBorder &&
    `
    border: 1px solid ${theme.colors.base.grey};
    border-radius: 3px;
  `}

  @media (min-width: ${theme.breakpoints.sm}) {
    height: 55px;
  }
`;

const Separator = styled.div`
  width: 1px;
  height: 48px;
  background: ${theme.colors.base.grey};

  @media (min-width: ${theme.breakpoints.sm}) {
    height: 55px;
  }
`;

const QuantityButton = styled.div`
  width: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: ${theme.font.size.lg};
  line-height: ${theme.font.size.xxl};
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.black};
  color: ${theme.colors.base.dark};
  cursor: pointer;
  user-select: none;

  &:hover {
    background: ${theme.colors.accent.primary};
  }
`;

const QuantityInput = styled.input`
  width: calc(100% - 2 * 10px);
  font-size: ${theme.font.size.lg};
  line-height: ${theme.font.size.xxl};
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.black};
  text-align: center;
  color: ${theme.colors.base.primary};
  border: none;
  outline: none;
`;

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items:center @media (max-width: ${theme.breakpoints.sm}) {
    padding: 0;
    height: 48px;
  }
`;
