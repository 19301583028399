import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { theme } from '../../../components/theme';
import { useAppState } from '../../../contexts';
import { useTags } from '../../../hooks/Tags';
import { Tag } from './Tag';

interface Props {
  selectedTag?: string;
  onTagSelected: (tag: string) => void;
}

const Tags: React.FC<Props> = ({ selectedTag, onTagSelected }) => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const [lang] = locale.split('-');
  const { data: tags = [] } = useTags();

  const handleTagClicked = (id: string) => () => onTagSelected(id);
  return (
    <TagsWrapper>
      {tags && tags.length > 0 && (
        <>
          <Tag tagId={'ALL'} tagLabel={t('Catalog.tag.all')} isActive={selectedTag === 'ALL'} onClick={handleTagClicked('ALL')} />
          {tags.map((tag) => (
            <Tag key={tag.tid} tagId={tag.tid} tagLabel={tag.copy[lang].name} isActive={selectedTag === tag.tid} onClick={handleTagClicked(tag.tid)} />
          ))}
        </>
      )}
    </TagsWrapper>
  );
};

export const TagsMemorized = React.memo(Tags);

const TagsWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${theme.spacing[0.5]} 0;
`;
