import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { heartBeat } from "../../animations";
import { useAppDispatch } from "../../contexts";
import { useSubscription } from "../../hooks/Subscriptions";
import { Avatar } from "../Avatar";
import { Link } from "../Link";
import { theme } from "../theme";

interface Props {
  className?: string;
}

export const DropdownMenu: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const appDispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { data: subscription } = useSubscription();
  const logoutHandler = (): void => {
    queryClient.removeQueries();
    appDispatch({ type: "DELETE_TOKEN", payload: {} });
    history.push("/login");
  };

  return (
    <ContainerStyled className={className}>
      <Avatar />
      <MenuStyled>
        <MenuLinkStyled>
          <Link to="/dashboard">{t(`Navigation.dashboard`)}</Link>
        </MenuLinkStyled>

        {(subscription?.lastDigits || subscription?.status === "cancel") && (
          <>
            <MenuLinkStyled>
              <Link to="/account">{t("Navigation.personal-infos")}</Link>
            </MenuLinkStyled>
            <MenuLinkStyled>
              <Link to="/order-history">{t("Navigation.order-history")}</Link>
            </MenuLinkStyled>
            <MenuLinkStyled>
              <LinkStyled to="/adhesions">
                <TextStyled>{t("Navigation.secondlife-prime")}</TextStyled>
              </LinkStyled>
            </MenuLinkStyled>
          </>
        )}
        <MenuLinkStyled>
          <Link onClick={logoutHandler}>{t("Navigation.logout")}</Link>
        </MenuLinkStyled>
      </MenuStyled>
    </ContainerStyled>
  );
};

const ContainerStyled = styled.div`
  position: relative;

  &:hover > ul {
    display: block;
  }

  &:hover > a {
    background-color: ${theme.colors.accent.primary};
  }
`;

const MenuStyled = styled.ul`
  display: none;
  right: -24px;
  min-width: 180px;
  position: absolute;
  top: 45px;
  padding: ${theme.spacing[0.5]} ${theme.spacing[1.5]};
  border-radius: ${theme.border.radius.md};
  background-color: ${theme.colors.base.light};
  z-index: 20;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: -12px;
    right: 28px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid ${theme.colors.accent.secondary};
  }

  &:after {
    content: "";
    position: absolute;
    top: -28px;
    right: -4px;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-bottom: 28px solid transparent;
  }

  & > li {
    :last-child {
      margin-top: ${theme.spacing[1]};
      padding: ${theme.spacing[1]} 0;
      border-top: 1px solid ${theme.colors.base.dark};
    }
  }
`;

const MenuLinkStyled = styled.li`
  padding: ${theme.spacing[0.5]} 0;
  list-style: none;
`;

const LinkStyled = styled(Link)`
  animation: ${heartBeat} 4s infinite;
  color: ${theme.colors.base.third};
`;

const TextStyled = styled.p`
  color: ${theme.colors.base.third};
`;
