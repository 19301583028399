import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Cart } from "./Cart";
import { DropdownMenu } from "../DropdownMenu";
import { Button } from "../Button";
import { Link } from "../Link";
import { LocaleToggle } from "./LocaleToggle";
import { theme } from "../theme";
import { useProcessingOrders } from "../../hooks/Orders";
import { useUser } from "../../hooks/Users";
import { useLocation } from "react-router-dom";

interface Props {
  className?: string;
}

export const Navigation: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const path = useLocation();
  const { data: user } = useUser();

  const isSignup = path.pathname.startsWith("/sign-up");
  return (
    <NavigationStyled className={className}>
      <NavLinksStyled>
        {!isSignup && (
          <li>
            <Link to="/products">{t("Navigation.products")}</Link>
          </li>
        )}
        <li>
          <Link to="/faq">{t("Navigation.help")}</Link>
        </li>
        <li>
          <LocaleToggle />
        </li>
        {user && user.email ? (
          <>
            <li>
              <Cart />
            </li>

            <li>
              <DropdownMenu />
            </li>
          </>
        ) : (
          <>
            <li>
              <Link to="/login">{t("Navigation.login")}</Link>
            </li>
            <li>
              <Button to="/sign-up/step-1" size="sm">
                {t("Navigation.get-started")}
              </Button>
            </li>
          </>
        )}
      </NavLinksStyled>
    </NavigationStyled>
  );
};

const NavigationStyled = styled.nav`
  display: none;

  @media (min-width: 840px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const BoldLink = styled(Link)``;

export const NavLinksStyled = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;

  & > li {
    &:after {
      color: red;
    }

    margin-left: ${theme.spacing[1.5]};

    &:first-child {
      margin-left: 0;
    }
  }
`;
