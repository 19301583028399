import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Button } from "../../../../components/Button";
import { useSignupNewsletter } from "../../../../hooks/Users";
import { Snackbar } from "../../../Snackbar";
import { alpha, theme } from "../../../theme";

interface Props {
  placeholder?: string;
}

export const Input: React.FC<Props> = ({ placeholder }) => {
  const { t } = useTranslation();
  const {
    mutate: mutateNewsletter,
    isSuccess,
    error,
  } = useMutation(useSignupNewsletter);
  const [email, setEmail] = useState("");

  const submitForm = useCallback(async () => {
    mutateNewsletter({ email });
  }, [email]);

  return (
    <>
      <SearchStyled>
        <InputStyled
          type="search"
          placeholder={placeholder || t(`Footer.newsletter.placeholder`)}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>): void =>
            setEmail(evt.currentTarget.value)
          }
        />
        <ButtonStyled secondary onClick={(): Promise<void> => submitForm()}>
          {t(`Footer.newsletter.sign-up`)}
        </ButtonStyled>
      </SearchStyled>
      {isSuccess && (
        <Snackbar type={"success"} text={t("Footer.Newsletter.success")} />
      )}
      {error && <Snackbar type={"error"} text={t("Generic.error")} />}
    </>
  );
};

const SearchStyled = styled.div`
  display: flex;
`;

const InputStyled = styled.input`
  flex: 1;
  padding: ${theme.spacing[1]};
  border: 0;
  background-color: ${theme.colors.accent.primary};

  &::placeholder {
    color: ${alpha(theme.colors.base.dark, 0.5)};
  }
`;

const ButtonStyled = styled(Button)`
  border-radius: 0;
`;
