import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useAppDispatch } from "../../contexts";
import { useSubscription } from "../../hooks/Subscriptions";
import { useUser } from "../../hooks/Users";
import { Loading } from "../Loading";

import config from "./../../../src/config";

interface Props {
  redirectTo?: string;
  children?: React.ReactNode;
}

export const WithAuth: React.FC<Props> = ({ redirectTo, children }) => {
  const history = useHistory();
  const appDispatch = useAppDispatch();
  const path = useLocation();
  const { data: user, isError, error } = useUser();
  const { data: subscription, error: errorSub } = useSubscription();
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      appDispatch({ type: "DELETE_TOKEN", payload: {} });
      return history.push("/login");
    }

    if (isError) {
      appDispatch({ type: "DELETE_TOKEN", payload: {} });
      return history.push("/login");
    }

    // if (user) {
    //   if (!user.entity_type) {
    //     history.push("/sign-up/step-3");
    //     return;
    //   }
    // }

    if (user) {
      // @ts-ignore
      if (window.woopra) {
        // @ts-ignore
        woopra.identify({
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
        });
        // @ts-ignore
        woopra.track();
      }

      // @ts-ignore
      if (window.FrontChat) {
        // @ts-ignore
        window.FrontChat("identity", {
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
        });
      }
    }

    if (
      subscription &&
      (!subscription.plans || subscription.plans.length === 0)
    ) {
      history.push("/sign-up/step-2");
      return;
    }

    if (subscription && !subscription.delivery) {
      history.push("/sign-up/step-4");
      return;
    }

    if (
      subscription &&
      (subscription.status !== "active" || !subscription?.lastDigits) &&
      path.pathname !== "/sign-up/step-4"
    ) {
      history.push("/sign-up/confirm");
      return;
    }

    if (redirectTo) {
      return history.push(`${redirectTo}`);
    }
  }, [error, token, user]);

  return user ? <>{children}</> : <Loading />;
};
