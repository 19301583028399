import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../components/theme";
import { usePerfectPlan } from "../../../hooks/Plans";
import { TPlan } from "../../../types/Plan";
import { TProduct } from "../../../types/Product";
import { ChoiceList } from "../../../components/ChoiceList";
import { TChoice } from "../../../components/ChoiceList/ChoiceList";
import { Heading } from "../../../components/Heading";
import { useTranslation } from "react-i18next";
import { pushEvent } from "../../../gtm";
import { EVENTS } from "../../../data/events";
import { Link } from "../../../components/Link";

interface Props {
  onPlans: (plans: (TPlan & { products: TProduct[]; price: string })[]) => void;
}

// Parent component
export const TreePlans: React.FC<Props> = ({ onPlans }) => {
  const [choice1, setChoice1] = useState<TChoice>();
  const [choice2, setChoice2] = useState<TChoice>();
  const [choice3, setChoice3] = useState<TChoice>();
  const eventPushedRef = useRef(false);

  const { data = [] } = usePerfectPlan(
    choice1 && choice2 && choice3 ? [choice1, choice2, choice3] : undefined
  );

  const { t } = useTranslation();

  const [choices1] = useState<TChoice[]>(
    ["fruits-vegetables", "fruits-only"].map((v) => ({
      day: "",
      time: "",
      value: v,
      text: t(`Choice1.${v}`),
    }))
  );

  const [choices2] = useState<TChoice[]>(
    ["local-only", "economy"].map((v) => ({
      day: "",
      time: "",
      value: v,
      text: t(`Choice2.${v}`),
    }))
  );
  const [choices3] = useState<TChoice[]>(
    ["bio", "no-preference"].map((v) => ({
      day: "",
      time: "",
      value: v,
      text: t(`Choice3.${v}`),
    }))
  );

  const onSelectChoice1 = (theChoice: TChoice) => {
    setChoice1({ ...theChoice });
  };
  const onSelectChoice2 = (theChoice: TChoice) => {
    setChoice2(theChoice);
  };
  const onSelectChoice3 = (theChoice: TChoice) => {
    setChoice3(theChoice);
  };

  const onSelectChoice4 = () => {
    setChoice1({ value: "corpo", time: "", day: "" });
    setChoice2({ value: "corpo", time: "", day: "" });
    setChoice3({ value: "corpo", time: "", day: "" });
  };

  useEffect(() => {
    if (choice1 && choice2 && choice3 && !eventPushedRef.current) {
      pushEvent(EVENTS.SIGNUP_COMPLETE_QUESTIONS);
      eventPushedRef.current = true;
    }
    if (data.length > 0) onPlans(data);
  }, [data, choice1, choice2, choice3, onPlans]);

  return (
    <>
      <WrapperStyled>
        <ContainerWrapper>
          <ContainerSingle>
            <HeadingStyled>{t("Signup.step1.choice1")}</HeadingStyled>
            <ChoiceList
              shouldReset={true}
              selectedChoice={choice1}
              choices={choices1}
              onSelect={onSelectChoice1}
            />
          </ContainerSingle>

          <ContainerSingle>
            <HeadingStyled>{t("Signup.step1.choice2")}</HeadingStyled>
            <ChoiceList
              shouldReset={true}
              selectedChoice={choice2}
              choices={choices2}
              onSelect={onSelectChoice2}
            />
          </ContainerSingle>
          <ContainerSingle>
            <HeadingStyled>{t("Signup.step1.choice3")} </HeadingStyled>
            <ChoiceList
              shouldReset={true}
              selectedChoice={choice3}
              choices={choices3}
              onSelect={onSelectChoice3}
            />
          </ContainerSingle>
          <ContainerSingle>
            <Link onClick={onSelectChoice4} size="lg">
              {t("Signup.step1.choice4")}
            </Link>
          </ContainerSingle>
        </ContainerWrapper>
      </WrapperStyled>
    </>
  );
};

const HeadingStyled = styled(Heading)`
  font-size: ${theme.font.size.xl};
`;

const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerWrapper = styled.div`
  margin-top: ${theme.spacing[1]};
  margin-bottom: ${theme.spacing[1]};
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  display: block;
`;

const ContainerSingle = styled.div`
  margin-top: ${theme.spacing[1]};
  margin-bottom: ${theme.spacing[1]};
`;
