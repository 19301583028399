import React from "react";
import styled, { css } from "styled-components";

import { Heading } from "../../components/Heading";
import { Text } from "../../components/Text";
import { Section } from "../../components/Section";
import { theme } from "../../components/theme";
import config from "../../config";

interface Props {
  title: string;
  subtitle?: string;
  image: string;
  imageAlt: string;
  wide?: boolean;
  imageOnTopWhenSmall?: boolean;
  centerWhenSmall?: boolean;
}

export const HeroMSL: React.FC<Props> = ({
  title,
  subtitle,
  image,
  imageAlt,
  imageOnTopWhenSmall = false,
  centerWhenSmall = false,
  wide = false,
  children,
}) => {
  return (
    <HeroSection wide={wide}>
      <ContentStyled>
        <ImageContainer imageOnTopWhenSmall={imageOnTopWhenSmall}>
          <PictureStyled>
            <source srcSet={`${config.CDN_URL}${image}.png`} type="image/png" />
            <source
              srcSet={`${config.CDN_URL}${image}.webp`}
              type="image/webp"
            />

            <img
              loading="lazy"
              src={`${config.CDN_URL}${image}.png`}
              alt={imageAlt}
            />
          </PictureStyled>
        </ImageContainer>
        <TaglineSection imageOnTopWhenSmall={imageOnTopWhenSmall}>
          <HeadingStyled
            element="h1"
            size="xl"
            centerWhenSmall={centerWhenSmall}
          >
            {title}
          </HeadingStyled>
          {subtitle ? (
            <TextStyled centerWhenSmall={centerWhenSmall}>
              {subtitle}
            </TextStyled>
          ) : null}
          <AdditionalContentStyled>{children}</AdditionalContentStyled>
        </TaglineSection>
      </ContentStyled>
    </HeroSection>
  );
};

const HeadingStyled = styled(Heading)`
  color: ${theme.colors.base.secondary};

  ${(props) =>
    props.centerWhenSmall &&
    css`
      @media (max-width: ${theme.breakpoints.md}) {
        text-align: center;
      }
    `}
`;

const TextStyled = styled(Text)`
  ${(props) =>
    props.centerWhenSmall &&
    css`
      @media (max-width: ${theme.breakpoints.md}) {
        text-align: center;
      }
    `}
`;

const HeroSection = styled(Section)<Pick<Props, "wide">>`
  position: relative;
  padding: 0;

  ${(props) =>
    props.wide &&
    css`
      @media (min-width: ${theme.breakpoints.md}) {
        padding: ${theme.spacing[1]} 0 ${theme.spacing[2]};
      }
    `}
`;

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.md}) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

const TaglineSection = styled.div<Pick<Props, "imageOnTopWhenSmall">>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
  margin: auto;
  ${(props) =>
    props.imageOnTopWhenSmall &&
    css`
      order: 2;
    `}

  @media (min-width: ${theme.breakpoints.xs}) {
    max-width: 90%;
    margin-right: ${theme.spacing[2]};
  }

  @media (min-width: ${theme.breakpoints.md}) {
    max-width: 55%;
  }
`;

const AdditionalContentStyled = styled.div`
  margin-top: ${theme.spacing[1.5]};
`;

const ImageContainer = styled.div<Pick<Props, "imageOnTopWhenSmall">>`
  display: flex;
  justify-content: center;
  justify-items: center;
  max-width: 640px;
  margin: auto;
  ${(props) =>
    props.imageOnTopWhenSmall &&
    css`
      order: 1;
    `}
`;

const PictureStyled = styled.picture`
  source,
  img {
    /* width: auto; */
    max-width: 100%;
    /* max-height: 440px; */

    /* @media (min-width: ${theme.breakpoints.xs}) {
      margin-top: -${theme.spacing[1]};
    } */
    @media (min-width: ${theme.breakpoints.md}) {
      align-self: center;
      display: flex;
    }
  }
`;
