import React from "react";
import styled from "styled-components";

import config from "../../config";

interface Props {
  path: string;
  alt: string;
}

export const Image: React.FC<Props> = ({ path, alt }) => {
  return (
    <PictureStyled>
      <picture>
        <source srcSet={`${config.CDN_URL}${path}.png`} type="image/png" />
        <source srcSet={`${config.CDN_URL}${path}.webp`} type="image/webp" />
        <img src={`${config.CDN_URL}${path}.png`} alt={alt} loading="lazy" />
      </picture>
    </PictureStyled>
  );
};

const PictureStyled = styled.div`
  img {
    max-width: 100%;
  }
`;
