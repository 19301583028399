import React from 'react';
import styled from 'styled-components';

import { theme } from '../theme';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export const List: React.FC<Props> = ({
  children,
  className
}) => {
  return <ListStyled className={className}>{ children }</ListStyled>;
};

const ListStyled = styled.ul`
  li > * {
    line-height: ${theme.font.size.md};
  }
`;
