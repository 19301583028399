import React from "react";
import styled from "styled-components";

import { theme } from "../theme";

interface Props {
  title?: React.ReactNode;
  id?: string;
  className?: string;
  bgColor?: string;
}

export const FormSection: React.FC<Props> = ({
  title,
  id,
  className,
  children,
  bgColor,
}) => {
  return (
    <FormSectionStyled id={id} bgColor={bgColor} className={className}>
      {title && <TitleStyled>{title}</TitleStyled>}
      {children}
    </FormSectionStyled>
  );
};

export const FormSectionStyled = styled.div<Props>`
  position: relative;
  padding: ${theme.spacing[1]} ${theme.spacing[2]} ${theme.spacing[2]};
  border-radius: ${theme.border.radius.md};
  background-color: ${(props) =>
    props.bgColor || `${theme.colors.accent.secondary}`};
  border-color: ${theme.colors.base.dark};

  & > * + * {
    margin-top: ${theme.spacing[1]};
  }
`;

export const TitleStyled = styled.div`
  padding-top: ${theme.spacing[1]};
`;
