import React from 'react';
import styled, { css } from 'styled-components';

import { theme } from '../theme';

export type LabelTags = 'p' | 'div' | 'span' | 'label' | 'address';
export type LabelSizes = 'xs' | 'sm' | 'md' | 'mdx' | 'lg' | 'xl';
export type LabelWeight = 'normal' | 'bold' | 'black';
export type LabelStyle = 'normal' | 'italic';

interface Props {
  size?: LabelSizes;
  weight?: LabelWeight;
  decoration?: LabelStyle;
  element?: LabelTags;
  muted?: boolean;
  htmlFor?: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const Label: React.FC<Props> = ({
  size = 'md',
  weight = 'normal',
  decoration = 'normal',
  element: Element = 'div',
  muted,
  children,
  className,
  onClick
}) => {
  return <Element className={className} onClick={onClick}>{ children }</Element>;
};

export const LabelStyled = styled(Label)`
  font-size: ${theme.font.size.md};
  font-weight: ${theme.font.weight.normal};

  ${props => props.size === 'xs' && css`
    font-size: ${theme.font.size.xs};
  `}

  ${props => props.size === 'sm' && css`
    font-size: ${theme.font.size.sm};
  `}

  ${props => props.size === 'mdx' && css`
    font-size: ${theme.font.size.mdx};
  `}

  ${props => props.size === 'lg' && css`
    font-size: ${theme.font.size.lg};
  `}

  ${props => props.size === 'xl' && css`
    font-size: ${theme.font.size.xl};
  `}

  ${props => props.weight === 'bold' && css`
    font-weight: ${theme.font.weight.bold};
  `}

  ${props => props.weight === 'black' && css`
    font-weight: ${theme.font.weight.black};
  `}

  ${props => props.decoration === 'italic' && css`
    font-style: italic;
  `}

  ${props => props.muted && css`
    color: ${theme.colors.neutral.secondary};
  `}
`;

export const LabelMemoized = React.memo(LabelStyled);
