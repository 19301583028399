import React from 'react';
import styled from 'styled-components';
import { Label } from '../../../components/Label';
import { Text } from '../../../components/Text';
import { theme } from '../../../components/theme';
import config from '../../../config';

interface Props {
  title: string;
  date?: string;
  comment: string;
  highlight?: string;
}

export const NextDeliveryCard: React.FC<Props> = ({ title, date, comment, highlight, children }) => {
  return (
    <NextDeliveryWrapper>
      <TextWrapper>
        <DeliveryTitle size='xxl' weight='black'>
          {title}
        </DeliveryTitle>
        {date && (
          <HighlightedLabel size='xl' weight='black'>
            {date}
          </HighlightedLabel>
        )}
        <CommentText size='lg' weight='black'>
          {comment}
        </CommentText>
        {highlight && (
          <HighlightedLabel size='xl' weight='black'>
            {highlight}
          </HighlightedLabel>
        )}
      </TextWrapper>
      <ItemPictureStyled>
        <source srcSet={`${config.CDN_URL}/common/images/placeholder-about-us-1.webp`} type='image/webp' />
        <source srcSet={`${config.CDN_URL}/common/images/placeholder-about-us-1.png`} type='image/png' />
        <img src={`${config.CDN_URL}/common/images/placeholder-about-us-1.png`} alt='Placeholder' loading='lazy' />
      </ItemPictureStyled>
      <ActionsWrapper>{children}</ActionsWrapper>
    </NextDeliveryWrapper>
  );
};

const NextDeliveryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  background: ${theme.colors.accent.secondary};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing[2]} ${theme.spacing[1.5]};

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[2]};
  }
`;

const DeliveryTitle = styled(Label)`
  font-size: ${theme.font.size.xl};
  line-height: ${theme.font.size.xxl};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.secondary};

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.xxl};
    line-height: ${theme.font.size.xxl};
  }
`;

const HighlightedLabel = styled(Label)`
  font-size: ${theme.font.size.mdx};
  line-height: ${theme.font.size.lg};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.primary};

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.xl};
    line-height: ${theme.font.size.xxl};
  }
`;

const CommentText = styled(Text)`
  margin-top: ${theme.spacing[1]};
  line-height: ${theme.font.size.mdx};
  line-height: ${theme.font.size.xl};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.dark};

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-top: ${theme.spacing[2]};
    font-size: ${theme.font.size.xl};
    line-height: ${theme.font.size.xxl};
  }
`;

const ItemPictureStyled = styled.picture`
  max-width: 450px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;

  img,
  source {
    max-width: 100%;
    border-bottom-left-radius: 8px;
    transform: scaleX(-1);
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: ${theme.spacing[1.5]};
  left: ${theme.spacing[1.5]};
  right: ${theme.spacing[1.5]};

  & > * {
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: center;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
    left: ${theme.spacing[2]};

    & > * {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: ${theme.spacing[0.5]};
      margin-right: ${theme.spacing[0.5]};

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
