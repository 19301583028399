import { Client as GMapClient } from "@googlemaps/google-maps-services-js";

import { TPayload } from "../../types/Payload";
import { TDeliveryProfile } from "../../types/Delivery";
import { handleResponse, authorizationHeader, basicHeader } from "../common";
import config from "../../config";
import { TFrequenceEnum } from "../../types/PickupPoint";

const pupApiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/pickup-points`;
const deliveryApiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/deliveries`;

/**
 * Get pickup locations by distance
 * @param lattitude - The lattitude of the user
 * @param longitude - The lattitude of the user
 * @param limit - The max number of returned pups
 * @param maxRange - The max range for a pup to be returned
 * @param additionalFields - The additional fields to return
 * @returns The product list
 */
export const getByDistance = async (
  lattitude: number,
  longitude: number,
  limit: number,
  maxRange = 15,
  additionalFields: string[] = []
): Promise<TPayload> => {
  return await fetch(`${pupApiUrl}/distance`, {
    method: "PUT",
    headers: basicHeader(),
    body: JSON.stringify({
      lattitude,
      longitude,
      limit,
      maxRange,
      additionalFields,
    }),
  })
    .then(handleResponse)
    .then((payload) => payload);
};

/**
 * Get the user delivery method
 * @returns The user delivery method
 */
export const fetchDeliveryMethod = async (): Promise<TPayload> => {
  return await fetch(`${deliveryApiUrl}`, {
    method: "GET",
    headers: authorizationHeader(),
  })
    .then(handleResponse)
    .then((payload) => payload);
};

/**
 * Create the user LAD delivery
 * @returns The user LAD delivery
 */
export const createLADDelivery = async (
  userId: string,
  address: Pick<
    TDeliveryProfile,
    | "address"
    | "address2"
    | "city"
    | "province"
    | "zip_code"
    | "country"
    | "phone"
    | "note"
  >,
  timeslotId: string,
  frequence: TFrequenceEnum
): Promise<TPayload> => {
  if (!userId)
    return {
      code: 401,
      payload: null,
    };

  return await fetch(`${deliveryApiUrl}/${userId}/LAD`, {
    method: "POST",
    headers: authorizationHeader(),
    body: JSON.stringify({
      ...address,
      timeslotId,
      frequence,
    }),
  })
    .then(handleResponse)
    .then((payload) => payload);
};
