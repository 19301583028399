import config from "../../config";
import axios from "redaxios";
import { useQuery } from "react-query";
import { THolidays } from "../../types/Holidays";

const apiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/users`;

/**
 * update holidays
 * @param start_at - holiday start at ?
 * @param end_at - holiday end at ?
 * @returns The Subscription object
 */
export const useUpdateHolidays = (payload: {
  start_at: Date;
  end_at: Date;
}) => {
  const token = localStorage.getItem("token");

  return axios
    .post(`${apiUrl}/holidays`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data.payload);
};

/**
 * delete holidays
 * @returns The Subscription object
 */
export const useResetHolidays = () => {
  const token = localStorage.getItem("token");

  return axios
    .delete(`${apiUrl}/holidays`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data.payload);
};

/**
 * fetch next deliveries from user
 * @returns The TNextDeliveriesResponse object
 */
export const useHolidays = () => {
  const token = localStorage.getItem("token");
  return useQuery<THolidays>(
    ["useHolidays"],
    () =>
      axios
        .get(`${apiUrl}/holidays`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data.payload),
    {
      enabled: !!token,
    }
  );
};
