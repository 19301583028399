import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { icons } from "../../assets/icons";
import { Text } from "../../components/Text";
import { formatError } from "../../utils/customError";
import { alpha, theme } from "../theme";

interface Props {
  type: "success" | "info" | "warning" | "error" | "neutral";
  text?: string;
  error?: any | unknown;
  onDismiss?: () => void;
}

export const Snackbar: React.FC<Props> = ({ type, text, error, onDismiss }) => {
  const { t } = useTranslation();
  const [showIt, setShowIt] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      dismiss();
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, [showIt]);

  const dismiss = () => {
    setShowIt(false);
    onDismiss && onDismiss();
  };

  let message = text;
  if (error && formatError(error).status) {
    const status = formatError(error).status;
    if (status > 500) {
      message = `${t("GenericError")} ${message}`;
    } else if (status >= 400 && status <= 500 && !text) {
      message = t(`ErrorApi.${formatError(error)?.data.message}`);
    }
  }
  return (
    <>
      {showIt && message && (
        <SnackbarWrapper>
          <SnackbarContainer type={type}>
            <TextStyled>{message}</TextStyled>
            <icons.close onClick={dismiss} />
          </SnackbarContainer>
        </SnackbarWrapper>
      )}
    </>
  );
};

const SnackbarWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  padding: ${theme.spacing[1]};
  z-index: 250;

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[2]};
  }
`;

const SnackbarContainer = styled.div<{ type: string }>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${theme.spacing[1]};
  border-radius: ${theme.border.radius.md};
  border: 2px solid transparent;

  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  ${(props) =>
    props.type === "success" &&
    css`
      border-color: ${alpha(
        theme.banner.success.color,
        theme.transparency.high
      )};
      background-color: ${theme.banner.success.background};
      color: ${theme.banner.success.color};
    `}

  ${(props) =>
    props.type === "info" &&
    css`
      border-color: ${alpha(theme.banner.info.color, theme.transparency.high)};
      background-color: ${theme.banner.info.background};
      color: ${theme.banner.info.color};
    `}

  ${(props) =>
    props.type === "warning" &&
    css`
      border-color: ${alpha(
        theme.banner.warning.color,
        theme.transparency.high
      )};
      background-color: ${theme.banner.warning.background};
      color: ${theme.banner.warning.color};
    `}

  ${(props) =>
    props.type === "error" &&
    css`
      border-color: ${alpha(theme.banner.error.color, theme.transparency.high)};
      background-color: ${theme.banner.error.background};
      color: ${theme.banner.error.color};
    `}

  ${(props) =>
    props.type === "neutral" &&
    css`
      border-color: ${alpha(
        theme.colors.accent.third,
        theme.transparency.high
      )};
      background-color: ${theme.colors.accent.third};
    `}
`;

const TextStyled = styled(Text)`
  flex: 1;
  margin-right: ${theme.spacing[1]};
  font-family: ${theme.font.family.secondary};
  color: unset;
`;
