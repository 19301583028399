import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { AlternateSection } from "../../components/AlternateSection";
import { FlashBannerReactivate } from "../../components/FlashBannerReactivate";
import { Label } from "../../components/Label";
import { Page } from "../../components/Page";
import { Select, TSelectChoice } from "../../components/Select/Select";
import { theme } from "../../components/theme";
import { WithAuth } from "../../components/WithAuth";
import { useAppState } from "../../contexts";
import { useOrders } from "../../hooks/Orders";
import { useSubscription } from "../../hooks/Subscriptions";
import { TOrder } from "../../types/Order";
import { Navigation } from "../Navigation";
import { Cell } from "./Cell";

export const OrderHistory: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const history = useHistory();
  const { data: orders } = useOrders(locale);
  const { data: subscription } = useSubscription();

  const Filters = [
    {
      label: t("History.filter.month", {
        month: new Intl.DateTimeFormat(locale, { month: "long" }).format(
          new Date()
        ),
      }),
      value: "MONTH",
    },
    {
      label: t("History.filter.year", { year: new Date().getFullYear() }),
      value: "YEAR",
    },
    { label: t("History.filter.all"), value: "ALL" },
  ] as TSelectChoice[];
  const [filter, setFilter] = useState(Filters[0]);
  const [filteredOrders, setFilteredOrders] = useState<TOrder[]>([]);

  useEffect(() => {
    if (subscription && subscription.plans.length === 0) {
      return history.push("/sign-up/step-2");
    }

    if (subscription && !subscription.lastDigits) {
      return history.push("/sign-up/confirm");
    }

    if (subscription && subscription.status === "cancel") {
      return history.push("/dashboard");
    }
  }, [subscription]);

  useEffect(() => {
    if (orders) {
      const filteredList = orders?.filter(
        (order: TOrder) =>
          order.status === "paid" &&
          (filter.value === "ALL" ||
            (filter.value === "YEAR" &&
              new Date().getFullYear() ===
                new Date(order.delivery_date).getFullYear()) ||
            (filter.value === "MONTH" &&
              new Date().getFullYear() ===
                new Date(order.delivery_date).getFullYear() &&
              new Date().getMonth() ===
                new Date(order.delivery_date).getMonth()))
      );

      setFilteredOrders(filteredList);
    }
  }, [filter, orders]);

  const onChangeFilter = (filter: TSelectChoice) => {
    setFilter(filter);
  };

  return (
    <WithAuth>
      <Page centerX centerY backgroundColor={theme.colors.base.background}>
        {subscription && subscription.status !== "active" && (
          <FlashBannerReactivate times={10} type="normal">
            {t("Subscription.cancelled")}
          </FlashBannerReactivate>
        )}
        <AlternateSection>
          <Navigation />
          <Card>
            <HistoryWrapper>
              <ListHeader>
                <Select
                  selectedChoice={filter}
                  choices={Filters}
                  onSelect={onChangeFilter}
                />
              </ListHeader>
              {filteredOrders && filteredOrders.length > 0 ? (
                <OrderList>
                  {filteredOrders?.map((order) => {
                    return <Cell key={order.oid} order={order} />;
                  })}
                </OrderList>
              ) : (
                <EmptyLabel size="md">{t("History.empty")}</EmptyLabel>
              )}
            </HistoryWrapper>
          </Card>
        </AlternateSection>
      </Page>
    </WithAuth>
  );
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[1.5]};
  padding: ${theme.spacing[2]} ${theme.spacing[1.5]};
  background: ${theme.colors.base.white};
  border: 2px solid ${theme.colors.base.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

const HistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${theme.spacing[1]};
  border-bottom: 1px solid ${theme.colors.base.grey};

  .select-value {
    width: 150px;
  }
`;

const OrderList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const OrderCell = styled.li`
  width: 100%;
`;

const BaseLabel = styled(Label)`
  line-height: ${theme.font.size.mdx};
  font-family: ${theme.font.family.secondary};
`;

const EmptyLabel = styled(BaseLabel)`
  padding: ${theme.spacing[2]} ${theme.spacing[1]} ${theme.spacing[1]}
    ${theme.spacing[1]};
  font-weight: ${theme.font.weight.bold};
  text-align: center;
`;
