import React from "react";
import styled, { css } from "styled-components";
import { icons } from "../../assets/icons";
import { Text } from "../Text";
import { theme } from "../theme";
import { formatNumber } from "../../utils/i18n";
import { useAppState } from "../../contexts";
import { useTranslation } from "react-i18next";
interface Props {
  price: number;
  size: string;
  browser: boolean;
  mobile: boolean;
}
export const MSLTag: React.FC<Props> = ({
  price,
  size = "mdx",
  browser = false,
  mobile = false,
}) => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  return (
    <MSLTagStyled size={size} browser={browser} mobile={mobile}>
      <MSLWrapperHover>
        <icons.mls />
        <HoverPrimeExplanation>
          <span> {t("Prime.explanation")}</span>
        </HoverPrimeExplanation>
      </MSLWrapperHover>
      <PriceTextMSL element="div" size={size}>
        {`${formatNumber(locale, price / 100)}$`}
      </PriceTextMSL>
    </MSLTagStyled>
  );
};

const MSLTagStyled = styled.div<Pick<Props, "size" | "browser" | "mobile">>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.accent.secondary};
  border-radius: ${theme.border.radius.lg};
  padding: 0 ${theme.spacing[0.25]};
  gap: ${theme.spacing[0.25]};

  ${(props) =>
    props.size === "mdx" &&
    css`
      svg {
        position: relative;
        top: 1px;
        width: 35px;
        height: 35px;
      }
    `}

  ${(props) =>
    props.browser &&
    css`
      @media (max-width: ${theme.breakpoints.sm}) {
        display: none;
      }
    `} 

  ${(props) =>
    props.mobile &&
    css`
      @media (min-width: ${theme.breakpoints.sm}) {
        display: none;
      }
    `}

  ${(props) =>
    props.size === "xl" &&
    css`
      svg {
        position: relative;
        top: 1px;
        width: 60px;
        height: 60px;
      }
      @media (max-width: ${theme.breakpoints.sm}) {
        svg {
          position: relative;
          top: 1px;
          width: 50px;
          height: 50px;
        }
      }
    `}
`;

const PriceText = styled(Text)<Pick<Props, "size">>`
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.colors.base.secondary};
  line-height: ${theme.font.size.mdx};
  display: flex;
  gap: ${theme.spacing[0.5]};
  align-items: center;
  ${(props) =>
    props.size === "lg" &&
    css`
      @media (min-width: ${theme.breakpoints.sm}) {
        font-size: ${theme.font.size.lg};
        line-height: ${theme.font.size.lg};
      }
    `}
`;

const HoverPrimeExplanation = styled.div`
  background-color: ${theme.colors.accent.primary};
  z-index: 1;
  padding: ${theme.spacing[1]};
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
`;

const PriceTextMSL = styled(PriceText)`
  color: ${theme.colors.base.primary};
  width: fit-content;
`;

const IconWrapperHover = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${theme.spacing[0.25]};
  margin: ${theme.spacing[0.25]} 0;
  > div {
    display: none;
  }

  &:hover > div {
    position: absolute;
    margin-top: ${theme.spacing[2]};
    display: block;
    border-radius: 8px;
    color: ${theme.colors.base.secondary};
  }
  svg,
  svg path {
    fill: ${theme.colors.base.primary};
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: flex-end;
    &:hover > div {
      position: absolute;
      z-index: 10;
      color: ${theme.colors.base.secondary};
      display: block;
      border-radius: 8px;
    }
  }
`;

const MSLWrapperHover = styled(IconWrapperHover)`
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: ${theme.breakpoints.sm}) {
    align-items: flex-start;
  }
`;
