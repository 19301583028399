import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { AlternateSection } from "../../../components/AlternateSection";
import { Button } from "../../../components/Button";
import { Heading } from "../../../components/Heading";
import { Link } from "../../../components/Link";
import { Page } from "../../../components/Page";
import { Snackbar } from "../../../components/Snackbar";
import { Text } from "../../../components/Text";
import { theme } from "../../../components/theme";
import { UserInfoForm } from "../../../components/UserInfoForm";
import { useAppDispatch, useAppState } from "../../../contexts";
import { useCreateUser, useSignupNewsletter } from "../../../hooks/Users";
import { TInitialUser } from "../../../types/User";
import { SignUpProgress } from "../SignUpProgress";
import { pushEvent } from "../../../gtm";
import { EVENTS } from "../../../data/events";

export const Step2: React.FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const token = localStorage.getItem("token");
  const history = useHistory();
  const { locale, plans } = useAppState();
  const appDispatch = useAppDispatch();
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [newsletter, setNewletter] = useState("add");
  const {
    data: userCreated,
    isSuccess,
    mutate,
    isLoading,
    error,
  } = useMutation(useCreateUser);
  const { mutate: mutateNewsletter } = useMutation(useSignupNewsletter);

  const [inputs, setInputs] = useState<TInitialUser>({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    confirmation_email: "",
    zip_code: "",
    phone: "",
    locale,
    referral: "",
    company_name: "",
  });

  useEffect(() => {
    if (isSuccess) {
      appDispatch({
        type: "SET_TOKEN",
        payload: {
          token: userCreated.token,
        },
      });
      appDispatch({ type: "SET_PLANS", payload: { plans: [] } });
      pushEvent(EVENTS.SIGNUP_COMPLETE_STEP_SIGNUP_FORM);
      history.push("/sign-up/step-4");

      return;
    } else {
      console.log("Error in signup:", error);
    }

    return () => {
      setInputs({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        confirmation_email: "",
        zip_code: "",
        phone: "",
        locale: locale,
        referral: "",
        company_name: "",
      });
      setIsValidPhone(false);
    };
  }, [isSuccess]);

  useEffect(() => {
    if (plans.length === 0) {
      return;
    }
  }, [plans]);

  useEffect(() => {
    if (token) {
      history.push("/sign-up/step-4");
      return;
    }
  }, [token]);

  const onInputChange = (key: string) => (value: string) => {
    const inputCopy = { ...inputs };
    if (key === "phone") {
      // @ts-ignore
      inputCopy[key] = value.toLowerCase();
    } else if (key === "zip_code") {
      // @ts-ignore
      inputCopy[key] = value.toUpperCase();
    } else {
      // @ts-ignore
      inputCopy[key] = value;
    }

    setInputs(inputCopy);
  };
  const onNewsletterChange = (isChecked: boolean) =>
    setNewletter(isChecked ? "add" : "");

  const onSubmitHandler = () => {
    if (inputs && inputs?.phone)
      mutate({
        ...inputs,
        phone: inputs.phone,
        plans: plans.map((plan) => ({
          pid: plan.pid,
          quantity: plan.persons,
        })),
      });
    if (newsletter === "add" && inputs.email) {
      mutateNewsletter({ email: inputs.email });
    }
  };
  const handleBack = () => {
    // If the plan is corpo, add a parameter to the prev step url (to show an entreprise name field)
    let params = new URLSearchParams(search);
    let corpo = params.get("corpo");
    return history.push(`/sign-up/step-2${corpo ? "?corpo=true" : ""}`);
  };
  return (
    <Page centerY centerX backgroundColor={theme.colors.base.background}>
      <AlternateSectionStyled>
        <HeaderWrapperStyled>
          <SignUpProgress current={3} title={t("Signup.heading.step3")} />
          <ContainerStyled>
            <HeadingStyled element="h1" size="sm">
              {t("Signup.step2.heading")}
            </HeadingStyled>
            <TextStyled size="mdx">{t("Signup.step2.explanation")}</TextStyled>
          </ContainerStyled>
        </HeaderWrapperStyled>
        <UserInfoForm
          inputs={inputs}
          isSignup={true}
          newsletter={newsletter}
          showPassword={true}
          isLoading={isLoading}
          setValue={false}
          disableEmailAndZip={false}
          onInputChange={onInputChange}
          onNewsletterChange={onNewsletterChange}
          setIsValidPhone={setIsValidPhone}
          onSubmit={onSubmitHandler}
        >
          <BottomSectionStyled centerX>
            <ButtonContainer>
              <ButtonStyled size="lg" primary onClick={handleBack}>
                {t("Common.action.back")}
              </ButtonStyled>
              <ButtonStyled
                size="lg"
                secondary
                disabled={!isValidPhone}
                type="submit"
              >
                {t("Signup.next")}
              </ButtonStyled>
            </ButtonContainer>
            <Terms>
              {t("Signup.step2.terms")}
              <LinkStyled to="/terms-and-conditions" target="_blank">
                {t("Signup.step2.termsLink")}
              </LinkStyled>
            </Terms>
          </BottomSectionStyled>
        </UserInfoForm>
        {error && <Snackbar type="error" error={error} />}
      </AlternateSectionStyled>
    </Page>
  );
};

const ButtonStyled = styled(Button)``;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between; // Aligns buttons on opposite ends on large screens
`;

const Terms = styled(Text)`
  margin-top: ${theme.spacing[1]};
  @media (max-width: ${theme.breakpoints.sm}) {
    order: 3; // Ensures the text is at the bottom
  }
`;
const AlternateSectionStyled = styled(AlternateSection)`
  position: relative;
  @media (max-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[1]} 0;
  }
`;

const HeaderWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${theme.spacing[2]};
`;

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing[0.5]};
  margin-top: ${theme.spacing[2]};
`;

const BottomSectionStyled = styled(AlternateSection)`
  margin-top: ${theme.spacing[1]};
  padding: 0;
`;

const HeadingStyled = styled(Heading)`
  line-height: ${theme.font.size.lg};
  text-align: center;
  letter-spacing: normal;

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.xl};
    line-height: ${theme.font.size.xxl};
    text-align: left;
    letter-spacing: 1px;
  }
`;

const TextStyled = styled(Text)`
  display: none;

  @media (min-width: ${theme.breakpoints.sm}) {
    display: flex;
    font-size: ${theme.font.size.lg};
    line-height: ${theme.font.size.xxl};
  }
`;

const LinkStyled = styled(Link)`
  text-decoration: underline;
`;
