import React from "react";

const NutritionAppIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="68"
      viewBox="0 0 65 68"
      fill="none"
    >
      <path
        d="M51.625 53.125V62.6875C51.625 65.0356 49.7231 66.9375 47.375 66.9375H13.375C11.0269 66.9375 9.125 65.0356 9.125 62.6875V40.375"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.125 21.25V5.3125C9.125 2.96437 11.0269 1.0625 13.375 1.0625H47.375C49.7231 1.0625 51.625 2.96437 51.625 5.3125V8.5"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.25 8.5V7.4375H15.5V19.125"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 42.5V54.1875H44.1875V53.125"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.125 60.5625H34.625"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6875 27.625H5.9375"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3125 27.625H24"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.375 34H17.625"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 34H36.75"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.7432 39.8198C34.5586 41.2702 35.4458 42.508 36.2135 43.5572C37.2839 45.0208 39.231 47.5841 42.2485 47.5841C43.89 47.5841 45.2341 47.2866 46.1186 47.0156C46.8783 46.7819 47.6779 46.7819 48.4375 47.0156C49.3221 47.2866 50.6661 47.5841 52.3077 47.5841C55.3252 47.5841 57.2722 45.0208 58.3427 43.5572C60.4119 40.7283 63.371 36.5102 63.371 30.4698C63.371 24.4295 59.8621 21.4147 57.336 20.4027C52.3077 18.3892 47.2794 20.4027 47.2794 20.4027C47.2794 20.4027 47.7761 16.0331 43.3933 14.4766"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.235 19.6244C39.2575 19.7519 38.2375 19.9936 37.2175 20.4027C34.6914 21.4147 31.1825 24.4295 31.1825 30.4699C31.1825 31.6572 31.302 32.9508 31.5039 34"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.2942 24.4296C49.2874 25.4363 45.2659 25.4363 44.2592 24.4296"
        stroke="#10594D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NutritionAppIcon;
