import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Label } from "../../../components/Label";
import { theme } from "../../../components/theme";
import { useAppState } from "../../../contexts";
import { formatCurrency } from "../../../utils/i18n";
import { TProduct } from "../../../types/Product";
import { useHistory } from "react-router-dom";

interface Props {
  product: TProduct & { quantity: number };
}

export const Cell: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const [lang] = locale.split("-");

  const history = useHistory();

  const goToProduct = () => {
    history.push(`/products/${product?.pid}`);
  };

  return (
    <OrderCell onClick={goToProduct} className={"pointer"}>
      <NameLabel className={"my-auto"} size="mdx">
        <div className={"product-small-image-container my-auto mr-3"}>
          {product?.images && product?.images.length > 0 && (
            <img src={product.images[0]} loading={"lazy"} />
          )}{" "}
        </div>
        <div className={"my-auto"}>{product?.copy[`${lang}`]?.name}</div>
      </NameLabel>
      <BaseLabel size="md"> {}</BaseLabel>
      <PriceWrapper>
        <BillWrapper>
          <BaseLabel size="md">
            {t("History.quantity")} {product.quantity}
          </BaseLabel>
          <BaseLabel size="md">
            {t("History.unit-price")}{" "}
            {formatCurrency(locale, product.display_price / 100)}
          </BaseLabel>

          <BaseLabel size="md">
            {t("History.format")} {product.volume / 100} {product.unit}
          </BaseLabel>
        </BillWrapper>
        <PriceLabel size="lg">
          {formatCurrency(
            locale,
            (product.display_price * product.quantity) / 100
          )}
        </PriceLabel>
      </PriceWrapper>
    </OrderCell>
  );
};

const OrderCell = styled.li`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing[1]} 0;
  border-bottom: 1px solid ${theme.colors.base.grey};

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
    padding: ${theme.spacing[1.5]} ${theme.spacing[1]};
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-left: ${theme.spacing[1.5]};
  }
`;

const BillWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${theme.spacing[1.5]};

  > div {
    margin-right: ${theme.spacing[1.5]};
  }

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:hover {
      fill: ${theme.colors.base.primary};
    }
  }
`;

const BaseLabel = styled(Label)`
  /* line-height: ${theme.font.size.mdx};
  font-family: ${theme.font.family.secondary}; */
  /* border-right: 1px solid #c4c4c4; */
`;

const NameLabel = styled(BaseLabel)`
  display: flex;
  width: 100%;
  margin-bottom: ${theme.spacing[1]};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.secondary};
  padding-right: 20px;

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 300px;
    margin-bottom: 0;
    border-right: 1px solid #c4c4c4;
  }
`;

const PriceLabel = styled(BaseLabel)`
  flex: 1;
  margin-right: ${theme.spacing[1.5]};
  line-height: ${theme.font.size.xl};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.colors.base.primary};
  text-align: left;

  @media (min-width: ${theme.breakpoints.sm}) {
    text-align: right;
  }
`;
