import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { Button } from "../Button";
import { Label } from "../Label";
import { Text } from "../Text";

import { theme } from "../theme";

interface Props {
  times: number;
  type?: "normal" | "warning" | "info" | "yellow" | "white";
  fix?: boolean;
}

export const FlashBanner: React.FC<Props> = ({
  times,
  type,
  fix,
  children,
}) => {
  const messages = [];

  for (let i = 0; i < times; ++i) {
    messages.push(
      <span aria-hidden={i > 0}>
        {children}
        <SpacerStyled aria-hidden={true} />
      </span>
    );
  }

  if (fix) {
    return (
      <FlashBannerStyled type={type}>
        <BannerCentered weight="bold" size="mdx">
          {children}
        </BannerCentered>
      </FlashBannerStyled>
    );
  }

  return (
    <FlashBannerStyled type={type}>
      <ContentStyled type={type}>
        {messages.map((message, index) => (
          <Fragment key={index}>{message}</Fragment>
        ))}
      </ContentStyled>
    </FlashBannerStyled>
  );
};

const BannerCentered = styled(Text)`
  display: flex;
  justify-content: center;
  background-color: ${theme.colors.base.third};
  text-align: center;
`;

const FlashBannerStyled = styled.div<Pick<Props, "type" | "fix">>`
  width: 100%;
  padding: ${theme.spacing[0.5]};
  background-color: ${theme.colors.accent.primary};
  overflow-x: hidden;
  font-weight: 600; 
  ${(props) =>
    props.type === "warning" &&
    css`
      background-color: ${theme.colors.base.primary};
    `}

  ${(props) =>
    props.type === "white" &&
    css`
      background-color: ${theme.colors.base.white};
    `}

  ${(props) =>
    props.type === "info" &&
    css`
      background-color: ${theme.colors.status.info};
    `}
  ${(props) =>
    props.type === "normal" &&
    css`
      background-color: ${theme.colors.base.secondary};
    `}

    ${(props) =>
    props.type === "yellow" &&
    css`
      background-color: ${theme.colors.base.third};
    `}
`;

const ContentStyled = styled.p<Pick<Props, "type">>`
  margin-left: -${theme.spacing[3]};
  color: ${theme.colors.base.white};
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  animation: rtl-moving 25s infinite linear;
  ${(props) =>
    props.type === "yellow" &&
    css`
      color: ${theme.colors.base.dark};
    `}
  @keyframes rtl-moving {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }
`;

const SpacerStyled = styled.span`
  padding-left: ${theme.spacing[0.5]};
  padding-right: ${theme.spacing[0.5]};

  &:before {
    content: "▪";
  }
`;
