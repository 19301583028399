import React from "react";
import styled from "styled-components";

import { Heading } from "../../components/Heading";
import { Section } from "../../components/Section";
import { Text } from "../../components/Text";
import { theme } from "../theme";
import config from "../../config";
import { useTranslation } from "react-i18next";
import { Link } from "../../components/Link";

export const AlternateLayout: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CommitmentStyled>
      <SectionStyled size="lg">
        <GridSection>
          <ItemContentStyled>
            <IconStyled>{mySvg}</IconStyled>
            <HeadingStyled>
              {t("About.AlternatateLayout.heading1")}
            </HeadingStyled>
            <Text>
              <span>{t("About.AlternatateLayout.content1")}</span>
              <br></br>
              <br></br>

              <span>{t("About.AlternatateLayout.content2")}</span>
            </Text>
          </ItemContentStyled>
          <ItemContentStyled>
            <IconStyled>{svgHeart}</IconStyled>

            <HeadingStyled>
              {t("About.AlternatateLayout.heading2")}
            </HeadingStyled>
            <Text>
              <span>{t("About.AlternatateLayout.content3")}</span>
              <br></br>
              <br></br>
            </Text>
            <Link to="/products">
              <b>{t("About.AlternatateLayout.content4")}</b>
            </Link>
          </ItemContentStyled>
          <ItemPictureStyled>
            <picture>
              <source
                srcSet={`${config.CDN_URL}/common/images/placeholder-about-us-1.webp`}
                type="image/webp"
              />
              <source
                srcSet={`${config.CDN_URL}/common/images/placeholder-about-us-1.png`}
                type="image/png"
              />
              <img
                src={`${config.CDN_URL}/common/images/placeholder-about-us-1.png`}
                alt="Placeholder"
                loading="lazy"
              />
            </picture>
          </ItemPictureStyled>
          <ItemContentStyled1>
            <IconStyled>{svgWorldFruit}</IconStyled>

            <HeadingStyled>
              {t("About.AlternatateLayout.heading3")}
            </HeadingStyled>
            <Text weight="bold">
              <span>{t("About.AlternatateLayout.content5")}</span>
              <br></br>
              <br></br>
            </Text>
          </ItemContentStyled1>
        </GridSection>
      </SectionStyled>
    </CommitmentStyled>
  );
};

const GridSection = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(150px, 1fr));
  @media (min-width: ${theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
`;
const ItemPictureStyled = styled.picture`
  img,
  source {
    max-width: 100%;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    position: flex;
    bottom: 0%;
    left: 0%;
    max-width: 80%;

    img,
    source {
      max-width: 100%;
    }
  }
`;
const SectionStyled = styled(Section)`
  position: relative;
  width: 100%;
  padding: 0;
  background-color: ${theme.colors.base.white};
`;

const CommitmentStyled = styled.ul`
  position: relative;
  width: 100%;
  margin-top: ${theme.spacing[2]};
  padding: ${theme.spacing[2]};

  &:before {
    content: "";
    position: absolute;
    top: calc(50% + ${theme.spacing[2]});
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${theme.colors.accent.secondary};
  }
`;

const HeadingStyled = styled(Heading)`
  color: ${theme.colors.base.primary};
`;

const ItemContentStyled = styled.div`
  padding: ${theme.spacing[2]};
`;

const ItemContentStyled1 = styled.div`
  padding-left: ${theme.spacing[2]};
  padding-right: ${theme.spacing[2]};
`;

const IconStyled = styled.span`
  svg {
    max-width: 80px;
    max-height: 80px;
  }
`;

const mySvg = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="4501.000000pt"
    height="4501.000000pt"
    viewBox="0 0 4501.000000 4501.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,4501.000000) scale(0.100000,-0.100000)"
      fill="#EE5340"
      stroke="none"
    >
      <path
        d="M25456 34644 c-102 -23 -386 -117 -527 -175 -604 -248 -964 -564
-1074 -943 -35 -118 0 -253 85 -330 22 -20 77 -54 122 -77 249 -125 400 -296
442 -501 15 -71 22 -198 12 -198 -3 0 -73 39 -156 86 -509 291 -1077 458
-1715 505 -200 14 -664 6 -870 -16 -880 -93 -1786 -375 -2780 -865 -1004 -495
-1857 -1109 -2655 -1909 -1749 -1754 -2847 -4128 -3074 -6651 -55 -605 -55
-1308 0 -1910 165 -1815 804 -3563 1895 -5185 436 -648 879 -1186 1439 -1746
303 -303 453 -441 715 -659 1179 -982 2469 -1585 3624 -1694 188 -18 541 -21
711 -6 374 34 721 124 1082 281 1013 441 1737 656 2503 740 170 19 277 22
1030 29 816 8 974 14 1215 46 369 49 792 189 1113 370 1148 645 2009 2025
2641 4236 123 430 287 1112 350 1453 237 1289 183 2452 -161 3510 -232 712
-571 1321 -1069 1920 -145 176 -574 602 -763 759 -809 673 -1859 1227 -2800
1477 -64 17 -117 32 -119 34 -1 1 4 67 13 146 34 317 30 763 -11 1129 -33 305
-116 730 -204 1055 -126 462 -364 1057 -470 1178 l-42 47 40 93 c66 155 141
363 198 552 309 1027 262 1994 -137 2775 -132 260 -235 388 -342 428 -78 29
-175 35 -261 16z m-2756 -2820 c320 -37 620 -120 889 -246 107 -50 333 -180
386 -222 l29 -23 -18 -29 c-11 -16 -87 -97 -170 -180 -164 -164 -293 -259
-461 -341 -113 -55 -282 -110 -300 -97 -7 5 -59 49 -116 98 -283 241 -744 485
-1091 578 -105 28 -262 30 -358 5 -202 -54 -365 -183 -446 -352 -115 -241 -74
-497 110 -679 84 -84 165 -130 322 -181 202 -66 438 -197 569 -316 l55 -51 0
-72 c0 -152 55 -406 124 -578 96 -237 212 -408 434 -638 128 -134 190 -178
300 -216 50 -18 77 -34 106 -66 52 -57 100 -160 131 -283 68 -262 230 -449
445 -516 82 -25 221 -27 300 -3 200 60 351 220 407 434 25 96 22 260 -7 380
-30 125 -102 334 -150 437 l-39 83 72 42 c308 179 586 398 854 670 l142 144
15 -40 c56 -148 148 -487 195 -716 71 -349 101 -636 101 -977 0 -357 -41 -691
-110 -893 -16 -47 -34 -112 -39 -144 -12 -68 -103 -300 -166 -426 -124 -246
-292 -478 -485 -670 -146 -146 -263 -239 -405 -322 -177 -104 -284 -243 -321
-418 -51 -241 73 -516 284 -628 32 -17 127 -55 212 -85 492 -172 1104 -462
1528 -724 384 -237 682 -464 941 -717 345 -339 556 -644 698 -1011 48 -122 97
-199 171 -265 110 -98 246 -150 394 -150 158 0 288 52 403 161 152 145 217
357 170 555 -33 141 -192 488 -322 702 -437 720 -1188 1388 -2170 1928 -131
72 -553 286 -587 297 -6 2 39 64 98 138 118 148 233 312 319 460 47 80 198
377 210 413 4 13 71 -2 263 -59 995 -299 2033 -936 2683 -1649 558 -611 915
-1273 1106 -2051 98 -396 135 -704 142 -1171 10 -638 -46 -1117 -217 -1859
-658 -2849 -1611 -4374 -2895 -4630 -213 -42 -322 -47 -1215 -55 -473 -5 -904
-13 -965 -19 -955 -94 -1727 -309 -2887 -804 -320 -137 -421 -170 -643 -208
-163 -29 -504 -36 -705 -15 -1127 119 -2440 849 -3591 1996 -1321 1317 -2315
3053 -2753 4805 -475 1902 -323 3980 429 5850 505 1257 1223 2342 2161 3265
683 672 1395 1179 2254 1604 924 458 1779 716 2542 766 167 11 474 3 638 -16z"
      />
    </g>
  </svg>
);

const svgHeart = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="4501.000000pt"
    height="4500.000000pt"
    viewBox="0 0 4501.000000 4500.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,4500.000000) scale(0.100000,-0.100000)"
      fill="#EE5340"
      stroke="none"
    >
      <path
        d="M19290 32173 c-25 -2 -117 -12 -205 -23 -714 -89 -1373 -365 -1923
-802 -164 -131 -450 -417 -581 -583 -525 -660 -828 -1463 -901 -2389 -15 -191
-12 -521 5 -671 112 -958 767 -2276 1848 -3720 461 -615 954 -1188 1502 -1744
1439 -1460 2741 -2245 3585 -2162 216 22 380 64 633 163 896 350 2130 1377
3314 2756 1343 1563 2403 3315 2677 4424 89 356 102 587 60 1008 -143 1431
-1025 2727 -2284 3356 -415 207 -837 332 -1284 379 -190 20 -570 20 -764 0
-883 -91 -1724 -480 -2344 -1084 l-128 -124 -95 92 c-685 655 -1509 1034
-2430 1121 -100 9 -577 11 -685 3z m775 -851 c202 -31 354 -68 545 -132 476
-159 876 -405 1230 -760 159 -159 235 -251 469 -575 96 -132 177 -244 181
-248 4 -5 90 105 191 245 203 279 285 383 397 503 424 454 1002 786 1602 921
213 48 362 65 605 71 254 6 413 -7 626 -51 778 -162 1493 -658 1982 -1377 299
-440 485 -915 570 -1459 17 -110 21 -182 21 -395 1 -252 0 -264 -27 -380 -375
-1590 -2719 -4735 -4562 -6120 -282 -211 -512 -356 -757 -475 -174 -85 -301
-131 -437 -160 -148 -32 -339 -25 -498 19 -754 205 -1885 1095 -3039 2391
-1500 1685 -2531 3427 -2655 4485 -22 186 -1 591 46 870 155 926 630 1691
1342 2161 404 267 874 428 1414 484 152 15 607 5 754 -18z"
      />
      <path
        d="M12435 25506 c-321 -64 -555 -284 -650 -613 -41 -141 -57 -269 -57
-468 1 -430 100 -937 338 -1724 143 -472 320 -983 729 -2106 313 -860 382
-1054 545 -1545 130 -393 207 -582 350 -865 484 -955 1281 -1912 2430 -2916
185 -161 444 -379 744 -624 120 -98 232 -197 248 -219 104 -143 157 -339 179
-664 29 -429 -57 -1105 -216 -1697 -54 -200 -61 -251 -46 -327 63 -303 410
-437 657 -255 105 77 132 132 203 407 269 1045 308 1985 110 2592 -37 114
-122 293 -182 385 -81 126 -163 209 -385 390 -286 233 -543 449 -722 604
-1109 962 -1882 1887 -2309 2764 -114 234 -169 376 -311 805 -140 423 -232
683 -520 1470 -659 1800 -934 2702 -1016 3330 -29 229 -8 450 43 450 58 -1
230 -134 364 -283 333 -371 664 -863 1364 -2032 345 -576 485 -804 615 -1005
l88 -135 11 -117 c58 -609 474 -1512 1369 -2972 195 -317 238 -371 338 -418
211 -99 449 -17 551 188 31 64 37 88 41 158 7 130 -8 166 -187 456 -545 881
-913 1563 -1116 2065 -79 197 -123 335 -160 499 l-6 29 73 -6 c253 -21 540
-181 816 -457 52 -52 246 -269 431 -484 570 -660 857 -972 1247 -1356 643
-633 1259 -1120 1924 -1521 186 -112 289 -189 391 -289 188 -186 301 -396 363
-670 40 -184 49 -311 43 -624 -6 -291 -20 -458 -77 -898 -105 -812 -143 -1233
-120 -1344 26 -127 114 -240 229 -297 62 -30 72 -32 177 -32 107 0 112 1 186
37 90 45 148 103 190 194 32 69 29 47 63 466 16 193 35 363 104 898 85 673
106 1015 80 1355 -42 567 -200 989 -507 1356 -174 207 -397 387 -712 574 -649
384 -1311 928 -2004 1645 -289 298 -485 518 -1132 1265 -190 220 -394 426
-528 535 -428 347 -915 517 -1368 479 -68 -5 -133 -11 -146 -12 -29 -2 -54 36
-520 813 -569 948 -865 1410 -1129 1765 -436 586 -796 894 -1152 986 -99 26
-265 33 -356 15z"
      />
      <path
        d="M32305 25514 c-271 -44 -534 -201 -818 -488 -372 -377 -744 -918
-1517 -2206 -183 -305 -380 -631 -438 -724 l-104 -168 -52 6 c-383 42 -690
-10 -1024 -174 -373 -183 -624 -404 -1132 -995 -537 -624 -817 -933 -1170
-1286 -630 -631 -1180 -1071 -1835 -1469 -319 -194 -501 -337 -668 -528 -416
-475 -581 -1042 -546 -1874 11 -263 32 -478 104 -1043 65 -515 80 -657 99
-920 16 -222 28 -269 90 -354 44 -59 138 -125 209 -146 65 -19 179 -19 244 0
126 38 235 148 275 279 19 62 20 84 15 215 -10 218 -31 421 -102 996 -82 659
-95 798 -102 1076 -11 432 37 704 169 962 66 129 142 229 259 342 112 108 171
151 400 290 931 569 1750 1283 2724 2380 88 99 286 326 440 505 391 455 522
586 721 720 163 111 399 200 526 200 l50 0 -7 -42 c-71 -428 -590 -1461 -1316
-2620 -116 -184 -138 -235 -146 -328 -26 -314 307 -539 596 -403 97 46 150
112 346 433 743 1217 1148 2035 1301 2627 33 130 64 324 64 404 0 31 11 56 49
112 101 151 287 452 629 1021 435 724 567 937 793 1276 198 297 366 527 516
705 205 245 418 416 467 376 63 -53 48 -396 -35 -786 -137 -649 -394 -1450
-974 -3035 -283 -775 -358 -987 -510 -1445 -146 -439 -203 -582 -336 -846
-336 -666 -849 -1333 -1580 -2054 -381 -375 -730 -685 -1300 -1152 -162 -132
-323 -270 -358 -306 -188 -193 -322 -471 -391 -817 -48 -241 -55 -328 -55
-685 0 -350 10 -484 61 -830 57 -386 186 -955 246 -1082 44 -94 140 -177 246
-212 213 -72 456 51 522 266 31 100 26 165 -25 353 -104 381 -185 838 -215
1214 -15 187 -12 511 5 656 26 229 91 422 176 527 19 23 151 137 294 254 618
504 974 820 1383 1223 787 776 1352 1516 1732 2266 153 304 223 477 380 950
143 428 271 788 598 1685 230 629 363 1007 489 1385 550 1657 639 2510 308
2975 -105 148 -272 266 -447 315 -72 21 -257 34 -323 24z"
      />
    </g>
  </svg>
);

const svgWorldFruit = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="4501.000000pt"
    height="4500.000000pt"
    viewBox="0 0 4501.000000 4500.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,4500.000000) scale(0.100000,-0.100000)"
      fill="#EE5340"
      stroke="none"
    >
      <path
        d="M22985 37596 c-295 -78 -717 -349 -1177 -754 -551 -485 -937 -1230
-1127 -2178 -67 -333 -112 -733 -131 -1169 -6 -137 -12 -259 -13 -270 -2 -15
-15 -2 -52 49 -398 554 -864 1030 -1400 1431 -649 486 -1277 779 -1955 915
-393 78 -877 91 -1295 34 -282 -39 -738 -142 -955 -216 -377 -129 -562 -280
-646 -528 -14 -42 -19 -83 -18 -170 0 -103 4 -125 32 -209 42 -121 94 -220
201 -384 123 -187 487 -668 620 -819 293 -333 651 -608 1071 -822 281 -143
449 -209 885 -346 519 -163 845 -234 1200 -260 360 -27 654 -69 896 -130 964
-244 1465 -893 1743 -2261 42 -207 85 -467 78 -474 -3 -3 -19 -5 -36 -5 -47 0
-328 -37 -541 -71 -1011 -161 -1790 -432 -2739 -954 -1256 -690 -2314 -1655
-3105 -2834 -1079 -1608 -1601 -3535 -1480 -5466 123 -1978 906 -3845 2248
-5360 154 -174 552 -572 727 -726 1510 -1332 3360 -2095 5358 -2209 229 -13
763 -13 992 0 1851 106 3598 779 5044 1944 342 275 804 719 1086 1041 1326
1519 2085 3368 2204 5370 30 511 -4 1368 -76 1905 -223 1672 -898 3137 -2010
4360 -380 418 -947 923 -1494 1330 -593 441 -1288 828 -1970 1097 -91 35 -226
89 -300 119 -74 29 -155 58 -180 63 -25 6 -119 33 -210 61 -676 211 -1416 334
-2241 376 l-126 6 -7 276 c-3 153 -9 378 -12 502 -7 252 3 396 41 586 63 310
194 563 400 770 218 220 447 353 873 509 672 244 1189 560 1647 1007 165 160
281 295 383 442 173 249 296 533 358 823 29 138 79 574 94 831 27 444 -40 647
-249 749 -51 25 -66 28 -166 28 -128 0 -168 -11 -390 -110 -205 -91 -315 -130
-455 -160 -69 -15 -177 -38 -240 -52 -353 -76 -748 -222 -1055 -389 -61 -32
-114 -61 -119 -63 -5 -2 9 30 32 70 64 117 146 312 195 464 136 420 156 721
87 1340 -25 232 -30 309 -25 385 15 191 11 281 -12 332 -29 63 -96 134 -158
166 -73 38 -206 41 -330 8z m-60 -1001 c15 -148 30 -329 33 -401 15 -404 -123
-839 -396 -1249 -129 -192 -248 -341 -469 -583 -176 -193 -290 -328 -340 -404
-14 -21 -28 -37 -30 -35 -8 9 141 415 240 652 180 432 471 1000 689 1347 31
48 61 108 68 133 19 68 8 162 -26 225 -33 62 -70 95 -143 128 -46 21 -64 24
-130 20 -86 -5 -140 -28 -194 -82 -37 -37 -209 -318 -331 -541 -226 -413 -441
-876 -596 -1287 -35 -92 -65 -166 -67 -164 -7 7 37 236 77 401 173 718 464
1257 875 1627 122 110 351 283 551 418 126 84 150 97 156 82 3 -9 18 -138 33
-287z m-6167 -1521 c598 -69 1170 -293 1742 -682 621 -421 1167 -970 1590
-1597 91 -134 212 -259 296 -303 126 -65 268 -77 398 -32 33 11 60 20 61 20 1
0 5 -109 8 -242 4 -185 13 -302 36 -493 17 -137 30 -253 28 -258 -1 -5 -25 27
-51 70 -77 122 -311 467 -400 588 -375 512 -737 911 -1071 1180 -680 547
-1862 1100 -3152 1475 -223 65 -276 71 -359 43 -67 -23 -154 -110 -177 -177
-36 -105 -10 -229 63 -305 52 -53 69 -61 255 -116 1014 -298 1938 -688 2570
-1083 323 -203 562 -397 801 -652 109 -116 244 -268 244 -274 0 -3 -53 13
-117 35 -315 106 -695 175 -1173 214 -432 34 -661 85 -1285 286 -307 99 -438
150 -625 244 -310 154 -551 327 -790 565 -148 147 -239 257 -461 555 -199 268
-323 449 -362 533 -29 62 -29 63 -11 83 11 12 37 32 59 44 196 115 899 273
1325 299 114 7 427 -4 558 -20z m8513 -329 c-49 -566 -90 -762 -211 -1010
-204 -417 -682 -864 -1264 -1182 -185 -101 -379 -185 -701 -304 -151 -55 -362
-152 -487 -224 -42 -25 -80 -45 -83 -45 -4 0 24 39 61 88 406 522 927 1024
1579 1518 210 159 238 191 257 293 44 238 -206 425 -422 316 -84 -43 -480
-351 -765 -596 -744 -640 -1293 -1298 -1649 -1980 -54 -102 -66 -119 -70 -98
-3 13 -20 143 -38 289 -29 239 -33 292 -33 535 0 149 4 301 8 339 l9 68 36
-35 c112 -107 289 -116 400 -22 22 19 83 99 135 178 716 1074 1521 1647 2617
1862 183 36 340 83 495 147 l130 54 3 -30 c2 -17 -1 -89 -7 -161z m-3771
-4862 c0 -269 -4 -481 -9 -473 -4 8 -16 56 -25 105 -10 50 -51 249 -92 444
-41 195 -73 355 -72 356 7 4 175 53 186 54 9 1 12 -103 12 -486z m626 -1523
c286 -10 532 -30 779 -61 356 -44 925 -155 925 -181 0 -4 -14 -36 -31 -69 -42
-84 -82 -197 -110 -309 -19 -80 -23 -122 -23 -265 -1 -200 11 -259 82 -405 39
-79 60 -109 132 -180 122 -121 238 -185 398 -216 63 -13 125 -15 344 -9 251 6
267 5 272 -12 3 -10 15 -74 26 -143 49 -295 105 -432 323 -797 168 -281 204
-379 194 -524 -7 -103 -43 -184 -181 -413 -200 -330 -252 -476 -277 -779 -15
-172 -6 -596 15 -693 47 -225 128 -343 429 -617 125 -115 168 -173 284 -379
108 -195 177 -290 283 -398 117 -118 202 -179 355 -255 252 -125 467 -168 973
-195 271 -15 348 -28 427 -73 87 -49 125 -133 174 -378 106 -528 107 -911 4
-1244 -14 -44 -65 -165 -114 -268 -193 -406 -262 -628 -300 -972 -7 -60 -10
-240 -8 -455 5 -487 1 -623 -17 -707 -10 -49 -45 -132 -108 -261 -52 -104
-111 -231 -131 -282 -177 -443 -31 -837 360 -967 73 -25 93 -27 235 -27 136 0
167 4 252 27 203 54 359 141 668 373 74 56 166 127 205 157 38 31 71 54 73 52
12 -12 -242 -441 -411 -692 -204 -305 -398 -558 -655 -853 -156 -180 -562
-587 -742 -745 -449 -392 -921 -726 -1423 -1007 -87 -48 -160 -88 -162 -88 -1
0 -34 39 -72 88 -95 120 -304 358 -361 410 -99 91 -207 154 -337 195 -139 43
-215 50 -625 52 -488 2 -524 7 -835 130 -258 102 -432 134 -614 116 -187 -20
-302 -61 -551 -199 -387 -214 -626 -297 -919 -318 l-93 -7 8 74 c16 132 11
396 -9 468 -63 230 -220 385 -462 456 -130 37 -329 34 -472 -8 -46 -13 -83
-22 -83 -20 0 3 18 26 41 51 51 58 113 185 135 277 23 94 23 291 0 410 -22
119 -46 203 -118 425 -56 171 -61 192 -61 275 1 99 29 207 81 300 28 52 21 47
251 181 46 27 116 79 157 117 128 119 177 218 197 395 l11 104 41 28 c57 39
153 144 209 228 71 107 120 220 171 397 26 88 58 189 71 225 47 125 163 251
319 344 35 21 140 62 259 101 404 132 558 238 666 460 83 171 99 282 110 765
12 526 15 540 181 719 193 209 287 360 329 525 36 144 21 330 -40 470 -118
277 -513 549 -885 610 -49 9 -166 20 -260 26 -193 11 -226 18 -283 59 -90 66
-324 402 -475 681 -162 300 -259 431 -392 525 -167 120 -387 160 -660 120 -55
-8 -123 -15 -151 -15 -50 0 -53 2 -105 58 -30 32 -94 116 -144 188 -216 310
-366 445 -568 509 -235 75 -447 45 -787 -110 -150 -69 -168 -73 -356 -85 -197
-11 -302 -37 -479 -115 -366 -162 -444 -171 -515 -58 -54 85 -71 159 -79 350
-7 166 -9 174 -40 237 -51 104 -144 174 -260 196 -30 5 -145 10 -257 10 -112
0 -204 4 -204 8 0 18 59 99 91 126 51 43 133 77 200 83 l61 6 211 -107 c268
-135 341 -156 568 -163 184 -6 268 8 380 64 185 93 277 262 313 579 22 184 25
200 47 219 10 10 72 47 138 84 209 115 360 231 701 540 102 92 245 219 319
282 353 302 375 318 485 358 70 25 75 25 281 21 447 -10 672 -5 775 17 325 70
531 257 627 570 25 82 27 101 27 268 0 159 -3 193 -26 290 -42 178 -142 417
-243 583 -20 32 -23 43 -13 47 11 4 340 19 538 23 30 1 172 -3 316 -8z m-1597
-244 c62 -71 160 -215 203 -299 16 -32 45 -107 65 -165 33 -95 37 -119 37
-209 1 -90 -1 -105 -19 -123 -37 -37 -117 -43 -425 -34 -469 15 -588 7 -765
-51 -171 -56 -349 -163 -530 -320 -55 -48 -152 -131 -215 -184 -63 -54 -200
-176 -305 -270 -339 -308 -433 -381 -612 -477 -207 -110 -305 -192 -382 -318
-67 -111 -101 -221 -127 -406 -9 -63 -18 -116 -19 -118 -9 -11 -108 29 -219
89 -230 124 -355 166 -523 176 -416 25 -826 -221 -1005 -603 -65 -139 -81
-216 -82 -389 -1 -183 17 -253 95 -369 60 -91 119 -143 219 -195 100 -52 199
-79 346 -93 l122 -12 22 -91 c79 -324 255 -563 506 -685 128 -64 215 -82 379
-83 204 0 333 33 607 157 175 79 191 83 353 91 227 12 343 43 610 163 61 28
129 55 152 61 77 21 107 -8 299 -278 174 -246 287 -354 449 -432 165 -79 315
-95 579 -60 152 20 161 20 179 3 33 -29 71 -91 177 -286 141 -262 359 -585
503 -749 160 -181 374 -299 612 -337 33 -5 136 -14 228 -20 93 -5 189 -15 215
-21 102 -22 312 -151 312 -191 0 -27 -54 -97 -174 -226 -173 -186 -242 -306
-296 -510 -42 -158 -51 -256 -60 -657 -4 -214 -10 -397 -13 -405 -3 -8 -8 -27
-11 -41 -7 -33 -45 -50 -300 -133 -105 -35 -229 -82 -276 -106 -248 -125 -482
-338 -612 -558 -61 -101 -113 -234 -173 -440 -60 -204 -87 -252 -175 -304
-181 -109 -274 -266 -289 -486 l-6 -96 -100 -56 c-144 -80 -191 -114 -275
-200 -158 -161 -281 -456 -310 -745 -19 -183 -2 -296 85 -565 72 -225 105
-353 108 -415 l2 -50 -59 -3 c-72 -4 -106 9 -147 56 -66 75 -101 188 -184 591
-31 151 -72 320 -90 377 -50 153 -131 302 -300 554 -178 265 -198 299 -231
388 -42 109 -55 195 -61 404 -8 280 4 376 78 609 82 259 101 350 102 489 1
180 -41 315 -148 475 -97 146 -209 249 -520 476 -184 134 -313 250 -350 314
-13 22 -53 114 -90 205 -126 314 -199 429 -440 704 -212 241 -256 309 -296
461 -21 82 -37 291 -25 338 3 14 53 85 112 158 167 212 222 320 246 483 23
165 -14 315 -100 403 -52 54 -107 81 -256 127 -225 71 -270 101 -424 283 -176
210 -316 309 -515 367 -87 25 -176 37 -426 56 -79 6 -154 15 -167 20 -29 11
-42 38 -89 180 -92 277 -211 484 -407 702 l-69 77 77 121 c97 155 289 428 416
595 723 948 1580 1731 2508 2287 682 409 1549 739 2400 912 l165 33 35 -30
c19 -17 59 -58 89 -91z m4137 -247 c428 -178 1008 -473 1446 -736 366 -219
860 -566 1104 -776 254 -219 588 -555 832 -839 1126 -1311 1792 -2927 1923
-4667 13 -185 8 -729 -11 -971 -44 -574 -146 -1188 -276 -1655 -35 -122 -41
-135 -105 -222 -235 -316 -503 -589 -912 -931 -168 -141 -445 -351 -568 -432
-97 -64 -179 -98 -179 -75 0 7 32 76 71 152 137 267 193 423 220 613 8 58 11
238 10 575 -3 730 12 807 249 1304 48 102 102 226 119 275 156 445 171 983 46
1612 -76 380 -184 615 -363 786 -201 192 -451 270 -952 298 -608 33 -784 118
-979 471 -170 306 -263 424 -504 636 -59 53 -115 109 -125 127 -14 26 -17 68
-20 249 -4 242 7 372 40 474 13 39 71 148 136 258 226 376 292 563 292 829 0
273 -71 478 -297 856 -182 304 -197 341 -238 580 -33 188 -54 261 -108 365
-105 202 -328 339 -593 364 -73 7 -152 5 -285 -5 -198 -15 -234 -13 -249 16
-28 53 0 211 60 335 32 67 103 175 114 175 2 0 48 -18 102 -41z m-10163 -4307
c48 -74 98 -183 146 -324 27 -79 58 -164 69 -190 63 -144 184 -275 318 -345
137 -71 240 -93 562 -118 152 -12 200 -19 231 -35 25 -12 82 -69 155 -154 156
-180 296 -299 429 -362 20 -10 37 -20 37 -23 0 -4 -24 -38 -53 -76 -70 -95
-121 -193 -153 -300 -21 -74 -27 -117 -31 -242 -6 -175 6 -292 48 -464 68
-281 160 -438 447 -767 204 -235 225 -266 310 -482 117 -294 175 -392 322
-544 105 -108 189 -178 415 -342 166 -121 295 -237 319 -289 15 -31 15 -41 2
-102 -8 -37 -37 -138 -65 -223 -27 -85 -57 -185 -66 -221 -45 -194 -59 -594
-30 -841 41 -348 107 -510 373 -906 166 -249 211 -326 243 -420 10 -30 43
-177 74 -328 60 -295 103 -452 164 -594 110 -254 242 -411 432 -510 134 -70
208 -85 409 -85 134 1 189 5 236 18 34 9 65 17 69 17 4 0 -33 -42 -81 -93 -49
-51 -130 -151 -181 -223 -50 -71 -101 -138 -112 -148 -11 -10 -85 -44 -165
-76 -259 -103 -421 -204 -552 -343 -129 -137 -209 -292 -254 -492 -13 -55 -24
-101 -26 -103 -9 -12 -436 203 -687 345 -656 374 -1184 778 -1732 1327 -263
263 -410 427 -618 688 -961 1206 -1565 2686 -1721 4218 -55 534 -55 1131 0
1670 74 736 258 1486 529 2160 102 253 148 360 156 360 4 0 18 -17 32 -38z
m6033 -9824 c-4 -45 -18 -139 -32 -208 -33 -169 -39 -255 -25 -332 25 -124
105 -238 212 -300 117 -68 358 -105 615 -95 422 18 768 125 1263 391 211 114
243 126 330 126 68 0 149 -25 381 -115 117 -46 282 -88 431 -111 90 -14 195
-18 498 -20 247 -2 401 -7 432 -14 38 -9 60 -24 103 -68 83 -88 216 -241 216
-251 0 -18 -393 -152 -687 -235 -1405 -397 -3037 -415 -4618 -50 -134 31 -406
102 -411 107 -5 5 17 184 27 221 17 63 58 133 105 176 48 44 162 104 272 144
268 96 404 200 566 435 43 62 102 138 132 171 54 57 138 110 177 110 19 0 19
-5 13 -82z"
      />
    </g>
  </svg>
);
