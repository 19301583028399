import React, { useState } from "react";
import styled from "styled-components";
import { Label } from "../Label";
import { theme } from "../theme";
import { useAppState } from "../../contexts";
import { TCategory } from "../../types/Category";
import { icons } from "../../assets/icons";
import { isMobile } from "react-device-detect";

interface Props {
  category: TCategory;
  subcategories?: TCategory[];
  onSelectCategory: (category: TCategory) => void;
}

const Category: React.FC<Props> = ({
  category,
  subcategories,
  onSelectCategory,
}) => {
  const { locale } = useAppState();
  const [lang] = locale.split("-");
  const [expanded, setExpanded] = useState(false);
  const handleCategorySelected = (cat: TCategory) => () => {
    if (cat.cid === "fresh-items-HSlcfFkP") {
      onSelectCategory(cat);
    }
    if (!isMobile) {
      onSelectCategory(cat);
    }

    if (cat.cid === category.cid) {
      setExpanded(!expanded);
    }
  };

  return (
    <CategoryWrapper key={category.cid}>
      <LabelWrapper onClick={() => onSelectCategory(category)} expanded={expanded}>
        {category.cid === "vegetables-pkdPWTx2" ||
        category.cid === "fruits-ZLog10uh" ? (
          <>
            <CategoryLabel
              size="mdx"
              weight="bold"
              onClick={() => onSelectCategory(category)}
            >
              {category.copy[lang].name}
            </CategoryLabel>
          </>
        ) : (
          <>
            <CategoryLabel
              size="mdx"
              weight="bold"
              onClick={handleCategorySelected(category)}
            >
              {category.copy[lang].name}
            </CategoryLabel>
            <icons.plainChevronDown onClick={() => setExpanded(!expanded)} />
          </>
        )}
      </LabelWrapper>
      <SubcategoriesWrapper expanded={expanded}>
        {subcategories?.map((subcategory) => (
          <SubcategoryLabel
            key={subcategory.cid}
            size="md"
            onClick={() => onSelectCategory(subcategory)}
          >
            {subcategory.copy[lang].name}
          </SubcategoryLabel>
        ))}
      </SubcategoriesWrapper>
    </CategoryWrapper>
  );
};

export const CategoryMemorized = React.memo(Category);

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing[1]};
  margin-right: 0;
  border-bottom: 1px solid ${theme.colors.accent.secondary};

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-right: ${theme.spacing[5]};
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const LabelWrapper = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: ${theme.breakpoints.md}) {
    margin-bottom: ${theme.spacing[1]};

  }

  svg {
    display: block;
    transform: rotate(-90deg);
    transition: transform ${theme.transition.fast};
  }

  &:hover {
    svg,
    svg path {
      fill: ${theme.colors.accent.secondary};
    }
  }

  ${(props) =>
    props.expanded &&
    `
    svg {
      transform: rotate(0deg);
    }
  `}

`;

const CategoryLabel = styled(Label)`
  flex: 1;
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.secondary};
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.base.primary};
  }
`;

const SubcategoriesWrapper = styled.div<{ expanded: boolean }>`
  max-height: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  overflow: hidden;
  transition: all ${theme.transition.fast};

  ${(props) =>
    props.expanded &&
    `
  padding-top: 0.5rem;
    max-height: 9000px;
  `}

`;

const SubcategoryLabel = styled(Label)`
  margin-bottom: ${theme.spacing[0.5]};
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.mdx};
  color: ${theme.colors.base.dark};
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.base.primary};
  }
`;
