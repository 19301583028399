import config from "../../config";
import axios from "redaxios";
import { TCoupon } from "../../types/Coupon";
import { useQuery } from "react-query";

const apiUrlCoupon = `${config.SERVICES.MARKETPLACE_API_URL}/coupons`;

/**
 * update coupon to user
 * @returns The Coupon object
 */
export const useUpdateCouponOrder = async (payload: { code: string }) => {
  const token = localStorage.getItem("token");
  const coupon: TCoupon = await axios
    .put(`${apiUrlCoupon}`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data.payload);

  return coupon;
};

export const useCouponUser = () => {
  const token = localStorage.getItem("token");

  return useQuery<TCoupon>(
    ["useCouponUser", token],
    async () => {
      const res = await axios.get(`${apiUrlCoupon}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data.payload;
    },
    {
      // The query will not execute until the oid exists
      enabled: !!token,
    }
  );
};
