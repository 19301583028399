import { addWeeks, isBefore } from "date-fns";
import React, { useEffect, useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { icons } from "../../../assets/icons";
import { Button } from "../../../components/Button";
import { Label } from "../../../components/Label";
import { Snackbar } from "../../../components/Snackbar";
import { theme } from "../../../components/theme";
import { useAppState } from "../../../contexts";
import {
  useHolidays,
  useResetHolidays,
  useUpdateHolidays,
} from "../../../hooks/Holidays";
import { TUserPlan } from "../../../types/Plan";
import { formatDate } from "../../../utils/i18n";

interface Props {
  userPlan?: TUserPlan;
}

export const Holidays: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const { data: holidays, refetch } = useHolidays();
  const [to, setTo] = useState<Date | undefined>();
  const [from, setFrom] = useState<Date | undefined>();
  const {
    mutate: mutateUpdate,
    isSuccess: isSuccessUpdate,
    error: errorUpdate,
  } = useMutation(useUpdateHolidays);
  const {
    mutate: mutateReset,
    isSuccess: isSuccessReset,
    error: errorReset,
  } = useMutation(useResetHolidays);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (holidays) {
      holidays.start_at && setFrom(new Date(holidays.start_at));
      holidays.end_at && setTo(new Date(holidays.end_at));
    }
  }, [holidays]);

  useEffect(() => {
    if (isSuccessReset || isSuccessUpdate) {
      refetch();
      queryClient.refetchQueries(["useNextDeliveries"], { active: true });
      queryClient.refetchQueries(["useProcessingOrders"], { active: true });
    }
  }, [isSuccessReset, isSuccessUpdate]);

  const onReset = () => {
    mutateReset();
    setFrom(undefined);
    setTo(undefined);
  };

  const onSubmitHandler = () => {
    if (from && to) {
      const start_at = isBefore(from, new Date()) ? new Date() : from;
      mutateUpdate({ start_at, end_at: to });
    }
  };

  const handleDayClick = (day: Date) => {
    const range = DateUtils.addDayToRange(day, { from, to });
    if (range.from) setFrom(range.from);
    if (range.to) setTo(range.to);
  };

  return (
    <Card>
      <ColumnWrapper>
        <TitleLabel size="mdx">{t("Info.holidays.title")}</TitleLabel>
        <HolidaysWrapper>
          {holidays && holidays.start_at && holidays.end_at ? (
            <>
              <BoldLabel size="md">{t("Info.holidays.scheduled")}</BoldLabel>
              <BaseLabel size="md">
                {t("Info.holidays.scheduled.date", {
                  start: formatDate(locale, new Date(holidays.start_at)),
                  end: formatDate(locale, new Date(holidays.end_at)),
                })}
              </BaseLabel>
            </>
          ) : (
            <BaseLabel size="md">{t("Info.holidays.select")}</BaseLabel>
          )}
        </HolidaysWrapper>
        <IconWrapper>
          <icons.pause fill={theme.colors.base.secondary} />
        </IconWrapper>
      </ColumnWrapper>
      <DayPickerWrapper>
        <DesktopDayPicker>
          <DayPicker
            disabledDays={[
              {
                before: new Date(),
                after: addWeeks(new Date(), 4),
              },
            ]}
            locale="fr"
            fromMonth={new Date()}
            toMonth={addWeeks(new Date(), 4)}
            className="Selectable"
            numberOfMonths={2}
            selectedDays={[from, { from, to }]}
            modifiers={{ from, to }}
            onDayClick={handleDayClick}
          />
        </DesktopDayPicker>
        <MobileDayPicker>
          <DayPicker
            disabledDays={[
              {
                before: new Date(),
                after: addWeeks(new Date(), 4),
              },
            ]}
            locale="fr"
            fromMonth={new Date()}
            toMonth={addWeeks(new Date(), 4)}
            className="Selectable"
            numberOfMonths={2}
            selectedDays={[from, { from, to }]}
            modifiers={{ from, to }}
            onDayClick={handleDayClick}
          />
        </MobileDayPicker>
        <ButtonWrapper>
          <Button primary onClick={onReset}>
            {t("Info.holidays.reset")}
          </Button>
          <Button secondary onClick={onSubmitHandler}>
            {t("Info.holidays.validate")}
          </Button>
        </ButtonWrapper>
      </DayPickerWrapper>
      {isSuccessUpdate && (
        <Snackbar type="success" text={t("Info.holidays.validate.success")} />
      )}
      {errorUpdate && (
        <Snackbar
          type="error"
          text={t("Info.holidays.reset.error")}
          error={errorUpdate}
        />
      )}
      {isSuccessReset && (
        <Snackbar type="success" text={t("Info.holidays.reset.success")} />
      )}
      {errorReset && (
        <Snackbar
          type="error"
          text={t("Info.holidays.validate.error")}
          error={errorReset}
        />
      )}
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing[2]} ${theme.spacing[1.5]};
  background: ${theme.colors.base.white};
  border: 2px solid ${theme.colors.base.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (min-width: ${theme.breakpoints.sm}) {
    padding-right: ${theme.spacing[2]};
    border-right: 1px solid ${theme.colors.base.grey};
  }
`;

const HolidaysWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: ${theme.spacing[1.5]} 0;
`;

const IconWrapper = styled.div`
  width: 57px;
  height: 57px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.accent.secondary};
  border-radius: 50%;

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 77px;
    height: 77px;
  }
`;

const TitleLabel = styled(Label)`
  margin-bottom: ${theme.spacing[1.5]};
  line-height: ${theme.font.size.lg};
  font-weight: ${theme.font.weight.bold};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.secondary};
`;

const BaseLabel = styled(Label)`
  line-height: ${theme.font.size.mdx};
  font-family: ${theme.font.family.secondary};
`;

const BoldLabel = styled(BaseLabel)`
  font-weight: ${theme.font.weight.bold};
`;

const DesktopDayPicker = styled.div`
  display: none;
  @media (min-width: ${theme.breakpoints.sm}) {
    display: flex;
  }
`;

const MobileDayPicker = styled.div`
  margin-top: ${theme.spacing[3]};
  @media (min-width: ${theme.breakpoints.sm}) {
    display: none;
  }
`;

const DayPickerWrapper = styled.div`
  .DayPicker-Day {
    outline: none;
  }

  .DayPicker-Day--today {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 1px solid ${theme.colors.base.primary};
    border-radius: 50%;

    &[aria-disabled="true"] {
      border: none;
    }
  }

  .DayPicker-Day:not(.DayPicker-Day--disabled) {
    color: #444444;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${theme.colors.base.primary};
    opacity: 0.7;
    color: ${theme.colors.base.white};
    border-radius: 0;

    &.DayPicker-Day--from {
      opacity: 1;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    &.DayPicker-Day--to {
      opacity: 1;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${theme.colors.accent.primary};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${theme.spacing[1]};

  > button {
    margin-left: ${theme.spacing[1]};
    margin-right: ${theme.spacing[1]};

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    > button {
      flex: 1;
    }
  }
`;
