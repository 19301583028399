import clsx from "clsx";
import React from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { alpha, theme } from "../theme";

export type SelectOption = {
  label: string;
  value: string | number;
};

interface Props {
  id?: string;
  name: string;
  options: SelectOption[];
  value?: string;
  defaultValue?: string | number;
  error?: boolean;
  onChange: (value: string) => void;
  className?: string;
  register: any;
  validation: any;
}

export const FormRadio: React.FC<Props> = ({
  id,
  name,
  options,
  defaultValue,
  value,
  error,
  onChange,
  className,
  register,
  validation,
}) => {
  const uniqId = uuid();

  const onValueChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange(evt.currentTarget.value);
  };

  if (options.length === 0) return null;

  return (
    <RadioWrapperStyled>
      {options.map((option, index) => (
        <RadioStyled key={index}>
          <input
            id={`${id}-${uniqId}-${option.value}`}
            className={clsx(error && "error", className)}
            type="radio"
            name={`${uniqId}-${name}`}
            value={option.value}
            checked={value === option.value}
            onChange={onValueChange}
            ref={register(validation)}
          />
          <LabelStyled htmlFor={`${id}-${uniqId}-${option.value}`}>
            {option.label}
          </LabelStyled>
        </RadioStyled>
      ))}
    </RadioWrapperStyled>
  );
};

const RadioWrapperStyled = styled.div`
  position: relative;

  & > * + * {
    margin-top: ${theme.spacing[0.5]};
  }
`;

const RadioStyled = styled.div`
  display: flex;
  align-items: center;

  & > * {
    cursor: pointer;
  }

  & > input:checked ~ label {
    font-weight: ${theme.font.weight.bold};
  }

  input {
    display: none;
  }

  & > input:checked ~ label:after {
    display: block;
  }
`;

const LabelStyled = styled.label`
  position: relative;
  padding-left: ${theme.spacing[1.5]};

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 14px;
    height: 14px;
    border-radius: ${theme.border.radius.round};
    border: 2px solid ${theme.colors.accent.third};
  }

  &:after {
    content: "";
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: ${theme.border.radius.round};
    background-color: ${theme.colors.base.dark};
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px ${theme.colors.accent.secondary};
  }

  &.error {
    box-shadow: 0 0 0 2px ${theme.banner.error.color};
    background-color: ${alpha(
      theme.banner.error.color,
      theme.transparency.high
    )};
  }
`;
