import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

import { Navigation } from "../../components/Navigation";
import { NavigationMobile } from "../../components/Navigation/NavigationMobile";
import { Section } from "../../components/Section";
import config from "../../config";
import { useAppState } from "../../contexts";
import { useBanner } from "../../hooks/Banners";
import { FlashBanner } from "../FlashBanner";
import { spacing, theme } from "../theme";
import { useUser } from "../../hooks/Users";

interface Props {
  className?: string;
}

export const Header: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const [lang] = locale.split("-");
  const { data: banner } = useBanner();
  const { data: user } = useUser();

  let logoLink = "/"
  if(user) logoLink ="/dashboard"

  return (
    <>
      {banner && (
        <FlashBanner times={0} type="yellow" fix>
          {ReactHtmlParser(banner?.copy[lang].text)}
        </FlashBanner>
      )}
      <HeaderStyled>
        <SectionStyled size="lg">
          <LeftSide>
            <RouterLink to={logoLink}>
              <ImgStyled
                src={`${config.CDN_URL}/common/images/logo1.png`}
                alt={t(`Footer.info.logo-alt`)}
                loading="lazy"
              />
            </RouterLink>
          </LeftSide>
          <RightSide>
            <Navigation />
            <NavigationMobile />
          </RightSide>
        </SectionStyled>
      </HeaderStyled>
    </>
  );
};

export const HeaderMemoized = React.memo(Header);

const HeaderStyled = styled.header`
  display: flex;
  justify-content: center;
  padding: ${theme.spacing[1.5]} ${theme.spacing[2]};
`;

const SectionStyled = styled(Section)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
`;

const LeftSide = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const RightSide = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ImgStyled = styled.img`
  height: ${spacing(7)};
  color: ${theme.colors.base.dark};
  fill: currentColor;

  &:hover {
    color: ${theme.colors.accent.secondary};
  }
`;
