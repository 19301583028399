import { useQuery } from "react-query";
import axios from "redaxios";
import config from "../../config";
import { TBanner } from "../../types/Banner";

type Params = Record<string, string | number | boolean | null | undefined>;

const apiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/banners`;

export const useBanner = () => {
  return useQuery<TBanner>(["useBanner"], () =>
    axios
      .get(`${apiUrl}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data.payload)
  );
};
