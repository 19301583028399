import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { AlternateSection } from "../../components/AlternateSection";
import { FlashBanner } from "../../components/FlashBanner";
import { FlashBannerReactivate } from "../../components/FlashBannerReactivate";
import { Page } from "../../components/Page";
import { Snackbar } from "../../components/Snackbar";
import { theme } from "../../components/theme";
import { WithAuth } from "../../components/WithAuth";
import { useDelivery } from "../../hooks/Delivery";
import { useSubscription } from "../../hooks/Subscriptions";
import { Navigation } from "../Navigation";
import { PrimeSelection } from "./PrimeSelection";
import { PrimePitch } from "./PrimePitch";
import { useHighlightedProducts } from "../../hooks/Products";
import { Product } from "../../components/Product";
import { SavingDone } from "../../components/SavingDone/SavingDone";

export const Prime: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: products = [] } = useHighlightedProducts("msl");

  const { data: subscription, error: errorSub } = useSubscription();
  const { data: delivery, error: errorDelivery } = useDelivery();
  const [selectedPrime, setSelectedPrime] = useState("yearly-prime");
  useEffect(() => {
    if (subscription && subscription?.plans?.length === 0) {
      return history.push("/sign-up/step-2");
    }

    if (subscription && !subscription?.lastDigits) {
      return history.push("/sign-up/confirm");
    }

    if (subscription?.status === "cancel") {
      return history.push("/dashboard");
    }
  }, [subscription]);

  return (
    <WithAuth>
      <Page centerX centerY backgroundColor={theme.colors.base.background}>
        {subscription?.status === "cancel" && (
          <FlashBannerReactivate times={10} type="normal">
            {t("Subscription.cancelled")}
          </FlashBannerReactivate>
        )}
        {delivery?.entity_type === "PUP" && !delivery.open && (
          <FlashBanner times={10} type="yellow">
            {t("Dashboard.pup-closed")}
          </FlashBanner>
        )}
        <AlternateSection>
          <Navigation />

          {subscription?.is_prime && (
            <SavingDoneStyled>
              <SavingDone />
            </SavingDoneStyled>
          )}
          <DashboardWrapper>
            <PrimePitch
              isShown
              backgroundColor={theme.colors.base.white}
              selectedPrime={selectedPrime}
              withTitle
              datas={[{ text: "Prime.offer.1" }, { text: "Prime.offer.3" }]}
              title={"Prime.offer"}
            />

            <SummaryWrapper>
              <PrimeSelection
                onSelect={(lookup_key) => {
                  setSelectedPrime(lookup_key);
                }}
              />
            </SummaryWrapper>
          </DashboardWrapper>
          <ProductsWrapper>
            {products.slice(0, 4).map((product) => (
              <Product
                isPreOrder={false} //TODO
                withBanner={false}
                key={product.pid}
                product={product}
                onSelectProduct={() => {}}
              />
            ))}
          </ProductsWrapper>

          {errorSub && <Snackbar type="error" error={errorSub} />}
          {errorDelivery && <Snackbar type="error" error={errorDelivery} />}
        </AlternateSection>
      </Page>
    </WithAuth>
  );
};

const SavingDoneStyled = styled.div`
  margin-top: ${theme.spacing[1.5]};
`;
const ProductsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${theme.spacing[1.5]};
  align-self: center;
  margin-top: ${theme.spacing[1.5]};

  @media (min-width: ${theme.breakpoints.sm}) {
    width: calc(2 * 256px + 20px);
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${theme.breakpoints.md}) {
    width: calc(3 * 256px + 2 * 20px);
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: ${theme.breakpoints.lg}) {
    width: calc(4 * 256px + 3 * 20px);
    grid-template-columns: repeat(4, 1fr);
  }
`;

const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[1.5]};
  @media (min-width: ${theme.breakpoints.md}) {
    flex-direction: row;
  }
`;

const SummaryWrapper = styled.div`
  flex: 35;
  margin-top: ${theme.spacing[0.5]};

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-left: ${theme.spacing[1.5]};
    margin-top: 0;
  }
`;
