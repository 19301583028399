import clsx from "clsx";
import React, { useState } from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { icons } from "../../assets/icons";
import { theme } from "../theme";

export type SelectOption = {
  label: string;
  value: string | number;
  complement: string | number;
};

interface Props {
  id?: string;
  name: string;
  options: SelectOption[];
  type?: "string" | "number";
  value?: string | number;
  error?: boolean;
  onChange: (value: string | number) => void;
  className?: string;
  register: any;
  validation: any;
}

export const FormTagRadio: React.FC<Props> = ({
  id,
  name,
  options,
  type,
  value,
  error,
  onChange,
  className,
  register,
  validation,
}) => {
  const uniqId = uuid();

  const onValueChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange(
      type === "string" ? evt.currentTarget.value : +evt.currentTarget.value
    );
  };

  if (options.length === 0) return null;

  return (
    <RadioWrapperStyled>
      {options.map((option, index) => (
        <RadioStyled
          key={index}
          className={value === option.value ? "selected" : "unselected"}
        >
          <input
            id={`${id}-${uniqId}-${option.value}`}
            className={clsx(error && "error", className)}
            type="radio"
            name={`${uniqId}-${name}`}
            value={option.value}
            checked={value === option.value}
            onChange={onValueChange}
            ref={register && validation ? register(validation) : null}
          />

          <LabelStyled htmlFor={`${id}-${uniqId}-${option.value}`}>
            <IconWrapper>
              <icons.radioSelected className="selected" />
              <icons.radioUnselected className="unselected" />
            </IconWrapper>
            {option.label}
            {option.complement && <span>{option.complement}</span>}
          </LabelStyled>
        </RadioStyled>
      ))}
    </RadioWrapperStyled>
  );
};

const RadioWrapperStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[0.25]};

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
    margin-top: 0;
  }
`;

const RadioStyled = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;
  margin: ${theme.spacing[0.5]} 0;
  cursor: pointer;

  > input {
    display: none;
  }

  > input:checked ~ label > div {
    .selected {
      opacity: 1;

      path {
        fill: ${theme.colors.base.third};
      }
    }

    .unselected {
      opacity: 0;
    }
  }

  > input:checked ~ label {
    color: ${theme.colors.base.white};
    background: ${theme.colors.base.secondary};
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    margin: 0 ${theme.spacing[0.5]};
  }
`;

const IconWrapper = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: ${theme.spacing[1.5]};

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity ease-in 0.3s;

    path {
      fill: ${theme.colors.base.secondary};
    }

    &.selected {
      opacity: 0;
    }

    &.unselected {
      opacity: 1;
    }
  }
`;
const LabelStyled = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${theme.spacing[1]} ${theme.spacing[2]} ${theme.spacing[1]}
    ${theme.spacing[1]};
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.black};
  font-size: ${theme.font.size.mdx};
  line-height: ${theme.font.size.xl};
  color: ${theme.colors.base.secondary};
  border: 1px solid ${theme.colors.base.white};
  border-radius: 64px;
  transition: background ease-in 0.3s, color ease-in 0.3s;
  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: hidden; /* Ensures overflowing text is hidden */
  text-overflow: ellipsis;
  span {
    padding-left: ${theme.spacing[1.5]};
    white-space: nowrap; /* Prevents the text from wrapping */
    overflow: hidden; /* Ensures overflowing text is hidden */
    text-overflow: ellipsis; /* Adds an ellipsis when text overflows */
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.lg};
  }
`;
